<div>
  <ng-container *ngIf="kiosks; else loading">
    <h5 *ngIf="!multiple">Select a Kiosk to continue</h5>
    <h5 *ngIf="multiple">Select Kiosks to continue</h5>
    <ng-container *ngIf="kiosks.length; else noKiosks">

      <p>{{ instruction }}</p>
      <mat-selection-list #selectionList [multiple]="multiple">
        <mat-list-option *ngFor="let kiosk of kiosks" [value]="kiosk">
          {{ kiosk.name }} — {{ kiosk.username }}
        </mat-list-option>
      </mat-selection-list>

      <mat-dialog-actions>
        <button color="primary" [disabled]="!selectionList._value?.length" [mat-dialog-close]="selectionList._value" mat-raised-button>Done</button>
      </mat-dialog-actions>

    </ng-container>
    <ng-template #noKiosks>
      No kiosks available to select
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <ng-container *ngIf="loadingError; else pleaseWait">{{ loadingError }}</ng-container>
    <ng-template #pleaseWait>
      Loading Kiosks...
    </ng-template>
  </ng-template>

</div>
