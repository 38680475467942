import { Component } from '@angular/core';
import { SelfCheckoutTemplateResponse } from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { ResourceListConfig } from 'esuite-dashboard/app/shared/components/resource-list/resource-list.component';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';

@Component({
  selector: 'app-self-checkout-template',
  template: `
    <app-list-sidebar-detail
      #view
      [listConfig]="listConfig"
      title="Self Checkout Templates"
      [(id)]="id"
    >
      <app-self-checkout-template-detail
        (updated)="view.list.updateRowBy('id', $event)"
        (created)="view.list.appendRow($event)"
        [id]="id"
      >
      </app-self-checkout-template-detail>
    </app-list-sidebar-detail>
  `,
})
export class SelfCheckoutTemplateComponent {
  id = -1;

  listConfig: ResourceListConfig<SelfCheckoutTemplateResponse> = {
    cols: [{ key: 'name', header: 'Name' }],
    edit: (tpl) => {
      this.id = tpl.id;
    },
    delete: (tpl) =>
      this.alertService.confirmLoaderAndResult(
        'Are you sure you want to remove this?',
        () =>
          getEsuiteApi()
            .selfCheckoutTemplate.remove(tpl.id)
            .then(() => true),
        'Template removed successfully',
        'Error removing template'
      ),
    rowLoader: () => {
      return getEsuiteApi().selfCheckoutTemplate.list();
    },
  };

  constructor(private alertService: AlertService) {}
}
