import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { Router } from '@angular/router';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { EsuiteApp } from 'esuite-client';

@Component({
  selector: 'app-directory-create',
  templateUrl: './directory-create.component.html',
  styles: [],
})
export class DirectoryCreateComponent implements OnInit {
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.makeCreateForm();
  }

  private makeCreateForm() {
    return this.fb.group({
      name: [null, Validators.required],
    });
  }

  async create() {
    this.form.disable();
    const createdDirectory = await this.alertService
      .runWithLoader(
        () => getEsuiteApi().directory.create(this.form.value),
        'Directory created successfully',
        'Error creating kiosk'
      )
      .finally(() => {
        this.form.enable();
      });
    this.router.navigate([EsuiteApp.Directory, createdDirectory.id]);
  }
}
