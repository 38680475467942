import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCategoryEditorComponent } from 'esuite-dashboard/app/pages/product-category/editor/product-category-editor.component';
import { ProductCategoryListComponent } from 'esuite-dashboard/app/pages/product-category/list/product-category-list.component';
import { ProductCategoryPageRoutingModule } from 'esuite-dashboard/app/pages/product-category/product-category-page-routing.module';

import { SharedModule } from 'esuite-dashboard/app/shared/shared.module';
import { CategoryIntegrationSelectorComponent } from 'esuite-dashboard/app/pages/product-category/components/category-integration-selector/category-integration-selector.component';
import { ProductCategoryIntegrationComponent } from './components/product-category-integration-filter/product-category-integration.component';



@NgModule({
  declarations: [
    CategoryIntegrationSelectorComponent,
    ProductCategoryEditorComponent,
    ProductCategoryListComponent,
    ProductCategoryIntegrationComponent,
  ],
  imports: [
    CommonModule,
    ProductCategoryPageRoutingModule,

    SharedModule,
  ],
})
export class ProductCategoryModule { }
