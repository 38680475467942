import { Component, OnInit } from '@angular/core';

@Component({
  template: `
    <h3 *ngIf="title" mat-dialog-title>{{ title }}</h3>
    <div class="p-2">
      <mat-spinner
        class="mx-auto"
        [diameter]="40"
        *ngIf="spinner"
      ></mat-spinner>
    </div>
    <div
      [ngClass]="spinner ? 'text-center mt-3' : ''"
      *ngIf="message"
      [innerHTML]="message | safe: 'html'"
    ></div>
    <mat-dialog-actions *ngIf="!spinner" align="end">
      <button
        *ngIf="!confirm"
        mat-button
        [mat-dialog-close]="true"
        cdkFocusInitial
      >
        Done
      </button>
      <button *ngIf="confirm" [mat-dialog-close]="false" mat-button>
        Cancel
      </button>
      <button
        *ngIf="confirm"
        [mat-dialog-close]="true"
        mat-raised-button
        color="primary"
      >
        OK
      </button>
    </mat-dialog-actions>
  `,
  styles: [``],
})
export class AlertComponent implements OnInit {
  message: string;
  title: string;
  confirm: boolean;

  spinner = false;

  ngOnInit(): void {}
}
