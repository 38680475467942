/*
 * FundListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceActionColumn,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceListViewRow,
  CadenceStackViewModel,
  CadenceStackViewTab,
  CadenceStackViewChild,
} from 'cadence';
import { FundCreateForm } from './fund-create';
import {
  FundDetailView,
} from '../index';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { FundResponse, RoleName } from 'esuite-client';

@CadenceView({
  title: 'Funds',
  showInMenu: '/self-checkouts',
})
export class FundResource extends CadenceStackViewModel {
  @CadenceStackViewTab(() => FundResource, ['fundList'])
  items: string;

  @CadenceStackViewChild(() => FundListView)
  fundList() {
    const list = new FundListView();
    return list;
  }
}

@CadenceView()
export class FundListView extends CadenceListViewModel<FundResponse> {
  @CadenceColumn(() => FundListView, {
    value: (row) =>
      row.image?.url
        ? `<div class="p-2"><img class="cad-thumbnail" src="${row.image.url}"></div>`
        : '',
  })
  image: number;

  @CadenceColumn()
  title: string;

  @CadenceAction({ label: 'Create' })
  createForm(): void {
    cadenceRouter.navigate(FundCreateForm, {
      from: this,
      events: {
        'action.create': 'created',
      },
    });
  }

  // @CadenceActionColumn({ label: 'Duplicate', header: '' })
  // async duplicateForm(row: CadenceListViewRow<FundListView>): Promise<void> {
  //   const dupRow = await getEsuiteApi().fund.duplicate(row.id);
  //   this._rows$.add(dupRow);
  // }

  @CadenceActionColumn({ label: 'Edit', header: '' })
  updateForm(row: CadenceListViewRow<FundListView>): void {
    cadenceRouter.navigate(FundDetailView, {
      from: this,
      params: {
        id: row.id,
      },
      events: {
        'action.update': 'updated',
      },
    });
  }

  @CadenceActionColumn({ label: 'Delete', header: '' })
  deleteForm(row: CadenceListViewRow<FundListView>) {
    return getEsuiteApi().fund.remove(row.id);
  }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  @CadenceViewEvent('updated')
  update(event: any): void {
    this._rows$.updateBy((c) => c.id === event.data.id, event.data);
  }

  @CadenceViewEvent('deleted')
  remove(event: any): void {
    this._rows$.deleteBy((c) => c.id === event.from._params.id);
  }

  async _init(): Promise<void> {
    this._rows$.set(await getEsuiteApi().fund.list());
  }
}
