<ng-container *ngIf="(active$ | async)">
    <ng-container [ngSwitch]="cadFormControl.controlType">


        <!-- CadenceFormControlType.Text -->
        <div class="px-2" *ngSwitchDefault>
            <mat-form-field appearance="outline">
                <mat-label>{{ cadFormControl.label }}</mat-label>
                <input
                  [disabled]="(disabled$ | async)"
                        matInput
                        type="text"
                        [name]="cadFormControl.name"
                        [formControl]="control"
                        [placeholder]="cadFormControl.label"
                />
            </mat-form-field>
        </div>


        <!-- CadenceFormControlType.Html -->
        <div class="px-2 form-group" *ngSwitchCase="ControlType.Html">
          <h5>{{ cadFormControl.label }}</h5>
          <mat-tab-group>
            <mat-tab label="Edit">
              <div #htmlEditor style="height: 540px;"></div>
            </mat-tab>
            <mat-tab label="View">
              <div style="height: 540px;" class="p-4 border">
                <iframe style="width: 100%; height: 100%; border: none;" [srcdoc]="renderHtml(control.value) | safe:'html'"></iframe>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

      <!-- CadenceFormControlType.Html -->
      <div class="px-2 form-group" *ngSwitchCase="ControlType.Textarea">
        <label>{{ cadFormControl.label }}</label>
        <textarea [formControl]="control" rows="5" class="d-block w-100"></textarea>
      </div>


        <!-- CadenceFormControlType.Date -->
        <div class="px-2" *ngSwitchCase="ControlType.Date">
            <mat-form-field appearance="outline">
                <mat-label>{{ cadFormControl.label }}</mat-label>
                <input
                  [disabled]="(disabled$ | async)"
                  matInput
                  type="date"
                  [name]="cadFormControl.name"
                  [formControl]="control"
                  [placeholder]="cadFormControl.label"
                />
            </mat-form-field>
        </div>


      <!-- CadenceFormControlType.Message -->
      <div class="px-2" *ngSwitchCase="ControlType.Message" [innerHTML]="control.value"></div>



        <!-- CadenceFormControlType.DatetimeLocal -->
        <div class="px-2" *ngSwitchCase="ControlType.DatetimeLocal">
            <mat-form-field appearance="outline">
                <mat-label>{{ cadFormControl.label }}</mat-label>
                <input
                  [disabled]="(disabled$ | async)"
                  matInput
                  type="datetime-local"
                  [name]="cadFormControl.name"
                  [formControl]="control"
                  [placeholder]="cadFormControl.label"
                />
            </mat-form-field>
        </div>



        <!-- CadenceFormControlType.Password -->
        <div class="px-2" *ngSwitchCase="ControlType.Password">
            <mat-form-field appearance="outline">
                <mat-label>{{ cadFormControl.label }}</mat-label>
                <input
                  autocomplete="off"
                  [disabled]="(disabled$ | async)"
                  matInput
                  type="password"
                  [name]="cadFormControl.name"
                  [formControl]="control"
                  [placeholder]="cadFormControl.label"
                />
            </mat-form-field>
        </div>




        <!-- CadenceFormControlType.Email -->
        <div class="px-2" *ngSwitchCase="ControlType.Email">
            <mat-form-field appearance="outline">
                <mat-label>{{ cadFormControl.label }}</mat-label>
                <input
                  [disabled]="(disabled$ | async)"
                  matInput
                  type="email"
                  [name]="cadFormControl.name"
                  [formControl]="control"
                  [placeholder]="cadFormControl.label"
                />
            </mat-form-field>
        </div>




        <!-- CadenceFormControlType.Number -->
        <div class="px-2" *ngSwitchCase="ControlType.Number">
            <mat-form-field appearance="outline">
                <mat-label>{{ cadFormControl.label }}</mat-label>
                <input
                  [disabled]="(disabled$ | async)"
                  matInput
                  type="number"
                  [name]="cadFormControl.name"
                  [formControl]="control"
                  [placeholder]="cadFormControl.label"
                />
            </mat-form-field>
        </div>




        <!-- CadenceFormControlType.Tel -->
        <div class="px-2" *ngSwitchCase="ControlType.Tel">
            <mat-form-field appearance="outline">
                <mat-label>{{ cadFormControl.label }}</mat-label>
                <input
                  [disabled]="(disabled$ | async)"
                  matInput
                  type="tel"
                  [name]="cadFormControl.name"
                  [formControl]="control"
                  [placeholder]="cadFormControl.label"
                />
            </mat-form-field>
        </div>



        <!-- CadenceFormControlType.Url -->
        <div class="px-2" *ngSwitchCase="ControlType.Url">
            <mat-form-field appearance="outline">
                <mat-label>{{ cadFormControl.label }}</mat-label>
                <input
                  [disabled]="(disabled$ | async)"
                  matInput
                  type="url"
                  [name]="cadFormControl.name"
                  [formControl]="control"
                  [placeholder]="cadFormControl.label"
                />
            </mat-form-field>
        </div>






        <!-- CadenceFormControlType.Time -->
        <div class="px-2" *ngSwitchCase="ControlType.Time">
            <mat-form-field appearance="outline">
                <mat-label>{{ cadFormControl.label }}</mat-label>
                <input
                  [disabled]="(disabled$ | async)"
                  matInput
                  type="time"
                  [name]="cadFormControl.name"
                  [formControl]="control"
                  [placeholder]="cadFormControl.label"
                />
            </mat-form-field>
        </div>




        <!-- CadenceFormControlType.Month -->
        <div class="px-2" *ngSwitchCase="ControlType.Month">
            <mat-form-field appearance="outline">
                <mat-label>{{ cadFormControl.label }}</mat-label>
                <input
                  [disabled]="(disabled$ | async)"
                  matInput
                  type="month"
                  [name]="cadFormControl.name"
                  [formControl]="control"
                  [placeholder]="cadFormControl.label"
                />
            </mat-form-field>
        </div>





        <!-- CadenceFormControlType.File -->
        <div  *ngSwitchCase="ControlType.File">
            <label>{{ cadFormControl.label }}</label>
            <input
                  [disabled]="(disabled$ | async)"
                    type="file"
                    [name]="cadFormControl.name"
                    (change)="control.setValue($event.target.files[0])"
                    [placeholder]="cadFormControl.label"
            />
        </div>



        <!-- CadenceFormControlType.Select -->
        <div class="px-2"  *ngSwitchCase="ControlType.Select">
            <mat-form-field appearance="outline">
                <mat-label>{{ cadFormControl.label }}</mat-label>
                <mat-select [disabled]="(disabled$ | async)" [multiple]="cadFormControl.multiple" [formControl]="control">
                    <mat-option *ngFor="let option of (options$ | async)" [value]="option.value">
                        <span [innerHTML]="option.label"></span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>



        <!-- CadenceFormControlType.Checkbox -->
        <div class="px-2"  *ngSwitchCase="ControlType.Checkbox">
            <mat-checkbox [disabled]="(disabled$ | async)" class="example-margin" [formControl]="control">
                {{ cadFormControl.label }}
            </mat-checkbox>
        </div>


        <!-- CadenceFormControlType.Checkbox -->
        <div class="px-2"  *ngSwitchCase="ControlType.ContentEditor">
          <ng-container>
            <quill-editor [disabled]="(disabled$ | async)" [formControl]="control"></quill-editor>
          </ng-container>
        </div>


        <!-- CadenceFormControlType.Custom -->
        <div class="p-2 mb-1"  *ngSwitchCase="ControlType.Custom">
          <ng-template #componentTarget></ng-template>
        </div>


    </ng-container>
</ng-container>
