import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'esuite-dashboard/app/shared/shared.module';
import { SpinToWinListComponent } from './spin-to-win-list/spin-to-win-list.component';
import { SpinToWinDetailComponent } from './spin-to-win-detail/spin-to-win-detail.component';
import { SpinToWinRoutingModule } from './spin-to-win-routing.module';
import { AppModule } from 'esuite-dashboard/app/app.module';

@NgModule({
  declarations: [SpinToWinListComponent, SpinToWinDetailComponent],
  imports: [
    CommonModule,
    SpinToWinRoutingModule,

    SharedModule,
  ],
})
export class SpinToWinModule {}
