import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectoryRoutingModule } from './directory-routing.module';
import { DirectoryListComponent } from './directory-list/directory-list.component';
import { DirectoryDetailComponent } from './directory-detail/directory-detail.component';
import { DirectoryFloorDetailComponent } from './floors/directory-floor-detail/directory-floor-detail.component';
import { DirectoryCreateComponent } from './directory-create/directory-create.component';
import { SharedModule } from 'esuite-dashboard/app/shared/shared.module';
import { DirectoryFloorListComponent } from './floors/directory-floor-list/directory-floor-list.component';
import { DirectoryListingListComponent } from './listings/directory-listing-list/directory-listing-list.component';
import { DirectoryListingCreateComponent } from './listings/directory-listing-create/directory-listing-create.component';
import { DirectoryFloorCreateComponent } from 'esuite-dashboard/app/pages/v2/directory/floors/directory-floor-create/directory-floor-create.component';
import { DirectoryListingDetailComponent } from 'esuite-dashboard/app/pages/v2/directory/listings/directory-listing-detail/directory-listing-detail.component';
import { DirectoryActivationDetailComponent } from './activations/directory-activation-detail/directory-activation-detail.component';
import { DirectoryActivationListComponent } from 'esuite-dashboard/app/pages/v2/directory/activations/directory-activation-list/directory-activation-list.component';

@NgModule({
  declarations: [
    // Directory
    DirectoryListComponent,
    DirectoryCreateComponent,
    DirectoryDetailComponent,
    // DirectoryListing
    DirectoryListingListComponent,
    DirectoryListingCreateComponent,
    DirectoryListingDetailComponent,
    // DirectoryFloor
    DirectoryFloorListComponent,
    DirectoryFloorCreateComponent,
    DirectoryFloorDetailComponent,
    // Directory Kiosk Activations
    DirectoryActivationListComponent,
    DirectoryActivationDetailComponent,
  ],
  imports: [DirectoryRoutingModule, CommonModule, SharedModule],
})
export class DirectoryModule {}
