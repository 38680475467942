import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ComponentIOStrategy, createComponentIO } from 'esuite-util/components';
import { memoize } from 'lodash';
import { JSONPath } from 'jsonpath-plus-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {
  LinkOptions,
  ResourceListColumn,
  ResourceListRow,
  ResourceListRowClickEvent,
} from './resource-list.types';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { PageEvent } from '@angular/material/paginator';

export type Unarray<T> = T extends (infer U)[] ? U : T;

export interface ResourceListConfig<R> {
  cols: ResourceListColumn<R>[];
  delete?: (item: R, ind: number, items: R[]) => Promise<any> | any;
  view?: (item: R, ind: number, items: R[]) => Promise<any> | any;
  edit?: (item: R, ind: number, items: R[]) => Promise<any> | any;
  links?: {
    label: string;
    onClick: (item: R, ind: number, items: R[]) => Promise<any> | any;
  }[];
  rowLoader: (pageParameters?: {
    skip: number;
    limit: number;
  }) => Promise<R[]> | R[];
  afterRowLoader?: (rows: R[]) => Promise<void> | void;
}

interface Pagination {
  pagination?: {
    count: number;
    total: number;
    skip: number;
    limit: number;
    nextPageParameters?: { skip: number; limit: number };
    previousPageParameters?: { skip: number; limit: number };
  };
}

const defaultConfig: ResourceListConfig<any> = {
  cols: [],
  // rowActions: [],
  rowLoader: () => [],
};

/*
Generic component for rendering CRUD tables
 */
@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss'],
})
export class ResourceListComponent<R> implements OnInit, OnDestroy {
  get rows(): R[] & Pagination {
    return this.rowsIO.get();
  }
  set rows(r: R[] & Pagination) {
    this.rowsIO.set(r);
  }

  showLoader = false;
  @Input()
  get config(): ResourceListConfig<R> {
    return this.configIO.get();
  }
  set config(c: ResourceListConfig<R>) {
    this.configIO.set({
      ...defaultConfig,
      ...c,
    });
  }

  @Input()
  emptyMessage = 'No items to show';

  constructor(
    private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef
  ) {}
  private rowsIO: ComponentIOStrategy<R[] & Pagination> = createComponentIO<
    R[] & Pagination
  >([]);
  rows$: Observable<R[] & Pagination> = this.rowsIO.value$;

  @Input()
  selector: boolean;

  selection = new SelectionModel<R>(true, []);
  subs: Subscription[] = [];

  /* Action Column */
  private configIO = createComponentIO<ResourceListConfig<R>>(
    defaultConfig as ResourceListConfig<R>
  );

  // Defines which cols are displayed and in what order.
  displayedCols$: Observable<string[]> = this.configIO.value$.pipe(
    filter((config) => !!config),
    map((config: ResourceListConfig<R>) => {
      let displayedCols = [
        ...config.cols.map((c, ind) => `col.${ind}`),
        ...this.getRowActions(this.config).map((key) => `action.${key}`),
        ...(config.links?.length ? ['links'] : []),
      ];
      if (this.selector) {
        displayedCols = ['selector', ...displayedCols];
      }
      return displayedCols;
    })
  );

  @Output()
  cellClick: EventEmitter<ResourceListRowClickEvent<R>> = new EventEmitter();

  cellRenderer: (
    row: ResourceListRow<R>,
    col: ResourceListColumn<R>
  ) => Promise<string> = memoize(
    async (row: ResourceListRow<R>, col: ResourceListColumn<R>) => {
      if (col.key) {
        return row[col.key];
      } else if (col.path) {
        // JSONPath stuff
        return JSONPath({ path: col.path, json: row as unknown as object });
      } else if (col.render) {
        return this.sanitizer.bypassSecurityTrustHtml(
          await col.render(row, this.rows, col)
        );
      }
      return row[col.key];
    },
    (row, col) => {
      return JSON.stringify([row, col]);
    }
  );

  getRowActions(c: ResourceListConfig<R>): ('delete' | 'view' | 'edit')[] {
    const actions = ['delete', 'view', 'edit'] as const;
    return actions.filter((k) => c[k]);
  }

  async handlePage(event: PageEvent): Promise<void> {
    if (event.pageIndex - event.previousPageIndex === 1) {
      // Next Page
      await this.runRowLoader(this.rows.pagination.nextPageParameters);
    } else if (event.pageIndex - event.previousPageIndex === -1) {
      await this.runRowLoader(this.rows.pagination.previousPageParameters);
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.rows.forEach((row) => this.selection.select(row));
  }

  handleCellClick(
    row: ResourceListRow<R>,
    col: ResourceListColumn<R>,
    event
  ): void {
    this.cellClick.emit({ row, col, event });
  }

  removeRowAt(index: number): void {
    this.rows = [...this.rows.slice(0, index), ...this.rows.slice(index + 1)];
  }

  updateRowBy(finder: ((row: R) => boolean) | string, newRow: R): void {
    const finderFn =
      typeof finder === 'string'
        ? (row) => row[finder] === newRow[finder]
        : finder;
    const ind = this.rows.findIndex(finderFn);
    this.updateRowAt(ind, newRow);
  }

  updateRowAt(ind: number, newRow: R): void {
    this.rows = [
      ...this.rows.slice(0, ind),
      newRow,
      ...this.rows.slice(ind + 1),
    ];
  }

  appendRow(row: R): void {
    this.rows = [...this.rows, row];
  }

  async handleView(item: R, itemInd: number): Promise<void> {
    await this.config.view(item, itemInd, this.rows);
  }
  async handleEdit(item: R, itemInd: number): Promise<void> {
    await this.config.edit(item, itemInd, this.rows);
  }
  async handleDelete(item: R, itemInd: number): Promise<void> {
    const deleted = await this.config.delete(item, itemInd, this.rows);
    if (deleted) {
      this.removeRowAt(itemInd);
    }
  }

  // Initialize selections and config subscriptions
  async ngOnInit(): Promise<void> {
    this.subs.push(
      this.selection.changed.subscribe(() => this.changeDetector.markForCheck())
    );
    await this.runRowLoader();
  }

  private async runRowLoader(params?): Promise<void> {
     ;
    this.showLoader = true;
    this.rows = await this.config.rowLoader();
    this.showLoader = false;
     ;
    this.config.afterRowLoader?.(this.rows);
  }

  makeCellLink: (
    row: ResourceListRow<R>,
    col: ResourceListColumn<R>
  ) => LinkOptions = (row, col) => {
    if (typeof col.link === 'function') {
      return col.link(row, this.rows, col);
    }
    return col.link;
  };

  // Destroy subscriptions
  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
