import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DirectoryFloor } from 'esuite-client';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { ActivatedRoute } from '@angular/router';
import { pluckAsInt } from 'esuite-dashboard/app/shared/utils/pluck-as-int';
import { switchMap, take, tap } from 'rxjs/operators';
import { MultiBehaviorSubject } from 'esuite-dashboard/app/shared/utils/multi-behaviorsubject';

@Component({
  selector: 'app-directory-floor-list',
  templateUrl: './directory-floor-list.component.html',
  styles: [],
})
export class DirectoryFloorListComponent {
  directoryId$ = this.activatedRoute.params.pipe(pluckAsInt('directoryId'));
  floors$: BehaviorSubject<DirectoryFloor[]> = new MultiBehaviorSubject(
    [],
    this.directoryId$.pipe(
      switchMap((directoryId) =>
        getEsuiteApi().directoryFloor.list(directoryId)
      )
    )
  );
  displayedColumns = ['name', 'actions'];
  search: string;

  constructor(
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute
  ) {}

  remove(directoryFloor: DirectoryFloor): Promise<any> {
    return this.alertService.confirmLoaderAndResult(
      'Are you sure you want to remove this directoryFloor?',
      () =>
        getEsuiteApi()
          .directoryFloor.remove(directoryFloor.id)
          .then(() => {
            // Previous list with the deleted directoryFloor removed
            this.floors$.next(
              this.floors$.value.filter((p) => p !== directoryFloor)
            );
          }),
      'Floor removed successfully',
      'Error removing Floor.'
    );
  }
}
