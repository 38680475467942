import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from 'esuite-dashboard/app/shared/components/dashboard/dashboard.component';
import { MediaCollectionListComponent } from 'esuite-dashboard/app/pages/media-collection/media-collection-list/media-collection-list.component';
import { MediaCollectionDetailComponent } from 'esuite-dashboard/app/pages/media-collection/media-collection-detail/media-collection-detail.component';
import { AuthGuard } from 'esuite-dashboard/app/shared/guards/auth-guard.service';
const routes: Routes = [
  {
    path: 'media-collections',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
      {
        path: '',
        component: MediaCollectionListComponent,
      },
      {
        path: ':mediaCollectionId',
        component: MediaCollectionDetailComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class MediaCollectionRoutingModule {}
