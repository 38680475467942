import { Component, OnInit, ViewChild } from '@angular/core';
import { ResourceListConfig } from 'esuite-dashboard/app/shared/components/resource-list/resource-list.component';
import {
  SelfCheckoutIntegrationCreateRequest,
  SelfCheckoutIntegrationResponse,
} from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelfCheckoutIntegrationDetailComponent } from 'esuite-dashboard/app/pages/self-checkout-integration/self-checkout-integration-detail/self-checkout-integration-detail.component';
import { ResourceListComponent } from 'esuite-dashboard/app/shared/components/resource-list/resource-list.component';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { CadenceActionColumn, CadenceListViewRow } from 'cadence';

@Component({
  selector: 'app-self-checkout-integration-list',
  template: `
    <div class="d-flex align-items-center justify-content-between">
      <h5>Checkout Integrations</h5>
      <button mat-raised-button color="primary" (click)="handleCreate()">
        Create
      </button>
    </div>
    <app-resource-list #integrationList [config]="config"> </app-resource-list>
  `,
})
export class SelfCheckoutIntegrationListComponent implements OnInit {
  @ViewChild('integrationList')
  integrationList: ResourceListComponent<SelfCheckoutIntegrationResponse>;
  config: ResourceListConfig<SelfCheckoutIntegrationResponse> = {
    afterRowLoader(
      rows: SelfCheckoutIntegrationResponse[]
    ): Promise<void> | void {
      return undefined;
    },
    cols: [
      {
        key: 'name',
        header: 'Integration',
      },
      { key: 'type', header: 'Type' },
    ],
    links: [
      {
        label: 'Duplicate',
        onClick: async (row) => {
          this.alertService.confirmLoaderAndResult(
            'Are you sure you want to duplicate integration <strong>' +
              row.name +
              '</strong>?',
            async () => {
              this.integrationList.appendRow(
                await getEsuiteApi().selfCheckoutIntegration.create({
                  name: 'Copy of ' + row.name,
                  type: row.type,
                  duplicateId: row.id,
                })
              );
            },
            'Integration duplicated successfully.',
            'Error duplicating integration.'
          );
        },
      },
    ],
    delete: (
      item: SelfCheckoutIntegrationResponse,
      ind: number,
      items: SelfCheckoutIntegrationResponse[]
    ) =>
      this.alertService.confirmLoaderAndResult(
        'Are you sure you want to remove this integration?',
        () =>
          getEsuiteApi()
            .selfCheckoutIntegration.remove(item.id)
            .then(() => true),
        'Integration removed successfully.',
        'Error removing integration'
      ),
    edit: (
      item: SelfCheckoutIntegrationResponse,
      ind: number,
      items: SelfCheckoutIntegrationResponse[]
    ) => {
      this.handleUpdate(item);
    },
    rowLoader(
      pageParameters: { skip: number; limit: number } | undefined
    ):
      | Promise<SelfCheckoutIntegrationResponse[]>
      | SelfCheckoutIntegrationResponse[] {
      return getEsuiteApi().selfCheckoutIntegration.list();
    },
  };

  constructor(private modal: NgbModal, private alertService: AlertService) {}

  ngOnInit(): void {}

  openIntegrationModal(
    integration?: SelfCheckoutIntegrationResponse
  ): NgbModalRef & {
    componentInstance: SelfCheckoutIntegrationDetailComponent;
  } {
    const modalRef = this.modal.open(SelfCheckoutIntegrationDetailComponent);
    const component: SelfCheckoutIntegrationDetailComponent =
      modalRef.componentInstance;
    if (integration) {
      component.id = integration.id;
      component.next = (id) =>
        this.integrationList.rows[
          this.integrationList.rows.findIndex((i) => i.id === id) + 1
        ]?.id;
      component.prev = (id) =>
        this.integrationList.rows[
          this.integrationList.rows.findIndex((i) => i.id === id) - 1
        ]?.id;
    } else {
      component.id = -1;
    }
    return modalRef;
  }

  async handleCreate(): Promise<void> {
    const modalRef = this.openIntegrationModal();
    try {
      const created: SelfCheckoutIntegrationResponse = await modalRef.result;
      if (created) {
        this.integrationList.appendRow(created);
      }
    } catch (e) {
       ;
    }
  }

  async handleUpdate(
    integration: SelfCheckoutIntegrationResponse
  ): Promise<void> {
    const modalRef = this.openIntegrationModal(integration);
    try {
      const updated: SelfCheckoutIntegrationResponse = await modalRef.result;
      if (updated) {
        this.integrationList.updateRowBy(
          (row) => row.id === updated.id,
          updated
        );
      }
    } catch (e) {
       ;
    }
  }
}
