/*
 * SelfCheckoutIntegrationDetailView
 * Generated delete resource from cadence/scripts/generate
 */

import {
  CadenceStackViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  cadenceRouter,
  CadenceFormControl,
  CadenceStackViewTab,
  CadenceStackViewChild,
} from 'cadence';

import { SelfCheckoutIntegrationUpdateForm } from './self-checkout-integration-update';

interface ISelfCheckoutIntegrationDetailViewParams {
  id: number;
}

@CadenceView({
  title: 'Self Checkout Integration Details',
})
export class SelfCheckoutIntegrationDetailView extends CadenceStackViewModel<ISelfCheckoutIntegrationDetailViewParams> {
  @CadenceStackViewTab(
    () => SelfCheckoutIntegrationDetailView,
    ['updateForm'],
    { label: 'General' }
  )
  generalTab: any;

  @CadenceStackViewChild(() => SelfCheckoutIntegrationUpdateForm)
  updateForm() {
    const form = new SelfCheckoutIntegrationUpdateForm();
    form._params = { id: this._params.id };
    return form;
  }
}
