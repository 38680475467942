<mat-form-field class="d-block w-100">
  <mat-chip-list  #chipList>
    <mat-chip [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(entry)"
              *ngFor="let entry of control.value">
      {{ entry }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
  <input (paste)="handlePaste($event)" [matChipInputFor]="chipList"
         [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
         [matChipInputAddOnBlur]="addOnBlur"
         (matChipInputTokenEnd)="add($event)">
</mat-form-field>
