import { ComponentIOStrategy, createComponentIO } from 'esuite-util/components';
import { Component, Input, ViewChild } from '@angular/core';
import { ListSidebarDetailComponent } from 'esuite-dashboard/app/shared/components/list-sidebar-detail/list-sidebar-detail.component';

@Component({
  selector: 'app-resource-identified-component',
  template: 'abstract AbstractResourceIdentifiedComponent',
})
export abstract class AbstractResourceIdentifiedComponent<T extends { id: number }> {
  @ViewChild('list') list: ListSidebarDetailComponent<T>;
  idIO: ComponentIOStrategy<number>;
  id$: ComponentIOStrategy<number>['value$'];
  constructor() {
    this.idIO = createComponentIO(-1);
    this.id$ = this.idIO.value$;
  }

  @Input()
  get id(): number {
    return this.idIO.get();
  }

  set id(i: number) {
    this.idIO.set(i);
  }

  get isNew(): boolean {
    return this.id === (-1 as any);
  }
}
