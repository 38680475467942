import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { getAbsoluteElementSize } from '../../utils/get-absolute-element-size';

/*
Provides controls for setting the width, height, y and x
properties of a form or class in pixels or percentage values.
Usable with ngModel syntax for each of the various properties
or as form controls for each property.
 */

/*
TODO:
- Add Alignment controls (for example if people want to align center / center) using transform
 */
@Component({
  selector: 'app-box-position',
  templateUrl: './box-position.component.html',
  styleUrls: ['./box-position.component.scss'],
})
export class BoxPositionComponent implements OnInit, OnDestroy, OnChanges {
  private UNITS = ['px', '%'];

  /*
  VARIABLES AND CONTROLS
   */

  boxForm: FormGroup;

  @Input()
  hideBackground = false;

  @Input()
  boxFormControl: AbstractControl;

  @Input()
  containerSize: [number, number] = [null, null];

  subscriptions: Subscription[];

  anchorBoxes = {
    tl: ['0%', '0%'],
    tc: ['-50%', '0%'],
    tr: ['-100%', '0%'],
    ml: ['0%', '-50%'],
    mc: ['-50%', '-50%'],
    mr: ['-100%', '-50%'],
    bl: ['0%', '-100%'],
    bc: ['-50%', '-100%'],
    br: ['-100%', '-100%'],
  };

  transform: string;
  alignmentY: string;
  alignmentX: string;

  getTransform(): string {
    const { transformX, transformY } = this.boxForm.value;
    return `${transformX},${transformY}`;
  }

  getAlignmentY(): string {
    const { top, transformY } = this.boxForm.value;
    return `${top},${transformY}`;
  }

  getAlignmentX(): string {
    const { left, transformX } = this.boxForm.value;
    return `${left},${transformX}`;
  }

  constructor(fb: FormBuilder) {
    this.boxForm = fb.group({
      width: [null, Validators.pattern(/[-.0-9]+(%|px)/)],
      height: [null, Validators.pattern(/[-.0-9]+(%|px)/)],
      widthPx: [null, Validators.required],
      heightPx: [null, Validators.required],
      top: ['0%', Validators.pattern(/[-.0-9]+(%|px)/)],
      left: ['0%', Validators.pattern(/[-.0-9]+(%|px)/)],
      transformX: ['0%', Validators.pattern(/[-.0-9]+(%|px)/)],
      transformY: ['0%', Validators.pattern(/[-.0-9]+(%|px)/)],

      /*
      Represents the background color of the box as comma-separated rgba string.
      @returns Color string returned as "255,255,255,0.5" for 50% opacity white.
       */
      bgColor: [null],

      /*
      Represents the image ID of the background image for this box.
      @returns ID of the background image as an integer.
      */
      bgImageId: [null],
    });
  }

  ngOnInit(): void {
    // Patch From Primary

    if (this.boxFormControl?.value) {
      this.boxForm.patchValue(this.boxFormControl.value);
      this.transform = this.getTransform();
      this.alignmentY = this.getAlignmentY();
      this.alignmentX = this.getAlignmentX();
    }

    this.subscriptions = [
      this.boxForm.valueChanges.pipe(pairwise()).subscribe(([prev, curr]) => {
        this.patchPxValues();
        this.transform = this.getTransform();
        this.alignmentY = this.getAlignmentY();
        this.alignmentX = this.getAlignmentX();
        if (this.boxFormControl) {
          this.boxFormControl.patchValue(this.boxForm.value);
        }
      }),
    ];

    const { transformX, transformY } = this.boxForm.value;
    this.activateAnchor([transformX, transformY]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((changeKey) => {
      const change = changes[changeKey];
      this[changeKey] = change.currentValue;
    });
  }

  private patchPxValues(): void {
    if (
      this.containerSize[0] &&
      this.containerSize[1] &&
      this.boxForm.value.width &&
      this.boxForm.value.height
    ) {
      const { width, height } = getAbsoluteElementSize(
        this.boxForm.value.width,
        this.boxForm.value.height,
        this.containerSize[0],
        this.containerSize[1]
      );
      this.boxForm.patchValue(
        {
          widthPx: width,
          heightPx: height,
        },
        { emitEvent: false }
      );
    }
  }

  private scaleUnit(val: string, scale: number): string {
    const { value, unit } = this.getUnitVal(val);
    return `${(value || 0) * scale}${unit}`;
  }

  private getUnitVal(val: string): { value: number; unit: string } {
    const unit = this.getUnitType(val);
    return {
      value: parseFloat(val.replace(unit, '')),
      unit,
    };
  }

  private getUnitType(val: string): string {
    for (const unit of this.UNITS) {
      if (val.indexOf(unit) > -1) {
        return unit;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  activateAnchor([transformX, transformY]): void {
    if (transformY === '0px') {
      transformY = '0%';
    }
    if (transformX === '0px') {
      transformX = '0%';
    }
    this.boxForm.patchValue({
      transformX,
      transformY,
    });
  }
}
