<div class="container-fluid py-3 layer-editor" [formGroup]="form">
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="addLayer(SlideType.Text)">
      <mat-icon>post_add</mat-icon>
      <span>Text</span>
    </button>
    <button mat-menu-item (click)="addLayer(SlideType.Image)">
      <mat-icon>add_a_photo</mat-icon>
      <span>Image</span>
    </button>
    <button mat-menu-item (click)="addLayer(SlideType.Video)">
      <mat-icon>play_circle_outline</mat-icon>
      <span>Video</span>
    </button>
    <button mat-menu-item (click)="addLayer(SlideType.Iframe)">
      <mat-icon>web</mat-icon>
      <span>URL</span>
    </button>
  </mat-menu>
  <div class="example-action-buttons">
    <button mat-raised-button color="primary" [matMenuTriggerFor]="menu">
      <mat-icon>playlist_add</mat-icon>&nbsp; Add Layer
    </button>
    <button mat-button (click)="accordion.openAll()">Expand All</button>
    <button mat-button (click)="accordion.closeAll()">Collapse All</button>

  </div>
  <mat-accordion formArrayName="layers" cdkDropList (cdkDropListDropped)="orderLayers($event)" class="example-headers-align" [multi]="false">
    <mat-expansion-panel cdkDrag  *ngFor="let layer of this.layerForms.controls; let i = index;" [formGroupName]="i">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon cdkDragHandle>
            drag_handle
          </mat-icon>
        </mat-panel-title>
        <mat-panel-description>
          {{ layer.value.type | titlecase }} Layer
          <ng-container [ngSwitch]="layer.value.type">
            <mat-icon *ngSwitchCase="'image'">insert_photo</mat-icon>
            <mat-icon *ngSwitchCase="'text'">article</mat-icon>
          </ng-container>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="container-fluid p-0">
        <div class="row">
          <ng-container [ngSwitch]="layer.value.type">

            <!-- Start Image Layer Editor -->
            <ng-container *ngSwitchCase="'image'">
              <app-image-upload
                class="w-100"
                [imageFormControl]="layer.get('imageId')"
              ></app-image-upload>
            </ng-container>
            <!-- End Image Layer Editor -->

            <!-- Start Video Layer Editor -->
            <ng-container *ngSwitchCase="'video'">
              <app-video-upload
                class="w-100"
                [videoFormControl]="layer.get('videoId')"
              ></app-video-upload>
            </ng-container>
            <!-- End Video Layer Editor -->

            <!-- Start iFrame Layer Editor -->
            <ng-container *ngSwitchCase="'iframe'">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>URL</mat-label>
                <input formControlName="iframeUrl" matInput type="url">
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Custom CSS</mat-label>
                <textarea matInput rows="10" formControlName="iframeCustomCss"></textarea>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Custom JS</mat-label>
                <textarea matInput rows="10" formControlName="iframeCustomJs"></textarea>
              </mat-form-field>
            </ng-container>
            <!-- End iFrame Layer Editor -->

            <!-- Start Text Layer Editor -->
            <ng-container *ngSwitchCase="'text'">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>Text</mat-label>
                <textarea
                  formControlName="text"
                  matInput></textarea>
              </mat-form-field>
              <div class="col-12">
                <mat-form-field class="ml-2">
                  <mat-label>Font Size</mat-label>
                  <input formControlName="fontSize" type="number" matInput>
                </mat-form-field>
                <input type="color" formControlName="color">
              </div>
              <div class="col-12">
                <h6>Align Text</h6>
                <button mat-icon-button
                        [color]="layer.value.vAlign === 'top' ? 'primary' : null"
                        (click)="layer.get('vAlign').setValue('top')">
                  <mat-icon>vertical_align_top</mat-icon>
                </button>
                <button mat-icon-button
                        [color]="layer.value.vAlign === 'center' ? 'primary' : null"
                        (click)="layer.get('vAlign').setValue('center')">
                  <mat-icon>vertical_align_center</mat-icon>
                </button>
                <button mat-icon-button
                        [color]="layer.value.vAlign === 'bottom' ? 'primary' : null"
                        (click)="layer.get('vAlign').setValue('bottom')">
                  <mat-icon>vertical_align_bottom</mat-icon>
                </button>
                <button mat-icon-button
                        [color]="layer.value.hAlign === 'left' ? 'primary' : null"
                        (click)="layer.get('hAlign').setValue('left')">
                  <mat-icon>format_align_left</mat-icon>
                </button>
                <button mat-icon-button
                        [color]="layer.value.hAlign === 'center' ? 'primary' : null"
                        (click)="layer.get('hAlign').setValue('center')">
                  <mat-icon>format_align_center</mat-icon>
                </button>
                <button mat-icon-button
                        [color]="layer.value.hAlign === 'right' ? 'primary' : null"
                        (click)="layer.get('hAlign').setValue('right')">
                  <mat-icon>format_align_right</mat-icon>
                </button>
              </div>
            </ng-container>
            <!-- End Text Layer Editor -->

          </ng-container>

          <app-box-position
            [containerSize]="slideSize"
            [boxFormControl]="layer.get('layout')">
          </app-box-position>

          <div>
            Link Layer <mat-slide-toggle [checked]="layer.get('link.type').value === DigitalSignageLayerLinkType.Activation" (change)="$event.checked ? layer.get('link.type').setValue(DigitalSignageLayerLinkType.Activation) : layer.get('link.type').setValue(DigitalSignageLayerLinkType.None)"></mat-slide-toggle>
          </div>
          <div [formGroup]="layer.get('link')" *ngIf="layer.get('link.type').value === DigitalSignageLayerLinkType.Activation">
            <mat-form-field>
              <mat-label>App</mat-label>
              <mat-select formControlName="appName">
                <mat-option [value]="esuiteApp.value" *ngFor="let esuiteApp of esuiteApps">
                  {{ esuiteApp.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="layer.get('link.appName').value === EsuiteApp.DigitalSignage">
              <mat-label>Signage</mat-label>
              <mat-select formControlName="instanceId">
                <mat-option *ngFor="let instance of digitalSignage" [value]="instance.id">
                  {{ instance.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="layer.get('link.appName').value === EsuiteApp.Photobooth">
              <mat-label>Photobooth</mat-label>
              <mat-select formControlName="instanceId">
                <mat-option *ngFor="let instance of photobooths" [value]="instance.id">
                  {{ instance.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="layer.get('link.appName').value === EsuiteApp.SelfCheckout">
              <mat-label>Self Checkout</mat-label>
              <mat-select formControlName="instanceId">
                <mat-option *ngFor="let instance of selfCheckouts" [value]="instance.id">
                  {{ instance.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="layer.get('link.appName').value === EsuiteApp.ShopifyCheckout">
              <mat-label>Shopify Checkout</mat-label>
              <mat-select formControlName="instanceId">
                <mat-option *ngFor="let instance of shopifyCheckouts" [value]="instance.id">
                  {{ instance.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <mat-action-row>
        <button mat-button color="warn" (click)="removeLayer(i)">Remove</button>
      </mat-action-row>
    </mat-expansion-panel>

  </mat-accordion>
</div>
