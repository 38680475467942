/*
 * UserListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceActionColumn,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceListViewRow,
} from 'cadence';
import { UserCreateForm } from './user-create';
import { UserDetailView } from './user-detail';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { UserResponse } from 'esuite-client';

@CadenceView({
  title: 'Users',
  showInMenu: '/settings',
  scope: {
    permissions: ['api/users#list'],
  },
})
export class UserListView extends CadenceListViewModel<UserResponse> {
  @CadenceColumn()
  id: number;

  @CadenceColumn(() => UserListView, {
    value: (item) => `${item.firstName} ${item.lastName}`,
  })
  name: string;

  @CadenceColumn()
  email: string;

  @CadenceAction({ label: 'Create' })
  createForm(): void {
    cadenceRouter.navigate(UserCreateForm, {
      from: this,
      events: {
        'action.create': 'created',
      },
    });
  }

  @CadenceActionColumn({ label: 'Edit' })
  updateForm(row: CadenceListViewRow<UserListView>): void {
    cadenceRouter.navigate(UserDetailView, {
      from: this,
      params: {
        id: row.id,
      },
    });
  }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  async _init(): Promise<void> {
    this._rows$.set(await getEsuiteApi().user.list());
  }
}
