import { getCadence } from 'cadence';

export interface ICadenceHook<T = any> {
  transform: (item: T) => any | Promise<any>;
}

export enum CadenceHookType {
  MenuFilter = 'MenuFilter',
}

export function CadenceHook(type: CadenceHookType): ClassDecorator {
  return (target: any) => {
    getCadence().hooks.set(target.name, {
      type,
      hook: target,
    });
  };
}
