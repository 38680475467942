import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'esuite-dashboard/app/shared/shared.module';
import { SelfCheckoutRoutingModule } from 'esuite-dashboard/app/pages/self-checkout/self-checkout-routing.module';
import { SelfCheckoutTemplateComponent } from './self-checkout-template/self-checkout-template.component';
import { SelfCheckoutListComponent } from './self-checkout-list/self-checkout-list.component';
import { SelfCheckoutDetailComponent } from './self-checkout-detail/self-checkout-detail.component';
import { SelfCheckoutTemplateDetailComponent } from './self-checkout-template/self-checkout-template-detail/self-checkout-template-detail.component';

@NgModule({
  declarations: [
    SelfCheckoutTemplateComponent,
    SelfCheckoutListComponent,
    SelfCheckoutDetailComponent,
    SelfCheckoutTemplateDetailComponent,
  ],
  imports: [
    CommonModule,
    SelfCheckoutRoutingModule,

    SharedModule,
  ],
})
export class SelfCheckoutModule {}
