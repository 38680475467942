import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DigitalSignageCreateRequest,
  DigitalSignageFrameType,
  DigitalSignageResponse,
  DigitalSignageUpdateRequest,
  EsuiteApp,
  KioskResponse,
} from 'esuite-client';
import { AbstractFormComponent } from 'esuite-dashboard/app/shared/classes/abstract-form.component';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { KioskPreviewService } from 'esuite-dashboard/app/shared/services/kiosk-preview.service';

@Component({
  selector: 'app-kiosk-layout-editor',
  templateUrl: './kiosk-layout-editor.component.html',
  styleUrls: ['./kiosk-layout-editor.component.scss'],
})
export class KioskLayoutEditorComponent
  extends AbstractFormComponent<
    DigitalSignageResponse,
    DigitalSignageCreateRequest,
    DigitalSignageUpdateRequest
  >
  implements OnInit
{
  @ViewChild('previewWrapper') previewWrapper: ElementRef<HTMLDivElement>;
  previewKiosk: KioskResponse = null;
  kiosks: KioskResponse[] = [];
  signage: DigitalSignageResponse;

  DigitalSignageFrameType = DigitalSignageFrameType;
  EsuiteApp = EsuiteApp;

  frameTypes = {
    [DigitalSignageFrameType.Slides]: {
      icon: 'slideshow',
      name: 'Slides',
      type: DigitalSignageFrameType.Slides,
    },
    [DigitalSignageFrameType.App]: {
      icon: 'touch_app',
      name: 'App',
      type: DigitalSignageFrameType.App,
    },
    [DigitalSignageFrameType.GoogleSlides]: {
      icon: 'add_to_drive',
      name: 'Google Slides',
      type: DigitalSignageFrameType.GoogleSlides,
    },
    [DigitalSignageFrameType.ShopifyCheckout]: {
      icon: 'shopping_cart',
      name: 'Shopify Checkout',
      type: DigitalSignageFrameType.ShopifyCheckout,
    },
  };

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    route: ActivatedRoute,
    private alertService: AlertService, // private esuiteClient: EsuiteClientService,
    private previewService: KioskPreviewService
  ) {
    super();
    this.connectRouteToIdentity('layoutId', route);
  }

  makeCreateForm(): FormGroup | Promise<FormGroup> {
    return this.fb.group({
      name: [null, Validators.required],
      enabled: [true],
      height: [null, Validators.required],
      width: [null, Validators.required],
    });
  }
  async makeUpdateForm(id: number): Promise<FormGroup> {
    this.signage = await getEsuiteApi().digitalSignage.get(id);
    return this.fb.group({
      name: [this.signage.name, Validators.required],
      enabled: [this.signage.enabled],
      height: [this.signage.height, Validators.required],
      width: [this.signage.width, Validators.required],
    });
  }
  async ngOnInit(): Promise<void> {
    this.kiosks = await getEsuiteApi().kiosk.list();
  }
  loadFormData(id: number): void | Promise<void> {
    // throw new Error('Method not implemented.');
  }
  handleCreate(dto: DigitalSignageCreateRequest): void | Promise<void> {
    // throw new Error('Method not implemented.');
  }

  async handleUpdate(dto: DigitalSignageUpdateRequest): Promise<void> {
    await this.alertService.runWithLoader(
      async () => {
        this.signage = await getEsuiteApi().digitalSignage.update(
          this.signage.id,
          dto
        );
      },
      'Layout updated successfully',
      'Error updating kiosk'
    );
    if (this.previewKiosk) {
      this.refreshPreview();
    }
  }

  async addFrame(type: DigitalSignageFrameType) {
    const newFrame = await getEsuiteApi().digitalSignageFrame.create(
      this.signage.id,
      {
        name: 'New Frame',
        type,
        layout: {
          width: `${this.signage.width}px`,
          height: `${this.signage.height}px`,
          widthPx: this.signage.width,
          heightPx: this.signage.height,
        },
      }
    );
    this.signage.frames = [...this.signage.frames, newFrame];
  }

  refreshPreview() {
    this.previewService.previewKiosk(this.previewKiosk, {
      app: EsuiteApp.DigitalSignage,
      id: this.signage.id,
    });
  }
}
