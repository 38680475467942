export const getAbsoluteElementSize = (
  elWidth: string,
  elHeight: string,
  parentWidth: number,
  parentHeight: number
): { width: number; height: number } => {
  let width = parseFloat(elWidth.replace('px', '').replace('%', ''));
  let height = parseFloat(elHeight.replace('px', '').replace('%', ''));
  if (elWidth.indexOf('%') > -1) {
    width = parseFloat(elWidth.replace('%', '')) * parentWidth * 0.01;
  }
  if (elWidth.indexOf('%') > -1) {
    height = parseFloat(elHeight.replace('%', '')) * parentHeight * 0.01;
  }
  return {
    width,
    height,
  };
};
