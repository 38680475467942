/*
 * DigitalSignageFrameCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../../app/api/esuite-api';
import { DigitalSignageFrameType } from 'esuite-client';

interface IDigitalSignageFrameCreateFormParams {
  signageId: number;
}

@CadenceView({
  title: 'Create Digital Signage Frame',
  viewType: 'dialog',
})
export class DigitalSignageFrameCreateForm extends CadenceFormViewModel<IDigitalSignageFrameCreateFormParams> {
  @CadenceFormControl()
  name: string;

  @CadenceFormControl(CadenceFormControlType.Select, {
    options: () => [
      { label: 'Slides', value: 'slides' },
      { label: 'App', value: 'app' },
      { label: 'Widget', value: 'widget' },
    ],
  })
  type: DigitalSignageFrameType;

  @CadenceFormControl(CadenceFormControlType.Number, { classes: 'w-50' })
  width: number;

  @CadenceFormControl(CadenceFormControlType.Number, { classes: 'w-50' })
  height: number;

  @CadenceFormAction({
    label: 'Create',
  })
  async create(): Promise<any> {
    /* Create logic goes here */
    return getEsuiteApi().digitalSignageFrame.create(this._params.signageId, {
      name: this.name,
      type: this.type,
      layout: {
        width: `${this.width}px`,
        height: `${this.height}px`,
        widthPx: this.width,
        heightPx: this.height,
      },
    });
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    this._postEvent('dismissDialog');
  }
}
