import { map } from 'rxjs/operators';

/*
RXJS Operator that plucks a property and converts to a string using parseInt
 */
export function pluckAsInt<T extends { [key: string]: string }>(name: keyof T) {
  return map((params: any) => {
    return parseInt(params[name], 10);
  });
}
