import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AdmissionResponse,
  AgreementResponse,
  DigitalSignageResponse,
  FundResponse,
  ProductCategoryIntegrationFilter,
  ProductCategoryIntegrationRequest,
  ProductCategoryResponse,
  ProductCategoryUpdateRequest,
  SelfCheckoutIntegrationResponse,
} from 'esuite-client';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductCategoryCreateRequest } from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { AbstractFormComponent } from 'esuite-dashboard/app/shared/classes/abstract-form.component';

/*
Allow users to select funds, admissions and integrated rows.
Also should be able to assign this category an image.
Form Components should use inputs, outputs and forms to perform API operations.
 */
@Component({
  selector: 'app-product-category-editor',
  templateUrl: './product-category-editor.component.html',
  styleUrls: ['./product-category-editor.component.scss'],
})
export class ProductCategoryEditorComponent
  extends AbstractFormComponent<
    ProductCategoryResponse,
    ProductCategoryCreateRequest,
    ProductCategoryUpdateRequest
  >
  implements OnInit
{
  // Free Form AtomYardData
  categories: ProductCategoryResponse[] = [];
  admissions: AdmissionResponse[] = [];
  agreements: AgreementResponse[] = [];
  funds: FundResponse[] = [];
  integrations: SelfCheckoutIntegrationResponse[] = [];
  signs: DigitalSignageResponse[] = [];

  constructor(
    activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router
  ) {
    super();
    this.connectRouteToIdentity('productCategoryId', activatedRoute);
  }

  async handleCreate(createReq: ProductCategoryCreateRequest) {
    try {
      const created = await getEsuiteApi().productCategory.create(createReq);
      alert('Created successfully.');
      this.router.navigate(['../', created.id], {
        relativeTo: this.activatedRoute,
      });
    } catch (e) {
      alert('Error creating category.');
       ;
    }
  }
  async handleUpdate(updateReq: ProductCategoryUpdateRequest) {
    try {
      await getEsuiteApi().productCategory.update(this.id, updateReq);
      alert('Updated successfully.');
    } catch (e) {
      alert('Error updating the category.');
       ;
    }
  }

  makeCategoryIntegrationForm(pci?: ProductCategoryIntegrationRequest) {
    const controlsConfig: Record<
      keyof Omit<ProductCategoryIntegrationRequest, 'id'>,
      any
    > = {
      filters: this.fb.array(
        pci
          ? pci.filters.map((f) => this.makeCategoryIntegrationFilterForm(f))
          : []
      ),
      integrationId: [pci ? pci.integrationId : null, Validators.required],
    };
    return this.fb.group(controlsConfig);
  }

  makeCategoryIntegrationFilterForm(filter?: ProductCategoryIntegrationFilter) {
    return this.fb.group({
      exec: [filter ? filter.exec : null],
      filter: [filter ? filter.filter : null],
      tags: [filter ? filter.tags : []],
      target: [filter ? filter.target : null],
    });
  }

  async ngOnInit() {
    await Promise.all([
      getEsuiteApi()
        .agreement.list()
        .then((agreements) => (this.agreements = agreements)),
      getEsuiteApi()
        .admission.list()
        .then((admissions) => (this.admissions = admissions)),
      getEsuiteApi()
        .fund.list()
        .then((funds) => (this.funds = funds)),
      getEsuiteApi()
        .selfCheckoutIntegration.list()
        .then((integrations) => (this.integrations = integrations)),
      getEsuiteApi()
        .digitalSignage.list()
        .then((signs) => (this.signs = signs)),
    ]);
  }

  makeCreateForm() {
    const controlsConfig = {
      name: [null, Validators.required],
      admissionIds: [[]],
      agreementIds: [[]],
      fundIds: [[]],
      imageId: [null],
      signId: [null],
      categoryIds: [[]],
      integrations: this.fb.array([]),
    };
    return this.fb.group(controlsConfig);
  }

  async makeUpdateForm(categoryId: number) {
    const cat = await getEsuiteApi().productCategory.get(categoryId);
    const controlsConfig: Record<keyof ProductCategoryUpdateRequest, any> = {
      name: [cat.name, Validators.required],
      admissionIds: [cat.admissionIds],
      agreementIds: [cat.agreementIds],
      fundIds: [cat.fundIds],
      imageId: [cat.imageId],
      signId: [cat.signId],
      categoryIds: [],
      integrations: this.fb.array(
        cat.integrations.map((i) => this.makeCategoryIntegrationForm(i))
      ),
    };
    return this.fb.group(controlsConfig);
  }

  loadFormData(id: number): Promise<void> | void {
    getEsuiteApi()
      .productCategory.list()
      .then(
        (categories) =>
          (this.categories = categories.filter((cat) => cat.id !== this.id))
      );
  }
}
