import {
  CadenceFormViewModel,
  CadenceObjectType,
  createCadenceViewMetadata,
  getCadence,
  ICadenceViewMetadataOptions,
} from 'cadence';
import { startCase } from 'lodash';
interface ICadenceFormArrayOptions extends ICadenceViewMetadataOptions {
  create?: boolean | string;
  delete?: boolean | string;
}

export function CadenceFormArray<
  FormView extends CadenceObjectType<CadenceFormViewModel>,
  From extends CadenceFormViewModel
>(
  formView: () => FormView,
  options: ICadenceFormArrayOptions = {},
  // cadFormArrayOptions: ICadenceFormArrayOptions,
): PropertyDecorator {
  return (target: FormView, propertyKey: string) => {
    getCadence().formArray.set((target as any).constructor.name, propertyKey, {
      create: true,
      delete: true,
      ...createCadenceViewMetadata(target, options),
      name: propertyKey,
      title: startCase(propertyKey),
      view: formView,
    });
    return {
      set(value: CadenceFormViewModel) {
        const data = this._formArrayData$.value;
        data[propertyKey] = value;
        this._formArrayData$.next(data);
      },
      get() {
        if (!this._formArrayData$.value[propertyKey]) {
          this._formArrayData$.value[propertyKey] = [];
        }
        return this._formArrayData$.value[propertyKey];
      },
      enumerable: true,
      configurable: true,
    };
  };
}
