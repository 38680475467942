/*
 * KioskDeleteForm
 * Generated delete resource from cadence/scripts/generate
 */

import {
  CadenceFormViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  cadenceRouter,
  CadenceFormControl,
} from 'cadence';
import { KioskListView } from './kiosk-list';
import { getEsuiteApi } from '../../../app/api/esuite-api';

interface IKioskDeleteFormParams {
  id: number;
}

@CadenceView({
  title: 'Delete Kiosk',
  viewType: 'dialog',
})
export class KioskDeleteForm extends CadenceFormViewModel<IKioskDeleteFormParams> {
  /*
   * Form Controls
   */
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  /*
   * Form Actions
   */
  @CadenceFormAction({
    label: 'Delete',
  })
  async delete(): Promise<void> {
    await getEsuiteApi().kiosk.remove(this.id);
  }

  /*
   * Form Events
   */
  @CadenceViewEvent('action.delete')
  afterDelete(): void {
    cadenceRouter.navigate(KioskListView, {
      from: this,
    });
  }
}
