/*
 * AgreementDeleteForm
 * Generated delete resource from cadence/scripts/generate
 */

import {
  CadenceFormViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  cadenceRouter,
  CadenceFormControl,
} from 'cadence';
import { AgreementListView } from './agreement-list';
import { getEsuiteApi } from '../../../app/api/esuite-api';

interface IAgreementDeleteFormParams {
  id: number;
}

@CadenceView({
  title: 'Delete Agreement',
  viewType: 'dialog',
})
export class AgreementDeleteForm extends CadenceFormViewModel<IAgreementDeleteFormParams> {
  /*
   * Form Controls
   */
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  /*
   * Form Actions
   */
  @CadenceFormAction({
    label: 'Delete',
  })
  async delete(): Promise<void> {
    return getEsuiteApi().agreement.remove(this._params.id);
  }

  /*
   * Form Events
   */
  @CadenceViewEvent('action.delete')
  afterDelete(): void {
    this._postEvent('dismissDialog');
  }
}
