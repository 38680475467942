<div class="container-fluid px-0 py-3" [formGroup]="form" *ngIf="frame">
  <div class="row">

    <div class="col-12 d-flex align-items-center justify-content-between">
      <mat-form-field appearance="outline" class="flex-grow-1">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name">
      </mat-form-field>
     <div>
       <button [disabled]="loading"
               mat-button color="primary"
               (click)="updateFrame()">
         Update
       </button>

       <button [disabled]="loading"
               mat-button color="warn"
               (click)="deleteFrame()">
         Remove
       </button>
     </div>
    </div>



    <!-- Slide options -->
    <div class="col-12" *ngIf="form.value.type === DigitalSignageFrameType.Slides">
      <div class="d-flex align-items-center justify-content-between">
        <h6>Edit Slides</h6>
        <button color="primary" mat-mini-fab (click)="createSlide()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div class="row mt-2" cdkDropList (cdkDropListDropped)="orderSlides($event)">
        <div cdkDrag class="col-12 col-md-4 mb-4 px-1" *ngFor="let slide of slides; let j = index;" [ngClass]="{ 'slide-paused': !slide.enabled }">
          <app-kiosk-slide
            [slideContainer]="[frame.layout.widthPx, frame.layout.heightPx]"
            [slide]="slide"
            (remove)="removeSlide(j)"
            (edit)="editSlide(j)"
            (toggle)="toggleSlide(slide)"
          ></app-kiosk-slide>
        </div>
      </div>
    </div>

    <!-- Google Slide options -->
    <div class="col-12" *ngIf="form.value.type === DigitalSignageFrameType.GoogleSlides" formGroupName="googleSlides">
      <div class="d-flex align-items-center justify-content-between">
        <h6>Edit Google Slides</h6>
      </div>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Google Slides ID:</mat-label>
        <input (change)="parseSlidesUrlToID($event)" matInput formControlName="id">
        <mat-hint>
          Copy and paste the URL from "File > Publish to web" box in Google Slides.
        </mat-hint>
      </mat-form-field>

      <mat-form-field class="w-100 mt-4" appearance="outline">
        <mat-label>Auto-advance slides (seconds):</mat-label>
        <input type="number" matInput formControlName="delay">
      </mat-form-field>

    </div>

    <!-- Shopify Checkout options -->
    <div class="col-12" *ngIf="form.value.type === DigitalSignageFrameType.ShopifyCheckout">
      <mat-form-field>
        <mat-label>Shopify Checkout</mat-label>
        <mat-select formControlName="shopifyCheckoutId">
          <mat-option [value]="shopifyCheckout.id" *ngFor="let shopifyCheckout of shopifyCheckouts">{{ shopifyCheckout.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <app-box-position
      [containerSize]="[this.signage.width, this.signage.height]"
      [boxFormControl]="form.get('layout')"
    ></app-box-position>


  </div>
</div>
