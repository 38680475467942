import { Component, OnDestroy, OnInit } from '@angular/core';
import { pluckAsInt } from 'esuite-dashboard/app/shared/utils/pluck-as-int';
import {
  BehaviorSubject,
  Observable,
  PartialObserver,
  Subscription,
} from 'rxjs';
import { DirectoryListing } from 'esuite-client';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { ActivatedRoute } from '@angular/router';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { switchMap, take } from 'rxjs/operators';
import { MultiBehaviorSubject } from 'esuite-dashboard/app/shared/utils/multi-behaviorsubject';

@Component({
  selector: 'app-directory-listing-list',
  templateUrl: './directory-listing-list.component.html',
  styles: [],
})
export class DirectoryListingListComponent implements OnInit {
  directoryId$ = this.activatedRoute.params.pipe(pluckAsInt('directoryId'));

  listings$: BehaviorSubject<DirectoryListing[]> = new MultiBehaviorSubject(
    [],
    // Start stream with initial list based on parent directory
    this.directoryId$.pipe(
      switchMap((directoryId) =>
        getEsuiteApi().directoryListing.list(directoryId)
      )
    )
  );
  displayedColumns = ['unit', 'name', 'actions'];
  search: string;

  constructor(
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  remove(directoryListing: DirectoryListing): Promise<any> {
    return this.alertService.confirmLoaderAndResult(
      'Are you sure you want to remove this listing?',
      () =>
        getEsuiteApi()
          .directoryListing.remove(directoryListing.id)
          .then(() => {
            // Previous list with the deleted directoryListing removed
            this.listings$.next(
              this.listings$.value.filter((p) => p !== directoryListing)
            );
          }),
      'Listing removed successfully',
      'Error removing Listing.'
    );
  }
}
