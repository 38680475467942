<mat-card *ngIf="form$ | async as form" [formGroup]="form">
  <mat-card-content>
    <div class="d-flex align-items-center justify-content-between mb-0">
      <span class="h5 m-0">{{ id === -1 ? 'Create' : 'Update' }} Game</span>
      <div>
        <app-kiosk-preview-launcher *ngIf="!isNew" [app]="EsuiteApp.SpinToWin" [id]="id"></app-kiosk-preview-launcher>
        <app-create-update-button
          [formComponent]="[this, form]"
          label="App">
        </app-create-update-button>
      </div>
    </div>
    <hr>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
    <mat-form-field class="d-block" appearance="outline">
      <mat-label>Screensaver Sign</mat-label>
      <mat-select formControlName="screensaverId">
        <mat-option [value]="null">None</mat-option>
        <mat-option [value]="sign.id" *ngFor="let sign of signs">{{ sign.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="d-block" appearance="outline">
      <mat-label>Background Sign</mat-label>
      <mat-select formControlName="backgroundId">
        <mat-option [value]="null">None</mat-option>
        <mat-option [value]="sign.id" *ngFor="let sign of signs">{{ sign.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="my-4" *ngIf="!isNew">
      <app-custom-field-editor [customControl]="form.get('fields')"></app-custom-field-editor>
      <textarea rows="15" class="my-3 w-100" [ngModelOptions]="{ standalone: true }" [(ngModel)]="configStr"></textarea>
    </div>
    <hr>
    <ng-container *ngIf="!isNew">
      <mat-form-field class="d-block" appearance="outline">
        <mat-label>Subject</mat-label>
        <input [formControl]="form.get(['emailShareNotification', 'subject'])" matInput />
      </mat-form-field>
      <app-template-editor [value]="form.get(['emailShareNotification', 'html']).value" (valueChange)="form.get(['emailShareNotification', 'html']).setValue($event)"></app-template-editor>
    </ng-container>
  </mat-card-content>
</mat-card>
