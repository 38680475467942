import { CadenceViewModel } from './cadence-view.model';
import { startCase, kebabCase } from 'lodash';
import { CadenceObjectType } from '../types';
import { Observable } from 'rxjs';
import { CadenceReactiveProperty } from 'cadence/types/cadence-reactive-property';

export interface ICadenceViewMetadata<
  T extends CadenceObjectType<CadenceViewModel> = CadenceObjectType<CadenceViewModel>
> {
  route: string;
  name: string;
  roles?: string[];
  title?: CadenceReactiveProperty<string, [any]>;
  classes?: string;
  view: () => T;
  showInMenu: 'root' | string | (() => T);
  viewType: 'window' | 'dialog';
  icon?: string;
  scope?: {
    apps?: string[];
    permissions?: string[];
  };
}

export interface ICadenceViewMetadataOptions<
  Parent extends CadenceObjectType<CadenceViewModel> = CadenceObjectType<CadenceViewModel>
> {
  title?: CadenceReactiveProperty<string, [any]>;
  classes?: string;
  showInMenu?: 'root' | string | (() => Parent);
  parentView?: () => any;
  viewType?: 'window' | 'dialog';
  icon?: string;
  roles?: string[];
  route?: string;
  scope?: {
    apps?: string[];
    permissions?: string[];
  };
}

export const createCadenceViewMetadata = (
  target: any,
  options: ICadenceViewMetadataOptions,
): ICadenceViewMetadata => {
  return {
    // title: startCase(target.name),
    route: kebabCase(target.name),
    ...options,
    name: target.name,
    viewType: options.viewType ? options.viewType : 'window',
    showInMenu: options.showInMenu,
    view: () => target,
  };
};
