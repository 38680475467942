import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takePromise } from 'esuite-dashboard/app/shared/utils/take-promise';
import { pluckAsInt } from 'esuite-dashboard/app/shared/utils/pluck-as-int';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import {
  DirectoryListing,
  DirectoryListingCreateRequest,
  EsuiteApp,
} from 'esuite-client';
import { filter, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-directory-listing-create',
  templateUrl: './directory-listing-create.component.html',
  styles: [],
})
export class DirectoryListingCreateComponent implements OnInit {
  directoryId$: BehaviorSubject<number> = new BehaviorSubject(null);
  form: FormGroup;
  floors$ = this.directoryId$.pipe(
    filter((d) => !!d),
    switchMap((directoryId) => getEsuiteApi().directoryFloor.list(directoryId))
  );
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.form = this.makeCreateForm();
    takePromise(
      this.activatedRoute.params.pipe(pluckAsInt('directoryId'))
    ).then((directoryId) => this.directoryId$.next(directoryId));
  }

  private makeCreateForm() {
    return this.fb.group({
      title: [null, Validators.required],
      unit: [null, Validators.required],
      floorId: [null, Validators.required],
    });
  }

  async handleCreate() {
    this.form.disable();
    const createDto: DirectoryListingCreateRequest = {
      ...this.form.value,
      directoryId: this.directoryId$.value,
    };
    const createdListing: DirectoryListing = await this.alertService
      .runWithLoader(
        () => getEsuiteApi().directoryListing.create(createDto),
        'Listing created successfully',
        'Error creating Directory Listing'
      )
      .finally(() => {
        this.form.enable();
      });
    this.router.navigate(
      [
        EsuiteApp.Directory,
        this.directoryId$.value,
        'listings',
        createdListing.id,
      ],
      {
        replaceUrl: true, // important so we don't go back to create page
      }
    );
  }
}
