/*
 * RevenueCenterCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { ApplicationCurrency, RevenueCenterResponse } from 'esuite-client';

interface IRevenueCenterCreateFormParams {}

@CadenceView({
  title: 'Create Revenue Center',
  viewType: 'dialog',
})
export class RevenueCenterCreateForm extends CadenceFormViewModel<
  IRevenueCenterCreateFormParams,
  RevenueCenterResponse
> {
  @CadenceFormControl()
  name: string;

  @CadenceFormControl(CadenceFormControlType.Number)
  taxRate: number;

  @CadenceFormControl(CadenceFormControlType.Select, {
    options: () => [
      { label: 'USD', value: 'USD' },
      { label: 'CAD', value: 'CAD' },
    ],
  })
  currency: ApplicationCurrency;

  @CadenceFormAction({
    label: 'Create',
  })
  async create(): Promise<any> {
    /* Create logic goes here */
    return getEsuiteApi().revenueCenter.create({
      name: this.name,
      taxRate: this.taxRate,
      currency: this.currency,
    });
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    this._postEvent('dismissDialog');
  }
}
