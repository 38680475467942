import { Component, OnInit } from '@angular/core';
import { pluckAsInt } from '../../../../../shared/utils/pluck-as-int';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../../shared/components/alert/alert.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { getEsuiteApi } from '../../../../../api/esuite-api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivationResponse } from 'esuite-client/dist/esuite-api/models/ActivationResponse';
import { MatDialog } from '@angular/material/dialog';
import { DirectoryActivationDetailComponent } from 'esuite-dashboard/app/pages/v2/directory/activations/directory-activation-detail/directory-activation-detail.component';
import { MultiBehaviorSubject } from 'esuite-dashboard/app/shared/utils/multi-behaviorsubject';

export interface DirectoryActivationData {
  primaryLayout: 'alpha' | 'list';
  floorIds?: number[];
  floorX?: number;
  floorY?: number;
}

@Component({
  selector: 'app-directory-activation-list',
  templateUrl: './directory-activation-list.component.html',
  styles: [],
})
export class DirectoryActivationListComponent {
  directoryId$ = this.activatedRoute.params.pipe(pluckAsInt('directoryId'));
  activations$ = new MultiBehaviorSubject(
    [],
    this.directoryId$.pipe(
      switchMap((directoryId) =>
        getEsuiteApi().activation.list({ directoryId })
      )
    )
  );
  search: string;
  displayedColumns = ['name', 'username', 'actions'];
  constructor(
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {}

  async openEditor(activationId: number, directoryId: number) {
    const dialogRef = this.dialog.open(DirectoryActivationDetailComponent, {
      data: {
        activationId,
        directoryId,
      },
    });
    const updated = await dialogRef.afterClosed().toPromise();
    if (updated) {
      this.activations$.next(
        this.activations$.value.map((prev) =>
          prev.id === activationId ? updated : prev
        )
      );
    }
  }
}
