/*
TO BE DEPRECIATED IN FAVOR OF ANGULAR COMPONENTS

The views provided by this library are to be depreciated
by standard Angular / Angular-material components
that are used in some of the newer API views.
 */

import {
  ICadenceFormActionMetadata,
  ICadenceFormControlMetadata,
  ICadenceFormArrayMetadata,
} from './types';
import { ICadenceActionMetadata } from './types';
import { CadenceViewSubscriptionMetadata } from './decorators/cadence-view-subscription.decorator';
import { ICadenceComponentMetadata } from './decorators/cadence-component.decorator';
import { ICadenceViewMetadata } from './models';
import { ICadenceListViewColumnMetadata } from './models';
import { ICadenceStackViewChildMetadata } from './models';
import {
  CadenceHookType,
  ICadenceHook,
} from 'cadence/decorators/cadence-hook.decorator';
import { ICadenceStackViewTabMetadata } from 'cadence/models/stack-view/cadence-stack-view-tab.decorator';

const CADENCE_VIEWS: ICadenceViewMetadata[] = [];

const CADENCE_REGISTRY: { [key: string]: any } = {};

export const getCadenceView = (nameOrClass: any): ICadenceViewMetadata => {
  if (typeof nameOrClass === 'string') {
    return CADENCE_REGISTRY[nameOrClass];
  } else {
    return Reflect.getMetadata('cadence:view', nameOrClass);
  }
};

export const getCadenceViews = (): { [key: string]: ICadenceViewMetadata } => {
  return CADENCE_REGISTRY;
};

export const getCadenceViewsAsArray = () => {
  return Object.keys(CADENCE_REGISTRY).map((crk) => CADENCE_REGISTRY[crk]);
};

const cadenceViewService = {
  list: () => {
    return CADENCE_VIEWS;
  },
  find: (query: ({ name: string, view: ICadenceView }) => boolean) => {
    return CADENCE_VIEWS.find(query);
  },
  set: (view: ICadenceViewMetadata) => {
    CADENCE_VIEWS.push(view);
    Reflect.defineMetadata('cadence:view', view, view.view());
    CADENCE_REGISTRY[view.name] = view;
  },
  get: (nameOrClass: any) => {
    if (typeof nameOrClass === 'string') {
      return cadenceViewService.getByName(nameOrClass);
    }
    return cadenceViewService.getByClass(nameOrClass);
  },
  getByName: (name: string) => {
    return CADENCE_VIEWS.find((n) => n.name === name);
  },
  getByClass: (cl: any) => {
    return CADENCE_VIEWS.find((n) => n.view === cl);
  },
};

const CADENCE_COLUMNS: Map<
  string,
  Map<string, ICadenceListViewColumnMetadata<any>>
> = new Map();

const cadenceColumnService = {
  list: (viewName: string) => {
    return CADENCE_COLUMNS.has(viewName)
      ? Array.from(CADENCE_COLUMNS.get(viewName).values())
      : [];
  },
  find: (viewName: string, query) => {
    return Array.from(CADENCE_COLUMNS.get(viewName).values()).find(query);
  },
  set: (
    viewName: string,
    name: string,
    options: ICadenceListViewColumnMetadata<any>
  ) => {
    let viewColumns = CADENCE_COLUMNS.get(viewName);
    if (!viewColumns) {
      viewColumns = new Map();
      CADENCE_COLUMNS.set(viewName, viewColumns);
    }
    viewColumns.set(name, options);
  },
};

const CADENCE_BUTTONS: Map<
  string,
  Map<string, ICadenceActionMetadata<any, any>>
> = new Map();

const cadenceButtonService = {
  list: (viewName: string) => {
    return Array.from(
      CADENCE_BUTTONS.has(viewName)
        ? CADENCE_BUTTONS.get(viewName).values()
        : []
    );
  },
  find: (viewName: string, query) => {
    return Array.from(CADENCE_BUTTONS.get(viewName).values()).find(query);
  },
  set: (
    viewName: string,
    name: string,
    options: ICadenceActionMetadata<any, any>
  ) => {
    let viewColumns = CADENCE_BUTTONS.get(viewName);
    if (!viewColumns) {
      viewColumns = new Map();
      CADENCE_BUTTONS.set(viewName, viewColumns);
    }
    viewColumns.set(name, options);
  },
};

const CADENCE_FORMS: ICadenceViewMetadata[] = [];

const cadenceFormService = {
  list: () => {
    return CADENCE_FORMS;
  },
  find: (query: (view: ICadenceViewMetadata) => boolean) => {
    return CADENCE_FORMS.find(query);
  },
  set: (view: ICadenceViewMetadata) => {
    CADENCE_FORMS.push(view);
    Reflect.defineMetadata('cadence:view', view, view.view());
    CADENCE_REGISTRY[view.name] = view;
  },
  get: (key: string) => {
    return CADENCE_FORMS.find((form) => form.name === key);
  },
  getByClass: (cl: any) => {
    return CADENCE_FORMS.find((form) => form.view === cl);
  },
};

const CADENCE_FORM_CONTROLS: Map<
  string,
  Map<string, ICadenceFormControlMetadata>
> = new Map();

const cadenceFormControlService = {
  list: (viewName: string) => {
    return Array.from(
      CADENCE_FORM_CONTROLS.get(viewName)
        ? CADENCE_FORM_CONTROLS.get(viewName).values()
        : []
    );
  },
  find: (viewName: string, query) => {
    return Array.from(
      CADENCE_FORM_CONTROLS.get(viewName)
        ? CADENCE_FORM_CONTROLS.get(viewName).values()
        : []
    ).find(query);
  },
  set: (
    viewName: string,
    name: string,
    options: ICadenceFormControlMetadata
  ) => {
    let viewColumns = CADENCE_FORM_CONTROLS.get(viewName);
    if (!viewColumns) {
      viewColumns = new Map();
      CADENCE_FORM_CONTROLS.set(viewName, viewColumns);
    }
    viewColumns.set(name, options);
  },
};

const CADENCE_FORM_ACTION: Map<
  string,
  Map<string, ICadenceFormActionMetadata>
> = new Map();

const cadenceFormActionService = {
  list: (viewName: string) => {
    return Array.from(
      CADENCE_FORM_ACTION.get(viewName)
        ? CADENCE_FORM_ACTION.get(viewName).values()
        : []
    );
  },
  find: (viewName: string, query) => {
    return Array.from(CADENCE_FORM_ACTION.get(viewName).values()).find(query);
  },
  set: (
    viewName: string,
    name: string,
    options: ICadenceFormActionMetadata
  ) => {
    let viewColumns = CADENCE_FORM_ACTION.get(viewName);
    if (!viewColumns) {
      viewColumns = new Map();
      CADENCE_FORM_ACTION.set(viewName, viewColumns);
    }
    viewColumns.set(name, options);
  },
};

const CADENCE_STACK_VIEWS: ICadenceViewMetadata[] = [];

const cadenceStackViewService = {
  list: () => {
    return CADENCE_STACK_VIEWS;
  },
  set: (view: ICadenceViewMetadata) => {
    CADENCE_STACK_VIEWS.push(view);
    Reflect.defineMetadata('cadence:view', view, view.view());
    CADENCE_REGISTRY[view.name] = view;
  },
  get: (nameOrClass: any) => {
    if (typeof nameOrClass === 'string') {
      return cadenceStackViewService.getByName(nameOrClass);
    }
    return cadenceStackViewService.getByClass(nameOrClass);
  },
  getByName: (name: string) => {
    return CADENCE_STACK_VIEWS.find((n) => n.name === name);
  },
  getByClass: (cl: any) => {
    return CADENCE_STACK_VIEWS.find((n) => n.view === cl);
  },
};

function createCadenceService<T>(metadataKey: string) {
  const repository: Map<string, Map<string, T>> = new Map();

  return {
    list: (viewName: string) => {
      return Array.from(
        repository.has(viewName) ? repository.get(viewName).values() : []
      );
    },
    set: (viewName: string, name: string, options: T) => {
      let viewColumns = repository.get(viewName);
      if (!viewColumns) {
        viewColumns = new Map();
        repository.set(viewName, viewColumns);
      }
      viewColumns.set(name, options);

      Reflect.defineMetadata(metadataKey, options, repository, name);
    },
  };
}

function createCadenceRepository<T>() {
  const repo = new Map();

  return {
    set: (target: string, data: any) => {
      repo.set(target, data);
    },
    list: () => {
      return Array.from(repo.values());
    },
  };
}

const stackViewChildren = createCadenceService<ICadenceStackViewChildMetadata>(
  'cadence:stackViewChildren'
);

const stackViewTabs = createCadenceService<ICadenceStackViewTabMetadata>(
  'cadence:stackViewTabs'
);

const subscriptions = createCadenceService<CadenceViewSubscriptionMetadata>(
  'cadence:subscriptions'
);
const formArray = createCadenceService<ICadenceFormArrayMetadata>(
  'cadence:customControl'
);
const components = createCadenceRepository<ICadenceComponentMetadata>();
const hooks =
  createCadenceRepository<{
    type: CadenceHookType;
    hook: ICadenceHook;
  }>();

export const getCadence = () => {
  return {
    view: cadenceViewService,
    column: cadenceColumnService,
    form: cadenceFormService,
    formControl: cadenceFormControlService,
    formAction: cadenceFormActionService,
    formArray,
    button: cadenceButtonService,
    stackView: cadenceStackViewService,
    stackViewChildren,
    stackViewTabs,
    subscriptions,
    components,
    hooks,
    // subscriptions: createCadenceService<CadenceViewSubscriptionMetadata>('cadence:subscriptions'),
  };
};
