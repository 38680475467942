import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractFormComponent } from 'esuite-dashboard/app/shared/classes/abstract-form.component';
import {
  PaymentGatewayResponse,
  PaymentGatewayType,
  PaymentTerminalCreateRequest,
  PaymentTerminalDevice,
  PaymentTerminalResponse,
  PaymentTerminalUpdateRequest,
} from 'esuite-client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { startCase } from 'lodash';
import { quickEnumToOptions } from 'esuite-dashboard/app/shared/utils/quick-enum-to-options';

@Component({
  selector: 'app-payment-gateway-create',
  templateUrl: './payment-gateway-create.component.html',
  styleUrls: ['./payment-gateway-create.component.scss'],
})
export class PaymentGatewayCreateComponent
  extends AbstractFormComponent<
    PaymentTerminalResponse,
    PaymentTerminalCreateRequest,
    PaymentTerminalUpdateRequest
  >
  implements OnInit
{
  terminal: PaymentTerminalResponse;
  gateway: PaymentGatewayResponse;
  PaymentTerminalDevice = PaymentTerminalDevice;
  PaymentGatewayType = PaymentGatewayType;
  deviceTypes = quickEnumToOptions(PaymentTerminalDevice);
  constructor(private activeModal: NgbActiveModal, private fb: FormBuilder) {
    super();
  }

  handleCreate(dto: PaymentTerminalCreateRequest): any {
    this.makeFormAdjustments();
    this.activeModal.close(dto);
  }

  handleUpdate(dto: PaymentTerminalUpdateRequest): any {
    this.makeFormAdjustments();
    this.activeModal.close(dto);
  }

  makeFormAdjustments(): void {
    // Format MAC address
    this.form
      .get('ipAddress')
      .setValue(
        this.form.get('ipAddress').value.toLowerCase().replace(/-/g, ':')
      );
  }

  loadFormData(id: number): Promise<void> | void {
    return undefined;
  }

  async ngOnInit(): Promise<void> {
    if (!this.terminal) {
      this.id = -1;
    } else {
      this.id = this.terminal.id;
    }
  }

  makeCreateForm(): FormGroup {
    return this.fb.group({
      name: [null, Validators.required],
      device: [null, Validators.required],
      ipAddress: [null],
      moneris: this.fb.group({
        ecrId: [null],
      }),
      openedge: this.fb.group({
        webTerminalId: [this.terminal?.openedge?.webTerminalId],
      }),
    });
  }

  makeUpdateForm(id: number): FormGroup {
    return this.fb.group({
      name: [this.terminal?.name, Validators.required],
      device: [this.terminal?.device, Validators.required],
      ipAddress: [this.terminal?.ipAddress],
      moneris: this.fb.group({
        ecrId: [this.terminal?.moneris?.ecrId],
      }),
      openedge: this.fb.group({
        webTerminalId: [this.terminal?.openedge?.webTerminalId],
      }),
    });
  }
}
