<h4>Create a Directory Listing</h4>

<form [formGroup]="form" >
  <div class="d-flex align-items-stretch justify-content-between flex-column">

    <mat-form-field appearance="fill">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Unit</mat-label>
      <input matInput formControlName="unit">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Select Floor</mat-label>
      <mat-select  formControlName="floorId">
        <mat-option [value]="floor.id" *ngFor="let floor of floors$ | async">{{ floor.name }}</mat-option>
      </mat-select>
    </mat-form-field>

  </div>
  <button mat-raised-button color="primary"
          (click)="handleCreate()"
          [disabled]="!form.enabled || form.invalid">
    Create
  </button>
</form>


