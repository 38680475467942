import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CadenceViewModel,
  CVM_REGISTRY,
  getCadenceView,
  ICadenceViewMetadata,
} from 'cadence';
import { Subscription } from 'rxjs';
import { filter, map, pluck, take, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

export interface IBaseCadViewComponent {
  // initializeView(): Promise<void>;
}

@Component({
  selector: 'app-base-cad-view',
  templateUrl: './base-cad-view.component.html',
  styleUrls: ['./base-cad-view.component.scss'],
})
export abstract class BaseCadViewComponent<
  ViewModel extends CadenceViewModel = CadenceViewModel
> implements OnInit, OnDestroy, IBaseCadViewComponent {
  private _cadView: ViewModel;

  @Input()
  get cadView(): ViewModel {
    return this._cadView;
  }
  set cadView(view: ViewModel) {
    this._cadView = view;
    this.cadViewMetadata = getCadenceView(view.constructor.name);
  }

  cadViewMetadata: ICadenceViewMetadata;

  private _cadViewEvents: any;

  @Input()
  get cadViewEvents(): any {
    return this._cadViewEvents;
  }
  set cadViewEvents(p: any) {
    this._cadViewEvents = p;
  }

  subscriptions: Subscription[] = [];

  constructor(protected route: ActivatedRoute) {}

  // Load view, params, events and initialize component
  ngOnInit(): void {
    // Load cadView from route if not passed in
    if (!this.cadView) {
      const cadViewRoute$ = this.route.data.pipe(
        // tap((c) =>  ),
        pluck('view'),
        filter((v) => !!v),
        pluck('name'),
        tap(async (t) => {
           ;
          const params = await this.route.queryParams
            .pipe(
              pluck('p'),
              map((str) => (str ? JSON.parse(atob(str)) : {})),
              take(1),
            )
            .toPromise();
           ;
           ;
          const events = await this.route.queryParams.pipe(
            pluck('e'),
            map((str) => (str ? JSON.parse(atob(str)) : {})),
            take(1),
          );
           ;
          this.cadView = new (getCadenceView(t).view())() as ViewModel;
          this.cadView._params = params;
           ;
          this.cadViewEvents = events;
          this.initializeView();
        }),
        // take(1),
        // tap((c) =>  ),
      );

      this.subscriptions.push(cadViewRoute$.subscribe());
    } else {
      this.initializeView();
    }
  }

  protected async initializeView() {
    if (this.cadViewEvents) {
      this.subscriptions.push(
        this.cadView._events$.subscribe((event) => {
          //  ;
          if (this.cadViewEvents.events?.[event.name]) {
            //  ;
            CVM_REGISTRY.get(this.cadViewEvents.to)?._postEvent({
              ...event,
              name: this.cadViewEvents.events[event.name],
              from: this.cadView,
            });
          }
        }),
      );
    }

    const subs = this.cadView._subscriptions;
    if (subs?.length) {
      subs.forEach((sub) => {
        this.subscriptions.push(
          sub.subject(this.cadView).subscribe((data) => {
            (sub.handler as () => void).apply(this.cadView, [data]);
          }),
        );
      });
    }
    await this.cadView._init?.();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    CadenceViewModel._destroyView(this.cadView);
  }
}
