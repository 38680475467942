/*
 * PaymentGatewayListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceActionColumn,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceListViewRow,
} from 'cadence';
import { PaymentGatewayCreateForm } from './payment-gateway-create';
import { PaymentGatewayUpdateForm } from './payment-gateway-update';
import { PaymentGatewayDeleteForm } from './payment-gateway-delete';
import { getEsuiteApi } from '../../../../app/api/esuite-api';

@CadenceView({
  title: 'Gateways',
  // showInMenu: () => RevenueCenterListView,
})
export class PaymentGatewayListView extends CadenceListViewModel<any> {
  @CadenceColumn()
  id: number;

  @CadenceColumn()
  name: string;

  @CadenceColumn()
  type: string;

  @CadenceAction({ label: 'Create' })
  createForm(): void {
    cadenceRouter.navigate(PaymentGatewayCreateForm, {
      from: this,
      events: {
        'action.create': 'created',
      },
    });
  }

  @CadenceActionColumn({ label: 'Delete' })
  deleteForm(row: CadenceListViewRow<PaymentGatewayListView>): void {
    cadenceRouter.navigate(PaymentGatewayDeleteForm, {
      from: this,
      events: {
        'action.delete': 'deleted',
      },
      params: {
        id: row.id,
      },
    });
  }

  @CadenceActionColumn({ label: 'Edit' })
  updateForm(row: CadenceListViewRow<PaymentGatewayListView>): void {
    cadenceRouter.navigate(PaymentGatewayUpdateForm, {
      from: this,
      params: {
        id: row.id,
        name: row.name,
      },
      events: {
        'action.update': 'updated',
      },
    });
  }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  @CadenceViewEvent('updated')
  update(event: any): void {
    this._rows$.updateBy((c) => c.id === event.data.id, event.data);
  }

  @CadenceViewEvent('deleted')
  remove(event: any): void {
    this._rows$.deleteBy((c) => c.id === event.from._params.id);
  }

  async _init(): Promise<void> {
    this._rows$.set(await getEsuiteApi().paymentGateway.list());
  }
}
