/*
 * FundOptionUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceFormViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  cadenceRouter,
  CadenceFormControl,
  CadenceFormControlType,
} from 'cadence';
import { CustomProductOptionType } from 'esuite-client';
import { getEsuiteApi } from '../../../../app/api/esuite-api';

interface IFundOptionUpdateFormParams {
  id: number;
  fundId: number;
}

@CadenceView()
export class FundOptionValueForm extends CadenceFormViewModel {
  @CadenceFormControl({ classes: 'w-50' })
  name: string;

  @CadenceFormControl({ classes: 'w-50' })
  label: string;

  @CadenceFormControl({ required: false, classes: 'w-75' })
  answer: string;

  @CadenceFormControl(CadenceFormControlType.Number, {
    required: false,
    classes: 'w-25',
  })
  quantity: number;
}

@CadenceView({
  title: 'Update Fund Option',
})
export class FundOptionUpdateForm extends CadenceFormViewModel<IFundOptionUpdateFormParams> {
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  @CadenceFormControl()
  name: string;

  @CadenceFormControl(CadenceFormControlType.Number, {
    required: false,
    classes: 'w-50',
  })
  price: number;

  @CadenceFormAction({
    label: 'Update',
  })
  async update(): Promise<any> {
    return getEsuiteApi().fundOption.update(this._params.fundId, this.id, {
      name: this.name,
      price: this.price,
    });
  }

  @CadenceViewEvent('action.update')
  afterUpdate(): void {
    this._postEvent('dismissDialog');
  }

  async _init() {
    const resp = await getEsuiteApi().fundOption.get(
      this._params.id,
      this._params.fundId,
    );
    this._controlData$.next({
      ...resp,
    });
  }
}
