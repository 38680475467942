/*
 * CheckoutLineItemCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  cadenceRouter,
  CadenceFormControl,
} from 'cadence';
import { CheckoutLineItemListView } from './checkout-line-item-list';

interface ICheckoutLineItemCreateFormParams {}

@CadenceView({
  title: 'Create Checkout Line Item',
  viewType: 'dialog',
})
export class CheckoutLineItemCreateForm extends CadenceFormViewModel<ICheckoutLineItemCreateFormParams> {
  @CadenceFormControl()
  name: string;

  @CadenceFormAction({
    label: 'Create',
  })
  async create(): Promise<any> {
    /* Create logic goes here */
    return { id: Date.now(), name: this.name };
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    cadenceRouter.navigate(CheckoutLineItemListView, {
      from: this,
    });
  }
}
