/*
 * OrganizationUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import { CadenceFormViewModel, CadenceView, CadenceFormAction, CadenceViewEvent, cadenceRouter, CadenceFormControl } from 'cadence';
import { OrganizationListView } from './organization-list';

interface IOrganizationUpdateFormParams {
  id: number;
}

@CadenceView({
  title: 'Update Organization',
})
export class OrganizationUpdateForm extends CadenceFormViewModel<IOrganizationUpdateFormParams> {

  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  @CadenceFormControl({
    default: (view) => view._params.name,
  })
  name: string;

  @CadenceFormAction({
    label: 'Update',
  })
  async update(): Promise<any> {
    return { id: this.id, name: this.name };
  }

  @CadenceViewEvent('action.update')
  afterUpdate(): void {
    this._postEvent('dismissDialog');
  }

  async _init(): Promise<void> {
    this._controlData$.next(
      { id: Date.now() + 1, name: 'Row 1' },
    );
  }
}
