import { CadenceViewModel } from '../cadence-view.model';
import { getCadence } from '../../cadence';

export class CadenceStackViewModel<
  Params = any,
  RemoteData = any
> extends CadenceViewModel<Params, RemoteData> {
  get _children() {
    return getCadence().stackViewChildren.list(this.constructor.name);
  }
  get _tabs() {
    return getCadence().stackViewTabs.list(this.constructor.name);
  }
}
