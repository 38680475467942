import { Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractFormComponent } from 'esuite-dashboard/app/shared/classes/abstract-form.component';
import {
  PaymentGatewayCreateRequest,
  PaymentGatewayResponse,
  PaymentGatewayType,
  PaymentGatewayUpdateRequest,
  PaymentTerminalCreateRequest,
  PaymentTerminalDevice,
  PaymentTerminalResponse,
  PaymentTerminalUpdateRequest,
} from 'esuite-client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { startCase } from 'lodash';
import { ResourceListConfig } from 'esuite-dashboard/app/shared/components/resource-list/resource-list.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentGatewayCreateComponent } from 'esuite-dashboard/app/pages/payment-gateway/payment-gateway-create/payment-gateway-create.component';
import { ResourceListComponent } from 'esuite-dashboard/app/shared/components/resource-list/resource-list.component';
import { updateLocationId } from 'esuite-dashboard/app/shared/utils/update-location-id';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { quickEnumToOptions } from 'esuite-dashboard/app/shared/utils/quick-enum-to-options';

@Component({
  selector: 'app-payment-gateway-detail',
  templateUrl: './payment-gateway-detail.component.html',
  styleUrls: ['./payment-gateway-detail.component.scss'],
})
export class PaymentGatewayDetailComponent extends AbstractFormComponent<
  PaymentGatewayResponse,
  PaymentGatewayCreateRequest,
  PaymentGatewayUpdateRequest
> {
  @ViewChild('deviceList')
  deviceList: ResourceListComponent<PaymentTerminalResponse>;
  gateway: PaymentGatewayResponse;
  PaymentGatewayType = PaymentGatewayType;
  gatewayTypes = quickEnumToOptions(PaymentGatewayType);
  deviceListConfig: ResourceListConfig<PaymentTerminalResponse> = {
    afterRowLoader(rows: PaymentTerminalResponse[]): Promise<void> | void {
      return undefined;
    },
    cols: [
      {
        header: 'Name',
        key: 'name',
      },
      {
        header: 'IP Address',
        key: 'ipAddress',
      },
    ],
    delete: (
      item: PaymentTerminalResponse,
      ind: number,
      items: PaymentTerminalResponse[]
    ) =>
      this.alertService.confirmLoaderAndResult(
        'Are you sure you want to delete this device?',
        () =>
          getEsuiteApi()
            .paymentTerminal.remove(item.id)
            .then(() => true),
        'Device removed successfully',
        'Error deleting device'
      ),
    edit: (
      item: PaymentTerminalResponse,
      ind: number,
      items: PaymentTerminalResponse[]
    ) => {
      this.openTerminalDialog(item);
    },
    rowLoader: () => {
       ;
      return getEsuiteApi().paymentTerminal.list({
        gatewayId: this.gateway.id,
      });
    },
  };
  constructor(
    activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private modal: NgbModal,
    private location: Location,
    private alertService: AlertService
  ) {
    super();
    this.connectRouteToIdentity('paymentGatewayId', activatedRoute);
  }

  async handleCreate(dto: PaymentGatewayCreateRequest): Promise<void> {
    this.alertService.runWithLoader(
      async () => {
        this.gateway = await getEsuiteApi().paymentGateway.create(dto);
        this.id = this.gateway.id;
        updateLocationId(this.id, this.location);
        await this.makeUpdateForm(this.gateway);
      },
      'Gateway created successfully',
      'Error creating gateway'
    );
  }

  async handleUpdate(dto: PaymentGatewayUpdateRequest): Promise<void> {
    this.alertService.runWithLoader(
      async () => {
        this.gateway = await getEsuiteApi().paymentGateway.update(
          this.gateway.id,
          dto
        );
        await this.makeUpdateForm(this.gateway);
      },
      'Gateway updated successfully',
      'Error updating gateway'
    );
  }

  async loadFormData(id: number): Promise<void> {
    if (id || id === 0) {
      this.gateway = await getEsuiteApi().paymentGateway.get(id);
    }
  }

  makeCreateForm(): FormGroup {
    return this.fb.group({
      name: [null, Validators.required],
      type: [null, Validators.required],
      moneris: this.fb.group({
        merchantId: [null],
      }),
      openedge: this.fb.group({
        webId: [null],
        webAuthKey: [null],
      }),
      cayan: this.fb.group({
        merchantKey: [null],
        merchantName: [null],
        merchantSiteId: [null],
      }),
    });
  }

  async openTerminalDialog(terminal?: PaymentTerminalResponse): Promise<void> {
    const modalRef = this.modal.open(PaymentGatewayCreateComponent);
    (modalRef.componentInstance as PaymentGatewayCreateComponent).gateway =
      this.gateway;
    (modalRef.componentInstance as PaymentGatewayCreateComponent).terminal =
      terminal;
    try {
      const createDto = await modalRef.result;
      if (!terminal) {
        await this.createDevice(createDto);
      } else {
        await this.updateDevice(terminal, createDto);
      }
    } catch {}
  }

  async createDevice(createDto: PaymentTerminalCreateRequest): Promise<void> {
    this.alertService.runWithLoader(
      async () => {
        const created = await getEsuiteApi().paymentTerminal.create({
          ...createDto,
          gatewayId: this.gateway.id,
        });
        this.deviceList.appendRow(created);
      },
      'Device created successfully',
      'Error creating device'
    );
  }

  async updateDevice(
    terminal: PaymentTerminalResponse,
    updateDto: PaymentTerminalUpdateRequest
  ): Promise<void> {
    this.alertService.runWithLoader(
      async () => {
        const updated = await getEsuiteApi().paymentTerminal.update(
          terminal.id,
          updateDto
        );
        this.deviceList.updateRowBy((row) => row.id === terminal.id, updated);
      },
      'Device updated successfully',
      'Error updating device'
    );
  }

  async makeUpdateForm(
    idOrGateway: number | PaymentGatewayResponse
  ): Promise<FormGroup> {
    const gateway =
      typeof idOrGateway === 'number'
        ? await getEsuiteApi().paymentGateway.get(idOrGateway)
        : idOrGateway;
    const updateForm = this.fb.group({
      name: [gateway.name, Validators.required],
      type: [gateway.type, Validators.required],
      moneris: this.fb.group({
        merchantId: [gateway.moneris?.merchantId],
      }),
      openedge: this.fb.group({
        webId: [gateway.openedge?.webId],
        webAuthKey: [gateway.openedge?.webAuthKey],
      }),
      cayan: this.fb.group({
        merchantKey: [gateway.cayan?.merchantKey],
        merchantName: [gateway.cayan?.merchantName],
        merchantSiteId: [gateway.cayan?.merchantSiteId],
      }),
    });
    return updateForm;
  }
}
