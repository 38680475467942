<div class="container-fluid py-4">
  <div class="jumbotron">
    <h1 class="display-5">Welcome to E Suite Dashboard</h1>
    <p class="lead">
      Select a Module to Start
    </p>
<!--    <hr class="my-4">-->
<!--    <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>-->
<!--    <p class="lead">-->
<!--      <a class="btn btn-primary btn-lg" href="#" role="button">Learn more</a>-->
<!--    </p>-->
  </div>
<!--  <div class="row">-->
<!--    <div class="col-4">-->
<!--      <div class="card">-->
<!--        <div class="card-header">-->
<!--          <h6 class="card-title mb-0">Kiosks</h6>-->
<!--        </div>-->
<!--        <div class="card-body">-->
<!--          <h6 class="text-center text-light mb-0" *ngIf="!kiosks.length">No kiosks available.</h6>-->
<!--          <table class="table w-100 table-responsive">-->
<!--            <tbody>-->
<!--            <tr *ngFor="let kiosk of kiosks; let i = index;">-->
<!--              <td>-->
<!--                <ng-container *ngIf="kiosk.lastScreenshot; else noScreenshot">-->
<!--                  <img  class="img-fluid my-2 d-block mx-auto" width="150px" src="{{kiosk.lastScreenshot}}">-->
<!--                </ng-container>-->
<!--                <ng-template #noScreenshot>-->
<!--                  <small class="text-muted text-center d-block py-4 mx-3 my-2" style="background: #f5f5f5">No Screenshot</small>-->
<!--                </ng-template>-->
<!--              </td>-->
<!--              <td>{{ kiosk.name }}</td>-->
<!--              <td>-->
<!--                <ng-container *ngIf="kiosk.offline?.getTime() > 0; else kioskOnline">-->
<!--                  <span class="badge badge-pill badge-danger">Offline</span>-->
<!--                </ng-container>-->
<!--                <ng-template #kioskOnline>-->
<!--                  <span class="badge badge-pill badge-success">Online</span>-->
<!--                </ng-template>-->
<!--              </td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--          </table>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row mt-4">-->
<!--    <div class="col-12 col-sm-4">-->
<!--      <div class="card mb-4" style="width: 100%">-->
<!--        <div class="card-body">-->
<!--          <h5 class="card-title">Self Order and POS</h5>-->
<!--          <h6 class="card-subtitle mb-2 text-muted">View reports, manage shifts and more.</h6>-->
<!--          <ul class="list-group list-group-flush">-->
<!--            <li class="list-group-item px-0">-->
<!--              <a [routerLink]="['epos']" class="card-link">Overview</a>-->
<!--            </li>-->
<!--            <li class="list-group-item px-0">-->
<!--              <a [routerLink]="['epos', 'orders']" class="card-link">Orders</a><br>-->
<!--            </li>-->
<!--            <li class="list-group-item px-0">-->
<!--              <a [routerLink]="['epos', 'reports', 'daily-summary']" class="card-link">Daily Summary</a>-->
<!--            </li>-->
<!--            <li class="list-group-item px-0">-->
<!--              <a [routerLink]="['epos', 'shifts']" class="card-link">Shifts</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-12 col-sm-4">-->
<!--      <div class="card mb-4" style="width: 100%">-->
<!--        <div class="card-body">-->
<!--          <h5 class="card-title">Directory Display</h5>-->
<!--          <h6 class="card-subtitle mb-2 text-muted">Manage directories and listings.</h6>-->
<!--          <ul class="list-group list-group-flush">-->
<!--            <li class="list-group-item px-0">-->
<!--              <a [routerLink]="['directory-display', 'listings']" class="card-link">Listings</a>-->
<!--            </li>-->
<!--            <li class="list-group-item px-0">-->
<!--              <a [routerLink]="['directory-display', 'kiosks']" class="card-link">Kiosks</a><br>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
