/*
 * UserUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { RoleResponse, UserUpdateRequest } from 'esuite-client';

interface IUserUpdateFormParams {
  id: number;
}

@CadenceView({
  title: 'Update User',
})
export class UserUpdateForm extends CadenceFormViewModel<IUserUpdateFormParams> {
  @CadenceFormControl(CadenceFormControlType.Email)
  email: string;

  @CadenceFormControl()
  firstName: string;

  @CadenceFormControl()
  lastName: string;

  @CadenceFormControl(CadenceFormControlType.Password, { required: false })
  password: string;

  @CadenceFormControl(CadenceFormControlType.Select, {
    multiple: true,
    options: async () => {
      const roles = await getEsuiteApi().role.list();
      return roles.map((r) => ({
        label: r.title,
        value: r.name,
      }));
    },
  })
  roles: string[];

  @CadenceFormAction({
    label: 'Update',
  })
  async update(): Promise<any> {
    return getEsuiteApi()
      .user.update(this._params.id, {
        ...(this._controlData$.value as UserUpdateRequest),
      })
      .then(() => window.location.reload());
  }

  @CadenceViewEvent('action.update')
  afterUpdate(): void {
    this._postEvent('dismissDialog');
  }

  async _init(): Promise<void> {
    const userData = await getEsuiteApi().user.get(this._params.id);
    this._controlData$.next({
      ...userData,
      roles: userData.roles.map((r) => r.name),
    });
  }
}
