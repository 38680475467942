import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { getCadenceViews } from 'cadence';
import { CadenceViewGuard } from './guards/cadence-view.guard';
import { getByViewModel } from 'cadence';
import { CadListViewComponent } from './components/cad-list-view/cad-list-view.component';
import { CadFormViewComponent } from './components/cad-form-view/cad-form-view.component';
import { CadStackViewComponent } from './components/cad-stack-view/cad-stack-view.component';

const views = getCadenceViews();

/* Temporarily necessary until I can figure out a way to declarative-ly load those components */
const comps = [
  CadListViewComponent,
  CadFormViewComponent,
  CadStackViewComponent,
];

const routes = Object.keys(views).map((path) => {
  const view = views[path];
  return {
    path: view.route,
    component: getByViewModel(view.view()).component(),
    data: { view, title: view.title, breadcrumb: view.title },
    canActivate: [CadenceViewGuard],
  };
});
export const cadenceRouterConfig: Routes = routes;

@NgModule({
  imports: [RouterModule.forChild(cadenceRouterConfig)],
  exports: [RouterModule],
  providers: [CadenceViewGuard],
})
export class CadenceRoutingModule {}
