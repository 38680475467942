import { CadenceReactiveProperty } from 'cadence/types/cadence-reactive-property';
import { CadenceViewModel } from 'cadence';
import { from, Observable, of } from 'rxjs';

const isFunction = (functionToCheck) => {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  );
};

/*
  Take in a Cadence Reactive Property and return
  an observable for that property.
 */
export const assignCadenceReactiveProperty = <T>(
  prop: CadenceReactiveProperty<T, any>,
  view: CadenceViewModel,
  ...data: any[]
): Observable<T> => {
  let val = prop;
  if (isFunction(prop)) {
     ;
    val = (prop as any)(view, ...data);
  }
  if (val instanceof Promise) {
    return from(val);
  } else if (val instanceof Observable) {
    return val;
  }
  return of(val) as Observable<T>;
};
