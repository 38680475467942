/*
 * DigitalSignageUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';

interface IDigitalSignageUpdateFormParams {
  id: number;
}

@CadenceView()
export class DigitalSignageUpdateForm extends CadenceFormViewModel<IDigitalSignageUpdateFormParams> {
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  @CadenceFormControl({
    default: (view) => view._params.name,
  })
  name: string;

  @CadenceFormControl(CadenceFormControlType.Number, { classes: 'w-50' })
  width: number;

  @CadenceFormControl(CadenceFormControlType.Number, { classes: 'w-50' })
  height: number;

  @CadenceFormAction({
    label: 'Update',
  })
  async update(): Promise<any> {
    return getEsuiteApi().digitalSignage.update(this._params.id, {
      name: this.name,
      width: this.width,
      height: this.height,
    });
  }

  @CadenceViewEvent('action.update')
  afterUpdate(): void {
    this._postEvent('dismissDialog');
  }

  async _init(): Promise<void> {
    this._controlData$.next(
      await getEsuiteApi().digitalSignage.get(this._params.id),
    );
  }
}
