import {
  CadenceObjectType,
  CadenceViewModel,
  getCadenceView,
  ICadenceViewMetadata,
} from 'cadence';
import { Observable, Subject } from 'rxjs';

interface CadenceRouterContext<
  T extends CadenceObjectType<CadenceViewModel> = CadenceObjectType<CadenceViewModel>
> {
  from: CadenceViewModel;
  params?: { [key: string]: any };
  events?: { [key: string]: string };
}

interface CadenceRouterEvent<T extends CadenceObjectType<CadenceViewModel>> {
  to: T;
  context: CadenceRouterContext<T>;
  route: [string, any];
}

export class CadenceRouter<
  T extends CadenceObjectType<CadenceViewModel> = CadenceObjectType<CadenceViewModel>
> {
  get events$(): Observable<CadenceRouterEvent<T>> {
    return this._events$.asObservable();
  }
  private _events$: Subject<CadenceRouterEvent<T>> = new Subject();

  // Prefix for all routes to be under
  prefix = 'cadence';

  /* Determines the strategy to create route path and query parameters
   * for all Cadence Views. Basically the framework (i.e. Angular) should
   * use the data created from this to navigate between routes.
   * */
  public getRoute(to: T, context?: CadenceRouterContext<T>): [string, any] {
    const viewData: ICadenceViewMetadata = getCadenceView(to);
    const queryParams: any = {};
    if (context.params) {
      queryParams.p = context.params;
    }
    if (context.events) {
      queryParams.e = {
        to: context.from._registration,
        events: context.events,
      };
    }
    return ['/' + this.prefix + '/' + viewData.route, queryParams];
  }

  serializeQueryParams(qp: any): { [key: string]: string } {
    const queryParams: any = {};
    Object.keys(qp).forEach(
      (key) => (queryParams[key] = btoa(JSON.stringify(qp[key]))),
    );
    return queryParams;
  }

  navigate<To extends T, From extends CadenceViewModel>(
    to: To,
    context: CadenceRouterContext,
  ): void {
    const event: CadenceRouterEvent<any> = {
      to,
      context,
      route: this.getRoute(to, context),
    };
    this._events$.next(event);
  }
}

export const cadenceRouter = new CadenceRouter();
