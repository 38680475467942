import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { nanoid } from 'nanoid';
import { getCadence } from '../cadence';
import { ICadenceViewEvent } from 'cadence/types/cadence-view-event.interface';
import { CadenceViewSubscriptionMetadata } from 'esuite-dashboard/cadence';

const cvmRegistry: Map<string, CadenceViewModel> = new Map();

/* Service to work with CVM REGISTRY, a global list of all instantiated CadenceViews */
export const CVM_REGISTRY = {
  get: (key: string) => {
    return cvmRegistry.get(key);
  },
  register: (model: CadenceViewModel) => {
    const key = nanoid();
    cvmRegistry.set(key, model);
    return key;
  },
  unregister: (modelOrKey: CadenceViewModel | string) => {
    if (typeof modelOrKey === 'string') {
      cvmRegistry.delete(modelOrKey);
    } else {
      cvmRegistry.delete(modelOrKey._registration);
    }
  },
};

export class CadenceViewModel<
  Params = any,
  RemoteData = { [key: string]: any }
> {
  readonly _registration = CVM_REGISTRY.register(this);
  private __events$: Subject<ICadenceViewEvent<this, any>> = new Subject();
  private __params$: BehaviorSubject<Params> = new BehaviorSubject<Params>(
    null,
  );

  _data$: BehaviorSubject<RemoteData> = new BehaviorSubject<RemoteData>(
    {} as RemoteData,
  );

  // private __remoteData$: BehaviorSubject<RemoteData> = new BehaviorSubject<RemoteData>(null);

  public static _destroyView(view: CadenceViewModel): void {
    view._postEvent('destroyView');
    CVM_REGISTRY.unregister(view);
  }

  get _events$(): Observable<ICadenceViewEvent<this, any>> {
    return this.__events$.asObservable();
  }

  get _params$(): Observable<Params> {
    return this.__params$.asObservable();
  }

  get _params(): Params {
    return this.__params$.value;
  }

  set _params(p: Params) {
    this.__params$.next(p);
  }

  public get _subscriptions(): CadenceViewSubscriptionMetadata[] {
    return getCadence().subscriptions.list(this.constructor.name);
  }

  _postEvent(
    nameOrObj: string | { name: string; data?: any; from?: CadenceViewModel },
    data?: any,
  ): void {
    if (typeof nameOrObj === 'string') {
      this.__events$.next({
        name: nameOrObj,
        data,
        view: this,
      });
    } else {
      this.__events$.next({
        ...nameOrObj,
        view: this,
      });
    }
  }

  _init(): void | Promise<void> {
    // Initialize the object here
  }
}
