/*
 * CheckoutCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { CheckoutResponse } from 'esuite-client';

interface ICheckoutCreateFormParams {
  activationId: number;
}

@CadenceView({
  title: 'Create Checkout',
  viewType: 'dialog',
})
export class CheckoutCreateForm extends CadenceFormViewModel<
  ICheckoutCreateFormParams,
  CheckoutResponse
> {
  @CadenceFormControl(CadenceFormControlType.Tel, {
    required: 'Fill in phone number.',
  })
  phone?: string;

  @CadenceFormControl(CadenceFormControlType.Email, {
    required: 'Enter a valid email',
  })
  email?: string;

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    this._postEvent('dismissDialog');
  }
}
