import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewSubscription,
} from 'cadence';
import { getEsuiteApi } from '../../../../../app/api/esuite-api';
import { CadenceFormArray } from '../../../../models/form-view/cadence-form-array.decorator';
import {
  bufferCount,
  distinctUntilChanged,
  filter,
  pluck,
  take,
} from 'rxjs/operators';
import {
  AdmissionOptionResponse,
  CheckoutLineItemResponse,
  ProductType,
} from 'esuite-client';

@CadenceView()
export class AdmissionLineItemOption extends CadenceFormViewModel<{
  option: AdmissionOptionResponse;
}> {
  @CadenceFormControl(CadenceFormControlType.Message, {
    default: (form: AdmissionLineItemOption) => {
      const { name, descriptionHtml } = form._params.option;
      return `${name}: ${descriptionHtml}`;
    },
  })
  message?: number;

  @CadenceFormControl(CadenceFormControlType.Number, {
    hidden: (form: AdmissionLineItemOption) =>
      form._params.option.type === 'quantity',
  })
  quantity?: number;

  @CadenceFormControl({
    hidden: (form: AdmissionLineItemOption) =>
      form._params.option.type === 'question',
  })
  answer?: string;
}

@CadenceView({
  viewType: 'dialog',
})
export class AddAdmissionLineItem extends CadenceFormViewModel<{
  checkoutId: number;
  lineItem?: CheckoutLineItemResponse;
}> {
  @CadenceFormControl({
    hidden: true,
    default: () => 'admission',
  })
  productType: 'admission' | 'appPurchase';

  @CadenceFormControl(CadenceFormControlType.Select, {
    disabled: (form: AddAdmissionLineItem) => !!form._params.lineItem,
    options: async (form: AddAdmissionLineItem) => {
      return (await getEsuiteApi().admission.list()).map((a) => ({
        label: a.title,
        value: a.id,
      }));
    },
  })
  productId: number;

  @CadenceFormControl(CadenceFormControlType.Number)
  quantity: number;

  @CadenceFormArray(() => AdmissionLineItemOption, { create: false })
  admissionOptions: AdmissionLineItemOption[];

  @CadenceFormAction()
  async save() {
    if (this._params.lineItem) {
      const resp = await getEsuiteApi().checkoutLineItem.update(
        this._params.lineItem.id,
        this._params.checkoutId,
        {
          quantity: this.quantity || 1,
          admissionOptions: this.admissionOptions.map((ao) => {
            return {
              id: ao._params.option.id,
              quantity: ao.quantity,
              answer: ao.answer,
            };
          }),
        },
      );
      return resp;
    } else {
      const resp = await getEsuiteApi().checkoutLineItem.create(
        this._params.checkoutId,
        {
          productType: ProductType.Admission,
          productId: this.productId,
          quantity: this.quantity || 1,
          admissionOptions: this.admissionOptions.map((ao) => {
            return {
              id: ao._params.option.id,
              quantity: ao.quantity,
              answer: ao.answer,
            };
          }),
        },
      );
      this._postEvent('dismissDialog');
      return resp;
    }
  }

  @CadenceViewSubscription(
    () => AddAdmissionLineItem,
    (add) =>
      add._controlData$.pipe(
        pluck('productId'),
        filter((p) => !!p),
        distinctUntilChanged(),
      ),
  )
  async updateFormArray(): Promise<void> {
    const options = await getEsuiteApi().admissionOption.list(this.productId);
    this._formArrayData$.next({
      admissionOptions: options.map(this.optionToForm),
    });
  }

  private optionToForm(op) {
    const option = new AdmissionLineItemOption();
    option._params = { option: op };
    return option;
  }

  _init(): void | Promise<void> {
    if (this._params.lineItem) {
      const {
        productType,
        admissionId,
        admissionOptions,
        appPurchaseId,
        quantity,
      } = this._params.lineItem;
      this._formArrayData$
        .pipe(bufferCount(2), take(1))
        .toPromise()
        .then(() => {
          admissionOptions.forEach((option) => {
            const foundOption = this.admissionOptions.find(
              (ao) => ao._params.option.id === option.id,
            );
            if (foundOption) {
              Object.assign(foundOption, option);
            }
          });
        });
      this._controlData$.next({
        productType,
        productId: admissionId || appPurchaseId,
        admissionOptions,
        quantity,
      });
    }
  }
}
