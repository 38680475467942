<div *ngIf="cadView" [ngClass]="cadViewMetadata.classes">
  <div class="d-flex align-items-start justify-content-between">
    <h5 *ngIf="cadViewMetadata.title">{{ cadViewMetadata.title }}</h5>
    <div>
      <app-cad-link *ngFor="let link of links" [link]="link"  [from]="cadView"></app-cad-link>
      <ng-container *ngIf="cadView._csvExport">
        <button mat-raised-button color="primary" (click)="exportToCsv()">Export</button>
      </ng-container>
    </div>
  </div>

  <mat-card class="mat-elevation-z m-0 p-0" *ngIf="(list$ | async) as list">
    <mat-card-content class="overflow-auto">
      <table mat-table class="w-100"
        [dataSource]="dataSource">
        <ng-container
          [matColumnDef]="column.name"
          *ngFor="let column of columns; let i = index;">

          <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>

          <td  mat-cell  *matCellDef="let row; let rowInd = index;">
            <ng-container *ngIf="column.action">
              <app-cad-link [link]="column.action" [paramData]="row" [from]="cadView"></app-cad-link>
            </ng-container>
            <div *ngIf="!column.action && dynamicValues[column.name] && dynamicValues[column.name][rowInd]" [innerHTML]="dynamicValues[column.name][rowInd] | async | safe:'html'"></div>
          </td>

          <td mat-footer-cell *matFooterCellDef>
              <div *ngIf="columns.length && column.footer" [innerHTML]="column.footer(cadView)"></div>
          </td>

        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tbody>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </tbody>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
      <mat-paginator
        *ngIf="(cadView._rows$.value$ | async)?.pagination as pagination"
        [length]="pagination.count"
        [pageSize]="pagination.limit"
        [pageIndex]="pageIndex"
        (page)="cadView._paginator.loadPage($event); pageIndex = $event.pageIndex"></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
