import { getCadence } from '../cadence';
import { CadenceViewModel } from './cadence-view.model';
import {
  createCadenceViewMetadata,
  ICadenceViewMetadata,
  ICadenceViewMetadataOptions,
} from './cadence-view.metadata';
import { CadenceObjectType } from '../types';

const cadenceService = getCadence();

export function CadenceView<T extends CadenceObjectType<CadenceViewModel>>(
  options: ICadenceViewMetadataOptions = {},
) {
  return (target: T) => {
    const _view = {
      ...createCadenceViewMetadata(target as any, options),
    } as ICadenceViewMetadata;
    cadenceService.view.set(_view);
  };
}
