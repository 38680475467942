<div class="container">
  <div class="row">
    <h5>Settings</h5>
    <div class="card w-100 mt-2">
      <div class="card-body container-fluid p-4">
        <div class="row">

          <!-- Organizations -->
          <div class="col-md-4 col-sm-6" *ngIf="userService.userHasAcceptableRoles([RoleName.OrganizationRegistrar])">
            <a [routerLink]="['..', 'cadence', 'organization-list-view']">Organizations</a>
            <p>View and manage organizations.</p>
          </div>


          <!-- Users -->
          <div class="col-md-4 col-sm-6" *ngIf="userService.userHasAcceptableRoles([RoleName.OrganizationAdmin])">
            <a [routerLink]="['..', 'cadence', 'user-list-view']">Users</a>
            <p>View and create users. Reset passwords.</p>
          </div>


          <!-- Agreements -->
          <div class="col-md-4 col-sm-6">
            <a [routerLink]="['..', 'cadence', 'agreements']">Agreements</a>
            <p>Manage agreements used throughout the application.</p>
          </div>


          <!-- Revenue Centers -->
          <div class="col-md-4 col-sm-6">
            <a [routerLink]="['..', 'cadence', 'agreements']">Revenue Centers</a>
            <p>Store and location settings for kiosk deployments.</p>
          </div>


          <!-- Templates -->
          <div class="col-md-4 col-sm-6">
            <a [routerLink]="['..', 'self-checkout-templates']">Templates</a>
            <p>Edit various templates for the platform.</p>
          </div>



          <!-- Integrations -->
          <div class="col-md-4 col-sm-6">
            <a [routerLink]="['..', 'self-checkout-integrations']">Integrations</a>
            <p>Setup integrations here to connect third-party apps.</p>
          </div>


          <!-- Payments -->
          <div class="col-md-4 col-sm-6">
            <a [routerLink]="['..', 'payments']">Payments</a>
            <p>Connect to payment terminals.</p>
          </div>


          <!-- Plugins -->
          <div class="col-md-4 col-sm-6">
            <a [routerLink]="['..', 'plugins']">Plugins</a>
            <p>Extend the functionality of E Suite Apps.</p>
          </div>


          <!-- Rest Apps -->
          <div class="col-md-4 col-sm-6">
            <a [routerLink]="['..', 'rest-apps']">REST Apps</a>
            <p>Integrate directly with E Suite Admin with private apps.</p>
          </div>



          <!-- Platforms -->
          <div class="col-md-4 col-sm-6">
            <a [routerLink]="['..', 'platforms']">Platforms</a>
            <p>Manage custom kiosk platforms.</p>
          </div>



        </div>
      </div>
    </div>
    <h5 class="mt-4">Downloads</h5>
    <div class="card w-100 mt-2">
      <div class="card-body container-fluid p-4">
        <div class="row">

          <!-- E Suite Player -->
          <div class="col-md-4 col-sm-6">
            <a target="_blank" href="https://urldefense.proofpoint.com/v2/url?u=https-3A__www.x-2Dcharge.com_downloads_files_RCM-2520Windows-5FGA.exe&d=DwMFaQ&c=zQ6tLaF7dShu6emFdFLQLQ&r=fPSlpy75tC3W8hMQwlSqSg_UPIhNgYmjYKFQwOkgikI&m=48O_tTT_C_JsxL9EiE5X09eWXgSdPHz5o6LlEkZ3yvM&s=8IL2Axu3_gJPSfBpDZmIkgeax1K_Tu8nmZlpTcwe3Xc&e=">RCM Windows</a>
            <p>For using Global Payments Integrated pinpads.</p>
          </div>

          <!-- RCM -->
          <div class="col-md-4 col-sm-6" *ngIf="latestPlayer">
            <a target="_blank" [href]="latestPlayer.url">{{ latestPlayer.name }}</a>
            <p>Download the latest E Suite Player to run apps on your kiosks.</p>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
