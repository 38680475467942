/*
 * UserDetailView
 * Generated delete resource from cadence/scripts/generate
 */

import {
  CadenceStackViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  cadenceRouter,
  CadenceFormControl,
  CadenceStackViewTab,
  CadenceStackViewChild,
} from 'cadence';

import { UserUpdateForm } from './user-update';

interface IUserDetailViewParams {
  id: number;
}

@CadenceView({
  title: 'User Details',
})
export class UserDetailView extends CadenceStackViewModel<IUserDetailViewParams> {
  @CadenceStackViewTab(() => UserDetailView, ['updateForm'], {
    label: 'General',
  })
  generalTab: any;

  @CadenceStackViewChild(() => UserUpdateForm)
  updateForm() {
    const form = new UserUpdateForm();
    form._params = { id: this._params.id };
    return form;
  }
}
