/*
 * PaymentGatewayUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  cadenceRouter,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { PaymentGatewayListView } from './payment-gateway-list';
import { getEsuiteApi } from '../../../../app/api/esuite-api';
import { PaymentGatewayType } from 'esuite-client';
import { map, pluck } from 'rxjs/operators';

interface IPaymentGatewayUpdateFormParams {
  id: number;
}

@CadenceView({
  title: 'Update Payment Gateway',
})
export class PaymentGatewayUpdateForm extends CadenceFormViewModel<IPaymentGatewayUpdateFormParams> {
  @CadenceFormControl()
  name: string;

  @CadenceFormControl(CadenceFormControlType.Text, { disabled: true })
  type: PaymentGatewayType;

  @CadenceFormControl({
    required: false,
    hidden: (form: PaymentGatewayUpdateForm) =>
      form._controlData$.pipe(
        pluck('type'),
        map((type) => type !== PaymentGatewayType.Moneris)
      ),
  })
  merchantId: string;

  @CadenceFormControl({
    required: false,
    hidden: (form: PaymentGatewayUpdateForm) =>
      form._controlData$.pipe(
        pluck('type'),
        map((type) => type !== PaymentGatewayType.Cayan)
      ),
  })
  merchantName: string;

  @CadenceFormControl({
    required: false,
    hidden: (form: PaymentGatewayUpdateForm) =>
      form._controlData$.pipe(
        pluck('type'),
        map((type) => type !== PaymentGatewayType.Cayan)
      ),
  })
  merchantSiteId: string;

  @CadenceFormControl({
    required: false,
    hidden: (form: PaymentGatewayUpdateForm) =>
      form._controlData$.pipe(
        pluck('type'),
        map((type) => type !== PaymentGatewayType.Cayan)
      ),
  })
  merchantKey: string;

  @CadenceFormControl({
    required: false,
    hidden: (form: PaymentGatewayUpdateForm) =>
      form._controlData$.pipe(
        pluck('type'),
        map((type) => type !== PaymentGatewayType.Openedge)
      ),
  })
  webId: string;

  @CadenceFormControl({
    required: false,
    hidden: (form: PaymentGatewayUpdateForm) =>
      form._controlData$.pipe(
        pluck('type'),
        map((type) => type !== PaymentGatewayType.Openedge)
      ),
  })
  webAuthKey: string;

  @CadenceFormAction({
    label: 'Update',
  })
  async update(): Promise<any> {
    return getEsuiteApi().paymentGateway.update(this._params.id, {
      name: this.name,
      type: this.type,
      moneris: {
        merchantId: this.merchantId,
      },
      cayan: {
        merchantName: this.merchantName,
        merchantSiteId: this.merchantSiteId,
        merchantKey: this.merchantKey,
      },
      openedge: {
        webId: this.webId,
        webAuthKey: this.webAuthKey,
      },
    });
  }

  @CadenceViewEvent('action.update')
  afterUpdate(): void {
    cadenceRouter.navigate(PaymentGatewayListView, {
      from: this,
    });
  }

  async _init(): Promise<void> {
    const gatewayData = await getEsuiteApi().paymentGateway.get(
      this._params.id
    );
    this._controlData$.next({
      ...gatewayData,
      merchantId: gatewayData.moneris?.merchantId,
      merchantName: gatewayData.cayan?.merchantName,
      merchantSiteId: gatewayData.cayan?.merchantSiteId,
      merchantKey: gatewayData.cayan?.merchantKey,
      webId: gatewayData.openedge?.webId,
      webAuthKey: gatewayData.openedge?.webAuthKey,
    });
  }
}
