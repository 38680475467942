<h1 matDialogTitle>{{ data.title }}</h1>
{{ 'ADD' }} {{ 'MEMBER' }}
<form [formGroup]="itemForm" (ngSubmit)="submit()" >
  <div fxLayout="row wrap" fxLayout.lt-sm="column">
    <div fxFlex="50" class="pr-16" *ngFor="let ctrl of controls">
      <mat-form-field class="full-width">
        <input
          matInput
          [type]="ctrl.type | cadFormControlInputType"
          [name]="ctrl.name"
          [formControl]="itemForm.controls[ctrl.name]"
          [placeholder]="ctrl.label"
        />
      </mat-form-field>
    </div>
    <div fxFlex="100" class="mt-16">
      <button mat-raised-button color="primary" [disabled]="itemForm.invalid">
        {{ 'SAVE' }}
      </button>
      <span fxFlex></span>
      <button
        mat-button
        color="warn"
        type="button"
        (click)="dialogRef.close(false)"
      >
        Cancel
      </button>
    </div>
  </div>
</form>
