import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { ShopifyCheckoutRoutingModule } from 'esuite-dashboard/app/pages/shopify-checkout/shopify-checkout-routing.module';

import { SharedModule } from 'esuite-dashboard/app/shared/shared.module';
import { LoginRoutingModule } from 'esuite-dashboard/app/pages/login/login-routing/login-routing.module';

@NgModule({
  declarations: [LoginComponent],
  imports: [LoginRoutingModule, CommonModule,  SharedModule],
})
export class LoginModule {}
