import { CadenceViewModel } from '../cadence-view.model';
import { BehaviorSubject } from 'rxjs';
import { getCadence } from '../../cadence';
import {
  ICadenceFormActionMetadata,
  ICadenceFormControlMetadata,
  ICadenceFormArrayMetadata,
} from 'cadence/types';

export class CadenceFormViewModel<
  Params = any,
  Response = any
> extends CadenceViewModel<Params, Response> {
  _controlData$: BehaviorSubject<any> = new BehaviorSubject<{
    [p: string]: any;
  }>({});

  _formArrayData$: BehaviorSubject<{
    [key: string]: any[];
  }> = new BehaviorSubject<{
    [p: string]: any[];
  }>({});

  public get _controls(): ICadenceFormControlMetadata[] {
    return getCadence().formControl.list(this.constructor.name);
  }

  public get _formArrays(): ICadenceFormArrayMetadata[] {
    return getCadence().formArray.list(this.constructor.name);
  }

  public get _actions(): ICadenceFormActionMetadata[] {
    return getCadence().formAction.list(this.constructor.name);
  }

  _reset(): void {
    this._postEvent({ name: 'resetForm' });
    this._controlData$.next({});
  }
}
