import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Ace } from '../../../../assets/ace';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { makeAceEditor } from '../ace-editor/make-ace-editor';

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss'],
})
export class TemplateEditorComponent implements AfterViewInit, OnDestroy {
  val = ''; // this is the updated value that the class accesses
  private localCustomControl: AbstractControl;

  @Input()
  get customControl(): AbstractControl {
    return this.localCustomControl;
  }
  set customControl(c: AbstractControl) {
    this.localCustomControl = c;
  }

  @Input()
  get value(): string {
    return this.val;
  }
  set value(val) {
    // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val;
    this.valueChange.emit(val);
    if (this.editor?.getValue() !== val) {
      this.editor?.setValue(val);
    }
  }
  @Output()
  valueChange: EventEmitter<string> = new EventEmitter();

  @ViewChild('htmlEditor') htmlEditor: ElementRef<HTMLDivElement>;
  editor: Ace.Editor;

  ngAfterViewInit(): void {
    this.editor = makeAceEditor({
      el: this.htmlEditor.nativeElement,
      mode: 'ace/mode/liquid',
      control: this.customControl,
      initialValue: this.value,
    });
  }
  ngOnDestroy(): void {
    this.editor?.destroy();
  }
}
