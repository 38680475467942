import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { EsuiteApp } from 'esuite-client';
import { BehaviorSubject } from 'rxjs';
import { pluckAsInt } from 'esuite-dashboard/app/shared/utils/pluck-as-int';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-directory-floor-create',
  templateUrl: './directory-floor-create.component.html',
  styles: [],
})
export class DirectoryFloorCreateComponent {
  directoryId$ = this.activatedRoute.params.pipe(pluckAsInt('directoryId'));
  form$ = this.directoryId$.pipe(
    map((directoryId) => {
      return this.makeCreateForm(directoryId);
    })
  );
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  private makeCreateForm(directoryId: number) {
    return this.fb.group({
      name: [null, Validators.required],
      number: [null, Validators.required],
      directoryId: [directoryId, Validators.required],
    });
  }

  async handleCreate(form: FormGroup) {
    form.disable();
    const createDto = {
      ...form.value,
    };
    const createdFloor = await this.alertService
      .runWithLoader(
        () => getEsuiteApi().directoryFloor.create(createDto),
        'Floor created successfully',
        'Error creating Directory Floor'
      )
      .finally(() => {
        form.enable();
      });
    this.router.navigate(
      [EsuiteApp.Directory, createDto.directoryId, 'floors', createdFloor.id],
      {
        replaceUrl: true, // important so we don't go back to create page
      }
    );
  }
}
