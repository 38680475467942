/*
 * KioskListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceActionColumn,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceListViewRow,
} from 'cadence';
import { KioskCreateForm } from './kiosk-create';
import { KioskUpdateForm } from './kiosk-update';
import { KioskDeleteForm } from './kiosk-delete';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { BaseEsuiteAppInstanceResponse, KioskResponse } from 'esuite-client';
import { memoize, startCase } from 'lodash';
import { getEsuitePlatformApi } from '../../../app/api/esuite-platform-api';
import { environment } from 'esuite-dashboard/environments/environment';

@CadenceView({
  title: 'Kiosks',
  showInMenu: 'root',
  icon: 'touch_app',
})
export class KioskListView extends CadenceListViewModel<KioskResponse> {
  // @CadenceColumn()
  // id: number;

  @CadenceColumn(() => KioskListView, {
    value: (row) =>
      row.lastScreenshot
        ? `<img class="img-fluid my-2 d-block mx-auto" width="75px" src="${row.lastScreenshot}">`
        : '<small class="text-muted text-center d-block py-4 mx-3 my-2" style="background: #f5f5f5">No Screenshot</small>',
  })
  thumbnail: string;

  @CadenceColumn()
  username: string;

  @CadenceColumn()
  name: string;

  @CadenceColumn(() => KioskListView, {
    value: (kiosk) => kiosk.lastState?.version || 'Unavailable',
  })
  platformVersion: string;

  @CadenceColumn(() => KioskListView, {
    value: (item) => {
      if (item.offline && item.offline.getTime() > 0) {
        return `<span class="badge badge-pill badge-danger">Offline</span>`;
      } else {
        return `<span class="badge badge-pill badge-success">Online</span>`;
      }
    },
  })
  status: string;

  @CadenceColumn(() => KioskListView, {
    value: async (item, view) => {
      return view.getActivationTitle(item.activationId);
    },
  })
  automation: string;

  private getActivationTitle: (activationId: number) => Promise<string>;

  @CadenceAction({ label: 'Create' })
  createForm(): void {
    cadenceRouter.navigate(KioskCreateForm, {
      from: this,
      events: {
        'action.create': 'created',
      },
    });
  }

  @CadenceActionColumn({ label: 'Launch', header: '' })
  async kioskLaunch(row: CadenceListViewRow<KioskListView>): Promise<void> {
    const platformClient = getEsuitePlatformApi();
    const params = {
      password: row.secret,
      username: row.username,
      url: environment.adminUrl,
    };
    platformClient.player.setProfile(params);
  }

  @CadenceActionColumn({ label: 'Preview', header: '' })
  async kioskPreview(row: CadenceListViewRow<KioskListView>): Promise<void> {
    const platformClient = getEsuitePlatformApi();

    const url = environment.adminUrl + `/player?preview=true`;
    platformClient.preview.configure({
      width: 1080,
      height: 1920,
      url,
      username: row.username,
      password: row.secret,
    });
  }

  @CadenceActionColumn({ label: 'Delete', header: '' })
  deleteForm(row: CadenceListViewRow<KioskListView>): void {
    cadenceRouter.navigate(KioskDeleteForm, {
      from: this,
      events: {
        'action.delete': 'deleted',
      },
      params: {
        id: row.id,
      },
    });
  }

  @CadenceActionColumn({ label: 'Edit', header: '' })
  updateForm(row: CadenceListViewRow<KioskListView>): void {
    cadenceRouter.navigate(KioskUpdateForm, {
      from: this,
      params: {
        id: row.id,
        name: row.name,
      },
      events: {
        'action.update': 'updated',
      },
    });
  }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  @CadenceViewEvent('updated')
  update(event: any): void {
    this._rows$.updateBy((c) => c.id === event.data.id, event.data);
  }

  @CadenceViewEvent('deleted')
  remove(event: any): void {
    this._rows$.deleteBy((c) => c.id === event.from._params.id);
  }

  async _init(): Promise<void> {
    this.getActivationTitle = memoize(async (activationId) => {
      if (activationId) {
        const activation = await getEsuiteApi().activation.get(activationId);
        return (
          `<span class="badge badge-pill badge-primary">${startCase(
            activation.type
          )}</span>` +
          '<br>' +
          activation.app.name
        );
      } else {
        return 'No activation';
      }
    });
    this._rows$.set(await getEsuiteApi().kiosk.list());
  }
}
