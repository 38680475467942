import { getCadence } from '../cadence';
import { ICadenceActionMetadata } from '../types';
import { CadenceViewModel } from '../index';

export interface ICadenceViewLinkOptions<
  From extends CadenceViewModel,
  To extends CadenceViewModel
> {
  label: string;
}

export function CadenceAction<
  From extends CadenceViewModel,
  To extends CadenceViewModel
>(options: ICadenceViewLinkOptions<From, To>): PropertyDecorator {
  return (target: any, method: string) => {
    const targetName = target.constructor.name;
    const cadenceAction: ICadenceActionMetadata = {
      name: method,
      ...options,
      action: target[method],
    };
    getCadence().button.set(targetName, method, cadenceAction);
    return null;
  };
}
