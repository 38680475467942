<ng-container *ngIf="(form$ | async) as form" >
  <div class="d-flex align-items-center justify-content-between">
    <h5>{{ signage.name }}</h5>
    <div>
      <app-kiosk-preview-launcher [app]="EsuiteApp.DigitalSignage" [id]="id"></app-kiosk-preview-launcher>
      <app-create-update-button
        [disabled]="form.invalid || !form.enabled"
        [isNew]="isNew"
        (create)="handleCreate(form.value)"
        (update)="handleUpdate(form.value)" label="Sign">
      </app-create-update-button>
    </div>
  </div>
  <div class="row">
    <div class="col-12" [formGroup]="form">

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>

      <div class="row">
        <mat-form-field appearance="outline" class="w-100 col-6">
          <mat-label>Width</mat-label>
          <input matInput type="number" formControlName="width">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100 col-6">
          <mat-label>Height</mat-label>
          <input matInput type="number" formControlName="height">
        </mat-form-field>
      </div>
<!--      <button mat-stroked-button color="accent" (click)="publishDraft()">Publish Draft</button>-->
<!--      <mat-slide-toggle class="ml-3" (change)="toggleEnabled($event)">Enabled</mat-slide-toggle>-->

      <mat-divider class="mt-3"></mat-divider>

      <button mat-raised-button color="primary" [matMenuTriggerFor]="menu" class="my-3">Add Frame</button>
      <mat-menu #menu="matMenu">
        <button *ngFor="let fr of frameTypes | keyvalue" mat-menu-item (click)="addFrame(fr.value.type)">
          <mat-icon class="text-muted">{{ fr.value.icon }}</mat-icon>&nbsp;
          <span>{{ fr.value.name }}</span>
        </button>
      </mat-menu>

      <mat-accordion [multi]="false">
        <mat-expansion-panel *ngFor="let frame of signage.frames; let i = index;">
          <mat-expansion-panel-header>
            <mat-panel-title class="d-flex align-items-center justify-content-between">
              <span>{{ frame.name }}</span>
              <small class="d-flex align-items-center text-muted">
                <span class="pr-2">{{ frameTypes[frame.type].name }}</span>
                <mat-icon>{{ frameTypes[frame.type].icon }}</mat-icon>
              </small>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-kiosk-frame
            [signage]="signage"

            [frame]="frame">
          </app-kiosk-frame>
        </mat-expansion-panel>
      </mat-accordion>

    </div>
  </div>
</ng-container>
