import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KiosksRoutingModule } from './kiosks-routing.module';
import { KioskListComponent } from './kiosk-list/kiosk-list.component';
import { KioskDetailComponent } from './kiosk-detail/kiosk-detail.component';
import { SharedModule } from '../../../shared/shared.module';
import { KioskCreateComponent } from './kiosk-create/kiosk-create.component';

/*
New V2 Module Structure
 */
@NgModule({
  declarations: [
    KioskListComponent,
    KioskDetailComponent,
    KioskCreateComponent,
  ],
  imports: [CommonModule, KiosksRoutingModule,  SharedModule],
})
export class KiosksModule {}
