import { CadenceView, CadenceViewEvent } from 'cadence';
import { CadenceFormControl, CadenceFormViewModel } from 'cadence';
import { CadenceFormAction } from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { MediaCollectionCreateRequest } from 'esuite-client';

@CadenceView()
export class MediaCollectionCreateForm extends CadenceFormViewModel {
  @CadenceFormControl({
    label: 'Collection Title',
  })
  name: string;
  @CadenceFormAction({
    label: 'Create',
  })
  create = () =>
    getEsuiteApi().mediaCollection.create(
      this._controlData$.value as MediaCollectionCreateRequest,
    );

  @CadenceViewEvent('action.create')
  afterFormPosted(): void {
    this._reset();
  }
}
