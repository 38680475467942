import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../../shared/components/dashboard/dashboard.component';
import { ShopifyCheckoutListComponent } from './shopify-checkout-list.component';
import { ShopifyCheckoutDetailComponent } from './shopify-checkout-detail.component';
import { AuthGuard } from 'esuite-dashboard/app/shared/guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'shopify_checkout',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ShopifyCheckoutListComponent,
      },
      {
        path: ':shopifyCheckoutId',
        component: ShopifyCheckoutDetailComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShopifyCheckoutRoutingModule {}
