import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  cadenceRouter,
  getCadence,
  provideLinkParams,
  ICadenceActionMetadata,
} from 'cadence';
import { CadenceViewModel } from 'cadence';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-cad-link',
  templateUrl: './cad-link.component.html',
  styleUrls: ['./cad-link.component.scss'],
})
export class CadLinkComponent implements OnInit {
  @Input()
  link: ICadenceActionMetadata;

  @Input()
  paramData: any;

  @Input()
  from: CadenceViewModel;

  href: string;
  queryParams = {};

  active$: Observable<boolean> = new BehaviorSubject(true);

  constructor() {}

  async handleAction(): Promise<void> {
     ;
    const resp = await this.link.action?.apply(this.from, [this.paramData]);
    this.from._postEvent('action.' + this.link.name, resp);
  }

  async ngOnInit(): Promise<void> {
    if (this.link.hidden === true) {
      this.active$ = new BehaviorSubject(false);
    } else if (typeof this.link.hidden === 'function') {
      const hiddenVal = this.link.hidden(this.from);
      if (hiddenVal instanceof Observable) {
        this.active$ = hiddenVal;
      } else if (hiddenVal instanceof Promise) {
        this.active$ = new BehaviorSubject(await hiddenVal);
      } else {
        this.active$ = new BehaviorSubject(hiddenVal);
      }
    }
  }
}
