/*
 * DigitalSignageCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';

interface IDigitalSignageCreateFormParams {}

@CadenceView({
  title: 'Create Digital Signage',
  viewType: 'dialog',
})
export class DigitalSignageCreateForm extends CadenceFormViewModel<IDigitalSignageCreateFormParams> {
  @CadenceFormControl()
  name: string;

  @CadenceFormControl(CadenceFormControlType.Number, { classes: 'w-50' })
  width: number;

  @CadenceFormControl(CadenceFormControlType.Number, { classes: 'w-50' })
  height: number;

  @CadenceFormAction({
    label: 'Create',
  })
  async create(): Promise<any> {
    /* Create logic goes here */
    return getEsuiteApi().digitalSignage.create({
      name: this.name,
      width: this.width,
      height: this.height,
    });
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    this._postEvent('dismissDialog');
  }
}
