/*
 * SelfCheckoutIntegrationCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { SelfCheckoutIntegrationType } from 'esuite-client';
import { getEsuiteApi } from '../../../../app/api/esuite-api';
import { quickEnumToOptions } from '../../../../app/shared/utils/quick-enum-to-options';

interface ISelfCheckoutIntegrationCreateFormParams {}

@CadenceView({
  title: 'Create Self Checkout Integration',
  viewType: 'dialog',
})
export class SelfCheckoutIntegrationCreateForm extends CadenceFormViewModel<ISelfCheckoutIntegrationCreateFormParams> {
  @CadenceFormControl()
  name: string;

  @CadenceFormControl(CadenceFormControlType.Select, {
    options: () => quickEnumToOptions(SelfCheckoutIntegrationType),
  })
  type: SelfCheckoutIntegrationType;

  @CadenceFormAction({
    label: 'Create',
  })
  async create(): Promise<any> {
    /* Create logic goes here */
    return getEsuiteApi().selfCheckoutIntegration.create({
      name: this.name,
      type: this.type,
    });
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    this._postEvent('dismissDialog');
  }
}
