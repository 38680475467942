/*
 * SelfCheckoutIntegrationUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../../app/api/esuite-api';

interface ISelfCheckoutIntegrationUpdateFormParams {
  id: number;
}

@CadenceView({
  title: 'Update Self Checkout Integration',
})
export class SelfCheckoutIntegrationUpdateForm extends CadenceFormViewModel<ISelfCheckoutIntegrationUpdateFormParams> {
  @CadenceFormControl()
  name: string;

  @CadenceFormControl({ required: false })
  shopifyShopName: string;

  @CadenceFormControl({ required: false })
  shopifyApiKey: string;

  @CadenceFormControl({ required: false })
  shopifyStorefrontAccessToken: string;

  @CadenceFormControl({ required: false })
  shopifyPassword: string;

  @CadenceFormControl({ required: false })
  shopifyDomain: string;

  @CadenceFormControl(CadenceFormControlType.Checkbox)
  useSalesChannel: boolean;

  @CadenceFormAction({
    label: 'Update',
  })
  async update(): Promise<any> {
    return getEsuiteApi().selfCheckoutIntegration.update(this._params.id, {
      name: this.name,
      shopify: {
        shopName: this.shopifyShopName,
        apiKey: this.shopifyApiKey,
        storefrontAccessToken: this.shopifyStorefrontAccessToken,
        password: this.shopifyPassword,
        domain: this.shopifyDomain,
        useSalesChannel: this.useSalesChannel,
      },
    });
  }

  @CadenceViewEvent('action.update')
  afterUpdate(): void {
    this._postEvent('dismissDialog');
  }

  async _init(): Promise<void> {
    const controlData = await getEsuiteApi().selfCheckoutIntegration.get(
      this._params.id,
    );
    this._controlData$.next({
      ...controlData,
      shopifyShopName: controlData.shopify?.shopName,
      shopifyApiKey: controlData.shopify?.apiKey,
      shopifyStorefrontAccessToken: controlData.shopify?.storefrontAccessToken,
      shopifyPassword: controlData.shopify?.password,
      shopifyDomain: controlData.shopify?.domain,
      useSalesChannel: controlData.shopify?.useSalesChannel,
    });
  }
}
