<ng-container *ngIf="
{
  activations: activations$ | async,
  directoryId: directoryId$ | async
} as view">

  <mat-form-field class="w-100" appearance="outline">
    <mat-label>Filter Kiosks</mat-label>
    <input [(ngModel)]="search" matInput type="search" placeholder="Filter Kiosks">
  </mat-form-field>
  <div style="max-height: 320px; overflow: auto">
    <table
      *ngIf="view.activations?.length; else noKiosks"
      mat-table
      [dataSource]="view.activations | filter: search"
      class="w-100"
    >
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>


      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">
          <a href="#" (click)="$event.preventDefault(); openEditor(element.id, view.directoryId)">{{ element.kiosk.name }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>Username</th>
        <td mat-cell *matCellDef="let element">{{ element.kiosk.username }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openEditor(element.id, view.directoryId)">
              <mat-icon>settings</mat-icon>
              <span>Settings</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>



    </table>
  </div>
  <ng-template #noKiosks>
    <div class="mx-auto text-center">
      <p class="text-center">No Kiosks to Show</p>
      <a [routerLink]="['new']">Create one now.</a>
    </div>
  </ng-template>

</ng-container>
