/*
 * FundUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceFormViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  CadenceFormControl,
  CadenceFormControlType,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { CadenceFormArray } from '../../models/form-view/cadence-form-array.decorator';
import { ProductStatus } from 'esuite-client';
import { startCase } from 'lodash';
import { ImageUploadComponent } from '../../../app/shared/components/image-upload/image-upload.component';
import { NoticeForm } from '../index';
import { quickEnumToOptions } from '../../../app/shared/utils/quick-enum-to-options';

interface IFundUpdateFormParams {
  id: number;
}

@CadenceView({
  title: 'Update Fund',
})
export class FundUpdateForm extends CadenceFormViewModel<IFundUpdateFormParams> {
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  @CadenceFormControl()
  title: string;

  @CadenceFormControl(CadenceFormControlType.Select, {
    multiple: true,
    required: false,
    options: async () => {
      const agreements = await getEsuiteApi().agreement.list();
      return agreements.map((a) => ({
        label: a.name,
        value: a.id,
      }));
    },
  })
  agreementIds: number[];

  @CadenceFormArray(() => NoticeForm, { classes: 'w-50' })
  notices: NoticeForm[];

  @CadenceFormControl(CadenceFormControlType.Select, {
    options: () => {
      return quickEnumToOptions(ProductStatus);
    },
  })
  status: ProductStatus;

  @CadenceFormControl(CadenceFormControlType.Checkbox, {
    default: () => false,
  })
  taxable: boolean;

  @CadenceFormAction({
    label: 'Update',
  })
  async update(): Promise<any> {
    return getEsuiteApi().fund.update(this.id, {
      title: this.title,
      status: this.status,
      taxable: this.taxable,
      imageId: this.imageId ? this.imageId : null,
      agreementIds: this.agreementIds,
      notices: this.notices.map((n) => n.notice),
    });
  }

  @CadenceViewEvent('action.update')
  afterUpdate(): void {
    alert('Fund updated successfully.');
  }

  @CadenceFormControl(CadenceFormControlType.Custom, {
    component: () => ImageUploadComponent,
    required: false,
  })
  imageId: number;

  async _init() {
    const resp = await getEsuiteApi().fund.get(this._params.id);
    this._controlData$.next({ ...resp, imageId: resp.image?.id });
    this._formArrayData$.next({
      notices: resp.notices
        ? resp.notices.map((notice) => {
            const form = new NoticeForm();
            form.notice = notice;
            return form;
          })
        : [],
    });
  }
}
