import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from 'ngx-file-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ImageUploadResponse } from 'esuite-client';

export type ApiImageUploader = (
  files: NgxFileDropEntry[],
  imageKey: string,
  controlKey: string
) => Promise<any>;

@Component({
  selector: 'app-api-component',
  template: `app-api-component`,
})
export abstract class ApiComponent implements OnInit, OnDestroy {
  protected localLoading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  errors: any = {};
  newImages: { [key: string]: ImageUploadResponse } = {};
  newUploads: { [key: string]: boolean } = {};
  form: FormGroup;
  protected formSub: Subscription;
  public files: NgxFileDropEntry[] = [];

  get loading(): boolean {
    return this.localLoading$.value;
  }
  set loading(val: boolean) {
    this.localLoading$.next(val);
  }
  get loading$(): Observable<boolean> {
    return this.localLoading$.asObservable();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }

  resetErrors(): void {
    this.errors = {};
    if (this.form) {
      this.form.setErrors({});
    }
  }

  displayErrors(errors): void {
    this.errors = errors;
    for (const err of Object.keys(errors)) {
      if (this.form.controls[err]) {
        this.form.controls[err].setErrors({ incorrect: true });
      }
    }
  }

  public makeImageUploader(
    uploadFactory: (data: File) => Promise<ImageUploadResponse>
  ): ApiImageUploader {
    return async (
      files: NgxFileDropEntry[],
      imageKey: string,
      controlKey: string
    ) => {
      this.loading = true;
      this.files = files;
      for (const droppedFile of files) {
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file(async (file: File) => {
            // Here you can access the real file
            const ni = await uploadFactory(file);
            this.form.controls[controlKey].setValue(ni.id);
            this.newUploads[imageKey] = false;
            this.newImages[imageKey] = ni;
          });
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        }
      }
      this.loading = false;
    };
  }
}
