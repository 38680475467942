import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DirectoryListing, Directory } from 'esuite-client';
import { AlertService } from '../../../../../shared/components/alert/alert.service';
import { pluckAsInt } from 'esuite-dashboard/app/shared/utils/pluck-as-int';
import { map, switchMap, tap } from 'rxjs/operators';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-directory-listing-detail',
  templateUrl: './directory-listing-detail.component.html',
  styleUrls: ['./directory-listing-detail.component.scss'],
})
export class DirectoryListingDetailComponent implements OnInit {
  directoryId$ = this.activatedRoute.params.pipe(pluckAsInt('directoryId'));

  listingId$ = this.activatedRoute.params.pipe(
    pluckAsInt('directoryListingId')
  );

  listing$ = this.listingId$.pipe(
    switchMap((listingId) => getEsuiteApi().directoryListing.get(listingId))
  );

  form$ = this.listing$.pipe(map((listing) => this.makeUpdateForm(listing)));
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute
  ) {}

  floors$ = this.directoryId$.pipe(
    switchMap((directoryId) => getEsuiteApi().directoryFloor.list(directoryId))
  );

  makeUpdateForm(listing: DirectoryListing): FormGroup {
    return this.fb.group({
      floorId: [listing.floorId, Validators.required],
      unit: [listing.unit, Validators.required],
      title: [listing.title, Validators.required],
      logoImageId: [listing.logoImage?.id],
      bannerImageId: [listing.bannerImage?.id],
      subtitle: [listing.subtitle],
      descriptionHtml: [listing.descriptionHtml],
      floorX: [listing.floorX],
      floorY: [listing.floorY],
    });
  }

  ngOnInit(): void {}

  handleUpdate(listingId: number, form: FormGroup) {
    form.disable();
    this.alertService
      .runWithLoader(
        () => getEsuiteApi().directoryListing.update(listingId, form.value),
        'Update successful',
        'Error updating'
      )
      .finally(() => form.enable());
  }
}
