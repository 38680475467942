/*
 * OrganizationCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { StorageUploadType } from 'esuite-client';

interface IOrganizationCreateFormParams {}

@CadenceView({
  title: 'Register Organization',
  viewType: 'dialog',
})
export class OrganizationCreateForm extends CadenceFormViewModel<IOrganizationCreateFormParams> {
  @CadenceFormControl()
  organizationName: string;

  @CadenceFormControl()
  organizationHandle: string;

  @CadenceFormControl()
  firstName: string;

  @CadenceFormControl()
  lastName: string;

  @CadenceFormControl(CadenceFormControlType.Email)
  email: string;

  @CadenceFormControl(CadenceFormControlType.Password)
  password: string;

  @CadenceFormControl(CadenceFormControlType.File, { required: false })
  import: File;

  @CadenceFormAction({
    label: 'Create',
  })
  async registerOrganization(): Promise<any> {
    // let importUrl;
    // if (this.import) {
    //   let importUpload = await getEsuiteApi().storageUpload.create('uploads', {
    //     uploadType: StorageUploadType.PresignedPost,
    //     name: `${this.organizationHandle}-db-import-${Date.now()}`,
    //   });
    //   const presignedPost = importUpload.presignedPost;
    //   const formData = new FormData();
    //   formData.append('Content-Type', this.import.type);
    //   Object.entries(presignedPost.fields).forEach(([k, v]) => {
    //     formData.append(k, v as string);
    //   });
    //
    //   formData.append('key', presignedPost.key);
    //   formData.append('acl', 'public-read');
    //   formData.append('file', this.import); // must be the last one
    //   await fetch(presignedPost.url, {
    //     method: 'POST',
    //     body: formData,
    //   });
    //   importUpload = await getEsuiteApi().storageUpload.complete('uploads', {
    //     uploadId: importUpload.id,
    //   });
    //   importUrl = importUpload.url;
    // }
    //  ;
    // /* Create logic goes here */
    // try {
    //   const registration = await getEsuiteApi().register.registerOrganization({
    //     organizationName: this.organizationName,
    //     organizationHandle: this.organizationHandle,
    //     firstName: this.firstName,
    //     lastName: this.lastName,
    //     email: this.email,
    //     password: this.password,
    //     importUrl,
    //   });
    //   alert('Organization registered successfully.');
    //   return registration.organization;
    // } catch (e) {
    //   alert('Error registering organization.');
    // }
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    this._postEvent('dismissDialog');
  }
}
