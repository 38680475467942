import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractFormComponent } from 'esuite-dashboard/app/shared/classes/abstract-form.component';
import {
  SelfCheckoutResponse,
  SelfCheckoutTemplateCreateRequest,
  SelfCheckoutTemplateResponse,
  SelfCheckoutTemplateTarget,
  SelfCheckoutTemplateUpdateRequest,
} from 'esuite-client';
import { startCase } from 'lodash';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { quickEnumToOptions } from 'esuite-dashboard/app/shared/utils/quick-enum-to-options';

@Component({
  selector: 'app-self-checkout-template-detail',
  templateUrl: './self-checkout-template-detail.component.html',
  styleUrls: ['./self-checkout-template-detail.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfCheckoutTemplateDetailComponent
  extends AbstractFormComponent<
    SelfCheckoutTemplateResponse,
    SelfCheckoutTemplateCreateRequest,
    SelfCheckoutTemplateUpdateRequest
  >
  implements OnInit
{
  templateTargets = quickEnumToOptions(SelfCheckoutTemplateTarget);
  selfCheckouts: SelfCheckoutResponse[] = [];
  constructor(private fb: FormBuilder) {
    super();
  }

  @Output()
  created: EventEmitter<SelfCheckoutTemplateResponse> = new EventEmitter<SelfCheckoutTemplateResponse>();

  @Output()
  updated: EventEmitter<SelfCheckoutTemplateResponse> = new EventEmitter<SelfCheckoutTemplateResponse>();

  async handleCreate(dto: SelfCheckoutTemplateCreateRequest): Promise<void> {
    const created = await getEsuiteApi().selfCheckoutTemplate.create(dto);
    this.id = created.id;
    this.created.emit(created);
  }

  async handleUpdate(dto: SelfCheckoutTemplateUpdateRequest): Promise<void> {
    const updated = await getEsuiteApi().selfCheckoutTemplate.update(
      this.id,
      dto
    );
    this.id = updated.id;
    this.updated.emit(updated);
  }

  async loadFormData(id: number): Promise<void> {}

  async ngOnInit(): Promise<void> {
    const [selfCheckouts] = await Promise.all([
      getEsuiteApi().selfCheckout.list(),
    ]);
    this.selfCheckouts = selfCheckouts;
  }

  makeCreateForm(): Promise<FormGroup> | FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      selfCheckoutIds: [[]],
      target: ['', Validators.required],
      template: [''],
    });
  }

  async makeUpdateForm(id: number): Promise<FormGroup> {
    const form = await getEsuiteApi().selfCheckoutTemplate.get(id);
    return this.fb.group({
      name: [form.name, Validators.required],
      selfCheckoutIds: [form.selfCheckoutIds],
      target: [form.target, Validators.required],
      template: [form.template],
    });
  }
}
