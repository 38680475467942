import { Observable } from 'rxjs';

export interface ICadenceActionMetadata<From = any, To = any, Params = any> {
  name: string;
  label?: string;
  action: () => void | Promise<void>;
  hidden?:
    | boolean
    | ((form: From) => Observable<boolean> | Promise<boolean> | boolean);
}
