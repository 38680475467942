import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { uniqBy } from 'lodash';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';

import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'esuite-dashboard/app/shared/components/confirm-dialog/confirm-dialog.component';
import { debounceTime } from 'rxjs/operators';

import {
  Activation,
  DigitalSignageLayerLinkTarget,
  DigitalSignageLayerLinkType,
  EsuiteApp,
  DigitalSignageSlideLayerModel,
  DigitalSignageSlideLayerType,
  EsuiteAppResponse,
  DigitalSignageResponse,
  SelfCheckoutResponse,
  PhotoboothResponse,
  ShopifyCheckoutResponse,
} from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { quickEnumToOptions } from 'esuite-dashboard/app/shared/utils/quick-enum-to-options';

@Component({
  selector: 'app-kiosk-layer-editor',
  templateUrl: './kiosk-layer-editor.component.html',
  styleUrls: ['./kiosk-layer-editor.component.scss'],
})
export class KioskLayerEditorComponent implements OnInit, OnDestroy {
  private localLayers$: BehaviorSubject<DigitalSignageSlideLayerModel[]> =
    new BehaviorSubject([]);

  digitalSignage: DigitalSignageResponse[] = [];
  photobooths: PhotoboothResponse[] = [];
  selfCheckouts: SelfCheckoutResponse[] = [];
  EsuiteApp = EsuiteApp;
  esuiteApps = quickEnumToOptions(EsuiteApp);
  DigitalSignageLayerLinkTarget = DigitalSignageLayerLinkTarget;
  DigitalSignageLayerLinkType = DigitalSignageLayerLinkType;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @Input()
  layerFormControl: AbstractControl;
  form: FormGroup;
  layerFormSub: Subscription;
  SlideType = DigitalSignageSlideLayerType;
  shopifyCheckouts: ShopifyCheckoutResponse[] = [];

  @Input()
  get layers(): DigitalSignageSlideLayerModel[] {
    return this.localLayers$.value;
  }

  set layers(ls: DigitalSignageSlideLayerModel[]) {

    this.localLayers$.next(ls);
    this.initLayerForms();
  }

  get layerForms(): FormArray {
    return this.form.controls.layers as FormArray;
  }

  @Input()
  slideSize: [number, number] = [null, null];

  constructor(private fb: FormBuilder, private dialog: MatDialog) {
    this.form = this.fb.group({
      layers: this.fb.array([]),
    });
    this.layerFormSub = this.form.valueChanges
      .pipe(debounceTime(500))
      .subscribe((changes) => {
        if (this.layerFormControl) {
          this.layerFormControl.setValue(this.layerForms.value);
        }
      });
  }

  private initLayerForms(): void {
    if (this.layers) {
      this.layers.forEach((layer) => {
        const layerForm = this.makeLayerForm(layer.type);
        layerForm.patchValue(layer);
        if (layer.link) {
          layerForm.get('link').patchValue(layer.link);
        }
        this.layerForms.push(layerForm);
      });
    }
  }

  ngOnDestroy(): void {
    this.layerFormSub.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    const [digitalSignage, selfCheckouts, photobooths, shopifyCheckouts] =
      await Promise.all([
        getEsuiteApi()
          .digitalSignage.list()
          .catch((e) => []),
        getEsuiteApi()
          .selfCheckout.list()
          .catch((e) => []),
        getEsuiteApi()
          .photobooth.list()
          .catch((e) => []),
        getEsuiteApi()
          .shopifyCheckout.list()
          .catch((e) => []),
      ]);
    this.digitalSignage = digitalSignage;
    this.selfCheckouts = selfCheckouts;
    this.photobooths = photobooths;
    this.shopifyCheckouts = shopifyCheckouts;
  }

  addLayer(type: DigitalSignageSlideLayerType): void {
    const form = this.makeLayerForm(type);
    this.layerForms.push(form);
  }

  makeLayerForm(
    type: DigitalSignageSlideLayerType,
    width = 0,
    height = 0
  ): FormGroup {
    const group = this.fb.group({
      layout: [
        {
          width: width + 'px',
          height: height + 'px',
          widthPx: width,
          heightPx: height,
        },
      ],
      type: [type, Validators.required],
      styles: this.fb.array([]),
      text: [''],
      fontSize: [14],
      color: ['#555555'],
      vAlign: ['center'],
      hAlign: ['center'],
      imageId: [null],
      image: [null],
      videoId: [null],
      iframeUrl: [null],
      iframeCustomCss: [null],
      iframeCustomJs: [null],
      link: this.fb.group({
        target: [DigitalSignageLayerLinkTarget.Full],
        type: [DigitalSignageLayerLinkType.None],
        instanceId: [null],
        appName: [null],
      }),
    });

    return group;
  }

  orderLayers(event): void {
    const { previousIndex, currentIndex } = event;
    const layerForm = this.layerForms.controls[previousIndex];
    this.layerForms.removeAt(previousIndex);
    this.layerForms.insert(currentIndex, layerForm);
  }

  async removeLayer(layerInd: number): Promise<void> {
    const confirm = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Layer Delete',
        messageHTML: `Are you sure you want to remove this layer?`,
        warn: true,
      },
    });
    const result = await confirm.afterClosed().toPromise();
    if (result) {
      this.layerForms.removeAt(layerInd);
    }
  }
}
