<mat-card>
  <mat-card-title>
    My Organizations
  </mat-card-title>
  <mat-card-content>
    <table mat-table [dataSource]="organizations" class="w-100">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
         —
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (change)="$event.checked ? this.selected = row : null"
                        [checked]="this.selected === row">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th class="px-20" mat-header-cell *matHeaderCellDef> Name </th>
        <td class="px-20" mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['select', 'name']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['select', 'name'];"></tr>
    </table>

    <div class="p-10 text-center" *ngIf="!organizations.length">
      No organizations to select.
    </div>

    <mat-paginator *ngIf="organizations.length > 5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="activeModal.close(false)">Cancel</button>
    <button mat-button color="primary" [disabled]="!selected" (click)="completeSelection()">Done</button>
  </mat-card-actions>
</mat-card>
