<ng-container *ngIf="form$ | async as form">
  <div *ngIf="frame" class="d-flex flex-column">
    <img  style="max-height: 480px; width: 100%; object-fit: contain" class="img-fluid my-3" [src]="frame.overlayImage?.url">
    <app-box-position [boxFormControl]="form.get('overlayImageLayout')" [hideBackground]="true"></app-box-position>
  </div>
  <mat-dialog-actions>
    <button mat-stroked-button color="warn" (click)="handleDelete()">Remove</button>
    <button mat-raised-button color="primary" (click)="handleUpdate(form.value)">Save</button>
  </mat-dialog-actions>
</ng-container>
