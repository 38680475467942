import {
  CadenceColumn,
  CadenceListViewModel,
  CadenceStackViewChild,
  CadenceStackViewModel,
  CadenceStackViewTab,
  CadenceView,
  CadenceViewSubscription,
} from 'cadence';
import { CheckoutUpdateForm } from '../index';
import { CheckoutLineItemListView } from './checkout-line-item';
import { CheckoutLineItemResponse, CheckoutResponse } from 'esuite-client';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import * as currency from 'currency.js';
import { map } from 'rxjs/operators';
import { toCurrency } from '../../util/toCurrency';
import { CheckoutIntegrationListView } from './checkout-integration';

@CadenceView()
export class CheckoutTotals extends CadenceListViewModel<
  { line: string; amount: string },
  { checkout: CheckoutResponse }
> {
  @CadenceColumn()
  line: string;

  @CadenceColumn()
  amount: string;
}

@CadenceView({
  classes: 'row',
  title: (view: CheckoutDetailView) =>
    view
      .checkoutLineItems()
      ._rows$.value$.pipe(
        map((rows) => 'Checkout: (' + rows.length + ' items)'),
      ),
})
export class CheckoutDetailView extends CadenceStackViewModel<{ id: number }> {
  // @CadenceStackViewTab(() => CheckoutDetailView, [
  //   'checkoutForm',
  //   'checkoutTotals',
  //   'checkoutLineItems',
  // ])
  // general: string;
  //
  // @CadenceStackViewTab(() => CheckoutDetailView, ['checkoutIntegrations'])
  // integrations: string;

  @CadenceStackViewChild(() => CheckoutUpdateForm, { classes: 'col-md-8' })
  checkoutForm() {
    const form = new CheckoutUpdateForm();
    form._params = { id: this._params.id };
    return form;
  }

  @CadenceStackViewChild(() => CheckoutTotals, { classes: 'col-md-4' })
  checkoutTotals() {
    const form = new CheckoutTotals();
    return form;
  }

  @CadenceStackViewChild(() => CheckoutLineItemListView)
  checkoutLineItems() {
    const form = new CheckoutLineItemListView();
    form._params = { checkoutId: this._params.id };
    return form;
  }
  //
  // @CadenceStackViewChild(() => CheckoutIntegrationListView)
  // checkoutIntegrations() {
  //   const form = new CheckoutIntegrationListView();
  //   form._params = { checkoutId: this._params.id };
  //   return form;
  // }

  @CadenceViewSubscription(
    () => CheckoutDetailView,
    (view) => view.checkoutLineItems()._rows$.value$,
  )
  async updateTotals(rows: CheckoutLineItemResponse[]) {
    const totals = this.checkoutTotals();
    const updatedCheckout = await getEsuiteApi().checkout.get(this._params.id);
    this.checkoutForm()._controlData$.next(updatedCheckout);
    totals._rows$.set([
      {
        amount: toCurrency(updatedCheckout.subtotalPrice),
        line: 'Subtotal',
      },
      {
        line: 'Taxes',
        amount: toCurrency(updatedCheckout.totalTax),
      },
      {
        line: 'Total',
        amount: toCurrency(updatedCheckout.totalPrice),
      },
    ]);
  }
}
