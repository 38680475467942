/*
 * UserCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { Role, UserCreateRequest } from 'esuite-client';

interface IUserCreateFormParams {}

@CadenceView({
  title: 'Create User',
  viewType: 'dialog',
})
export class UserCreateForm extends CadenceFormViewModel<IUserCreateFormParams> {
  @CadenceFormControl(CadenceFormControlType.Email)
  email: string;

  @CadenceFormControl()
  firstName: string;

  @CadenceFormControl()
  lastName: string;

  @CadenceFormControl(CadenceFormControlType.Password)
  password: string;

  @CadenceFormControl(CadenceFormControlType.Select, {
    multiple: true,
    options: async () => {
      const roles = await getEsuiteApi().role.list();
      return roles.map((r) => ({
        label: r.title,
        value: r.name,
      }));
    },
  })
  roles: Role[];

  @CadenceFormAction({
    label: 'Create',
  })
  async create(): Promise<any> {
    /* Create logic goes here */
    return getEsuiteApi().user.create({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      password: this.password,
      roles: this.roles,
    });
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    this._postEvent('dismissDialog');
  }
}
