/*
 * CheckoutListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceActionColumn,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceListViewRow,
} from 'cadence';
import { CheckoutCreateForm } from './checkout-create';
import { CheckoutUpdateForm } from './checkout-update';
import { CheckoutDeleteForm } from './checkout-delete';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { CheckoutDetailView } from '../index';
import { toCurrency } from '../../util/toCurrency';

@CadenceView({
  title: 'Checkouts',
  showInMenu: '/self-checkouts',
})
export class CheckoutListView extends CadenceListViewModel<any> {
  @CadenceColumn()
  id: number;

  @CadenceColumn()
  email: string;

  @CadenceColumn()
  token: string;

  @CadenceColumn(() => CheckoutListView, {
    value: (row) => toCurrency(row.totalPrice),
  })
  totalPrice: string;

  @CadenceAction({ label: 'Create' })
  createForm(): void {
    cadenceRouter.navigate(CheckoutCreateForm, {
      from: this,
      events: {
        'action.create': 'created',
      },
    });
  }

  @CadenceActionColumn({ label: 'Delete' })
  deleteForm(row: CadenceListViewRow<CheckoutListView>): void {
    cadenceRouter.navigate(CheckoutDeleteForm, {
      from: this,
      events: {
        'action.delete': 'deleted',
      },
      params: {
        id: row.id,
      },
    });
  }

  @CadenceActionColumn({ label: 'Edit' })
  updateForm(row: CadenceListViewRow<CheckoutListView>): void {
    cadenceRouter.navigate(CheckoutDetailView, {
      from: this,
      params: {
        id: row.id,
      },
      events: {
        'action.update': 'updated',
      },
    });
  }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  @CadenceViewEvent('updated')
  update(event: any): void {
    this._rows$.updateBy((c) => c.id === event.data.id, event.data);
  }

  @CadenceViewEvent('deleted')
  remove(event: any): void {
    this._rows$.deleteBy((c) => c.id === event.from._params.id);
  }

  async _init(): Promise<void> {
    this._rows$.set(await getEsuiteApi().checkout.list());
  }
}
