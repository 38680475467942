import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { UserService } from 'esuite-dashboard/app/shared/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private userService: UserService,
    private router: Router,
  ) {
    this.form = fb.group({
      username: [
        null,
        Validators.compose([Validators.required, Validators.email]),
      ],
      password: [null, Validators.required],
    });
  }

  ngOnInit(): void {}

  login() {
    return this.alertService.runWithLoader(
      async () => {
        const { token } = await getEsuiteApi().auth.authenticate(
          'user',
          this.form.value
        );
        localStorage.setItem('accessToken', token);
        this.router.navigate(['/']);
        return this.userService.getUser();
      },
      (user) => `Hi ${user.firstName},<br>Welcome back to E Suite.`,
      'Unable to log you in.<br>Check your username and password and try again.'
    );
  }
}
