import { bootstrapCadence } from 'cadence/cadence-views';

bootstrapCadence();

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'esuite-dashboard/app/app.module';

if (!(window.location.hostname.indexOf('myeflyndev:3000') > -1)) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
