import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from 'esuite-dashboard/app/shared/components/dashboard/dashboard.component';
import { PaymentGatewayListComponent } from 'esuite-dashboard/app/pages/payment-gateway/payment-gateway-list/payment-gateway-list.component';
import { PaymentGatewayDetailComponent } from 'esuite-dashboard/app/pages/payment-gateway/payment-gateway-detail/payment-gateway-detail.component';
import { AuthGuard } from 'esuite-dashboard/app/shared/guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'payments',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
      {
        path: '',
        component: PaymentGatewayListComponent,
      },
      {
        path: ':paymentGatewayId',
        component: PaymentGatewayDetailComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
})
export class PaymentGatewayRoutingModule {}
