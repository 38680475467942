import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestAppListComponent } from './rest-app-list/rest-app-list.component';
import { RestAppDetailComponent } from './rest-app-detail/rest-app-detail.component';

import { SharedModule } from 'esuite-dashboard/app/shared/shared.module';
import { RestAppRoutingModule } from 'esuite-dashboard/app/pages/rest-app/rest-app-routing.module';

@NgModule({
  declarations: [RestAppListComponent, RestAppDetailComponent],
  imports: [CommonModule, SharedModule, RestAppRoutingModule],
})
export class RestAppModule {}
