import { CadenceFormViewModel } from '../models';
import { Observable } from 'rxjs';

export enum CadenceFormControlType {
  // Implemented:
  File = 'file',
  ContentEditor = 'content_editor',
  Text = 'text',
  Textarea = 'textarea',
  Select = 'select',
  Checkbox = 'checkbox',
  Date = 'date',
  DatetimeLocal = 'datetime_local',
  Password = 'password',
  Email = 'email',
  Tel = 'tel',
  Time = 'time',
  Url = 'url',
  Number = 'number',
  Month = 'month',
  Html = 'html',
  Custom = 'custom',

  Message = 'message',

  // To Be Implemented:
  Button = 'button',
  Color = 'color',
  Hidden = 'hidden',
  Radio = 'radio',
  Range = 'range',
}

type CadenceFormControlBaseOptions<Form> = {
  hidden:
    | boolean
    | ((form: Form) => Observable<boolean> | Promise<boolean> | boolean);
  disabled:
    | boolean
    | ((form: Form) => Observable<boolean> | Promise<boolean> | boolean);
  name: string;
  classes?: string;
  component?: () => any;
  props?:
    | object
    | ((form: Form) => Observable<object> | Promise<object> | object);
  label: string;
  type: () => any;
  required: boolean | string;
  default?: (form: Form) => any;
  // value: (inputValue: any, data: Form) => any;
  controlType: CadenceFormControlType;
};

type CadenceFormControlTypeSelectOptions<Form> =
  CadenceFormControlBaseOptions<Form> & {
    controlType: CadenceFormControlType.Select;
    multiple?: boolean;
    options: (
      form: Form,
    ) =>
      | { label: string; value: any }[]
      | Promise<{ label: string; value: any }[]>
      | Observable<{ label: string; value: any }[]>;
  };

type CadenceFormControlTypeCustomOptions<Form> =
  CadenceFormControlBaseOptions<Form> & {
    controlType: CadenceFormControlType.Custom;
    component: () => any;
    props?: (form: Form) => any;
  };

export type ICadenceFormControlMetadata<
  Type extends CadenceFormControlType = CadenceFormControlType,
  Form extends CadenceFormViewModel = CadenceFormViewModel,
> = Type extends CadenceFormControlType.Select
  ? CadenceFormControlTypeSelectOptions<Form>
  : Type extends CadenceFormControlType.Custom
  ? CadenceFormControlTypeCustomOptions<Form>
  : CadenceFormControlBaseOptions<Form>;

export interface ICadenceFormActionMetadata<Form = any> {
  name: string;
  label: string;
  eventName: string;
  hidden?:
    | boolean
    | ((form: Form) => Observable<boolean> | Promise<boolean> | boolean);
  disabled?:
    | boolean
    | ((form: Form) => Observable<boolean> | Promise<boolean> | boolean);
}

/*
Input types
 */
