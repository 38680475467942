/*
 * AdmissionCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  cadenceRouter,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { AdmissionCreateRequest, ProductStatus } from 'esuite-client';
import { startCase } from 'lodash';
import { quickEnumToOptions } from '../../../app/shared/utils/quick-enum-to-options';

interface IAdmissionCreateFormParams {}

@CadenceView({
  title: 'Create Admission',
  viewType: 'dialog',
})
export class AdmissionCreateForm extends CadenceFormViewModel<IAdmissionCreateFormParams> {
  @CadenceFormControl()
  title: string;

  @CadenceFormControl(CadenceFormControlType.Select, {
    options: () => {
      return quickEnumToOptions(ProductStatus);
    },
  })
  status: ProductStatus;

  @CadenceFormControl(CadenceFormControlType.Checkbox, {
    default: () => false,
  })
  taxable: boolean;

  @CadenceFormAction({
    label: 'Create',
  })
  create(): Promise<any> {
    /* Create logic goes here */
    return getEsuiteApi().admission.create({
      title: this.title,
      status: this.status,
      taxable: this.taxable,
    });
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    this._postEvent('dismissDialog');
  }
}
