import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewSubscription,
} from 'cadence';
import { getEsuiteApi } from '../../../../../app/api/esuite-api';
import {
  FundOptionResponse,
  CheckoutLineItemResponse,
  ProductType,
} from 'esuite-client';

@CadenceView({
  viewType: 'dialog',
})
export class AddFundLineItem extends CadenceFormViewModel<{
  checkoutId: number;
  lineItem?: CheckoutLineItemResponse;
}> {
  @CadenceFormControl({
    hidden: true,
    default: () => 'fund',
  })
  productType: 'fund';

  @CadenceFormControl()
  customAmount: number;

  @CadenceFormControl(CadenceFormControlType.Select, {
    disabled: (form: AddFundLineItem) => !!form._params.lineItem,
    options: async (form: AddFundLineItem) => {
      return (await getEsuiteApi().fund.list()).map((a) => ({
        label: a.title,
        value: a.id,
      }));
    },
  })
  productId: number;

  @CadenceFormAction()
  async save() {
    const resp = await getEsuiteApi().checkoutLineItem.create(
      this._params.checkoutId,
      {
        productType: ProductType.Fund,
        productId: this.productId,
        quantity: 1,
        fundOption: {
          customAmount: parseFloat(`${this.customAmount}`),
        },
      }
    );
    this._postEvent('dismissDialog');
    return resp;
  }
}
