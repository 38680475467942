import {
  ICadenceStackViewChildMetadata,
  ICadenceStackViewMetadata,
} from './cadence-stack-view-child.metadata';
import { startCase } from 'lodash';
import { CadenceObjectType, CadenceSubscriptionFactory } from '../../types';
import { CadenceViewModel } from 'cadence/models';
import { CadenceStackViewModel } from 'cadence/models';
import { getCadence } from '../../cadence';
import 'reflect-metadata';
import { filter, take } from 'rxjs/operators';

interface ICadenceStackViewChildOptions<
  StackView extends CadenceViewModel,
  Child extends CadenceViewModel
> {
  title?: string;
  classes?: string;
}
/*
Allows a function to be called once to render the view
 */
export function CadenceStackViewChild<
  StackView extends CadenceStackViewModel,
  Child extends CadenceViewModel
>(
  child: () => CadenceObjectType<Child>,
  options?: ICadenceStackViewChildOptions<StackView, Child>,
): MethodDecorator {
  return (
    target: StackView,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ) => {
    const childOps: ICadenceStackViewChildMetadata = {
      name: propertyKey,
      title: startCase(propertyKey),
      ...options,
      view: (parent: CadenceViewModel) => parent[propertyKey].apply(parent),
    };
    getCadence().stackViewChildren.set(
      target.constructor.name,
      propertyKey,
      childOps,
    );

    const originalMethod = descriptor.value;
    const resultMap: Map<string, CadenceViewModel> = new Map();

    descriptor.value = function (...args: any[]) {
       ;
      if (!this) {
        return;
      }
      const registration = (this as CadenceViewModel)._registration;
      if (resultMap.has(registration)) {
        return resultMap.get(registration);
      }
      resultMap.set(
        (this as CadenceViewModel)._registration,
        originalMethod.apply(this, args),
      );
      (this as CadenceViewModel)._events$
        .pipe(
          filter((event) => event.name === 'destroyView'),
          take(1),
        )
        .subscribe((e) => resultMap.delete(registration));
      return resultMap.get(registration);
    };
  };
}
