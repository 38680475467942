/*
 * TransactionCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  cadenceRouter,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { TransactionListView } from './transaction-list';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { TransactionCreateRequest, TransactionMethod, TransactionType } from 'esuite-client';
import { startCase } from 'lodash';
import { quickEnumToOptions } from '../../../app/shared/utils/quick-enum-to-options';

interface ITransactionCreateFormParams {
  orderId: number;
}

@CadenceView({
  title: 'Create Transaction',
  viewType: 'dialog',
})
export class TransactionCreateForm extends CadenceFormViewModel<ITransactionCreateFormParams> {
  @CadenceFormControl(CadenceFormControlType.Number)
  amount: number;

  @CadenceFormControl(CadenceFormControlType.Select, {
    default: () => 'Purchase',
    options: () => {
      return [
        {
          label: 'Purchase',
          value: 'purchase',
        },
        {
          label: 'Void',
          value: 'void',
        },
        {
          label: 'Refund',
          value: 'refund',
        },
      ];
    },
  })
  type: TransactionType;

  @CadenceFormControl(CadenceFormControlType.Select, {
    default: () => 'CAD',
    options: () => {
      return [
        {
          label: 'USD',
          value: 'USD',
        },
        {
          label: 'CAD',
          value: 'CAD',
        },
      ];
    },
  })
  currency: 'USD' | 'CAD';

  @CadenceFormControl({ required: false })
  message: string;

  @CadenceFormControl(CadenceFormControlType.Select, {
    options: () => {
      return quickEnumToOptions(TransactionMethod);
    },
  })
  method: string;

  @CadenceFormAction({
    label: 'Create',
  })
  async create(): Promise<any> {
    /* Create logic goes here */
    // order id
    // activation id
    // revenue center id
    // messages
    // cash
    // status
    return getEsuiteApi().transaction.create(<TransactionCreateRequest>{
      method: this.method,
      orderId: this._params.orderId,
      currency: this.currency,
      amount: this.amount * 100,
      type: this.type,
      messages: [
        { name: 'Cash Note', key: 'cashNote', value: this.message as any },
      ],
      cash: true,
    });
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    this._postEvent('dismissDialog');
  }
}
