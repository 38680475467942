/*
 * FundDetailView
 * Generated delete resource from cadence/scripts/generate
 */

import {
  CadenceStackViewModel,
  CadenceView,
  CadenceStackViewTab,
  CadenceStackViewChild,
} from 'cadence';
import {
  FundOptionListView,
  FundUpdateForm,
} from '../index';

interface IFundDetailViewParams {
  id: number;
}

@CadenceView({
  title: 'Fund Details',
})
export class FundDetailView extends CadenceStackViewModel<IFundDetailViewParams> {
  @CadenceStackViewTab(() => FundDetailView, ['updateFund'])
  general: string;

  @CadenceStackViewTab(() => FundDetailView, ['admissionOptions'])
  options: string;

  @CadenceStackViewChild(() => FundUpdateForm, {
    title: 'Fund Update',
  })
  updateFund() {
    const form = new FundUpdateForm();
    form._params = {
      id: this._params.id,
    };
    return form;
  }

  @CadenceStackViewChild(() => FundOptionListView, {
    title: 'Fund Options',
  })
  admissionOptions() {
    const form = new FundOptionListView();
    form._params = {
      id: this._params.id,
    };
    return form;
  }
}
