import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { createComponentIO } from 'esuite-util/components';

import { AbstractFormComponent } from '../../classes/abstract-form.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-update-button',
  templateUrl: './create-update-button.component.html',
  styleUrls: ['./create-update-button.component.scss'],
})
export class CreateUpdateButtonComponent {
  private isNewIO = createComponentIO(false);
  private disabledIO = createComponentIO(false);
  private labelIO = createComponentIO('');

  @Input()
  formComponent: [AbstractFormComponent<any, any, any>, FormGroup];

  private get form(): FormGroup {
    return this.formComponent?.[1];
  }

  private get component(): AbstractFormComponent<any, any, any> {
    return this.formComponent?.[0];
  }

  @Input()
  routerLink: string[] = [];

  @Input()
  get isNew(): boolean {
    return this.component ? this.component.isNew : this.isNewIO.get();
  }

  set isNew(n: boolean) {
    this.isNewIO.set(n);
  }

  @Input()
  get disabled(): boolean {
    return this.form
      ? this.form.invalid || !this.form.enabled
      : this.disabledIO.get();
  }

  set disabled(n: boolean) {
    this.disabledIO.set(n);
  }

  @Input()
  get label(): string {
    return this.labelIO.get();
  }

  set label(n: string) {
    this.labelIO.set(n);
  }

  @Output()
  create: EventEmitter<void> = new EventEmitter();

  @Output()
  update: EventEmitter<void> = new EventEmitter();

  constructor() {}

  handleCreate(): void {
    this.create.emit();
    if (this.component) {
      this.component.handleCreate(this.form.value);
    }
  }
  handleUpdate(): void {
    this.update.emit();
    if (this.component) {
      this.component.handleUpdate(this.form.value);
    }
  }
}
