import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsComponent } from 'esuite-dashboard/app/pages/settings/settings.component';
import { DashboardComponent } from 'esuite-dashboard/app/shared/components/dashboard/dashboard.component';
import { AuthGuard } from 'esuite-dashboard/app/shared/guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'settings',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: SettingsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
