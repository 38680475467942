import { CadenceViewModel } from '../cadence-view.model';
import { getCadence } from '../../cadence';
import { ImmutableCollection } from 'esuite-dashboard/cadence/classes/immutable-collection';
import {
  ICadenceActionMetadata,
  ICadenceListViewColumnMetadata,
} from 'esuite-dashboard/cadence';
import { BehaviorSubject, Subject } from 'rxjs';
import { Json2CsvTransform } from 'json2csv/transforms';

/*
CadenceView is a page consisting of

Filters above items.
List of items on the left.
Editor of items on the right.

Links:      [---] [---] [---] [---] [---] [---]
Filters:    [------] [------] [------] [------]

Columns:    *****  *****  ****  *****  **** ***
Row:        -----  -----  ----  -----  ---- ---
Row:        -----  -----  ----  -----  ---- ---
Row:        -----  -----  ----  -----  ---- ---
Row:        -----  -----  ----  -----  ---- ---
Row:        -----  -----  ----  -----  ---- ---


Cadence type class includes:
- filters @CadenceFilter()
- cols (including link cols) @CadenceColumn()
- create form @CadenceFormView({ action: CadenceForms.Create })
- update form @CadenceFormView({ action: CadenceForms.Update })
 */

export interface FieldValueCallbackInfo {
  label: string;
  default?: string;
}

export type FieldValueCallback<T> =
  | FieldValueCallbackWithoutField<T>
  | FieldValueCallbackWithField<T>;

export type FieldValueCallbackWithoutField<T> = (row: T) => any;

export type FieldValueCallbackWithField<T> = (
  row: T,
  field: FieldValueCallbackInfo
) => any;

export interface FieldInfo<T> {
  label?: string;
  default?: string;
  value: string | FieldValueCallback<T>;
}

export interface CSVExportOptions<T> {
  fields?: Array<string | FieldInfo<T>>;
  ndjson?: boolean;
  defaultValue?: string;
  quote?: string;
  escapedQuote?: string;
  delimiter?: string;
  eol?: string;
  excelStrings?: boolean;
  header?: boolean;
  includeEmptyRows?: boolean;
  withBOM?: boolean;
  transforms?: Array<Json2CsvTransform<any, any>>;
}
export class CadenceListViewPaginator {
  length;
  pageIndex = 0;
  pageSize = 50;
  events$: Subject<{
    name: 'nextPage' | 'previousPage' | 'firstPage' | 'lastPage';
  }>;
  constructor(public pageSizeOptions = [50]) {}
  loadPage: (options?: any) => Promise<any>;
}

export class CadenceListViewModel<
  Row = any,
  Params = any
> extends CadenceViewModel<Params, Row> {
  _paginator: CadenceListViewPaginator;
  pageIndex = 0;
  pageSize = 50;

  /*
  Allow exporting to CSV by setting this property with
  CSV generation options.
   */
  _csvExport: CSVExportOptions<Row> & {
    pager: (options?: any) => Promise<any>;
  };

  _rows$: ImmutableCollection<Row> = new ImmutableCollection<Row>();

  get _columns(): ICadenceListViewColumnMetadata[] {
    return getCadence().column.list(this.constructor.name);
  }

  get _links(): ICadenceActionMetadata[] {
    return getCadence().button.list(this.constructor.name);
  }
}
