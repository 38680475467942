import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiComponent } from 'esuite-dashboard/app/shared/api-component';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { DigitalSignageResponse } from 'esuite-client';

@Component({
  selector: 'app-kiosk-layout-detail',
  templateUrl: './kiosk-layout-detail.component.html',
  styleUrls: ['./kiosk-layout-detail.component.scss'],
})
export class KioskLayoutDetailComponent extends ApiComponent implements OnInit {
  form: FormGroup;
  editMode = false;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public activeModal: MatDialogRef<KioskLayoutDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { edit: boolean; layout: DigitalSignageResponse },
    private router: Router,
  ) {
    super();
    this.resetErrors();
  }

  async create(): Promise<void> {
    this.loading = true;
    this.resetErrors();
    try {
      const layoutCreate = await getEsuiteApi().digitalSignage.create(
        this.form.value,
      );
      this.activeModal.close(layoutCreate);
      this.router.navigate(['digital-signage', layoutCreate.id]);
    } catch (e) {
      if (e.status === 422 && e.error) {
        this.displayErrors(e.error);
      }
       ;
    }
    this.loading = false;
  }

  async update(): Promise<void> {
    this.loading = true;
    this.resetErrors();
    const layoutUpdate = await getEsuiteApi().digitalSignage.update(
      this.data.layout.id,
      this.form.value,
    );
    this.loading = false;
    this.activeModal.close(layoutUpdate);
  }

  async ngOnInit(): Promise<void> {
    if (this.data?.edit && this.data?.layout) {
      this.editMode = true;
      this.form = this.fb.group({
        name: [this.data.layout.name, Validators.required],
        width: [this.data.layout.width, Validators.required],
        height: [this.data.layout.height, Validators.required],
      });
    } else {
      this.form = this.fb.group({
        name: ['', Validators.required],
        width: [null, Validators.required],
        height: [null, Validators.required],
      });
    }
  }
}
