import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {
  getCadence,
  ICadenceFormControlMetadata,
  ICadenceViewMetadata,
} from 'cadence';

@Component({
  selector: 'app-ngx-table-popup',
  templateUrl: './ngx-table-popup.component.html',
})
export class NgxTablePopupComponent implements OnInit {
  public itemForm: FormGroup;
  controls: ICadenceFormControlMetadata[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      form: ICadenceViewMetadata;
      payload: any;
      title: string;
    },
    public dialogRef: MatDialogRef<NgxTablePopupComponent>,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.controls = getCadence().formControl.list(this.data.form.name);
    this.buildItemForm(this.data.payload, this.controls);
  }
  buildItemForm(item, controlList: ICadenceFormControlMetadata[]) {
    const controls: { [key: string]: any } = {};
    for (const ctrl of controlList) {
      controls[ctrl.name] = [ctrl.default];
    }
    this.itemForm = this.fb.group(controls);
  }

  submit() {
    this.dialogRef.close(this.itemForm.value);
  }
}
