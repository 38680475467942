import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OrganizationResponse } from 'esuite-client';

@Component({
  selector: 'app-organization-selector',
  templateUrl: './organization-selector.component.html',
  styleUrls: ['./organization-selector.component.scss']
})
export class OrganizationSelectorComponent implements OnInit {


  organizations: OrganizationResponse[] = [];
  selected: OrganizationResponse;

  constructor(
    public activeModal: MatDialogRef<OrganizationSelectorComponent>,
  ) { }


  ngOnInit(): void { }

  completeSelection(): void {
    this.activeModal.close(this.selected);
  }

}
