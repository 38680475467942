import { Component, OnInit, ViewChild } from '@angular/core';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { ProductCategoryResponse } from 'esuite-client';
import { ResourceListColumn } from 'esuite-dashboard/app/shared/components/resource-list/resource-list.types';
import {
  ResourceListComponent,
  ResourceListConfig,
} from 'esuite-dashboard/app/shared/components/resource-list/resource-list.component';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';

@Component({
  selector: 'app-product-category-list',
  template: `
    <div class="d-flex align-items-center justify-content-between">
      <h5>Product Categories</h5>
      <app-create-update-button
        [routerLink]="['new']"
        [isNew]="true"
        label="Category"
      ></app-create-update-button>
    </div>
    <app-resource-list #categoryList [config]="config"> </app-resource-list>
  `,
  styles: [],
})
export class ProductCategoryListComponent {
  @ViewChild('categoryList')
  categoryList: ResourceListComponent<ProductCategoryResponse>;
  cols: ResourceListColumn<ProductCategoryResponse>[] = [
    { key: 'id', header: 'ID' },
    { key: 'name', header: 'Name', link: (r) => [r.id] },
  ];

  config: ResourceListConfig<ProductCategoryResponse> = {
    cols: [{ key: 'name', header: 'Name', link: (r) => [r.id] }],
    links: [
      {
        label: 'Duplicate',
        onClick: (category) => this.duplicate(category),
      },
    ],
    rowLoader(pageParameters?: {
      skip: number;
      limit: number;
    }): Promise<ProductCategoryResponse[]> | ProductCategoryResponse[] {
      return getEsuiteApi().productCategory.list();
    },
    delete: (cat) =>
      this.alertService.confirmLoaderAndResult(
        'Are you sure you want to delete this category?',
        () =>
          getEsuiteApi()
            .productCategory.remove(cat.id)
            .then(() => true),
        'Category removed successfully',
        'Error removing category'
      ),
  };

  constructor(private alertService: AlertService) {}

  async duplicate(cat: ProductCategoryResponse): Promise<void> {
    this.alertService.confirmLoaderAndResult(
      'Are you sure you want to duplicate this category?',
      async () => {
        const duplicated = await getEsuiteApi().productCategory.create({
          duplicateId: cat.id,
          name: 'Copy of ' + cat.name,
        });
        this.categoryList.appendRow(duplicated);
      },
      'Category duplicated successfully',
      'Error duplicating category'
    );
  }
}
