/*
 * FundOptionCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../../app/api/esuite-api';

interface IFundOptionCreateFormParams {
  fundId: number;
}

@CadenceView({
  title: 'Create Fund Option',
  viewType: 'dialog',
})
export class FundOptionCreateForm extends CadenceFormViewModel<IFundOptionCreateFormParams> {
  @CadenceFormControl()
  name: string;

  @CadenceFormControl(CadenceFormControlType.Number)
  price: number;

  @CadenceFormAction({
    label: 'Create',
  })
  async create(): Promise<any> {
    /* Create logic goes here */
    return getEsuiteApi().fundOption.create(this._params.fundId, {
      name: this.name,
      price: this.price,
    });
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    this._postEvent('dismissDialog');
  }
}
