/*
 * AdmissionUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceStackViewChild,
  CadenceStackViewModel,
  CadenceView,
} from 'cadence';

import {
  AdmissionOptionListView,
  AdmissionUpdateForm,
} from '../index';
import { CadenceStackViewTab } from '../../models/stack-view/cadence-stack-view-tab.decorator';

interface IAdmissionUpdateFormParams {
  id: number;
}

@CadenceView({
  title: 'Update Admission',
})
export class AdmissionDetail extends CadenceStackViewModel<IAdmissionUpdateFormParams> {
  @CadenceStackViewTab(() => AdmissionDetail, ['updateAdmission'])
  general: string;

  @CadenceStackViewTab(() => AdmissionDetail, ['admissionOptions'])
  options: string;

  @CadenceStackViewChild(() => AdmissionUpdateForm, {
    title: 'Admission Update',
  })
  updateAdmission() {
    const form = new AdmissionUpdateForm();
    form._params = {
      id: this._params.id,
    };
    return form;
  }

  @CadenceStackViewChild(() => AdmissionOptionListView, {
    title: 'Admission Options',
  })
  admissionOptions() {
    const form = new AdmissionOptionListView();
    form._params = {
      id: this._params.id,
    };
    return form;
  }
}
