import { EsuiteClient } from 'esuite-client';
import { createEsuiteClient } from 'esuite-client';

(window as any).api = createEsuiteClient({
  accessToken: localStorage.getItem('accessToken'),
});

export const getEsuiteApi = () => {
  return (window as any).api as EsuiteClient;
};
