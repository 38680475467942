import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { ShopifyCheckoutRoutingModule } from './shopify-checkout-routing.module';
import { ShopifyCheckoutListComponent } from './shopify-checkout-list.component';
import { ShopifyCheckoutDetailComponent } from './shopify-checkout-detail.component';
import {
  ShopifyCheckoutMenuCardComponent,
  ShopifyCheckoutMenuEditorComponent,
} from './shopify-checkout-menu-editor/shopify-checkout-menu-editor.component';

@NgModule({
  declarations: [
    ShopifyCheckoutListComponent,
    ShopifyCheckoutDetailComponent,
    ShopifyCheckoutMenuEditorComponent,
    ShopifyCheckoutMenuCardComponent,
  ],
  imports: [
    ShopifyCheckoutRoutingModule,
    CommonModule,

    SharedModule,
  ],
})
export class ShopifyCheckoutModule {}
