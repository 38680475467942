import { Pipe, PipeTransform } from '@angular/core';
import { JSONPath } from 'jsonpath-plus-browser';
import { isArray } from 'lodash';
@Pipe({
  name: 'cadPath',
})
export class CadPathPipe implements PipeTransform {
  transform(value: string | string[], obj: any): any {
    if (isArray(value)) {
      return value.map((v) => this.transform(v, obj));
    }
    if (obj[value]) {
      return obj[value];
    }
    return JSONPath({
      path: value,
      json: obj,
    });
  }
}
