/*
 * CheckoutLineItemListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceActionColumn,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceListViewRow,
  ICadenceViewEvent,
} from 'cadence';
import { CheckoutLineItemResponse } from 'esuite-client';
import { getEsuiteApi } from '../../../../app/api/esuite-api';
import { AddAdmissionLineItem } from './add-admission/add-admission-line-item';
import * as currency from 'currency.js';
import { toCurrency } from '../../../util/toCurrency';
import { AddFundLineItem } from './add-admission/add-fund-line-item';

@CadenceView({
  title: 'Checkout Line Item List',
})
export class CheckoutLineItemListView extends CadenceListViewModel<
  CheckoutLineItemResponse,
  { checkoutId: number }
> {
  @CadenceColumn(() => CheckoutLineItemListView, {
    header: '#',
    value: (row, list) => list._rows$.value.indexOf(row) + 1,
  })
  id: number;

  @CadenceColumn()
  title: string;

  @CadenceColumn()
  quantity: number;

  @CadenceColumn(() => CheckoutLineItemListView, {
    value: (row) => toCurrency(row.unitPrice),
  })
  unitPrice: number;

  @CadenceColumn(() => CheckoutLineItemListView, {
    value: (row) => {
      return currency(row.unitPrice * row.quantity).format();
    },
  })
  subtotal: number;

  // @CadenceAction({ label: 'Add App Purchase' })
  // addAppPurchase(): void {
  //   cadenceRouter.navigate(CheckoutLineItemCreateForm, {
  //     from: this,
  //     events: {
  //       'action.create': 'created',
  //     },
  //   });
  // }

  @CadenceAction({ label: 'Add Admission' })
  addAdmission(): void {
    cadenceRouter.navigate(AddAdmissionLineItem, {
      from: this,
      params: {
        ...this._params,
      },
      events: {
        'action.save': 'created',
      },
    });
  }

  @CadenceAction({ label: 'Add Fund' })
  addFund(): void {
    cadenceRouter.navigate(AddFundLineItem, {
      from: this,
      params: {
        ...this._params,
      },
      events: {
        'action.save': 'created',
      },
    });
  }

  @CadenceActionColumn({ label: 'Delete' })
  async deleted(row: CadenceListViewRow<CheckoutLineItemListView>) {
    await getEsuiteApi().checkoutLineItem.remove(
      row.id,
      this._params.checkoutId,
    );
    return row;
  }

  @CadenceActionColumn({ label: 'Edit' })
  updateForm(row: CadenceListViewRow<CheckoutLineItemListView>): void {
    cadenceRouter.navigate(AddAdmissionLineItem, {
      from: this,
      params: {
        ...this._params,
        lineItem: row,
      },
      events: {
        'action.save': 'updated',
      },
    });
  }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  @CadenceViewEvent('updated')
  update(event: any): void {
    this._rows$.updateBy((c) => c.id === event.data.id, event.data);
  }

  @CadenceViewEvent('action.deleted')
  remove(event: ICadenceViewEvent): void {
    this._rows$.deleteBy((c) => c.id === event.data.id);
  }

  async _init(): Promise<void> {
    const rows = await getEsuiteApi().checkoutLineItem.list(
      this._params.checkoutId,
    );
    this._rows$.set(rows);
  }
}
