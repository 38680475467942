/*
 * OrderCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  cadenceRouter,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { OrderListView } from '../index';
import { CadenceFormArray } from '../../models/form-view/cadence-form-array.decorator';
import { filter, pluck, switchMap } from 'rxjs/operators';
import { once } from 'lodash';
import { ProductType } from 'esuite-client';

interface IOrderCreateFormParams {}

const getAdmissions = once(() => getEsuiteApi().admission.list());
const getAppPurchases = once(() => getEsuiteApi().appPurchase.list());

@CadenceView()
class OrderLineItemCreateForm extends CadenceFormViewModel {
  @CadenceFormControl(CadenceFormControlType.Select, {
    options: () => [
      { label: 'Admission', value: 'admission' },
      { label: 'App Purchase', value: 'appPurchase' },
    ],
  })
  productType: ProductType;

  @CadenceFormControl(CadenceFormControlType.Select, {
    options: (form: OrderLineItemCreateForm) => {
      return form._controlData$.pipe(
        pluck('productType'),
        filter((v) => !!v),
        switchMap(async (productType) => {
          let products = [];
          if (productType === 'admission') {
            products = await getAdmissions();
          } else if (productType == 'appPurchase') {
            products = await getAppPurchases();
          }
          return products.map((p) => ({ label: p.title, value: p.id }));
        }),
      );
    },
  })
  productId: number;

  @CadenceFormControl(CadenceFormControlType.Number)
  quantity: number;
}

@CadenceView({
  title: 'Create Order',
})
export class OrderCreateForm extends CadenceFormViewModel<IOrderCreateFormParams> {
  @CadenceFormControl(CadenceFormControlType.Select, {
    options: async (form: OrderCreateForm) => {
      const activations = await getEsuiteApi().revenueCenter.list();
      return activations.map((ac) => ({
        label: ac.name,
        value: ac.id,
      }));
    },
  })
  revenueCenterId: number;

  @CadenceFormControl({ required: false })
  note: string;

  @CadenceFormControl({ required: false })
  email: string;

  @CadenceFormControl({ required: false })
  phone: string;

  @CadenceFormArray(() => OrderLineItemCreateForm)
  lineItems: OrderLineItemCreateForm[];

  @CadenceFormAction({
    label: 'Create',
  })
  async create(): Promise<any> {
    /* Create logic goes here */
    return getEsuiteApi().order.create({
      lineItems: this.lineItems,
      revenueCenterId: this.revenueCenterId,
      note: this.note,
      email: this.email,
      phone: this.phone,
    });
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    cadenceRouter.navigate(OrderListView, {
      from: this,
    });
  }
}
