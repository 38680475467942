import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'esuite-dashboard/app/shared/components/confirm-dialog/confirm-dialog.component';
import { KioskSlideEditorComponent } from '../kiosk-slide-editor/kiosk-slide-editor.component';
import {
  BaseEsuiteAppInstanceResponse,
  DigitalSignageFrameResponse,
  DigitalSignageResponse,
  DigitalSignageSlideCreateRequest,
  DigitalSignageSlideResponse,
  DigitalSignageSlideUpdateRequest,
  EsuiteApp,
  DigitalSignageFrameType,
  ShopifyCheckoutResponse,
} from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';

@Component({
  selector: 'app-kiosk-frame',
  templateUrl: './kiosk-frame.component.html',
  styleUrls: ['./kiosk-frame.component.scss'],
})
export class KioskFrameComponent implements OnInit, OnDestroy {
  @Input()
  frame: DigitalSignageFrameResponse;

  @Input()
  signage: DigitalSignageResponse;

  slides$: BehaviorSubject<DigitalSignageSlideResponse[]> = new BehaviorSubject(
    []
  );
  loading = false;
  form: FormGroup;

  DigitalSignageFrameType = DigitalSignageFrameType;
  EsuiteApp = EsuiteApp;

  private slideSub: Subscription;
  shopifyCheckouts: ShopifyCheckoutResponse[] = [];

  get slides(): DigitalSignageSlideResponse[] {
    return this.slides$.value;
  }

  set slides(sls) {
    this.slides$.next(sls.map((s, ind) => ({ ...s, sort: ind + 1 })));
  }

  constructor(private fb: FormBuilder, private dialog: MatDialog) {
    this.form = this.makeFrameForm();
    this.slideSub = this.slides$.subscribe(() => {
      this.form.get('slideIds').setValue(this.slides.map((s) => s.id));
       ;
    });
  }

  async ngOnInit(): Promise<void> {
    // this.resource = this.layoutService.of(this.frame.layout_id).frames;
    this.form.patchValue({
      ...this.frame,
      slideIds: this.frame.slides.map((s) => s.id),
      layout: {
        ...this.frame.layout,
        bgImageId: this.frame.bgImageId,
      },
    });
    // const sorted = this.frame.slides.sort((a, b) => a.sort - b.sort);
    this.slides$.next(this.frame.slides);
    this.shopifyCheckouts = await getEsuiteApi().shopifyCheckout.list();
  }

  parseSlidesUrlToID(event) {
    if (
      this.form.value.googleSlides?.id.indexOf(
        'https://docs.google.com/presentation/d/e/'
      ) > -1
    ) {
      setTimeout(() => {
        this.form
          .get('googleSlides.id')
          .setValue(
            this.form.value.googleSlides.id
              .split('https://docs.google.com/presentation/d/e/')[1]
              .split('/')[0]
          );
      }, 10);
    }
  }

  ngOnDestroy(): void {
    this.slideSub.unsubscribe();
  }

  makeFrameForm(): FormGroup {
    return this.fb.group({
      name: [null, Validators.required],
      type: [null, Validators.required],
      googleSlides: this.fb.group({ id: [null], delay: [8] }),
      layout: [null],
      app: [null],
      instanceId: [null],
      shopifyCheckoutId: [null],
      widgetId: [null],
      slideIds: [[]],
    });
  }

  async createSlide(): Promise<void> {
    this.loading = true;
    const dialogRef = this.dialog.open(KioskSlideEditorComponent, {
      disableClose: true,
      data: {
        width: this.frame.layout.widthPx,
        height: this.frame.layout.heightPx,
      },
    });
    const slide: DigitalSignageSlideCreateRequest = await dialogRef
      .afterClosed()
      .toPromise();
    if (slide) {
      // Add to frames
      const createdSlide = await getEsuiteApi().digitalSignageSlide.create({
        ...slide,
        frameId: this.frame.id,
      });
      this.slides = [...this.slides, createdSlide];
    }
    this.loading = false;
  }

  async removeSlide(slideInd: number): Promise<void> {
    this.loading = true;

    const confirm = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Slide Delete',
        messageHTML: `Are you sure you want to remove this slide?`,
        warn: true,
      },
    });
    const result = await confirm.afterClosed().toPromise();
    if (result) {
      try {
        await getEsuiteApi().digitalSignageSlide.remove(
          this.slides[slideInd].id
        );
        const newSlides = [
          ...this.slides.slice(0, slideInd),
          ...this.slides.slice(slideInd + 1),
        ];
        this.slides = newSlides;
      } catch (e) {
         ;
      }
    }
    this.loading = false;
  }

  async toggleSlide(slide: DigitalSignageSlideResponse): Promise<void> {
    this.loading = true;
    const slideInd = this.slides.indexOf(slide);
    // const update = await this.slideService.update(slide.id, {
    //   enabled: !slide.enabled,
    // });

    this.slides = [
      ...this.slides.slice(0, slideInd),
      {
        ...slide,
        enabled: !slide.enabled,
      },
      ...this.slides.slice(slideInd + 1),
    ];

    this.loading = false;
  }

  async deleteFrame(): Promise<void> {
    this.loading = true;

    const confirm = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Frame Delete',
        messageHTML: `Are you sure you want to delete this frame?`,
        warn: true,
      },
    });
    const result = await confirm.afterClosed().toPromise();
    if (result) {
      try {
        await getEsuiteApi().digitalSignageFrame.remove(
          this.signage.id,
          this.frame.id
        );
        const ind = this.signage.frames.findIndex(
          (f) => f.id === this.frame.id
        );
        this.signage.frames = [
          ...this.signage.frames.slice(0, ind),
          ...this.signage.frames.slice(ind + 1),
        ];
        // this.frameDelete.emit();
      } catch (e) {}
    }
    this.loading = false;
  }

  async updateFrame(): Promise<void> {
    this.loading = true;

    try {
      this.frame = await getEsuiteApi().digitalSignageFrame.update(
        this.signage.id,
        this.frame.id,
        {
          ...this.form.value,
          bgImageId: this.form.value.layout?.bgImageId,
        }
      );
      alert('Frame updated successfully.');
    } catch (e) {
      alert('Error uploading frame.');
       ;
    }

    this.loading = false;
  }

  async editSlide(slideInd: number): Promise<void> {
    this.loading = true;
    const slide = this.slides[slideInd];
    const dialogRef = this.dialog.open(KioskSlideEditorComponent, {
      width: '90vw',
      maxWidth: '90vw',
      maxHeight: '90vh',
      disableClose: true,
      data: {
        edit: true,
        slide,
        width: this.frame.layout.widthPx,
        height: this.frame.layout.heightPx,
      },
    });
    const updatedSlide: DigitalSignageSlideUpdateRequest = await dialogRef
      .afterClosed()
      .toPromise();
    if (updatedSlide) {
       ;
      const s = await getEsuiteApi().digitalSignageSlide.update(
        slide.id,
        updatedSlide
      );
      this.slides = [
        ...this.slides.slice(0, slideInd),
        s,
        ...this.slides.slice(slideInd + 1),
      ];
    }
    this.loading = false;
  }

  orderSlides(event): void {
    this.form.markAsTouched();
    const { previousIndex, currentIndex } = event;
    const slides = [...this.slides];
    const slide = slides.splice(previousIndex, 1)[0];
    slides.splice(currentIndex - 1, 0, slide);
    this.slides = slides;
  }
}
