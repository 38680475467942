/*
 * RevenueCenterUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { ApplicationCurrency, RevenueCenterResponse } from 'esuite-client';

interface IRevenueCenterUpdateFormParams {
  id: number;
}

@CadenceView({
  title: 'Update Revenue Center',
})
export class RevenueCenterUpdateForm extends CadenceFormViewModel<
  IRevenueCenterUpdateFormParams,
  RevenueCenterResponse
> {
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  @CadenceFormControl({
    default: (view) => view._params.name,
  })
  name: string;

  @CadenceFormControl(CadenceFormControlType.Number)
  taxRate: number;

  @CadenceFormControl(CadenceFormControlType.Select, {
    options: () => [
      { label: 'USD', value: 'USD' },
      { label: 'CAD', value: 'CAD' },
    ],
  })
  currency: ApplicationCurrency;

  @CadenceFormAction({
    label: 'Update',
  })
  async update(): Promise<any> {
    return getEsuiteApi().revenueCenter.update(this.id, {
      name: this.name,
      taxRate: this.taxRate,
      currency: this.currency,
    });
  }

  @CadenceViewEvent('action.update')
  afterUpdate(): void {
    // cadenceRouter.navigate(RevenueCenterListView, {
    //   from: this,
    // });
  }

  async _init(): Promise<void> {
    this._controlData$.next(
      await getEsuiteApi().revenueCenter.get(this._params.id),
    );
  }
}
