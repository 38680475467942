/*
 * CheckoutLineItemDeleteForm
 * Generated delete resource from cadence/scripts/generate
 */

import {
  CadenceFormViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  cadenceRouter,
  CadenceFormControl,
} from 'cadence';
import { CheckoutLineItemListView } from './checkout-line-item-list';

interface ICheckoutLineItemDeleteFormParams {
  id: number;
}

@CadenceView({
  title: 'Delete Checkout Line Item',
  viewType: 'dialog',
})
export class CheckoutLineItemDeleteForm extends CadenceFormViewModel<ICheckoutLineItemDeleteFormParams> {
  /*
   * Form Controls
   */
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  /*
   * Form Actions
   */
  @CadenceFormAction({
    label: 'Delete',
  })
  async delete(): Promise<void> {
    /* Delete logic goes here */
  }

  /*
   * Form Events
   */
  @CadenceViewEvent('action.delete')
  afterDelete(): void {
    cadenceRouter.navigate(CheckoutLineItemListView, {
      from: this,
    });
  }
}
