import { startCase } from 'lodash';

/*
Quick and dirty way to convert enums into an array of selectable options.
Useful for generating dynamic select inputs
 */
export function quickEnumToOptions(options: {
  [key: string]: string | number;
}): { label: string, value: string | number }[] {
  return Object.values(options).map((value) => ({
    label: startCase(`${value}`),
    value,
  }));
}
