/*
 * DigitalSignageListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceActionColumn,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceListViewRow,
} from 'cadence';
import { DigitalSignageCreateForm } from './digital-signage-create';
import { DigitalSignageUpdateForm } from './digital-signage-update';
import { DigitalSignageDetailView } from './digital-signage-detail';
import { getEsuiteApi } from '../../../app/api/esuite-api';

@CadenceView({
  title: 'Digital Signage',
  // showInMenu: 'root',
})
export class DigitalSignageListView extends CadenceListViewModel<any> {
  @CadenceColumn()
  id: number;

  @CadenceColumn()
  name: string;

  @CadenceAction({ label: 'Create' })
  createForm(): void {
    cadenceRouter.navigate(DigitalSignageCreateForm, {
      from: this,
      events: {
        'action.create': 'created',
      },
    });
  }

  @CadenceActionColumn({ label: 'Delete' })
  deleteForm(row: CadenceListViewRow<DigitalSignageListView>): void {
    cadenceRouter.navigate(DigitalSignageDetailView, {
      from: this,
      events: {
        'action.delete': 'deleted',
      },
      params: {
        id: row.id,
      },
    });
  }

  @CadenceActionColumn({ label: 'Edit' })
  updateForm(row: CadenceListViewRow<DigitalSignageListView>): void {
    cadenceRouter.navigate(DigitalSignageDetailView, {
      from: this,
      params: {
        id: row.id,
      },
    });
  }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  @CadenceViewEvent('deleted')
  remove(event: any): void {
    this._rows$.deleteBy((c) => c.id === event.from._params.id);
  }

  async _init(): Promise<void> {
    this._rows$.set(await getEsuiteApi().digitalSignage.list());
  }
}
