<mat-card *ngIf="slide" class="p-0 overflow-hidden">
  <app-digital-signage-slide-preview
    [slide]="slide"
    [container]="slideContainer"
  >
  </app-digital-signage-slide-preview>
  <mat-card-actions class="pb-3 d-flex align-items-center justify-content-center">
    <button mat-icon-button color="warn" (click)="remove.emit()">
      <mat-icon>delete</mat-icon>
    </button>
    <button mat-icon-button color="primary" (click)="edit.emit()">
      <mat-icon>create</mat-icon>
    </button>
    <button mat-icon-button color="primary" (click)="toggle.emit()">
      <mat-icon *ngIf="!slide.enabled">play_arrow</mat-icon>
      <mat-icon *ngIf="slide.enabled">pause</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
