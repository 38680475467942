import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractFormComponent } from 'esuite-dashboard/app/shared/classes/abstract-form.component';
import {
  DigitalSignageResponse,
  EsuiteApp,
  SpinToWinCreateRequest,
  SpinToWinResponse,
  SpinToWinUpdateRequest,
} from 'esuite-client';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';

@Component({
  selector: 'app-spin-to-win-detail',
  templateUrl: './spin-to-win-detail.component.html',
  styleUrls: ['./spin-to-win-detail.component.scss'],
})
export class SpinToWinDetailComponent
  extends AbstractFormComponent<
    SpinToWinResponse,
    SpinToWinCreateRequest,
    SpinToWinUpdateRequest
  >
  implements OnInit
{
  signs: DigitalSignageResponse[] = [];
  EsuiteApp = EsuiteApp;
  // frames: SpinToWinFrameResponse[] = [];
  @Output()
  create: EventEmitter<SpinToWinResponse> = new EventEmitter<SpinToWinResponse>();
  @Output()
  update: EventEmitter<SpinToWinResponse> = new EventEmitter<SpinToWinResponse>();
  configStr: string;
  constructor(
    private fb: FormBuilder,
    activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private alertService: AlertService
  ) {
    super();
    // this.connectRouteToIdentity('spinToWinId', activatedRoute);
  }

  handleCreate(dto: SpinToWinCreateRequest): void {
    this.alertService.runWithLoader(
      async () => {
        const created = await getEsuiteApi().spinToWin.create(dto);
        this.id = created.id;
        this.makeUpdateForm(this.id);
        this.create.emit(created);
      },
      'SpinToWin created successfully.',
      'Error creating spinToWin.'
    );
  }

  handleUpdate(dto: SpinToWinUpdateRequest): void {
    this.alertService.runWithLoader(
      async () => {
        const updated = await getEsuiteApi().spinToWin.update(this.id, {
          ...dto,
          config: JSON.parse(this.configStr),
        });
        this.update.emit(updated);
      },
      'SpinToWin updated successfully.',
      'Error creating spinToWin.'
    );
  }

  async loadFormData(id: number): Promise<void> {}

  async ngOnInit(): Promise<void> {
    const [digitalSignage] = await Promise.all([
      getEsuiteApi().digitalSignage.list(),
    ]);
    this.signs = digitalSignage;
  }

  makeCreateForm(): Promise<FormGroup> | FormGroup {
    return this.fb.group({
      name: [null, Validators.required],
      fields: [[]],
      backgroundId: [],
      screensaverId: [],
      emailShareNotification: this.fb.group({
        html: [''],
        subject: [''],
      }),
    });
  }

  async makeUpdateForm(id: number): Promise<FormGroup> {
    const spinToWin = await getEsuiteApi().spinToWin.get(id);
    this.configStr = JSON.stringify(spinToWin.config, null, 2);
    // this.frames = spinToWin.frames;
    return this.fb.group({
      name: [spinToWin.name, Validators.required],
      fields: [spinToWin.fields],
      backgroundId: [spinToWin.backgroundId],
      screensaverId: [spinToWin.screensaverId],
      emailShareNotification: this.fb.group({
        html: [spinToWin.emailShareNotification.html],
        subject: [spinToWin.emailShareNotification.subject],
      }),
    });
  }
}
