import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from 'esuite-dashboard/app/shared/components/dashboard/dashboard.component';
import { PhotoboothListComponent } from 'esuite-dashboard/app/pages/photobooth/photobooth-list/photobooth-list.component';
import { PhotoboothDetailComponent } from 'esuite-dashboard/app/pages/photobooth/photobooth-detail/photobooth-detail.component';
import { AuthGuard } from 'esuite-dashboard/app/shared/guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'photobooths',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
      {
        path: '',
        component: PhotoboothListComponent,
      },
      {
        path: ':photoboothId',
        component: PhotoboothDetailComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PhotoboothRoutingModule {}
