import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

/*
Used to open components as pages (via href) or dialogs (via dialog)
 */
@Component({
  selector: 'app-app-link',
  templateUrl: './app-link.component.html',
  styleUrls: ['./app-link.component.scss']
})
export class AppLinkComponent implements OnInit {

  @Input()
  href: string;

  @Input()
  type: 'link' | 'button' = 'link';

  constructor() { }

  ngOnInit(): void {
  }

}
