/*
 * KioskCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  cadenceRouter,
  CadenceFormControl,
} from 'cadence';
import { KioskListView } from './kiosk-list';
import { KioskResponse } from 'esuite-client';
import { getEsuiteApi } from '../../../app/api/esuite-api';

interface IKioskCreateFormParams {}

@CadenceView({
  title: 'Create Kiosk',
  viewType: 'dialog',
})
export class KioskCreateForm extends CadenceFormViewModel<IKioskCreateFormParams> {
  @CadenceFormControl()
  name: string;

  @CadenceFormControl()
  username: string;

  @CadenceFormControl()
  password: string;

  @CadenceFormAction({
    label: 'Create',
  })
  create(): Promise<KioskResponse> {
    /* Create logic goes here */
    return getEsuiteApi().kiosk.create({
      name: this.name,
      username: this.username,
      password: this.password,
    });
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    cadenceRouter.navigate(KioskListView, {
      from: this,
    });
  }
}
