import { Observable } from 'rxjs';

export type CadenceUnarray<Response> = Response extends (infer Single)[]
  ? Single
  : Response;

export type CadenceUnobservable<Response> = Response extends Observable<
  infer Single
>
  ? Single
  : Response;
