import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ResourceListComponent, ResourceListConfig } from '../resource-list/resource-list.component';

@Component({
  selector: 'app-list-sidebar-detail',
  templateUrl: './list-sidebar-detail.component.html',
  styleUrls: ['./list-sidebar-detail.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListSidebarDetailComponent<T extends { id: number }>
{
  @ViewChild('list') list: ResourceListComponent<T>;
  @Input()
  title: string;

  private localId = -1;

  @Input()
  get id(): number {
    return this.localId;
  }

  set id(v: number) {
    this.localId = v;
    this.idChange.emit(v);
  }

  @Output()
  idChange: EventEmitter<number> = new EventEmitter();

  @Input()
  listConfig: ResourceListConfig<T>;
}
