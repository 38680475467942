import { Injectable } from '@angular/core';
import { cadenceRouter } from 'cadence';
import { Route, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CadenceNavigationService {
  constructor(private router: Router) {
    cadenceRouter.events$.subscribe((event) => {
      const route = cadenceRouter.getRoute(event.to, event.context);
      let queryParams: any = {};
      if (route[1]) {
        queryParams = cadenceRouter.serializeQueryParams(route[1]);
      }
      this.router.navigate([route[0]], {
        queryParams,
      });
    });
  }
}
