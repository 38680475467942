import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelfCheckoutIntegrationListComponent } from './self-checkout-integration-list/self-checkout-integration-list.component';
import { SelfCheckoutIntegrationDetailComponent } from './self-checkout-integration-detail/self-checkout-integration-detail.component';
import { SharedModule } from 'esuite-dashboard/app/shared/shared.module';
import { SelfCheckoutIntegrationRoutingModule } from 'esuite-dashboard/app/pages/self-checkout-integration/self-checkout-integration-routing.module';

@NgModule({
  declarations: [
    SelfCheckoutIntegrationListComponent,
    SelfCheckoutIntegrationDetailComponent,
  ],
  imports: [CommonModule, SharedModule, SelfCheckoutIntegrationRoutingModule],
})
export class SelfCheckoutIntegrationModule {}
