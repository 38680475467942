import { Component, OnInit } from '@angular/core';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'esuite-dashboard';

  constructor() {}

  async ngOnInit(): Promise<void> {
    // const org = await getEsuiteApi().organization.get();
    // (document as HTMLDocument).title = `${org.name} — E Suite Dashboard`;
    // (window as any).ESUITE_API = getEsuiteApi();
  }
}
