/*
TO BE DEPRECIATED IN FAVOR OF ANGULAR COMPONENTS

The views provided by this library are to be depreciated
by standard Angular / Angular-material components
that are used in some of the newer API views.

Decorators and utility functions to enable you to describe "Cadence Views"
which can then be provided (with custom-written views) to a rendering
engine like ng-cadence which will render the views inside a customizable Angular application.

Cadence Philosophy.
All you need to build any kind of backend management application are lists, forms, stacks
and a clean, reactive way to tie them together.

Lists:   Reactive list of items (or rows) represented as text, html or custom list item components.
Forms:   Reactive list of standard or custom input controls.
Stacks:  Reactive combinations of lists, forms and Web Components.

=======
LISTS — Views for searching, sorting, filtering and iterating through resources.
Lists are primarily described in "cols" i.e. the relevant common properties of
the resources being viewed.

@CadenceColumn()
  - by default render the result of looking up the property name in the row.
  - can provide custom HTML (or web components) to render as well.
  - can be hidden or shown via observables
  - can have custom headers.
  - can trigger actions.
  - can link to views.
=======


Easy-to-understand, self-contained, declarative views

 */

/* View Classes and Decorators */
export * from './models';

/* Additional Decorators */
export * from './decorators/cadence-link.decorator';
export * from './decorators/cadence-component.decorator';
export * from './decorators/cadence-view-event.decorator';
export * from './decorators/cadence-view-subscription.decorator';

/* Types */
export * from './types';

/* Enums */

/* Services */
export * from './cadence';

/* Router */
export * from './router/cadence-router';

/* Util */
export * from './util';
