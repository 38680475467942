/*
 * AdmissionTemplateDeleteForm
 * Generated delete resource from cadence/scripts/generate
 */

import { CadenceFormViewModel, CadenceView, CadenceFormAction, CadenceViewEvent, cadenceRouter, CadenceFormControl } from 'cadence';
import { AdmissionTemplateListView } from './admission-template-list';


interface IAdmissionTemplateDeleteFormParams {
  id: number;
}

@CadenceView({
  title: 'Delete Admission Template',
  viewType: 'dialog',
})
export class AdmissionTemplateDeleteForm extends CadenceFormViewModel<IAdmissionTemplateDeleteFormParams> {

  /*
   * Form Controls
   */
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  /*
   * Form Actions
   */
  @CadenceFormAction({
    label: 'Delete',
  })
  async delete(): Promise<void> {
    /* Delete logic goes here */
  }


  /*
   * Form Events
   */
  @CadenceViewEvent('action.delete')
  afterDelete(): void {
    cadenceRouter.navigate(AdmissionTemplateListView, {
      from: this,
    });
  }
}
