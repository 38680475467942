/*
TO BE DEPRECIATED IN FAVOR OF ANGULAR COMPONENTS

The views provided by this library are to be depreciated
by standard Angular / Angular-material components
that are used in some of the newer API views.
 */
import { getCadenceViews } from 'cadence';

export * from './media-collection/media-collection.view';
export * from './media-collection/media-collection-album-detail.view';
export * from './image-upload/image-upload.view';
export * from './self-checkout/self-checkout-integration';
export * from './kiosk';
export * from './organization';
export * from './user';
export * from './admission';
export * from './admission/admission-option';
export * from './fund';
export * from './fund/fund-option';
export * from './admission/admission-template';
export * from './digital-signage';
export * from './checkout';
export * from './order';
export * from './transaction';
export * from './agreement';

export * from './_shared';

export * from './revenue-center';
export * from './revenue-center/payment-gateway';
export * from './customer';
export * from './_hooks';

/* Import all custom views used by the application */
export const bootstrapCadence = () => {
   console.log('Cadence ready.');
};
