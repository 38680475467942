import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ResourceListConfig } from 'esuite-dashboard/app/shared/components/resource-list/resource-list.component';
import {
  RestAppUpdateRequest,
  RestAppCreateRequest,
  RestAppResponse,
} from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { ListSidebarDetailComponent } from 'esuite-dashboard/app/shared/components/list-sidebar-detail/list-sidebar-detail.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentIOStrategy, createComponentIO } from 'esuite-util/components';
import { intOrNewFromParams } from 'esuite-dashboard/app/shared/utils/int-or-new-from-params';
import { Observable } from 'rxjs';
import { updateLocationId } from 'esuite-dashboard/app/shared/utils/update-location-id';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';

@Component({
  selector: 'app-rest-app-list',
  templateUrl: './rest-app-list.component.html',
  styleUrls: ['./rest-app-list.component.scss'],
})
export class RestAppListComponent implements OnInit {
  @ViewChild('restAppList')
  restAppList: ListSidebarDetailComponent<RestAppResponse>;

  // ID State
  private idIO: ComponentIOStrategy<number> = createComponentIO(-1);

  get id(): number {
    return this.idIO.get();
  }

  set id(i: number) {
    this.idIO.set(i);
  }

  id$: Observable<number> = this.idIO.value$;

  // List configuration
  listConfig: ResourceListConfig<RestAppResponse> = {
    cols: [
      {
        key: 'name',
        header: 'Name',
      },
    ],
    delete: (app) =>
      this.alertService.confirmLoaderAndResult(
        'Are you sure you want to remove this?',
        async () => {
          await getEsuiteApi().restApp.remove(app.id);
          // Reset menu to new id if showing the removed app
          if (this.id === app.id) {
            this.id = -1;
          }
          return true;
        },
        'App deleted successfully.',
        'Error deleting app'
      ),
    edit: (app) => this.handleEdit(app),
    rowLoader: () => {
      return getEsuiteApi().restApp.list();
    },
  };

  ngOnInit(): void {
    this.idIO.subscribeTo(
      this.activatedRoute.params.pipe(intOrNewFromParams('restAppId'))
    );
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private alertService: AlertService
  ) {}

  /*
  Handlers
   */
  handleEdit(app: RestAppResponse): void {
    updateLocationId(app.id, this.location);
    this.id = app.id;
  }

  async handleUpdate(dto: RestAppUpdateRequest): Promise<void> {
    this.alertService.runWithLoader(
      async () => {
        const updated = await getEsuiteApi().restApp.update(this.id, dto);
        this.restAppList.list.updateRowBy((r) => r.id === this.id, updated);
      },
      'App updated successfully',
      'Error updating app'
    );
  }
  handleCreate(dto: RestAppCreateRequest): void {
    this.alertService.runWithLoader(
      async () => {
        const created = await getEsuiteApi().restApp.create(dto);
        this.restAppList.list.appendRow(created);
        this.id = created.id;
      },
      'App created successfully',
      'Error creating app'
    );
  }
}
