<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12 w-100 d-flex align-items-center justify-content-between pb-4">
      <h5>{{ title }}</h5>
      <button [disabled]="id === -1" mat-mini-fab color="primary" (click)="id = -1"><mat-icon>add</mat-icon></button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-5">
      <app-resource-list #list [config]="listConfig"></app-resource-list>
    </div>
    <div class="col-md-7">
      <ng-content></ng-content>
    </div>
  </div>
</div>
