import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractFormComponent } from 'esuite-dashboard/app/shared/classes/abstract-form.component';
import {
  DigitalSignageCreateRequest,
  DigitalSignageResponse,
  DigitalSignageUpdateRequest, EsuiteApp,
} from 'esuite-client';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-digital-signage-editor',
  templateUrl: './digital-signage-editor.component.html',
  styleUrls: ['./digital-signage-editor.component.scss'],
})
export class DigitalSignageEditorComponent extends AbstractFormComponent<
  DigitalSignageResponse,
  DigitalSignageCreateRequest,
  DigitalSignageUpdateRequest
> {
  DigitalSignage = EsuiteApp.DigitalSignage
  constructor(private route: ActivatedRoute) {
    super();
    this.connectRouteToIdentity('layoutId', route);
  }

  handleCreate(dto: DigitalSignageCreateRequest): Promise<void> | void {
    return undefined;
  }

  handleUpdate(dto: DigitalSignageUpdateRequest): Promise<void> | void {
    return undefined;
  }

  loadFormData(id: number): Promise<void> | void {
    return undefined;
  }

  makeCreateForm(): Promise<FormGroup> | FormGroup {
    return undefined;
  }

  makeUpdateForm(id: number): Promise<FormGroup> | FormGroup {
    return undefined;
  }
}
