import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractFormComponent } from 'esuite-dashboard/app/shared/classes/abstract-form.component';
import {
  MediaCollectionAccessCodeResponse,
  MediaCollectionCreateRequest,
  MediaCollectionResponse,
  MediaCollectionUpdateRequest,
} from 'esuite-client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { updateLocationId } from 'esuite-dashboard/app/shared/utils/update-location-id';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {
  ResourceListComponent,
  ResourceListConfig,
} from 'esuite-dashboard/app/shared/components/resource-list/resource-list.component';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';

@Component({
  selector: 'app-media-collection-detail',
  templateUrl: './media-collection-detail.component.html',
  styleUrls: ['./media-collection-detail.component.scss'],
})
export class MediaCollectionDetailComponent
  extends AbstractFormComponent<
    MediaCollectionResponse,
    MediaCollectionCreateRequest,
    MediaCollectionUpdateRequest
  >
  implements OnInit
{
  @ViewChild('accessCodeList')
  accessCodeList: ResourceListComponent<MediaCollectionAccessCodeResponse>;
  accessCodeListConfig: ResourceListConfig<MediaCollectionAccessCodeResponse> =
    {
      afterRowLoader(
        rows: MediaCollectionAccessCodeResponse[]
      ): Promise<void> | void {
        return undefined;
      },
      cols: [{ render: (code) => code.accessCode.code, header: 'Code' }],
      delete: async (coll, id) => {
        await getEsuiteApi().mediaCollectionAccessCode.remove(coll.id);
        // this.accessCodeList.removeRowAt(id);
        return true;
      },
      // edit: () => { this.edit},
      rowLoader: (
        pageParameters: { skip: number; limit: number } | undefined
      ) => {
        return getEsuiteApi().mediaCollectionAccessCode.list(this.id);
      },
      // view: () => {},
    };
  constructor(
    private fb: FormBuilder,
    activatedRoute: ActivatedRoute,
    private location: Location,
    private alertService: AlertService
  ) {
    super();
    this.connectRouteToIdentity('mediaCollectionId', activatedRoute);
  }
  async handleCreate(
    dto: MediaCollectionCreateRequest
  ): Promise<MediaCollectionResponse> {
    const created = await getEsuiteApi().mediaCollection.create(dto);
    this.id = created.id;
    this.makeUpdateForm(created);
    updateLocationId(this.id, this.location);
    return created;
  }

  async createAccessCode() {
    const accessCode = await getEsuiteApi().mediaCollectionAccessCode.create({
      collectionId: this.id,
    });
    this.accessCodeList.appendRow(accessCode);
  }

  async sync(): Promise<void> {
    this.alertService.runWithLoader(
      () => getEsuiteApi().mediaCollection.sync(this.id),
      'Sync completed successfully.',
      'Error syncing thumbnails.'
    );
  }

  handleUpdate(dto: MediaCollectionUpdateRequest): void {
    this.alertService.runWithLoader(
      () => getEsuiteApi().mediaCollection.update(this.id, dto),
      'Media collection updated successfully.',
      'Error updating media collection.'
    );
  }

  loadFormData(id: number): Promise<void> | void {
    return undefined;
  }

  ngOnInit(): Promise<void> | void {
    return undefined;
  }

  makeCreateForm(): Promise<FormGroup> | FormGroup {
    return this.fb.group({
      name: [null, Validators.required],
      syncRecord: this.fb.group({
        accessKeyId: [null],
        endpoint: [null],
        bucket: [null],
        path: [null],
        secretAccessKey: [null],
      }),
    });
  }

  async makeUpdateForm(
    idOrMediaCollection: number | MediaCollectionResponse
  ): Promise<FormGroup> {
    const selfCheckout =
      typeof idOrMediaCollection === 'number'
        ? await getEsuiteApi().mediaCollection.get(idOrMediaCollection)
        : idOrMediaCollection;
    return this.fb.group({
      name: [selfCheckout.name, Validators.required],
      syncRecord: this.fb.group({
        accessKeyId: [selfCheckout.syncRecord?.accessKeyId],
        endpoint: [selfCheckout.syncRecord?.endpoint],
        bucket: [selfCheckout.syncRecord?.bucket],
        path: [selfCheckout.syncRecord?.path],
        secretAccessKey: [selfCheckout.syncRecord?.secretAccessKey],
      }),
    });
  }
}
