/*
 * SelfCheckoutIntegrationListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceActionColumn,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceListViewRow,
} from 'cadence';
import { SelfCheckoutIntegrationCreateForm } from './self-checkout-integration-create';
import { SelfCheckoutIntegrationDetailView } from './self-checkout-integration-detail';
import { getEsuiteApi } from '../../../../app/api/esuite-api';

@CadenceView({
  title: 'Integrations',
  // showInMenu: 'root',
})
export class SelfCheckoutIntegrationListView extends CadenceListViewModel<any> {
  @CadenceColumn()
  id: number;

  @CadenceColumn()
  name: string;

  @CadenceAction({ label: 'Create' })
  createForm(): void {
    cadenceRouter.navigate(SelfCheckoutIntegrationCreateForm, {
      from: this,
      events: {
        'action.create': 'created',
      },
    });
  }

  @CadenceActionColumn({ label: 'Delete' })
  deleteForm(row: CadenceListViewRow<SelfCheckoutIntegrationListView>): void {
    cadenceRouter.navigate(SelfCheckoutIntegrationDetailView, {
      from: this,
      events: {
        'action.delete': 'deleted',
      },
      params: {
        id: row.id,
      },
    });
  }

  @CadenceActionColumn({ label: 'Edit' })
  updateForm(row: CadenceListViewRow<SelfCheckoutIntegrationListView>): void {
    cadenceRouter.navigate(SelfCheckoutIntegrationDetailView, {
      from: this,
      params: {
        id: row.id,
      },
    });
  }


  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  @CadenceViewEvent('deleted')
  remove(event: any): void {
    this._rows$.deleteBy((c) => c.id === event.from._params.id);
  }

  async _init(): Promise<void> {
    this._rows$.set(await getEsuiteApi().selfCheckoutIntegration.list());
  }
}
