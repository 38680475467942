import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { RoleName, UserResponse } from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { intersection } from 'lodash';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user$ = new BehaviorSubject(null);

  get user() {
    return this.user$.value;
  }

  set user(u: UserResponse) {
    this.user$.next(u);
  }

  constructor() {}

  userHasAcceptableRoles(roles?: RoleName[]): boolean {
    if (!this.user) {
      return false;
    }
    if (!roles) {
      return true;
    }
    const userRoles = this.user.roles.map((r) => r.name as unknown as RoleName);
    return intersection(roles, userRoles).length > 0;
  }

  async getUser() {
    this.user$.next(await getEsuiteApi().auth.getUser('user'));
    return this.user;
  }
}
