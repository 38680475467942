/*
 * OrganizationDetailView
 * Generated delete resource from cadence/scripts/generate
 */

import { CadenceStackViewModel, CadenceView, CadenceFormAction, CadenceViewEvent, cadenceRouter, CadenceFormControl, CadenceStackViewTab, CadenceStackViewChild } from 'cadence';

import { OrganizationUpdateForm } from './organization-update';


interface IOrganizationDetailViewParams {
  id: number;
}

@CadenceView({
  title: 'Organization Details',
})
export class OrganizationDetailView extends CadenceStackViewModel<IOrganizationDetailViewParams> {
    @CadenceStackViewTab(() => OrganizationDetailView, ['updateForm'], { label: 'General' })
    generalTab: any;

    @CadenceStackViewChild(() => OrganizationUpdateForm)
    updateForm() {
        const form = new OrganizationUpdateForm();
        form._params = { id: this._params.id };
        return form;
    }

}
