import { MediaCollectionCreateForm } from './media-collection-create.form';
import { MediaCollectionView } from './media-collection.view';
import { CadenceViewEvent, ICadenceViewEvent } from 'cadence';
import { MediaCollectionResponse } from 'esuite-client';
import { CadenceStackViewModel } from 'cadence';
import { CadenceView } from 'cadence';
import { CadenceStackViewChild } from 'cadence';

@CadenceView({
  title: 'Stack View',
})
export class MediaCollectionAlbumDetailView extends CadenceStackViewModel {
  // Describes a general purpose reactive HTML component
  // @CadenceStackViewChild(
  //   () => MediaCollectionAlbumDetailView,
  // )
  // welcomeMessage: string;
  /*
  @CadenceLabel() // Headings
  @CadenceText() // Text content
  @CadenceHtml() // Custom reactive HTML
  @CadenceTab() // Auto group view elements into tabs.
*/
  @CadenceStackViewChild(() => MediaCollectionCreateForm, {
    title: 'Create a collection',
  })
  createCollection() {
    const form = new MediaCollectionCreateForm();
    form._controlData$.next({ name: 'test' });
    return form;
  }

  @CadenceStackViewChild(() => MediaCollectionView, {
    title: 'List of Media Collections',
  })
  mediaCollections() {
    return new MediaCollectionView();
  }

  @CadenceViewEvent('action.create', (view: MediaCollectionAlbumDetailView) => {
    return view.createCollection();
  })
  pushNewRow(event: ICadenceViewEvent<MediaCollectionResponse>): void {
    const mediaCollections = this.mediaCollections();
    mediaCollections._rows$.add(event.data);
  }
}
