<ng-container *ngIf="{
  form: form$ | async,
  floors: floors$ | async,
  listingId: listingId$ | async
} as view;">
  <div class="d-flex align-items-center justify-content-between">
    <h5>Edit Listing</h5>
    <div>
      <button mat-raised-button color="primary" (click)="handleUpdate(view.listingId, view.form)">Update</button>
    </div>
  </div>
  <form *ngIf="view.form" [formGroup]="view.form" class="container-fluid p-0">
      <div class="d-flex align-items-stretch justify-content-center flex-column">

        <mat-form-field  appearance="fill" class="w-100">
          <mat-label>Title</mat-label>
          <input formControlName="title" matInput>
        </mat-form-field>

        <mat-form-field  appearance="fill" class="w-100">
          <mat-label>Subtitle</mat-label>
          <input formControlName="subtitle" matInput>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Floor</mat-label>
          <mat-select formControlName="floorId">
            <mat-option *ngFor="let floor of view.floors" [value]="floor.id">{{ floor.name }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field  appearance="fill" class="w-100">
          <mat-label>Unit</mat-label>
          <input formControlName="unit" matInput>
        </mat-form-field>

        <hr>

        <h6 class="mt-3 mb-1">Floor Location</h6>

        <div class="row">
          <div class="col-6">
            <mat-form-field  appearance="fill" class="w-100" style="font-size: 13px">
              <input formControlName="floorX" matInput type="number" placeholder="x%">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field  appearance="fill" class="w-100" style="font-size: 13px">
              <input formControlName="floorY" matInput type="number" placeholder="y%">
            </mat-form-field>
          </div>
        </div>

        <h5>Logo</h5>
        <app-image-upload [imageFormControl]="view.form.get('logoImageId')"></app-image-upload>
        <hr>
        <h5>Banner</h5>
        <app-image-upload [imageFormControl]="view.form.get('bannerImageId')"></app-image-upload>
      </div>
  </form>
</ng-container>
