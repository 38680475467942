import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractFormComponent } from 'esuite-dashboard/app/shared/classes/abstract-form.component';
import {
  Activation,
  DigitalSignageResponse,
  EsuiteApp,
  KioskResponse,
  PaymentGatewayType,
  PaymentTerminalResponse,
  ProductCategoryResponse,
  RevenueCenterResponse,
  SelfCheckoutCreateRequest,
  SelfCheckoutIntegrationResponse,
  SelfCheckoutResponse,
  SelfCheckoutUIMode,
  SelfCheckoutUpdateRequest,
  ShopifyFulfillmentBehavior,
  ShopifyInventoryBehavior,
  UnavailableProductDisplay,
} from 'esuite-client';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { ShopifyCustomerMarketingBehavior } from 'esuite-client';
import { startCase } from 'lodash';
import { updateLocationId } from 'esuite-dashboard/app/shared/utils/update-location-id';
import { FieldOption, SelfCheckoutCountryResponse } from 'esuite-client';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { quickEnumToOptions } from 'esuite-dashboard/app/shared/utils/quick-enum-to-options';

@Component({
  selector: 'app-self-checkout-detail',
  templateUrl: './self-checkout-detail.component.html',
  styleUrls: ['./self-checkout-detail.component.scss'],
})
export class SelfCheckoutDetailComponent
  extends AbstractFormComponent<
    SelfCheckoutResponse,
    SelfCheckoutCreateRequest,
    SelfCheckoutUpdateRequest
  >
  implements OnInit
{
  loadingActivation: number;
  constructor(
    private alertService: AlertService,
    activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private location: Location
  ) {
    super();
    this.connectRouteToIdentity('selfCheckoutId', activatedRoute);
  }

  get hasPayment(): boolean {
    return [
      'default',
      'pos',
      // 'omd',
      // 'osd',
    ].includes(this.activationForm.get('type').value);
  }
  customerPanelOpenState = false;
  kiosks: KioskResponse[] = [];

  FieldOption = FieldOption;
  // AtomYardData
  unavailableProductDisplay = quickEnumToOptions(UnavailableProductDisplay);
  selfCheckout: SelfCheckoutResponse;
  selfCheckoutUIMode = quickEnumToOptions(SelfCheckoutUIMode);
  revenueCenters: RevenueCenterResponse[] = [];
  integrations: SelfCheckoutIntegrationResponse[] = [];
  categories: ProductCategoryResponse[] = [];
  signs: DigitalSignageResponse[] = [];
  countries: SelfCheckoutCountryResponse[] = [];
  inventoryBehaviors = quickEnumToOptions(ShopifyInventoryBehavior);
  fulfillmentBehaviors = quickEnumToOptions(ShopifyFulfillmentBehavior);
  marketingBehaviors = quickEnumToOptions(ShopifyCustomerMarketingBehavior);

  // @ViewChild('kioskSelector') kioskSelector: KioskSelectorComponent;

  // Current Activation State
  activation: Activation;
  activationForm: FormGroup;
  paymentTerminalGroups: {
    name: string;
    terminals: PaymentTerminalResponse[];
  }[] = [];
  paymentProviders = quickEnumToOptions(PaymentGatewayType);
  EsuiteApp = EsuiteApp;

  handleCreate(dto: SelfCheckoutCreateRequest): void {
    this.alertService.runWithLoader(
      async () => {
        const created = await getEsuiteApi().selfCheckout.create(dto);
        this.id = created.id;
        this.makeUpdateForm(created);
        updateLocationId(this.id, this.location);
      },
      'Self checkout created successfully',
      'Error creating self checkout.'
    );
  }

  handleUpdate(dto: SelfCheckoutUpdateRequest): void {
    this.alertService.runWithLoader(
      () => getEsuiteApi().selfCheckout.update(this.id, dto),
      'Self checkout updated successfully.',
      'Error updating self checkout'
    );
  }

  async loadFormData(id: number): Promise<void> {}

  async ngOnInit(): Promise<void> {
    const [
      revenueCenters,
      categories,
      signs,
      gateways,
      integrations,
      countries,
      kiosks,
    ] = await Promise.all([
      getEsuiteApi().revenueCenter.list(),
      getEsuiteApi().productCategory.list(),
      getEsuiteApi().digitalSignage.list(),
      getEsuiteApi().paymentGateway.list(),
      getEsuiteApi().selfCheckoutIntegration.list(),
      getEsuiteApi().selfCheckoutCountry.list(),
      getEsuiteApi().kiosk.list(),
    ]);
    this.revenueCenters = revenueCenters;
    this.categories = categories;
    this.signs = signs;
    this.integrations = integrations;
    this.countries = countries;
    this.kiosks = kiosks;
    this.paymentTerminalGroups = await Promise.all(
      gateways.map(async (gateway) => {
        return {
          name: gateway.name,
          terminals: await getEsuiteApi().paymentTerminal.list({
            gatewayId: gateway.id,
          }),
        };
      })
    );
  }

  makeCreateForm(): Promise<FormGroup> | FormGroup {
    return this.fb.group({
      name: [null, Validators.required],
      revenueCenterId: [null, Validators.required],
      categoryIds: [[], Validators.required],
      integrationIds: [[]],
      screensaverId: [null],
      bannerId: [null],
      maxIdleTime: [null, Validators.required],
      screensaverTimeout: [null, Validators.required],
      preferences: this.fb.group({
        // countries: this.makeCountryForm(),
        checkout: this.fb.group({
          forceAddSingleItem: [false],
          fields: this.fb.group({
            name: [FieldOption.Hide],
            phone: [FieldOption.Hide],
            email: [FieldOption.Show],
            shippingAddress: [FieldOption.Hide],
          }),
        }),
        ui: this.fb.group({
          enableTags: [true],
          unavailableProductDisplay: [UnavailableProductDisplay.ShowBadge],
          menuStartIndex: [null],
          mode: [SelfCheckoutUIMode.Default],
        }),
        order: this.fb.group({
          includeTags: [[]],
          defaultCustomer: [null],
          // sendToShopify: [true],
          inventoryLocation: [null],
          enableNotification: [false],
          customerMarketingBehavior: [
            ShopifyCustomerMarketingBehavior.OptInRequired,
          ],
          inventoryBehavior: [ShopifyInventoryBehavior.DecrementObeyingPolicy],
          fulfillmentBehavior: [ShopifyFulfillmentBehavior.Default],
        }),
      }),
    });
  }

  getFormArray(form, key: string[]): FormArray {
    return form.get(key) as FormArray;
  }

  createPrinter = (printerData: { name: string; printerName: string }) => {
    return this.fb.group({
      name: [printerData.name, Validators.required],
      printerName: [printerData.printerName, Validators.required],
    });
  };

  async editActivation(kiosk: KioskResponse): Promise<void> {
    this.loadingActivation = kiosk.id;
    this.activationForm?.disable();
    this.activation = (
      await getEsuiteApi().activation.list({
        kioskId: kiosk.id,
        selfCheckoutId: this.selfCheckout.id,
      })
    )[0];
    const data: any = this.activation.data || {
      printerName: null,
      paymentTerminal: {},
    };
    this.activationForm = this.fb.group({
      type: [this.activation.type, Validators.required],
      paymentTerminalId: [this.activation.paymentTerminalId],
      data: this.fb.group({
        printerName: [data.printerName],
        printers: this.fb.array((data.printers || []).map(this.createPrinter)),
      }),
    });
    this.activationForm.enable();
    this.loadingActivation = null;
  }

  updateActivation(): void {
    this.alertService.runWithLoader(
      () =>
        getEsuiteApi().activation.update(
          this.activation.id,
          this.activationForm.value
        ),
      'Activation updated successfully.',
      'Error updating activation.'
    );
  }

  async makeUpdateForm(
    idOrSelfCheckout: number | SelfCheckoutResponse
  ): Promise<FormGroup> {
    this.selfCheckout =
      typeof idOrSelfCheckout === 'number'
        ? await getEsuiteApi().selfCheckout.get(idOrSelfCheckout)
        : idOrSelfCheckout;
    const form = this.fb.group({
      name: [this.selfCheckout.name, Validators.required],
      revenueCenterId: [this.selfCheckout.revenueCenterId, Validators.required],
      categoryIds: [this.selfCheckout.categoryIds, Validators.required],
      integrationIds: [this.selfCheckout.integrationIds],
      screensaverId: [this.selfCheckout.screensaverId],
      bannerId: [this.selfCheckout.bannerId],
      countryIds: [this.selfCheckout.countryIds],
      maxIdleTime: [this.selfCheckout.maxIdleTime, Validators.required],
      screensaverTimeout: [
        this.selfCheckout.screensaverTimeout,
        Validators.required,
      ],
      preferences: this.fb.group({
        shopify: this.fb.group({
          discountCodes: [this.selfCheckout.preferences.shopify?.discountCodes],
          hideCollectionImage: [
            this.selfCheckout.preferences.shopify?.hideCollectionImage,
          ],
          mulberryToken: [this.selfCheckout.preferences.shopify?.mulberryToken],
          parcelportToken: [
            this.selfCheckout.preferences.shopify?.parcelportToken,
          ],
          penguinLockersToken: [
            this.selfCheckout.preferences.shopify?.penguinLockersToken,
          ],
          localDelivery: this.fb.group({
            enabled: [
              this.selfCheckout.preferences.shopify?.localDelivery?.enabled,
            ],
            atomYardConfigUrl: [
              this.selfCheckout.preferences.shopify?.localDelivery
                ?.atomYardConfigUrl,
            ],
          }),
        }),
        checkout: this.fb.group({
          forceAddSingleItem: [
            this.selfCheckout.preferences.checkout.forceAddSingleItem,
          ],
          fields: this.fb.group({
            name: [
              this.selfCheckout.preferences.checkout.fields?.name ||
                FieldOption.Hide,
            ],
            phone: [
              this.selfCheckout.preferences.checkout.fields?.phone ||
                FieldOption.Hide,
            ],
            email: [
              this.selfCheckout.preferences.checkout.fields?.email ||
                FieldOption.Show,
            ],
            shippingAddress: [
              this.selfCheckout.preferences.checkout.fields?.shippingAddress ||
                FieldOption.Hide,
            ],
          }),
        }),
        ui: this.fb.group({
          enableTags: [this.selfCheckout.preferences.ui.enableTags],
          unavailableProductDisplay: [
            this.selfCheckout.preferences.ui.unavailableProductDisplay,
          ],
          menuStartIndex: [this.selfCheckout.preferences.ui.menuStartIndex],
          mode: [this.selfCheckout.preferences.ui.mode],
        }),
        order: this.fb.group({
          defaultCustomer: [
            this.selfCheckout.preferences.order.defaultCustomer?.email,
          ],
          enableNotification: [
            !!this.selfCheckout.preferences.order.enableNotification,
          ],
          includeTags: [this.selfCheckout.preferences.order.includeTags || []],
          inventoryLocation: [
            this.selfCheckout.preferences.order.inventoryLocation,
          ],
          customerMarketingBehavior: [
            this.selfCheckout.preferences.order.customerMarketingBehavior,
          ],
          inventoryBehavior: [
            this.selfCheckout.preferences.order.inventoryBehavior,
          ],
          fulfillmentBehavior: [
            this.selfCheckout.preferences.order.fulfillmentBehavior,
          ],
        }),
      }),
    });
    return form;
  }
  splitByLines(str): string[] {
    return str.split('\n');
  }
  joinByLines(str: string[]): string {
    return str.join('\n');
  }
}
