<h5 mat-dialog-title>{{ editMode ? 'Edit' : 'Create' }} Layout</h5>

<mat-dialog-content *ngIf="form; else spinner">
  <form [formGroup]="form" class="container-fluid p-0">
    <mat-form-field class="w-100" appearance="fill">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput>
      <mat-error *ngFor="let err of errors.name">{{ err }}</mat-error>
    </mat-form-field>
    <div class="row">
      <mat-form-field class="col-6" appearance="fill">
        <mat-label>Width</mat-label>
        <input type="number" formControlName="width" matInput>
        <mat-error *ngFor="let err of errors.width">{{ err }}</mat-error>
      </mat-form-field>
      <mat-form-field class="col-6" appearance="fill">
        <mat-label>Height</mat-label>
        <input type="number" formControlName="height" matInput>
        <mat-error *ngFor="let err of errors.height">{{ err }}</mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button *ngIf="!editMode" color="primary" mat-raised-button [disabled]="form.invalid || loading" (click)="create()"> Add Layout</button>
  <button *ngIf="editMode" color="primary" mat-raised-button [disabled]="form.invalid || loading" (click)="update()"> Update Layout</button>
  <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
</mat-dialog-actions>
<ng-template #spinner>
  <mat-spinner [diameter]="50"></mat-spinner>
</ng-template>
