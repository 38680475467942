import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from 'esuite-dashboard/app/shared/components/dashboard/dashboard.component';
import { SelfCheckoutListComponent } from 'esuite-dashboard/app/pages/self-checkout/self-checkout-list/self-checkout-list.component';
import { SelfCheckoutDetailComponent } from 'esuite-dashboard/app/pages/self-checkout/self-checkout-detail/self-checkout-detail.component';
import { SelfCheckoutTemplateComponent } from 'esuite-dashboard/app/pages/self-checkout/self-checkout-template/self-checkout-template.component';
import { SelfCheckoutIntegrationListComponent } from 'esuite-dashboard/app/pages/self-checkout-integration/self-checkout-integration-list/self-checkout-integration-list.component';
import { AuthGuard } from 'esuite-dashboard/app/shared/guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'self-checkout-integrations',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: SelfCheckoutIntegrationListComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class SelfCheckoutIntegrationRoutingModule {}
