/*
 * AdmissionOptionCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  cadenceRouter,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../../app/api/esuite-api';
import { AdmissionOptionCreateRequest } from 'esuite-client';

interface IAdmissionOptionCreateFormParams {
  admissionId: number;
}

@CadenceView({
  title: 'Create Admission Option',
  viewType: 'dialog',
})
export class AdmissionOptionCreateForm extends CadenceFormViewModel<IAdmissionOptionCreateFormParams> {
  @CadenceFormControl()
  name: string;

  @CadenceFormControl(CadenceFormControlType.Select, {
    options: () => [
      { label: 'Question', value: 'question' },
      { label: 'Quantity', value: 'quantity' },
    ],
  })
  type: string;

  @CadenceFormControl({ required: false })
  question: string;

  @CadenceFormControl(CadenceFormControlType.Checkbox, { required: false })
  required: boolean;

  @CadenceFormAction({
    label: 'Create',
  })
  async create(): Promise<any> {
    /* Create logic goes here */
    return getEsuiteApi().admissionOption.create(this._params.admissionId, ({
      type: this.type,
      name: this.name,
      question: this.question,
      required: this.required,
    } as unknown) as AdmissionOptionCreateRequest);
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    this._postEvent('dismissDialog');
  }
}
