import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { createComponentIO } from 'esuite-util/components';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { swapArrayLocs } from 'esuite-util';
import { swapFormArrayLocs } from 'esuite-dashboard/app/shared/utils/swap-form-array-locs';
import { intOrNewFromParams } from 'esuite-dashboard/app/shared/utils/int-or-new-from-params';
import { AbstractResourceIdentifiedComponent } from 'esuite-dashboard/app/shared/mixins/resource-identified.mixin';

/*
Basic strategy for components that can create or update resources using a form.

- tracks an ID observable. If the ID observable has a null or -1 value we run makeCreateForm()
  otherwise we run makeUpdateForm()
- runs loadFormData only if a valid ID is available
- optionally connect the ID observable to the activated route to track ID from route parameter.
 */
@Component({
  selector: 'app-abstract-form-component',
  template: 'abstract AbstractFormComponent',
})
export abstract class AbstractFormComponent<
  T extends { id: number },
  C,
  U
> extends AbstractResourceIdentifiedComponent<T> {
  form: FormGroup;
  formMode: 'create' | 'update';
  get updateMode() {
    return this.formMode === 'update';
  }
  get createMode() {
    return this.formMode === 'create';
  }
  constructor() {
    super();
    this.form$ = this.idIO.value$.pipe(
      switchMap(async (id) => {
        if (!id || id === -1) {
          return [await this.makeCreateForm(), 'create' as const] as const;
        }
        await this.loadFormData(id);
        return [await this.makeUpdateForm(id), 'update' as const] as const;
      }),
      tap(([form, type]) => {
        this.formMode = type;
        this.form = form;
      }),
      map(([form]) => {
        return form;
      })
    );
  }
  // IO
  protected activatedRoute: ActivatedRoute;

  form$: Observable<FormGroup>;

  utils = {
    swapArrayLocs,
    swapFormArrayLocs,
  };

  connectRouteToIdentity(
    routeParamIdKey: string,
    activatedRoute: ActivatedRoute
  ): void {
    // Subscribe to route ID if no active input
    this.activatedRoute = activatedRoute;
    this.idIO.subscribeTo(
      this.activatedRoute.params.pipe(intOrNewFromParams(routeParamIdKey))
    );
  }

  // Design create form
  abstract makeCreateForm(): Promise<FormGroup> | FormGroup;

  // Design update form
  abstract makeUpdateForm(id: number): Promise<FormGroup> | FormGroup;

  // Load data initially required for the component
  // that does not depend on the resource id
  // abstract loadFreeFormData(): Promise<void> | void;

  // Load data requiring the resource ID
  abstract loadFormData(id: number): Promise<void> | void;
  abstract handleCreate(dto: C): Promise<any> | any;
  abstract handleUpdate(dto: U): Promise<any> | any;
}
