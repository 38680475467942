import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCategoryPageRoutingModule } from 'esuite-dashboard/app/pages/product-category/product-category-page-routing.module';

import { SharedModule } from 'esuite-dashboard/app/shared/shared.module';
import { StartPageRoutingModule } from 'esuite-dashboard/app/pages/start/start-page-routing.module';
import { StartComponent } from 'esuite-dashboard/app/pages/start/start.component';



@NgModule({
  declarations: [
    StartComponent,
  ],
  imports: [
    CommonModule,
    StartPageRoutingModule,

    SharedModule,
  ]
})
export class StartModule { }
