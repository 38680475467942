import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractResourceIdentifiedComponent } from 'esuite-dashboard/app/shared/mixins/resource-identified.mixin';
import { SpinToWinResponse } from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { ResourceListConfig } from 'esuite-dashboard/app/shared/components/resource-list/resource-list.component';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';

@Component({
  selector: 'app-spin-to-win-list',
  template: `
    <app-list-sidebar-detail
      *ngIf="{ id: id$ | async } as outputs"
      #list
      [listConfig]="listConfig"
      title="Spin To Win Games"
      [(id)]="id"
    >
      <app-spin-to-win-detail
        (update)="handleUpdate($event)"
        (create)="handleCreate($event)"
        [id]="id"
      >
      </app-spin-to-win-detail>
    </app-list-sidebar-detail>
  `,
  styles: [],
})
export class SpinToWinListComponent
  extends AbstractResourceIdentifiedComponent<SpinToWinListComponent>
  implements OnInit
{
  constructor(private alertService: AlertService) {
    super();
  }

  listConfig: ResourceListConfig<SpinToWinResponse> = {
    cols: [
      {
        key: 'name',
        header: 'Name',
      },
    ],
    delete: (app) => {
      return this.alertService.confirmLoaderAndResult(
        `Are you sure you want to remove the game <strong>${app.name}</strong>?`,
        () =>
          getEsuiteApi()
            .spinToWin.remove(app.id)
            .then(() => true),
        'Game removed successfully.',
        'Error removing game'
      );
    },
    edit: (app) => (this.id = app.id),
    rowLoader: () => {
      return getEsuiteApi().spinToWin.list();
    },
  };

  ngOnInit(): void {}

  handleUpdate = ($event: any) => {
    this.list.list.updateRowBy((row) => row.id === $event.id, $event);
  };
  handleCreate = ($event: any) => {
    this.list.list.appendRow($event);
  };
}
