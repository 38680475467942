import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CadCustomControl } from 'cadence/cad-custom-control';
import {
  SelfCheckoutIntegrationResponse,
  SelfCheckoutIntegrationType,
} from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export enum AppFilterTarget {
  Products = 'products',
  Collections = 'collections',
}

export enum AppFilterType {
  None = 'none',
  Tag = 'tag',
  Handle = 'handle',
}

interface AppFilter {
  integration: SelfCheckoutIntegrationType;
  filter: AppFilterType;
  target: AppFilterTarget;
  tag?: string;
  handles?: string[];
}

@Component({
  selector: 'app-category-integration-selector',
  templateUrl: './category-integration-selector.component.html',
  styleUrls: ['./category-integration-selector.component.html'],
})
export class CategoryIntegrationSelectorComponent
  implements OnInit, CadCustomControl, OnDestroy
{
  private localProps: { integrationId: number };
  @Input()
  get props(): { integrationId: number } {
    return this.localProps;
  }

  set props(p) {
    this.localProps = p;
    if (this.props?.integrationId) {
      this.initIntegrationFilters(this.props.integrationId);
    }
  }

  @Input()
  control: any[] = [];

  @Output()
  controlChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  integration: SelfCheckoutIntegrationResponse;
  SelfCheckoutIntegrationType = SelfCheckoutIntegrationType;

  selectedFilters: FormGroup[] = [];

  AppFilterTarget = AppFilterTarget;
  // appFilterTargets = Object.keys(AppFilterTarget);
  appFilterTargetNames = Object.values(AppFilterTarget);

  AppFilterType = AppFilterType;
  // appFilterTypes = Object.keys(AppFilterType);
  appFilterTypeNames = Object.values(AppFilterType);

  constructor(private fb: FormBuilder) {}

  addFilter(data): void {
    this.selectedFilters.push(
      this.fb.group({
        integration: [this.integration.type, Validators.required],
        filter: [data?.filter || AppFilterType.None, Validators.required],
        tag: [data?.tag || ''],
        handles: [data?.handles || []],
        target: [data?.target || AppFilterTarget.Products, Validators.required],
      })
    );
  }
  removeFilter(filter: FormGroup): void {
    this.selectedFilters.splice(this.selectedFilters.indexOf(filter), 1);
  }

  async ngOnInit(): Promise<void> {
  }

  async initIntegrationFilters(integrationId: number): Promise<void> {
    this.integration = await getEsuiteApi().selfCheckoutIntegration.get(
      integrationId
    );
    for (const fl of this.control) {
      this.addFilter(fl);
    }
  }

  ngOnDestroy(): void {}

  syncChanges(): void {
    const control = this.selectedFilters.map((ff) => {
      return {
        ...ff.value,
        exec: this.calculateExecution(ff.value),
      };
    });
    this.controlChange.emit(control);
  }

  calculateExecution(item: AppFilter): string {
    if (item.target === AppFilterTarget.Products) {
      if (item.filter === AppFilterType.Tag) {
        // Tag-based product filter
        return `$.products[?(@.tags.indexOf('${item.tag}') > -1)]`;
      }
      // All product filter
      return `$.products`;
    } else if (item.target === AppFilterTarget.Collections) {
      // All collections filter
      if (item.filter === AppFilterType.Handle) {
        // Tag-based collections filter
        return `$.collections[?(${JSON.stringify(
          item.handles
        )}.indexOf(@.handle) > -1]`;
      }
      return `$.collections`;
    }
  }

  // Available filters:
  /*
  - Products
    - All
    - By Tag
  - Collections
    - All
    - By Selection

   */
}
