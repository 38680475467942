<mat-card *ngIf="form$ | async as form" [formGroup]="form">
  <mat-card-title>
    <span class="h5">{{ isNew ? 'Create' : 'Update' }} Integration</span>
    <app-create-update-button
      [disabled]="form.invalid || !form.enabled"
      [isNew]="isNew"
      class="form-submit-btn"
      (create)="handleCreate(form.value)" (update)="handleUpdate(form.value)"  label="Integration">
    </app-create-update-button>
  </mat-card-title>
  <mat-card-header>

  </mat-card-header>
  <mat-card-content>
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of types" [value]="type.value">{{ type.label }}</mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container [ngSwitch]="form.get('type').value">
      <ng-container *ngSwitchCase="SelfCheckoutIntegrationType.Shopify" formGroupName="shopify">

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Shop Name</mat-label>
          <input matInput formControlName="shopName">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>API Key</mat-label>
          <input matInput formControlName="apiKey">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Domain</mat-label>
          <input matInput formControlName="domain">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Storefront Access Token</mat-label>
          <input matInput formControlName="storefrontAccessToken">
        </mat-form-field>

        <mat-checkbox formControlName="submitOrders" class="w-100">
          Send orders to Shopify
        </mat-checkbox>

        <mat-checkbox formControlName="requireShippingMethod" class="w-100">
          Require Shipping Method
        </mat-checkbox>


      </ng-container>
    </ng-container>
  </mat-card-content>
  <mat-card-actions class="d-flex align-items-center justify-content-between">
    <button mat-button *ngIf="(prev(id)) as prevInd" (click)="id = prevInd" slot="start" class="mr-auto">Previous</button>
    <button mat-button *ngIf="(next(id)) as nextInd" (click)="id = nextInd" slot="end" class="ml-auto">Next</button>
  </mat-card-actions>
</mat-card>
