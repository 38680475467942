import { Component, OnInit } from '@angular/core';
import { PhotoboothFrameResponse } from 'esuite-client';
import { AbstractFormComponent } from 'esuite-dashboard/app/shared/classes/abstract-form.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-photobooth-frame-detail',
  templateUrl: './photobooth-frame-detail.component.html',
  styleUrls: ['./photobooth-frame-detail.component.scss'],
})
export class PhotoboothFrameDetailComponent
  extends AbstractFormComponent<PhotoboothFrameResponse, any, any>
  implements OnInit
{
  frame: PhotoboothFrameResponse;
  photoboothId: number;
  constructor(
    private fb: FormBuilder,
    private modalRef: MatDialogRef<PhotoboothFrameDetailComponent>
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    if (this.frame) {
      this.id = this.frame.id;
    }
  }

  handleCreate(dto: any): any {}

  async handleUpdate(dto: any): Promise<any> {
    const updated = await getEsuiteApi().photoboothFrame.update(
      this.frame.id,
      this.photoboothId,
      dto
    );
    this.modalRef.close(updated);
  }

  async handleDelete() {
    await getEsuiteApi().photoboothFrame.remove(
      this.frame.id,
      this.photoboothId
    );
    this.modalRef.close(-1);
  }

  loadFormData(id: number): Promise<void> | void {
    return undefined;
  }

  makeCreateForm(): Promise<FormGroup> | FormGroup {
    return undefined;
  }

  async makeUpdateForm(id: number): Promise<FormGroup> {
    return this.fb.group({
      overlayImageLayout: [this.frame.overlayImageLayout],
    });
  }
}
