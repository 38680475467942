import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AppPlugin } from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { ActivatedRoute } from '@angular/router';
import { map, pluck, take } from 'rxjs/operators';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicFormService } from '@ng-dynamic-forms/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-plugin-detail',
  template: `
    <a [routerLink]="['..']" class="d-block mb-3">Back to Plugins</a>
    <ng-container [formGroup]="form" *ngIf="plugin; else noPlugin">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <h3 class="m-0">{{ plugin.title }}</h3>
        <button mat-raised-button color="primary" (click)="save()">Save</button>
      </div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" />
      </mat-form-field>
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <button
            mat-icon-button
            color="primary"
            *ngIf="view !== 'settings'"
            (click)="view = 'settings'"
          >
            <mat-icon>dynamic_form</mat-icon>
          </button>
          <button
            mat-icon-button
            color="primary"
            *ngIf="view !== 'code'"
            (click)="view = 'code'"
          >
            <mat-icon>code</mat-icon>
          </button>
        </div>
        <div>
          <button mat-icon-button color="warn" (click)="vmConsole$.next([])">
            <mat-icon>clear</mat-icon>
          </button>
          <button
            mat-icon-button
            color="primary"
            (click)="showConsole = !showConsole"
          >
            <mat-icon *ngIf="showConsole">visibility_off</mat-icon>
            <mat-icon *ngIf="!showConsole">visibility</mat-icon>
          </button>
        </div>
      </div>
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col" [ngClass]="[view === 'code' ? '' : 'd-none']">
            <app-ace-editor
              [style]="{ height: '60vh', 'min-height': '320px' }"
              mode="javascript"
              [customControl]="form.get('functions')"
            ></app-ace-editor>
          </div>
          <div class="col" [ngClass]="[view === 'settings' ? '' : 'd-none']">
            <form
              class="border p-4"
              *ngIf="pluginViewFormModel && pluginViewFormGroup"
              [formGroup]="pluginViewFormGroup"
              (ngSubmit)="handlePluginViewSubmit()"
            >
              <dynamic-material-form
                [group]="pluginViewFormGroup"
                [model]="pluginViewFormModel"
              >
                <ng-template modelType="GROUP">
                  <hr />
                </ng-template>
              </dynamic-material-form>
              <button
                [disabled]="!pluginViewFormGroup.valid"
                type="submit"
                mat-raised-button
                color="primary"
                value="Save Configuration"
              >
                Save Configuration
              </button>
            </form>
          </div>
          <div *ngIf="showConsole" class="col-md-6">
            <div
              #console
              class="border p-4 bg-dark text-white"
              style="
              font-family: 'monospace';
              height: 60vh;
              overflow: auto;
              font-size: 0.8em;
              min-height: 320px"
            >
              <div
                *ngFor="let o of vmConsole$ | async"
                class="border-bottom py-2"
              >
                <span class="text-muted small">{{
                  o.date | date: 'M/d/yy h:mm:ss a'
                }}</span>
                <div>{{ o.line }}</div>
              </div>
            </div>
            <div
              class="pt-4 border-top small"
              *ngIf="{
                stats: (vmStat$ | async),
                average: (vmStatAverage | async)
              } as statView"
            >
              <ng-container *ngIf="statView.stats.length">
                <p>
                  <strong>Time elapsed</strong>
                  {{ statView.stats[0].elapsed / 60000 | number }}
                  min.
                </p>
                <p>
                  <strong>CPU (latest)</strong>
                  {{ statView.stats[0].cpu | number }}%
                </p>
                <p>
                  <strong>Memory (latest)</strong>
                  {{ statView.stats[0].memory / 1000000 | number }}
                  mb
                </p>
              </ng-container>
              <p *ngIf="statView.average?.cpu">
                <strong>CPU (average)</strong>
                {{ statView.average.cpu | number }}%
              </p>
              <p *ngIf="statView.average?.memory">
                <strong>Memory (average)</strong>
                {{ statView.average.memory / 1000000 | number }} mb
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noPlugin>
      <div class="mx-auto text-center">Plugin not found.</div>
    </ng-template>
  `,
  styles: [],
})
export class PluginDetailComponent implements OnInit, OnDestroy {
  @ViewChild('console') console: ElementRef<HTMLDivElement>;
  form: FormGroup;
  plugin: AppPlugin;
  vmConsole$: BehaviorSubject<{ date: Date; line: string }[]> =
    new BehaviorSubject([]);
  vmStat$: BehaviorSubject<
    {
      date: Date;
      cpu: number; // 10.0,            // percentage (from 0 to 100*vcore)
      memory: number; // 357306368,    // bytes
      ppid: number; // 312,            // PPID
      pid: number; // 727,             // PID
      ctime: number; // 867000,        // ms user + system time
      elapsed: number; // 6650000,     // ms since the start of the process
      timestamp: number; // 864000000  // ms since epoch
    }[]
  > = new BehaviorSubject([]);
  vmStatAverage = this.vmStat$.pipe(
    map((vmStats) => {
      const sums = vmStats.reduce(
        (total, s) => {
          return {
            cpu: total.cpu + s.cpu,
            memory: total.memory + s.memory,
          };
        },
        { cpu: 0, memory: 0 }
      );
      return {
        cpu: sums.cpu / vmStats.length,
        memory: sums.memory / vmStats.length,
      };
    })
  );
  eventSource: EventSource;
  showConsole = true;
  view = 'code';
  pluginViewFormGroup;
  pluginViewFormModel;

  constructor(
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private fb: FormBuilder,
    private formService: DynamicFormService
  ) {}

  async ngOnInit(): Promise<void> {
    this.plugin = await this.alertService.loader(async () =>
      getEsuiteApi().appPlugin.get(
        +(await this.activatedRoute.params
          .pipe(pluck('pluginId'), take(1))
          .toPromise())
      )
    );
    this.form = this.makeForm();
    this.prepareOutput();
    this.prepareView();
  }

  prepareOutput(): void {
    if (this.eventSource) {
      this.eventSource.close();
    }
    this.eventSource = new EventSource(`/api/plugins/${this.plugin.id}/output`);
    this.eventSource.addEventListener('log', (message: MessageEvent) => {
      const vmConsole = this.vmConsole$.value.slice();
      vmConsole.unshift({ date: new Date(), line: message.data });
      vmConsole.splice(150);
      this.vmConsole$.next(vmConsole);
    });
    this.eventSource.addEventListener('stat', (message: MessageEvent) => {
      const vmStat = this.vmStat$.value.slice();
      vmStat.unshift({ date: new Date(), ...JSON.parse(message.data) });
      vmStat.splice(60);
      this.vmStat$.next(vmStat);
    });
     ;
  }

  private makeForm(): FormGroup {
    return this.fb.group({
      title: [this.plugin.title],
      functions: [this.plugin.functions],
    });
  }

  ngOnDestroy(): void {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  async save(): Promise<void> {
    this.plugin = await this.alertService.runWithLoader(
      async () =>
        getEsuiteApi().appPlugin.update(this.plugin.id, this.form.value),
      'Plugin saved successfully',
      'Error saving plugin'
    );
    this.form = this.makeForm();
    this.vmStat$.next([]);
    setTimeout(() => {
      this.prepareView();
    }, 10000);
  }

  private async prepareView(): Promise<void> {
    //  ;
    this.pluginViewFormModel = this.formService.fromJSON(
      (
        (await getEsuiteApi().appPluginHook.useMap('pluginViewFormModel', {
          body: null,
        })) as any
      ).result
    );
    this.pluginViewFormGroup = this.formService.createFormGroup(
      this.pluginViewFormModel
    );
  }

  async handlePluginViewSubmit() {
    this.alertService.loader(() =>
      getEsuiteApi().appPluginHook.useAction('pluginViewFormSubmit', {
        body: this.pluginViewFormGroup.value,
      })
    );
    await this.prepareView();
  }
}
