<ng-container *ngIf="integration?.type === SelfCheckoutIntegrationType.Shopify">
  <button color="primary" class="mb-3" mat-raised-button (click)="addFilter()">Add</button>
  <ng-container *ngFor="let selected of selectedFilters">
    <mat-card [formGroup]="selected" (click)="syncChanges()">
      <mat-card-content>
        <mat-form-field appearance="outline" >
          <mat-select formControlName="target" (selectionChange)="syncChanges()">
            <mat-option [value]="AppFilterTarget.Products">Products</mat-option>
            <mat-option [value]="AppFilterTarget.Collections">Collections</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  >
          <mat-select formControlName="filter" (selectionChange)="syncChanges()">
            <mat-option [value]="AppFilterType.None">All</mat-option>
            <mat-option *ngIf="selected.get('target').value === AppFilterTarget.Products" [value]="AppFilterType.Tag">By Tag</mat-option>
            <mat-option *ngIf="selected.get('target').value === AppFilterTarget.Collections" [value]="AppFilterType.Tag">By Handles</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="selected.get('filter').value === AppFilterType.Tag">
          <mat-label>Enter tag</mat-label>
          <input (change)="syncChanges()" formControlName="tag" matInput placeholder="Ex. Pizza" >
        </mat-form-field>
        <button mat-icon-button color="warn" (click)="removeFilter(selected)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-card-content>
    </mat-card>
  </ng-container>
</ng-container>
