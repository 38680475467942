import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DigitalSignageSlideResponse } from 'esuite-client';

@Component({
  selector: 'app-kiosk-slide',
  templateUrl: './kiosk-slide.component.html',
  styleUrls: ['./kiosk-slide.component.scss'],
})
export class KioskSlideComponent implements OnInit {
  private localSlide: DigitalSignageSlideResponse;

  @Input()
  get slide(): DigitalSignageSlideResponse {
    return this.localSlide;
  }
  set slide(s: DigitalSignageSlideResponse) {
    this.localSlide = s;
  }

  @Input()
  slideContainer: [number, number] = [null, null];

  @Output()
  edit: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  remove: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  toggle: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {
  }
}
