<ng-container
  *ngIf="{
    directories: directories$ | async
  } as view"
>

  <!-- Header -->
  <div class="d-flex align-items-center justify-content-between pb-4">
    <h3 class="m-0">Directories</h3>
    <a mat-mini-fab color="primary" [routerLink]="['new']">
      <mat-icon>add</mat-icon>
    </a>
  </div>

  <!-- List Table -->
  <table
    *ngIf="view.directories"
    mat-table
    [dataSource]="view.directories"
    class="w-100"
  >
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="[element.id]">{{ element.name }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="remove(element)">
            <mat-icon>delete</mat-icon>
            <span>Remove</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

  </table>

</ng-container>
