/*
 * DigitalSignageFrameUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { DigitalSignageFrameResponse } from 'esuite-client';
import { getEsuiteApi } from '../../../../app/api/esuite-api';
import { ImageUploadComponent } from '../../../../app/shared/components/image-upload/image-upload.component';

interface IDigitalSignageFrameUpdateFormParams {
  frame: DigitalSignageFrameResponse;
  signageId: number;
}

@CadenceView({
  title: 'Update Digital Signage Frame',
  viewType: 'dialog',
})
export class DigitalSignageFrameUpdateForm extends CadenceFormViewModel<IDigitalSignageFrameUpdateFormParams> {
  @CadenceFormControl()
  name: string;

  @CadenceFormControl(CadenceFormControlType.Number, { classes: 'w-50' })
  width: number;

  @CadenceFormControl(CadenceFormControlType.Number, { classes: 'w-50' })
  height: number;

  @CadenceFormControl(CadenceFormControlType.Custom, {
    component: () => ImageUploadComponent,
    required: false,
  })
  bgImageId: number;

  @CadenceFormAction({
    label: 'Update',
  })
  async update(): Promise<any> {
    return getEsuiteApi().digitalSignageFrame.update(
      this._params.signageId,
      this._params.frame.id,
      {
        ...this._controlData$.value,
        layout: {
          width: `${this.width}px`,
          height: `${this.height}px`,
          widthPx: this.width,
          heightPx: this.height,
        },
      },
    );
  }

  @CadenceViewEvent('action.update')
  afterUpdate(): void {
    this._postEvent('dismissDialog');
  }

  async _init(): Promise<void> {
    this._controlData$.next({
      ...this._params.frame,
      width: this._params.frame.layout.width,
      height: this._params.frame.layout.height,
    });
  }
}
