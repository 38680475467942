import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentGatewayListComponent } from './payment-gateway-list/payment-gateway-list.component';
import { PaymentGatewayDetailComponent } from './payment-gateway-detail/payment-gateway-detail.component';
import { PaymentGatewayRoutingModule } from 'esuite-dashboard/app/pages/payment-gateway/payment-gateway-routing.module';
import { SharedModule } from 'esuite-dashboard/app/shared/shared.module';
import { PaymentGatewayCreateComponent } from './payment-gateway-create/payment-gateway-create.component';

@NgModule({
  declarations: [
    PaymentGatewayListComponent,
    PaymentGatewayDetailComponent,
    PaymentGatewayCreateComponent,
  ],
  imports: [PaymentGatewayRoutingModule, CommonModule, SharedModule],
})
export class PaymentGatewayModule {}
