import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Directory } from 'esuite-client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../../../shared/components/alert/alert.service';
import { getEsuiteApi } from '../../../../api/esuite-api';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { pluckAsInt } from 'esuite-dashboard/app/shared/utils/pluck-as-int';

@Component({
  selector: 'app-directory-detail',
  templateUrl: './directory-detail.component.html',
  styles: [],
})
export class DirectoryDetailComponent {
  directoryId$ = this.activatedRoute.params.pipe(pluckAsInt('directoryId'));

  directory$ = this.directoryId$.pipe(
    switchMap((directoryId) => getEsuiteApi().directory.get(directoryId))
  );

  form$ = this.directory$.pipe(
    map((directory) => this.makeUpdateForm(directory))
  );

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  private makeUpdateForm(directory: Directory) {
    return this.fb.group({
      name: [directory.name, Validators.required],
    });
  }

  handleUpdate(directoryId: number, form: FormGroup) {
    form.disable();
    const update = { ...form.value };

    this.alertService
      .runWithLoader(
        () => getEsuiteApi().directory.update(directoryId, update),
        'Directory updated successfully',
        'Error updating directory'
      )
      // If an error is thrown we need to enable the form again
      .finally(() => {
        form.enable();
      });
  }
}
