import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ResourceListComponent,
  ResourceListConfig,
} from 'esuite-dashboard/app/shared/components/resource-list/resource-list.component';
import { MediaCollectionResponse } from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';

@Component({
  selector: 'app-media-collection-list',
  template: `
    <div class="d-flex align-items-center justify-content-between">
      <h5>Media Collections</h5>
      <app-create-update-button
        [isNew]="true"
        [routerLink]="['new']"
        label="Media Collections"
      ></app-create-update-button>
    </div>
    <app-resource-list
      emptyMessage="No Media Collections to show."
      #list
      [config]="config"
    >
    </app-resource-list>
  `,
})
export class MediaCollectionListComponent implements OnInit {
  @ViewChild('list') list: ResourceListComponent<MediaCollectionResponse>;
  config: ResourceListConfig<MediaCollectionResponse> = {
    cols: [{ key: 'name', header: 'Name', link: (r) => [r.id] }],
    delete: (sign) =>
      this.alertService.confirmLoaderAndResult(
        'Are you sure you want to delete this media collection?',
        () =>
          getEsuiteApi()
            .mediaCollection.remove(sign.id)
            .then(() => true),
        'Media Collection deleted successfully.',
        'Error deleting media collection'
      ),
    links: [
      {
        label: 'Duplicate',
        onClick: async (item, itemInd) => {
          const duplicated = await getEsuiteApi().mediaCollection.duplicate(
            item.id
          );
          this.list.appendRow(duplicated);
        },
      },
    ],
    rowLoader(pageParameters?: {
      skip: number;
      limit: number;
    }): Promise<MediaCollectionResponse[]> | MediaCollectionResponse[] {
      return getEsuiteApi()
        .mediaCollection.list({ ...pageParameters })
        .then((rs) => {
           ;
          return rs;
        });
    },
  };
  constructor(private alertService: AlertService) {}

  ngOnInit(): void {}

  handleCreate() {}
}
