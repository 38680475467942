import { Component, OnInit } from '@angular/core';
import { KioskResponse } from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit {
  constructor() {}

  // kiosks: KioskResponse[] = [];

  async ngOnInit(): Promise<void> {
    // this.kiosks = await getEsuiteApi().kiosk.list();
  }
}
