import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
// import { EsuiteClientService, OrganizationService } from 'esuite-lib';
import { VideoUploadResponse } from 'esuite-client';
import { getEsuiteApi } from '../../../api/esuite-api';

@Component({
  selector: 'app-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.scss'],
})
export class VideoUploadComponent implements OnInit {
  formSub: Subscription;
  loading = false;

  @Input()
  get label(): string {
    return this.localLabel;
  }
  set label(iu: string) {
    this.localLabel = iu;
  }

  @Input()
  get video(): VideoUploadResponse {
    return this.localVideo;
  }
  set video(iu: VideoUploadResponse) {
    this.localVideo = iu;
    this.videoChange.emit(iu);
  }

  @Input()
  get videoFormControl(): AbstractControl {
    return this.localFormControl;
  }
  set videoFormControl(fc: AbstractControl) {
    this.localFormControl = fc;
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
    this.detectForm();
  }

  @Output()
  videoChange: EventEmitter<VideoUploadResponse> = new EventEmitter<VideoUploadResponse>();

  constructor(
    private dialog: MatDialog,
  ) {}

  // Holds the video ID of the upload
  private localFormControl: AbstractControl;

  private localVideo: VideoUploadResponse;
  uploadingSrc;

  removed: any = false;

  private localLabel = 'Upload Video';

  remove(): void {
    this.removed = { value: this.videoFormControl.value, video: this.video };
    this.videoFormControl.setValue(null);
    this.video = null;
  }
  undoRemove(): void {
    this.videoFormControl.setValue(this.removed.value);
    this.video = this.removed.video;
    this.removed = false;
  }

  async ngOnInit(): Promise<void> {}

  private async reloadFormVideo(): Promise<VideoUploadResponse> {
     ;
    this.video = await getEsuiteApi().videoUpload.get(
      this.videoFormControl.value,
    );
    return this.video;
  }

  private detectForm(): void {
     ;
    if (this.videoFormControl) {
       ;
      this.formSub = this.videoFormControl.valueChanges
        .pipe(startWith(this.videoFormControl.value))
        .subscribe(async (videoId) => {
           ;
          if (videoId && !this.video) {
            this.video = await this.reloadFormVideo();
          }
        });
    }
  }

  async startUpload(event): Promise<void> {
    this.loading = true;
     ;
    event[0].fileEntry.file((file) => {
       ;
      setTimeout(async () => {
        this.uploadingSrc = URL.createObjectURL(file);
         ;
        try {
          const video = await this.uploadVideo(file);
          this.setVideo(video);
          this.uploadingSrc = null;
          this.removed = false;
        } catch (e) {
           ;
        }
        this.loading = false;
      });
    });
  }

  private setVideo(video: VideoUploadResponse): void {
    if (this.videoFormControl) {
      this.videoFormControl.setValue(video.id);
    }
    this.video = video;
  }

  private async uploadVideo(file: File): Promise<VideoUploadResponse> {
    // Here you can access the real file
    const video = await getEsuiteApi().videoUpload.uploadFile(file);
    this.setVideo(video);
    return video;
  }

  // async openMediaLibrary(): Promise<void> {
  //   const dialogRef = this.dialog.open(MediaLibraryComponent, {
  //     width: '90vw',
  //     maxWidth: '90vw',
  //     maxHeight: '90vh',
  //   });
  //   const videos: VideoUploadResponse[] = await dialogRef
  //     .afterClosed()
  //     .toPromise();
  //   if (videos?.length) {
  //     this.setVideo(videos[0]);
  //   }
  // }

  ngOnDestroy(): void {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }
}
