<mat-tab-group *ngIf="form$ | async as form">
  <mat-tab label="Gateway" [formGroup]="form">
    <div class="d-flex align-items-start justify-content-between mt-4 mb-2">
      <h5>Gateway</h5>
      <app-create-update-button
        [disabled]="form.invalid || !form.enabled"
        [isNew]="isNew"
        (create)="handleCreate(form.value)"
        (update)="handleUpdate(form.value)" label="Gateway" >
      </app-create-update-button>
    </div>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type" class="w-100">
        <mat-option [value]="type.value" *ngFor="let type of gatewayTypes">{{ type.label }}</mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container [ngSwitch]="form.get('type').value">
      <!-- Moneris Fields -->
      <ng-container  *ngSwitchCase="PaymentGatewayType.Moneris" formGroupName="moneris" >
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Merchant ID</mat-label>
          <input matInput type="text" formControlName="merchantId">
        </mat-form-field>
      </ng-container>
      <!-- OpenEdge Fields -->
      <ng-container  *ngSwitchCase="PaymentGatewayType.Openedge" formGroupName="openedge" >
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Web ID</mat-label>
          <input matInput type="text" formControlName="webId">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Web Auth Key</mat-label>
          <input matInput type="text" formControlName="webAuthKey">
        </mat-form-field>
      </ng-container>
      <!-- Cayan Fields -->
      <ng-container *ngSwitchCase="PaymentGatewayType.Cayan" formGroupName="cayan">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Merchant Name</mat-label>
          <input matInput type="text" formControlName="merchantName">
        </mat-form-field>
        <mat-form-field  appearance="outline" class="w-100">
          <mat-label>Site ID</mat-label>
          <input matInput type="text" formControlName="merchantSiteId">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Merchant Key</mat-label>
          <input matInput type="text" formControlName="merchantKey">
        </mat-form-field>

      </ng-container>
    </ng-container>
  </mat-tab>
  <mat-tab label="Devices" *ngIf="gateway">
    <mat-card class="mt-3 p-0">
      <div class="d-flex align-items-start justify-content-between mb-3">
        <h5>Devices</h5>
        <app-create-update-button
          [disabled]="form.invalid || !form.enabled"
          [isNew]="true"
          (create)="openTerminalDialog()" label="Device" >
        </app-create-update-button>
      </div>
      <mat-card-content>
        <app-resource-list #deviceList [config]="deviceListConfig"></app-resource-list>
      </mat-card-content>
    </mat-card>
  </mat-tab>
</mat-tab-group>
