<h4>Create a Directory Floor</h4>

<ng-container *ngIf="{
  form: form$ | async
} as view">
  <form *ngIf="view.form" [formGroup]="view.form" >
    <div class="d-flex align-items-stretch justify-content-between flex-column">

      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Number</mat-label>
        <input matInput formControlName="number">
      </mat-form-field>

    </div>
    <button mat-raised-button color="primary"
            (click)="handleCreate(view.form)"
            [disabled]="!view.form.enabled || view.form.invalid">
      Create
    </button>
  </form>
</ng-container>


