import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phpDate'
})
export class PhpDatePipe implements PipeTransform {

  transform(value: string|number, ...args: unknown[]): unknown {
    if (`${value}`.length > 10) {
      return new Date(parseInt(value as string, 10));
    } else {
      return new Date(parseInt(value as string, 10) * 1000);
    }
  }

}
