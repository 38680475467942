import { CadenceFormViewModel } from 'cadence';
import {
  CadenceFormControlType,
  CadenceObjectType,
  ICadenceFormControlMetadata,
} from '../../types';
import { startCase } from 'lodash';
import { getCadence } from '../../cadence';

type defaultKeys =
  | 'name'
  | 'hidden'
  | 'disabled'
  | 'label'
  | 'type'
  // | 'value'
  | 'default'
  | 'required'
  | 'controlType';

type ICadenceFormControlDefaults<
  Type extends CadenceFormControlType,
  Form extends CadenceFormViewModel
> = Pick<ICadenceFormControlMetadata<Type, Form>, defaultKeys>;

export type ICadenceFormControlOptions<
  Type extends CadenceFormControlType,
  From extends CadenceFormViewModel
> = Omit<ICadenceFormControlMetadata<Type, From>, defaultKeys> &
  Partial<ICadenceFormControlDefaults<Type, From>>;

export function CadenceFormControl<
  Type extends CadenceFormControlType,
  From extends CadenceFormViewModel
>(
  controlTypeOrOptions?: Type | ICadenceFormControlOptions<Type, From>,
  options?: ICadenceFormControlOptions<Type, From>,
): any {
  return (target: From, name: string) => {
    const formControlOptions =
      typeof controlTypeOrOptions === 'string' ? options : controlTypeOrOptions;
    const targetName = target.constructor.name;
    const t = Reflect.getMetadata('design:type', target, name);

    const controlType =
      typeof controlTypeOrOptions === 'string'
        ? controlTypeOrOptions
        : t == Number
        ? CadenceFormControlType.Number
        : CadenceFormControlType.Text;
    const defaults = {
      name,
      hidden: false,
      label: startCase(name),
      type: t,
      // value: (userInput: any) => userInput,
      required: true,
      controlType,
      default: (model: CadenceFormViewModel) => {
        return model._controlData$[name];
      },
    };

    const cadenceFormControl = {
      ...defaults,
      ...(formControlOptions || {}),
    } as ICadenceFormControlMetadata<Type, From>;
    getCadence().formControl.set(targetName, name, cadenceFormControl);

    let val;
    return {
      set(value) {
        val = value;
         ;
        // this._postEvent({
        //   name: 'controlChange',
        //   data: {
        //     name,
        //     value,
        //     newValue: value,
        //     oldValue: this._controlData$.value[name],
        //   },
        // });
        const data = this._controlData$.value;
        data[name] = value;
        this._controlData$.next(data);
      },
      get() {
        return this._controlData$?.value[name];
      },
      enumerable: true,
      configurable: true,
    };
  };
}
