import { Component, OnInit } from '@angular/core';
import { KioskResponse } from 'esuite-client';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-kiosk-list',
  templateUrl: './kiosk-list.component.html',
  styles: [],
})
export class KioskListComponent implements OnInit {
  kiosks$: BehaviorSubject<KioskResponse[]> = new BehaviorSubject([]);
  displayedColumns = ['id', 'name', 'actions'];

  constructor(private alertService: AlertService) {}

  ngOnInit(): void {
    getEsuiteApi()
      .kiosk.list()
      .then((l) => this.kiosks$.next(l));
  }

  remove(kiosk: KioskResponse): Promise<any> {
    return this.alertService.confirmLoaderAndResult(
      'Are you sure you want to remove this kiosk?',
      () =>
        getEsuiteApi()
          .kiosk.remove(kiosk.id)
          .then(() => {
            // Previous list with the deleted kiosk removed
            this.kiosks$.next(this.kiosks$.value.filter((p) => p !== kiosk));
          }),
      'Kiosk removed successfully',
      'Error removing kiosk'
    );
  }
}
