import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-plugin-create',
  template: `
    <a [routerLink]="['..']" class="d-block mb-3">Back to Plugins</a>
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h3 class="m-0">New Plugin</h3>
      <button mat-raised-button color="primary" (click)="create()">
        Create
      </button>
    </div>
    <ng-container [formGroup]="form">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Title</mat-label>
        <input
          matInput
          formControlName="title"
          placeholder="Enter Plugin Title"
        />
      </mat-form-field>
    </ng-container>
  `,
  styles: [],
})
export class PluginCreateComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private router: Router
  ) {
    this.form = this.makeForm();
  }

  ngOnInit(): void {}

  private makeForm(): FormGroup {
    return this.fb.group({
      title: [null, Validators.required],
    });
  }

  async create(): Promise<void> {
    const created = await this.alertService.runWithLoader(
      () => getEsuiteApi().appPlugin.create(this.form.value),
      'Pluigin created successfully',
      'Error creating plugin'
    );
     ;
    this.router.navigate(['plugins', created.id]);
  }
}
