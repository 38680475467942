<div class="w-100 h-100 d-flex align-items-center justify-content-center">
  <mat-card>
    <mat-card-content [formGroup]="form">
      <img width="120px" src="assets/eflyn-logo.png" class="img-responsive mx-auto d-block py-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>E-mail</mat-label>
        <input formControlName="username" matInput type="email" placeholder="example@organization.com">
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Password</mat-label>
        <input formControlName="password" matInput type="password" placeholder="example@organization.com">
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="login()" [disabled]="form.invalid" mat-raised-button color="primary">Sign In</button>
    </mat-card-actions>
  </mat-card>

</div>
