<ng-container [formGroup]="boxForm">
  <mat-form-field appearance="outline" class="col-6 col-sm-3">
    <mat-label>Width</mat-label>
    <input matInput type="text" formControlName="width">
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-6 col-sm-3">
    <mat-label>Height</mat-label>
    <input matInput type="text" formControlName="height">
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-6 col-sm-3">
    <mat-label>Top</mat-label>
    <input matInput type="text" formControlName="top">
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-6 col-sm-3">
    <mat-label>Left</mat-label>
    <input matInput type="text" formControlName="left">
  </mat-form-field>

  <div class="px-3"><h6>Alignment</h6></div>
  <div class="px-3 d-flex align-items-center justify-content-between">
    <div class="alignment-selector">
      <button mat-icon-button
              (click)="boxForm.patchValue({ top: '0%', transformY: '0%' })"
              [color]="alignmentY !== '0%,0%' ? null : 'primary'">
        <mat-icon>border_top</mat-icon>
      </button>
      <button mat-icon-button
              (click)="boxForm.patchValue({ top: '50%', transformY: '-50%' })"
              [color]="alignmentY !== '50%,-50%' ? null : 'primary'">
        <mat-icon>border_horizontal</mat-icon>
      </button>
      <button mat-icon-button
              (click)="boxForm.patchValue({ top: '100%', transformY: '-100%' })"
              [color]="alignmentY !== '100%,-100%' ? null : 'primary'">
        <mat-icon>border_bottom</mat-icon>
      </button>
      <br>
      <button mat-icon-button
              (click)="boxForm.patchValue({ left: '0%', transformX: '0%' })"
              [color]="alignmentX !== '0%,0%' ? null : 'primary'">
        <mat-icon>border_left</mat-icon>
      </button>
      <button mat-icon-button
              (click)="boxForm.patchValue({ left: '50%', transformX: '-50%' })"
              [color]="alignmentX !== '50%,-50%' ? null : 'primary'">
        <mat-icon>border_vertical</mat-icon>
      </button>
      <button mat-icon-button
              (click)="boxForm.patchValue({ left: '100%', transformX: '-100%' })"
              [color]="alignmentX !== '100%,-100%' ? null : 'primary'">
        <mat-icon>border_right</mat-icon>
      </button>
    </div>
    <div class="anchor-selector">
      <div *ngFor="let box of anchorBoxes | keyvalue"
           (click)="activateAnchor(box.value)"
           [class.active]="transform === box.value.join(',')"
           [ngClass]="[ 'anchor-' + box.key ]" class="anchor-box"></div>
    </div>
  </div>

  <div class="p-3" *ngIf="!hideBackground">
    <h6>Background</h6>
    <div class="mb-3 bp-color-picker">
      <input
        [colorPicker]="boxForm.get('bgColor').value"
        (colorPickerChange)="boxForm.get('bgColor').setValue($event)"
        [ngStyle]="{ background: boxForm.get('bgColor').value }"
      />
    </div>
    <app-image-upload
      [imageFormControl]="boxForm.get('bgImageId')"
    >
    </app-image-upload>
  </div>

</ng-container>




