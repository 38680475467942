import { Pipe, PipeTransform } from '@angular/core';
import { getCadence } from 'cadence';
import { ICadenceViewMetadata } from 'cadence';

const cadence = getCadence();

/*
Look up a Cadence view by class or name.
 */
@Pipe({
  name: 'cadView',
})
export class CadViewPipe implements PipeTransform {
  transform(
    value: string | (() => any),
    ...args: unknown[]
  ): ICadenceViewMetadata {
    if (typeof value === 'string') {
      return cadence.view.get(value);
    }
    return cadence.view.getByClass(value);
  }
}
