import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import {
  CadenceComponent,
  CadenceStackViewModel,
  getCadence,
  ICadenceComponentMetadata,
  ICadenceStackViewChildMetadata,
} from 'cadence';
import { ActivatedRoute } from '@angular/router';
import {
  BaseCadViewComponent,
  IBaseCadViewComponent,
} from '../base-cad-view/base-cad-view.component';
import { Observable } from 'rxjs';
import { sortBy } from 'lodash';
import { assignCadenceReactiveProperty } from '../../../util/assign-cadence-reactive-property';
import { ICadenceStackViewTabMetadata } from '../../../models/stack-view/cadence-stack-view-tab.decorator';

interface ICadStackViewComponentChild {
  component: ComponentRef<BaseCadViewComponent>;
  view: ICadenceStackViewChildMetadata;
  _render: (cadView: CadenceStackViewModel) => void;
}

@Component({
  selector: 'app-cad-stack-view',
  templateUrl: './cad-stack-view.component.html',
  styleUrls: ['./cad-stack-view.component.scss'],
})
@CadenceComponent(() => CadenceStackViewModel)
export class CadStackViewComponent
  extends BaseCadViewComponent<CadenceStackViewModel>
  implements IBaseCadViewComponent {
  viewChildren$: ICadStackViewComponentChild[] = [];
  activeTab: ICadenceStackViewTabMetadata;

  title$: Observable<string>;

  @ViewChildren('componentTarget', { read: ViewContainerRef })
  targets: QueryList<ViewContainerRef>;

  tabFlatSort = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    route: ActivatedRoute,
  ) {
    super(route);
  }

  renderChildView = (
    childViewMetadata: ICadenceStackViewChildMetadata,
    ind: number,
    cadView: CadenceStackViewModel,
  ): ComponentRef<BaseCadViewComponent> => {
    const childView = childViewMetadata.view(cadView);
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      getCadence()
        .components.list()
        .find(
          (c: ICadenceComponentMetadata) => childView instanceof c.viewModel(),
        )
        ?.component(),
    );
    const target = this.targets.toArray()[ind];

    const componentRef = target.createComponent(factory);
    (componentRef.location.nativeElement as HTMLDivElement).classList.add(
      childViewMetadata.classes ? childViewMetadata.classes : 'w-100',
    );
    (componentRef.instance as BaseCadViewComponent).cadView = childView;
    return componentRef as ComponentRef<BaseCadViewComponent>;
  };

  protected async initializeView() {
    await super.initializeView();
    this.cadView._tabs.forEach((t) => {
      this.tabFlatSort = [...this.tabFlatSort, ...t.children];
    });
    this.title$ = assignCadenceReactiveProperty(
      this.cadViewMetadata.title,
      this.cadView,
    );
    this.viewChildren$.forEach((vc) => vc.component.destroy());
    this.viewChildren$ = [];
    this.viewChildren$ = sortBy(this.cadView._children, (a) =>
      this.tabFlatSort.indexOf(a.name),
    ).map((childViewMetadata, ind) => {
      const childView: ICadStackViewComponentChild = {
        component: null,
        view: childViewMetadata,
        _render: (cadView) => {
          childView.component = this.renderChildView(
            childViewMetadata,
            ind,
            cadView,
          );
        },
      };
      return childView;
    });
    setTimeout(() => {
      this.viewChildren$.forEach((cv) => {
        cv._render(this.cadView);
      });
    }, 150);
  }
}
