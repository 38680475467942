import {
  CadenceFormViewModel,
  CadenceViewModel,
  ICadenceViewMetadata,
} from 'cadence/models';
import { CadenceObjectType } from 'esuite-dashboard/cadence';

export interface ICadenceFormArrayMetadata extends ICadenceViewMetadata {
  name: string;
  title: string;
  create: boolean | string;
  delete: boolean | string;
  view: () => CadenceObjectType<CadenceFormViewModel>;
}
