import { ICadenceViewMetadata } from '../cadence-view.metadata';
import { CadenceViewModel } from '../cadence-view.model';
import { CadenceObjectType, CadenceSubscriptionFactory } from '../../types';
import { CadenceStackViewModel } from 'cadence/models';

export interface ICadenceStackViewMetadata extends ICadenceViewMetadata {
  name: string;
  view: any;
  children: ICadenceStackViewChildMetadata[];
}

export interface ICadenceStackViewChildMetadata {
  name: string;
  title: string;
  classes?: string;
  view: (view: CadenceStackViewModel) => CadenceViewModel;
}
