// Create a BehaviorSubject that also tracks multiple sources internally
import {
  BehaviorSubject, defer,
  from,
  Observable,
  ObservableInput,
  of,
  SchedulerLike,
  Subscription,
} from 'rxjs';

/*
Similar to BehaviorSubject but also merges streams from any number of
ObservableInputs using from rxjs from. Overrides the subscribe method to
automatically manage subscribing and unsubscribing of source observables based on
number of observers to this subject.
 */
export class MultiBehaviorSubject<T> extends BehaviorSubject<T> {
  private subscribed: Subscription[] = [];
  private observables: Observable<T>[] = [];
  constructor(
    value = null,
    source: ObservableInput<T>,
    ...additionalSources: ObservableInput<T>[]
  ) {
    super(value);
    this.observables = [
      from(source),
      ...additionalSources.map((as) => from(as)),
    ];
  }
  subscribe(observer): Subscription {
    const observerSub = super.subscribe(observer);
    const observerUnsub = observerSub.unsubscribe;
    if (!this.subscribed.length && this.observables.length) {
      this.subscribed = this.observables.map((o) =>
        o.subscribe((v) => this.next(v))
      );
    }
    observerSub.unsubscribe = () => {
      observerUnsub.apply(observerSub);
      if (this.observers.length === 0) {
        this.subscribed.forEach((sub) => sub.unsubscribe());
        this.subscribed = [];
      }
    };
    return observerSub;
  }
}
