import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
} from '@angular/platform-browser';
import { orderBy } from 'lodash';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform<T>(value: T[], transformer): T[] {
    return orderBy(value, transformer);
  }
}
