/*
 * DigitalSignageFrameDetailView
 * Generated delete resource from cadence/scripts/generate
 */

import {
  CadenceStackViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  cadenceRouter,
  CadenceFormControl,
  CadenceStackViewTab,
  CadenceStackViewChild,
  CadenceViewSubscription,
} from 'cadence';

import { DigitalSignageFrameUpdateForm } from './digital-signage-frame-update';
import {
  DigitalSignageSlideDetailView,
  DigitalSignageSlideListView,
} from '../digital-signage-slide';
import { DigitalSignageFrameResponse } from 'esuite-client';
import { filter } from 'rxjs/operators';

interface IDigitalSignageFrameDetailViewParams {
  frame: DigitalSignageFrameResponse;
  signageId: number;
}

@CadenceView({
  viewType: 'dialog',
})
export class DigitalSignageFrameDetailView extends CadenceStackViewModel<IDigitalSignageFrameDetailViewParams> {
  @CadenceStackViewTab(() => DigitalSignageFrameDetailView, ['updateForm'], {
    label: 'General',
  })
  generalTab: any;

  // @CadenceStackViewTab(
  //   () => DigitalSignageFrameDetailView,
  //   ['slides', 'slideEditor'],
  //   {
  //     label: 'Slides',
  //   },
  // )
  // slidesTab: any;

  @CadenceStackViewChild(() => DigitalSignageFrameUpdateForm)
  updateForm() {
    const form = new DigitalSignageFrameUpdateForm();
    form._params = {
      frame: this._params.frame,
      signageId: this._params.signageId,
    };
    return form;
  }

  // Forward event
  @CadenceViewSubscription(
    () => DigitalSignageFrameDetailView,
    (view) =>
      view
        .updateForm()
        ._events$.pipe(filter((e) => e.name === 'action.update')),
  )
  frameUpdated(event) {
    this._postEvent(event);
  }

  // @CadenceStackViewChild(() => DigitalSignageSlideListView, {
  //   classes: 'col-md-4',
  // })
  // slides() {
  //   const form = new DigitalSignageSlideListView();
  //   form._params = { frame: this._params.frame };
  //   return form;
  // }

  // @CadenceStackViewChild(() => DigitalSignageSlideDetailView, {
  //   classes: 'col-md-8',
  // })
  // slideEditor() {
  //   const form = new DigitalSignageSlideDetailView();
  //   // form._params = { frame: this._params.frame };
  //   return form;
  // }
  //
  // @CadenceViewSubscription(
  //   () => DigitalSignageFrameDetailView,
  //   (view) =>
  //     view
  //       .slides()
  //       ._events$.pipe(filter((e) => e?.name === 'action.selectSlide')),
  // )
  // setSlideEditor(event: any) {
  //    ;
  //   this.slideEditor().updateForm()._controlData$.next(event.data);
  // }
}
