import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'esuite-dashboard/app/shared/shared.module';
import { OrganizationPageRoutingModule } from 'esuite-dashboard/app/pages/organization/organization-page-routing.module';
import { OrganizationSelectorComponent } from 'esuite-dashboard/app/pages/organization/organization-selector/organization-selector.component';


@NgModule({
  declarations: [OrganizationSelectorComponent],
  imports: [
    CommonModule,
    SharedModule,
    OrganizationPageRoutingModule,
  ],
})
export class OrganizationModule {}

