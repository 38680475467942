import { Component, OnInit } from '@angular/core';
import { AppPlugin } from 'esuite-client';
import { MatTableDataSource } from '@angular/material/table';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-plugin-list',
  template: `
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h3 class="m-0">Plugins</h3>
      <button mat-button color="primary" [routerLink]="['new']">
        Create Plugin
      </button>
    </div>
    <table
      *ngIf="plugins.length; else noPlugins"
      mat-table
      [dataSource]="pluginDataSource"
      class="w-100"
    >
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="[element.id]">{{ element.title }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="[element.id]">{{ element.name }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="remove(element)">
              <mat-icon>delete</mat-icon>
              <span>Remove</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
    </table>
    <ng-template #noPlugins>
      <div class="mx-auto text-center">
        <p class="text-center">No Plugins to Show</p>
        <a [routerLink]="['new']">Create one now.</a>
      </div>
    </ng-template>
  `,
  styles: [],
})
export class PluginListComponent implements OnInit {
  private pluginsSubject = new BehaviorSubject([]);
  get plugins(): AppPlugin[] {
    return this.pluginsSubject.value;
  }
  set plugins(p: AppPlugin[]) {
    this.pluginsSubject.next(p);
    this.pluginDataSource.data = p;
  }
  pluginDataSource: MatTableDataSource<AppPlugin> =
    new MatTableDataSource<AppPlugin>();
  displayedColumns = ['id', 'title', 'name', 'actions'];

  constructor(private alertService: AlertService) {}

  async ngOnInit(): Promise<void> {
    this.plugins = await getEsuiteApi().appPlugin.list({});
    this.pluginDataSource.data = this.plugins;
  }

  remove(plugin: AppPlugin): Promise<any> {
    return this.alertService.confirmLoaderAndResult(
      'Are you sure you want to remove this plugin?',
      () =>
        getEsuiteApi()
          .appPlugin.remove(plugin.id)
          .then(() => {
            this.plugins = this.plugins.filter((p) => p !== plugin);
          }),
      'Plugin removed successfully',
      'Error removing plugin'
    );
  }
}
