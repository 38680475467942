<ng-container *ngIf="cadView">
  <h5 *ngIf="title$ | async as title">{{ title }}</h5>
  <mat-tab-group *ngIf="cadView._tabs.length">
    <mat-tab  *ngFor="let tab of cadView._tabs" [label]="tab.label" >
      <div class="d-flex flex-wrap" [ngClass]="cadViewMetadata.classes">
        <ng-template class="w-100" #componentTarget *ngFor="let item of tab.children"></ng-template>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="d-flex flex-wrap" *ngIf="!cadView._tabs.length">
    <ng-container [ngClass]="cadViewMetadata.classes">
      <ng-container *ngFor="let child of viewChildren$">
          <ng-template class="w-100" #componentTarget ></ng-template>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
