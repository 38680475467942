<div class="d-flex align-items-center justify-content-between mb-4">
  <h5 class="m-0">Floors</h5>
  <button mat-mini-fab color="primary" [routerLink]="['floors', 'new']">
    <mat-icon>add</mat-icon>
  </button>
</div>
<mat-form-field class="w-100" appearance="outline">
  <mat-label>Filter Floors</mat-label>
  <input [(ngModel)]="search" matInput type="search" placeholder="Filter Floors">
</mat-form-field>
<div style="max-height: 320px; overflow: auto">
  <table
    *ngIf="floors$ | async as floors; else noFloors"
    mat-table
    [dataSource]="floors | filter: search | orderBy: 'name'"
    class="w-100"
  >
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="['floors', element.id]">{{ element.name }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="remove(element)">
            <mat-icon>delete</mat-icon>
            <span>Remove</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>
  </table>
</div>
<ng-template #noFloors>
  <div class="mx-auto text-center">
    <p class="text-center">No Floors to Show</p>
    <a [routerLink]="['new']">Create one now.</a>
  </div>
</ng-template>
