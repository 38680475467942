import {
  CadenceHook,
  CadenceHookType,
  ICadenceHook,
} from '../../decorators/cadence-hook.decorator';
import { ICadenceViewMetadata } from 'cadence';

@CadenceHook(CadenceHookType.MenuFilter)
export class filterNavMenuByAccessHook
  implements ICadenceHook<ICadenceViewMetadata[]> {
  async transform(views: ICadenceViewMetadata[]) {
    const resultViews = [...views];
    return resultViews;
  }
}
