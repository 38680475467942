<h5 mat-dialog-title>
  {{title}}
</h5>
<mat-dialog-content>
  <p *ngIf="messageHTML; else msg" [innerHTML]="messageHTML">
    {{ messageHTML }}
  </p>

  <ng-template #msg>
    <p>{{ message }}</p>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="primary" (click)="onDismiss()">No</button>
  <button mat-raised-button [color]="warn ? 'warn' : 'primary'" (click)="onConfirm()">Yes</button>
</mat-dialog-actions>
