import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KioskPickerComponent } from 'esuite-dashboard/app/shared/components/kiosk-picker/kiosk-picker.component';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';


@Injectable({
  providedIn: 'root',
})
export class ElsService {
  constructor(private dialogService: MatDialog) {}

  platforms = {
    login: async (id: string, options: { key: string }) => {
      const modalRef = this.dialogService.open(KioskPickerComponent);
      const result = await modalRef.afterClosed().toPromise();
      if (result[0]) {
        getEsuiteApi().auth.platformLogin({
          kioskId: result[0].id,
          platformId: id,
          key: options.key,
        });
      }
    },
  };

  async handleLink($event: string): Promise<void> {
    const url = new URL($event);
    const [a, b, handler, id, actionStr] = url.href.slice(4).split('/');
    const action = actionStr.split('?')[0];
    if (url.protocol === 'els:' && this[handler]?.[action]) {
      const search = url.search.slice(1);
      const options = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      await this[handler][action](id, options);
    } else {
      throw new Error('Invalid link or unsupported action');
    }
  }
}
