import { getCadence } from '../cadence';
import { CadenceObjectType } from '../types';
import { ICadenceComponentMetadata } from '../decorators/cadence-component.decorator';
import { CadenceViewModel } from '../models';

export const getByViewModel = <T extends CadenceViewModel>(
  type: CadenceObjectType<T>,
): ICadenceComponentMetadata => {
  const components = getCadence().components.list();
  return components.find((c) => {
    if (c.viewModel() === type) {
      return true;
    }
    if (c.viewModel() === Object.getPrototypeOf(type)) {
      return true;
    }
  });
};
