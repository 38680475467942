<app-list-sidebar-detail
  *ngIf="{ id: id$ | async } as outputs"
  #restAppList
  [listConfig]="listConfig"
  title="Rest Apps" [(id)]="id">

  <app-rest-app-detail
    (update)="handleUpdate($event)"
    (create)="handleCreate($event)"
    [id]="id">
  </app-rest-app-detail>
</app-list-sidebar-detail>
