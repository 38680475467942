import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from 'esuite-dashboard/app/shared/components/dashboard/dashboard.component';
import { PluginListComponent } from 'esuite-dashboard/app/pages/plugin/plugin-list/plugin-list.component';
import { PluginDetailComponent } from 'esuite-dashboard/app/pages/plugin/plugin-detail/plugin-detail.component';
import { PluginCreateComponent } from 'esuite-dashboard/app/pages/plugin/plugin-create/plugin-create.component';
import { AuthGuard } from 'esuite-dashboard/app/shared/guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'plugins',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PluginListComponent,
      },
      {
        path: 'new',
        component: PluginCreateComponent,
      },
      {
        path: ':pluginId',
        component: PluginDetailComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PluginRoutingModule {}
