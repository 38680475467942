import { EsuiteClient, MediaCollectionResponse } from 'esuite-client';
import { MediaCollectionAlbumView } from './media-collection-album.view';
import { MediaCollectionAccessCodeView } from './media-collection-access-code.view';
import { MediaCollectionCreateForm } from './media-collection-create.form';
import { CadenceAction } from 'cadence';
import { MediaCollectionDeleteForm } from './media-collection-delete.form';
import { CadenceViewEvent } from 'cadence';
import {
  CadenceColumn,
  CadenceActionColumn,
  CadenceListViewModel,
} from 'cadence';
import { CadenceView } from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';

@CadenceView({
  title: 'Media Collection',
})
export class MediaCollectionView extends CadenceListViewModel<MediaCollectionResponse> {
  // View linking to View get View and Form as generics
  @CadenceAction({
    label: 'Create Collection',
  })
  createForm: MediaCollectionCreateForm;

  @CadenceColumn(() => MediaCollectionView, {
    header: 'Name Label',
    value: (item) => item.name,
  })
  name: string;

  @CadenceColumn(() => MediaCollectionView, {
    header: 'Enabled',
    value: (item) => item.enabled,
  })
  enabled: boolean;

  // Row linking to view get Row and view as generics
  @CadenceActionColumn({
    label: 'View Albums',
    params: {
      collectionId: (view, data) => data.id,
    },
  })
  albums: MediaCollectionAlbumView;

  @CadenceActionColumn({
    label: 'View Access Codes',
    params: {
      collectionId: (view, data) => data.id,
    },
  })
  accessCodes: MediaCollectionAccessCodeView;

  @CadenceActionColumn({
    label: 'Delete',
    params: {
      collectionId: (view, data) => data.id,
    },
    events: {
      'action.delete': 'collectionDeleted',
    },
  })
  deleteCollection: MediaCollectionDeleteForm;

  async _init(): Promise<void> {
    this._rows$.set(await getEsuiteApi().mediaCollection.list());
  }

  @CadenceViewEvent('collectionDeleted')
  removeDeletedRow(event): void {
    this._rows$.deleteBy((item) => item.id === event.form?.collectionId);
  }
}
