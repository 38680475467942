import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cadViewLinkParams',
})
export class CadViewLinkParamsPipe implements PipeTransform {
  transform<T>(paramMapper: any, row: any): unknown {
    const params: any = {};
    if (paramMapper) {
      Object.keys(paramMapper).forEach((key) => {
        params[key] = paramMapper[key](row);
      });
    }
    return params;
  }
}
