<ng-container *ngIf="id; else noId">
  <mat-card *ngIf="(form$ | async) as form" [formGroup]="form">
    <mat-card-title>
      <div class="d-flex align-items-center justify-content-between">
        <span>{{ isNew ? 'Create' : 'Update' }} Product Category</span>
        <app-create-update-button
          [disabled]="form.invalid || !form.enabled"
          [isNew]="isNew"
          (create)="handleCreate(form.value)"
          (update)="handleUpdate(form.value)" label="Category">
        </app-create-update-button>
      </div>
    </mat-card-title>
    <mat-card-content>

        <mat-form-field appearance="outline" class="d-block mt-3">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="outline" class="d-block mt-3">
          <mat-label>Admissions</mat-label>
          <mat-select formControlName="admissionIds" multiple>
            <mat-option *ngFor="let admission of admissions" [value]="admission.id">{{ admission.title }}</mat-option>
          </mat-select>
        </mat-form-field>

      <mat-form-field appearance="outline" class="d-block mt-3">
        <mat-label>Agreements</mat-label>
        <mat-select formControlName="agreementIds" multiple>
          <mat-option *ngFor="let agreement of agreements" [value]="agreement.id">{{ agreement.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="d-block mt-3">
        <mat-label>Funds</mat-label>
        <mat-select formControlName="fundIds" multiple>
          <mat-option *ngFor="let fund of funds" [value]="fund.id">{{ fund.title }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="d-block mt-3">
        <mat-label>Digital Signage</mat-label>
        <mat-select formControlName="signId">
          <mat-option [value]="null">None</mat-option>
          <mat-option *ngFor="let sign of signs" [value]="sign.id">{{ sign.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="d-block mt-3">
        <mat-label>Product Categories</mat-label>
        <mat-select formControlName="categoryIds" multiple>
          <mat-option *ngFor="let category of categories" [value]="category.id">{{ category.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="(form | pickFormArray: 'integrations') as integrationForms">
        <h5>Integrations</h5>
        <button mat-stroked-button color="primary" (click)="integrationForms.push(makeCategoryIntegrationForm())">Add Integration</button>
        <mat-card class="d-block mt-3"
          *ngFor="let integrationForm of integrationForms.controls; let formInd = index"
          [formGroup]="integrationForm">

          <ng-container *ngIf="(integrationForm | pickFormArray: 'filters') as integrationFilters">
            <mat-card-content>
              <mat-form-field>
                <mat-select formControlName="integrationId">
                  <mat-option [value]="int.id" *ngFor="let int of integrations">{{ int.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngFor="let integrationFilter of integrationFilters.controls; let filterInd = index;">
                <button mat-icon-button color="warn" (click)="integrationFilters.removeAt(filterInd)">
                  <mat-icon>delete</mat-icon>
                </button>
                <button mat-icon-button color="primary" (click)="utils.swapFormArrayLocs(integrationFilters, filterInd, filterInd - 1)">
                  <mat-icon>arrow_upward</mat-icon>
                </button>
                <button mat-icon-button color="primary" (click)="utils.swapFormArrayLocs(integrationFilters, filterInd, filterInd + 1)">
                  <mat-icon>arrow_downward</mat-icon>
                </button>
                <app-product-category-integration
                  (filterChange)="integrationFilter.setValue($event)"
                  [filter]="integrationFilter.value">

                </app-product-category-integration>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-button color="warn" (click)="integrationForms.removeAt(formInd)">Remove</button>
              <button mat-stroked-button color="primary" (click)="integrationFilters.push(makeCategoryIntegrationFilterForm())">Add Filter</button>
            </mat-card-actions>
          </ng-container>
        </mat-card>
      </ng-container>

    </mat-card-content>
  </mat-card>
</ng-container>

<ng-template #noId>
  <h5 class="text-muted text-center">Product category doesn't exist.</h5>
</ng-template>

