<ng-container *ngIf="type === 'link'">
  <a [href]="href">
    <ng-content></ng-content>
  </a>
</ng-container>
<ng-container *ngIf="type === 'button'">
  <button mat-stroked-button>
    <ng-content></ng-content>
  </button>
</ng-container>
