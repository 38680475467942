/*
 * OrderUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { OrderResponse } from 'esuite-client';

interface IOrderUpdateFormParams {
  order: OrderResponse;
}

@CadenceView({
  title: 'Update Order',
})
export class OrderUpdateForm extends CadenceFormViewModel<IOrderUpdateFormParams> {
  // @CadenceFormControl(CadenceFormControlType.Select, {
  //   options: () => {
  //     return [
  //       { label: 'Paid', value: 'Paid' }
  //     ]
  //   }
  // })
  // status: string;
  //
  // @CadenceFormAction({
  //   label: 'Update',
  // })
  // async update(): Promise<any> {
  //   return getEsuiteApi().order.update()
  // }
  //
  // @CadenceViewEvent('action.update')
  // afterUpdate(): void {}
  //
  // async _init(): Promise<void> {
  //   this._controlData$.next(await getEsuiteApi().order.get(this._params.id));
  // }
}
