/*
 * OrderList
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceAction,
  CadenceActionColumn,
  CadenceColumn,
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceListViewModel,
  CadenceListViewPaginator,
  CadenceListViewRow,
  cadenceRouter,
  CadenceStackViewChild,
  CadenceStackViewModel,
  CadenceView,
  CadenceViewEvent,
  CadenceViewSubscription,
  ICadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { EsuiteApp, KioskRemoteCommand, OrderResponse } from 'esuite-client';
import { OrderDetailView } from './order-view';
import { toCurrency } from '../../util/toCurrency';
import { toDisplayDate } from '../../util/toDisplayDate';
import { startCase } from 'lodash';
import { toDateControl } from '../../util/to-date-control';
import { flatten } from 'lodash';

@CadenceView()
class OrderListFilters extends CadenceFormViewModel {
  @CadenceFormControl(CadenceFormControlType.Date, {
    classes: 'w-50',
  })
  fromDate: string;

  @CadenceFormControl(CadenceFormControlType.Date, {
    classes: 'w-50',
  })
  toDate: string;

  @CadenceFormAction()
  async filterOrders(): Promise<OrderResponse[]> {
    const orderList = await getEsuiteApi().order.list({
      fromDate: this.fromDate,
      toDate: this.toDate,
    });
    return orderList;
  }

  _init(): void | Promise<void> {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 1);
    const toDate = new Date();
    this.fromDate = toDateControl(fromDate);
    this.toDate = toDateControl(toDate);
    this.filterOrders();
  }
}

@CadenceView({
  title: 'Orders',
  showInMenu: '/self-checkouts',
})
export class OrderListView extends CadenceStackViewModel {
  kiosks = [];
  filters: OrderListFilters;
  @CadenceStackViewChild(() => OrderListFilters)
  orderListFilters() {
    this.filters = new OrderListFilters();
    return this.filters;
  }
  @CadenceStackViewChild(() => OrderList)
  orderList() {
    getEsuiteApi()
      .kiosk.list()
      .then((ks) => (this.kiosks = ks));
    const list = new OrderList();
    list._csvExport = {
      pager: (options) =>
        getEsuiteApi().order.list({
          ...options,
          fromDate: this.filters.fromDate,
          toDate: this.filters.toDate,
        }),
      fields: [
        {
          label: 'Date',
          value: (row: OrderResponse) =>
            [
              new Date(row.createdAt).toLocaleDateString(),
              new Date(row.createdAt).toLocaleTimeString(),
            ].join(' '),
        },
        {
          label: 'Order',
          value: 'name',
        },
        { label: 'Email', value: 'email' },
        {
          label: 'Items',
          value: (row: OrderResponse) =>
            flatten([
              ...row.lineItems.map((li) => li.name),
              ...row.integrations.map((integration) =>
                (integration.shopify.checkout as any).lineItems.map(
                  (cli) => cli.title
                )
              ),
            ]).join('\n'),
        },
        {
          label: 'Subtotal',
          value: (row: OrderResponse) => row.subtotalPrice.toFixed(2),
        },
        {
          label: 'Taxes',
          value: (row: OrderResponse) => row.totalTax.toFixed(2),
        },
        {
          label: 'Total',
          value: (row: OrderResponse) => row.totalPrice.toFixed(2),
        },
        {
          label: 'Kiosk',
          value: (row: OrderResponse) =>
            this.kiosks.find((k) => k.id === row.kioskId)?.name,
        },
        {
          label: 'Payment Method',
          value: (row: OrderResponse) => startCase(row.transactions[0]?.method),
        },
        {
          label: 'Payment Status',
          value: (row: OrderResponse) => startCase(row.financialStatus),
        },
      ],
    };
    // list._paginator.enabled =
    list._paginator = new CadenceListViewPaginator();
    list._paginator.loadPage = async (page) => {
      const skip = page.pageIndex * page.pageSize;
      const limit = page.pageSize;
      list._rows$.set([]);
      list._rows$.set(
        await getEsuiteApi().order.list({
          fromDate: this.filters.fromDate,
          toDate: this.filters.toDate,
          skip,
          limit,
        })
      );
    };
    return list;
  }

  @CadenceViewSubscription(
    () => OrderListView,
    (view) => view.orderListFilters()._events$
  )
  processEvent(event: ICadenceViewEvent) {
    if (event.name === 'action.filterOrders') {
      this.orderList()._rows$.set(event.data);
    }
  }
}

@CadenceView()
export class OrderList extends CadenceListViewModel<OrderResponse> {
  @CadenceColumn(() => OrderList, {
    value: (order) => toDisplayDate(order.createdAt),
  })
  date: string;

  @CadenceColumn(() => OrderList, {
    footer: () =>
      `<div class="py-4">${['Subtotal', 'Taxes', 'Total'].join('<br>')}</div>`,
  })
  name: string;

  @CadenceColumn()
  email: string;

  @CadenceColumn(() => OrderList, {
    value: (row) => startCase(row.transactions[0]?.method || 'Unavailable'),
  })
  paymentMethod: string;

  @CadenceColumn(() => OrderList, {
    value: (row) => row.integrations[0]?.shopify?.orderId || 'None',
  })
  shopifyOrderId: string;

  @CadenceColumn(() => OrderList, {
    header: 'Total',
    value: (row) => toCurrency(row.totalPrice),
    footer: (view) =>
      `<div class="py-4">${[
        toCurrency(
          view._rows$.value.reduce(
            (total, curr) => total + curr.subtotalPrice,
            0
          )
        ),
        toCurrency(
          view._rows$.value.reduce((total, curr) => total + curr.totalTax, 0)
        ),
        toCurrency(
          view._rows$.value.reduce((total, curr) => total + curr.totalPrice, 0)
        ),
      ].join('<br>')}</div>`,
  })
  total: string;

  @CadenceActionColumn({ label: 'Sync with Shopify' })
  async syncToShopify(row: CadenceListViewRow<OrderList>): Promise<void> {
    if (row.integrations[0]?.shopify?.orderId) {
      alert('Order is already in Shopify');
      return;
    }
    await getEsuiteApi().selfCheckoutOrder.submitShopifyOrder(
      row.id,
      row.selfCheckoutId,
      row.integrations[0].id
    );
    const order = await getEsuiteApi().order.get(row.id);
    this._rows$.updateBy((r) => r.id === row.id, order);
  }

  @CadenceActionColumn({ label: 'Delete' })
  async deleteForm(row: CadenceListViewRow<OrderList>): Promise<void> {
    const confirmDelete = confirm('Are you sure you want to delete this?');
    if (confirmDelete) {
      await getEsuiteApi().order.remove(row.id);
      this._rows$.deleteBy((c) => c.id === row.id);
    }
  }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  @CadenceViewEvent('updated')
  update(event: any): void {
    this._rows$.updateBy((c) => c.id === event.data.id, event.data);
  }

  // @CadenceViewEvent('deleted')
  // remove(event: any): void {
  //   this._rows$.deleteBy((c) => c.id === event.from._params.id);
  // }

  async _init(): Promise<void> {
    // this._rows$.set(await getEsuiteApi().order.list());
  }
}
