<div class="d-flex align-items-center justify-content-between">
  <h4>Create a Directory</h4>
  <button mat-raised-button color="primary"
          (click)="create()"
          [disabled]="!form.enabled || form.invalid">
    Create
  </button>
</div>

<form [formGroup]="form">
  <div class="d-flex align-items-stretch justify-content-between flex-column">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
  </div>
</form>
