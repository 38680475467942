/*
 * OrganizationListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceActionColumn,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceListViewRow,
} from 'cadence';
import { OrganizationCreateForm } from './organization-create';
import { OrganizationDetailView } from './organization-detail';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { OrganizationResponse, RoleName } from 'esuite-client';

@CadenceView({
  title: 'Organizations',
  showInMenu: '/settings',
  roles: [RoleName.OrganizationRegistrar],
})
export class OrganizationListView extends CadenceListViewModel<{ subdomain: string }> {

  @CadenceColumn(() => OrganizationListView, {
    value: (item) => {
      const orgLink = [
        'https://' +
          item.subdomain +
          '.' +
          window.location.host.split('.').slice(1).join('.'),
      ];
      return `<a target="_blank" href="${orgLink}">${item.subdomain}</a>`;
    },
  })
  name: string;

  @CadenceAction({ label: 'Create' })
  createForm(): void {
    cadenceRouter.navigate(OrganizationCreateForm, {
      from: this,
      events: {
        'action.create': 'created',
      },
    });
  }

  @CadenceActionColumn({ label: 'Export' })
  async exportOrganization(
    row: CadenceListViewRow<OrganizationListView>
  ): Promise<void> {
    const { url } = await getEsuiteApi().organization.export();
    alert(`Organization "${row.subdomain}" has been exported to ${url}`);
  }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  async _init(): Promise<void> {
    this._rows$.set(
      await fetch('https://ens.myeflyn.com/map').then((r) => r.json())
    );
  }
}
