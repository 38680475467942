import { Component, Inject, OnInit } from '@angular/core';
import {
  Directory,
  DirectoryFloor,
  DirectoryFloorCreateRequest,
  DirectoryFloorUpdateRequest,
} from 'esuite-client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../../../shared/components/alert/alert.service';
import { getEsuiteApi } from '../../../../../api/esuite-api';
import { pluckAsInt } from '../../../../../shared/utils/pluck-as-int';
import { map, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-directory-floor-detail',
  templateUrl: './directory-floor-detail.component.html',
  styleUrls: ['./directory-floor-detail.component.scss'],
})
export class DirectoryFloorDetailComponent implements OnInit {
  floorId$ = this.activatedRoute.params.pipe(
    pluckAsInt('directoryFloorId'),
  );

  floor$ = this.floorId$.pipe(
    switchMap((floorId) => getEsuiteApi().directoryFloor.get(floorId))
  );

  form$ = this.floor$.pipe(map((floor) => this.makeUpdateForm(floor)));

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  private makeUpdateForm(floor: DirectoryFloor) {
    return this.fb.group({
      mapImageId: [floor.mapImage?.id],
      name: [floor.name, Validators.required],
      number: [floor.number, Validators.required],
    });
  }

  handleUpdate(floorId: number, form: FormGroup) {
    form.disable();
    const update = { ...form.value };

    // In case no password is provided delete it from the update request
    if (!update.password) {
      delete update.password;
    }
    this.alertService
      .runWithLoader(
        () => getEsuiteApi().directoryFloor.update(floorId, update),
        'Directory floor updated successfully',
        'Error updating floor'
      )
      // If an error is thrown we need to enable the form again
      .finally(() => {
        form.enable();
      });
  }
}
