import { Component, Input, OnInit, Output } from '@angular/core';
import { createComponentIO } from 'esuite-util/components';
import { ProductCategoryIntegrationFilter } from 'esuite-client';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  collectionsByHandlesPath,
  collectionsByIdsPath,
  collectionsByTitlesPath,
  productsByHandlesPath,
  productsByIdsPath,
  productsByTagsPath,
  productsByTitlesPath,
} from 'esuite-dashboard/app/pages/product-category/components/product-category-integration-filter/filter-to-exec';

@Component({
  selector: 'app-product-category-integration',
  templateUrl: './product-category-integration.component.html',
  styleUrls: ['./product-category-integration.component.scss'],
})
export class ProductCategoryIntegrationComponent implements OnInit {

  @Input()
  get filter() {
    return this.filterIO.get();
  }
  set filter(f: ProductCategoryIntegrationFilter) {
    this.filterIO.set(f);
    if (f.tags) {
      this.tagEntries = f.tags;
    }
  }

  constructor() {}
  tagEntries = [];

  private filterIO = createComponentIO<ProductCategoryIntegrationFilter>();

  @Output()
  filterChange = this.filterIO.output$;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  private static calculateExec(f: ProductCategoryIntegrationFilter): string {
    if (f.target === 'shopifyProduct') {
      switch (f.filter) {
        case 'none':
          return '$.products';
        case 'handles':
          return productsByHandlesPath(f.tags);
        case 'titles':
          return productsByTitlesPath(f.tags);
        case 'ids':
          return productsByIdsPath(f.tags);
        case 'tags':
          return productsByTagsPath(f.tags);
      }
    } else if (f.target === 'shopifyCollection') {
      switch (f.filter) {
        case 'none':
          return '$.collections';
        case 'handles':
          return collectionsByHandlesPath(f.tags);
        case 'titles':
          return collectionsByTitlesPath(f.tags);
        case 'ids':
          return collectionsByIdsPath(f.tags);
      }
    }
    return null;
  }

  ngOnInit(): void {}

  updateFilter(update: Partial<ProductCategoryIntegrationFilter>) {
     ;
    const updatedFilter = {
      ...this.filter,
      ...update,
    };
    this.filter = {
      ...updatedFilter,
      exec: ProductCategoryIntegrationComponent.calculateExec(updatedFilter),
    };
     ;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.tagEntries.push(value);
    }

    // Clear the input value
    event.input.value = '';
    this.updateFilter({ tags: this.tagEntries });
  }

  remove(v: string): void {
    const index = this.tagEntries.indexOf(v);

    if (index >= 0) {
      this.tagEntries.splice(index, 1);
    }
    this.updateFilter({ tags: this.tagEntries });
  }
}
