<ng-container *ngIf="userService.user$ | async as user">
  <mat-toolbar color="accent" class="dashboard-header">
    <mat-toolbar-row class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <button mat-icon-button>
          <mat-icon (click)="sidenav.toggle()">menu</mat-icon>&nbsp;
        </button>
        <img src="../../../../assets/eflyn-logo-white.png" style="margin-left: 8px; height: 16px">
        <div class="spacer"></div>
      </div>
      <button mat-icon-button>
        <mat-icon (click)="openScanner()">qr_code</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container class="bg-white">
    <mat-sidenav [opened]="opened" [mode]="navMode" #sidenav style="min-width: 220px;">
      <mat-nav-list style="padding-top: 48px; height: 100%;" class="d-flex flex-column justify-content-between ">
        <div>
          <a mat-list-item [routerLink]="['/dashboard']" routerLinkActive="active-menu">
            <mat-icon>home</mat-icon>&nbsp;
            Home
          </a>
          <ng-container *ngFor="let item1 of (menuItems$ | async)">


            <ng-container *ngIf="userService.userHasAcceptableRoles(item1.roles)">
              <a
                mat-list-item [routerLink]="[item1.state]" routerLinkActive #rla="routerLinkActive" [ngClass]="rla.isActive ? activeClass : topLevelView === item1 ? 'bg-dark text-white' : ''">
                <mat-icon *ngIf="item1.icon">{{ item1.icon }}</mat-icon>&nbsp;
                {{ item1.name }}
              </a>

              <ng-container *ngIf="topLevelView === item1">
                <ng-container *ngFor="let item2 of item1.sub">
                  <a
                    *ngIf="(userService.userHasAcceptableRoles(item2.roles))"
                    class="pl-2"
                    style="font-size: 0.9em; height: 32px !important"
                    mat-list-item
                    [routerLink]="[item2.state]"
                    routerLinkActive #rla2="routerLinkActive"
                    [ngClass]="rla2.isActive ? activeClass : 'bg-dark text-white'"
                  >
                    {{ item2.name }}
                  </a>
                </ng-container>

              </ng-container>
            </ng-container>

          </ng-container>
        </div>
        <div>
          <a mat-list-item (click)="signout()" class="pl-5">
              <mat-icon>
                logout
              </mat-icon>
              Sign Out
          </a>

          <div class="p-3">
            <p style="opacity: 0.5;" class="small text-muted text-center">
              {{ user.email }}
            </p>
            <p style="opacity: 0.5;" class="small text-muted text-center">
              {{ version }}
            </p>
          </div>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="dashboard-content px-3 pb-3 pt-1">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
