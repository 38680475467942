<mat-card *ngIf="form" [formGroup]="form">

  <mat-card-content>
    <h6>Custom Fields</h6>
    <div class="py-3" formArrayName="fields">
      <table class="table small" [ngClass]="{'table-responsive' : edit}">
        <thead *ngIf="!edit">
        <tr>
          <th>#</th>
          <th>Label</th>
          <th>Name</th>
        </tr>
        </thead>
        <tbody cdkDropList="edit" [cdkDropListDisabled]="!edit" (cdkDropListDropped)="drop($event)">
        <tr cdkDrag *ngFor="let fieldControl of form.get('fields').controls; let i = index;" [formGroup]="fieldControl">
          <td cdkDragHandle>{{ i + 1 }}</td>
          <td *ngIf="!edit" style="max-width: 130px; overflow-wrap: break-word;">
            {{ fieldControl.get('label').value }}
          </td>
          <td *ngIf="!edit">
            {{ fieldControl.get('name').value }}
          </td>
          <td *ngIf="edit">
            <mat-form-field >
              <mat-label>Label</mat-label>
              <input *ngIf="fieldControl.get('component').value !== 'checkbox'" matInput formControlName="label">
              <textarea *ngIf="fieldControl.get('component').value === 'checkbox'" matInput formControlName="label"></textarea>
            </mat-form-field>
          </td>
          <td *ngIf="edit">
            <mat-form-field >
              <mat-label>Name</mat-label>
              <input matInput formControlName="name">
            </mat-form-field>
          </td>
          <td  *ngIf="edit">
            <mat-form-field style="width: 75px;">
              <mat-label>Component</mat-label>
              <mat-select formControlName="component">
                <mat-option [value]="'line'">Line</mat-option>
                <mat-option [value]="'email'">E-mail</mat-option>
                <mat-option [value]="'checkbox'">Checkbox</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td *ngIf="edit">
            <mat-form-field style="width: 75px;">
              <mat-label>Width</mat-label>
              <mat-select formControlName="width">
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td *ngIf="edit">
            <mat-form-field style="width: 75px;">
              <mat-label>Option</mat-label>
              <mat-select formControlName="option">
                <mat-option [value]="FieldOption.Hide">Hide</mat-option>
                <mat-option [value]="FieldOption.Show">Show</mat-option>
                <mat-option [value]="FieldOption.Required">Required</mat-option>
                <mat-option (click)="removeField(form, i)"><span class="text-danger">Remove</span></mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button (click)="addNewField(form)" *ngIf="edit">New Field</button>
    <button mat-raised-button color="primary" (click)="done(form)" *ngIf="edit">Done</button>
    <button mat-stroked-button color="primary" (click)="edit = true" *ngIf="!edit">Edit</button>
  </mat-card-actions>
</mat-card>
