import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject, Subscription } from 'rxjs';

import { NavigationEnd, Router } from '@angular/router';
import {
  IChildItem,
  IMenuItem,
  NavigationService,
} from '../../services/navigation.service';
import { filter, map, tap } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { getEsuiteApi } from '../../../api/esuite-api';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { CodeScannerComponent } from '../code-scanner/code-scanner.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav;
  sidebarSub: Subscription;
  menuItems$: BehaviorSubject<IMenuItem[]> = this.navigationSerivce.menuItems;
  tl: IMenuItem;
  version = '';
  get topLevelView(): IMenuItem {
    return this.tl;
  }

  activeClass = 'bg-black text-light font-weight-bold';

  set topLevelView(t: IMenuItem) {
    this.tl = t;
  }
  activeView: IMenuItem | IChildItem;

  sub: Subscription;

  navMode = 'side';
  opened = true;

  constructor(
    public userService: UserService,
    private router: Router,
    private http: HttpClient,
    private navigationSerivce: NavigationService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog
  ) {

    breakpointObserver.observe(['(min-width: 768px)']).subscribe((e) => {
      if (!e.matches) {
        this.navMode = 'over';
        this.opened = false;
      } else {
        this.navMode = 'side';
        this.opened = true;
      }
    });
    this.sub = this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map((e: NavigationEnd) => {
          const state = e.url;
          let nextTopLevelView;
          for (const item1 of this.menuItems$.value) {
            if (item1.state === state) {
              nextTopLevelView = item1;
              break;
            } else if (item1.sub?.length) {
              for (const item2 of item1.sub) {
                if (item2.state === state) {
                  nextTopLevelView = item1;
                  break;
                }
              }
            }
          }
          this.topLevelView = nextTopLevelView;
        })
      )
      .subscribe();
  }

  async ngOnInit(): Promise<void> {
    this.version = (
      (await this.http.get('/api/status').toPromise()) as any
    ).version;
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async signout() {
    await getEsuiteApi().auth.logoutUser('user');
    window.location.href = '/dashboard/login';
  }

  openScanner() {
    this.dialog.open(CodeScannerComponent, {
      ariaLabel: 'aac-m-0_p-0',
    });
  }
}
