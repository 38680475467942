import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewSubscription,
} from 'cadence';
import { getEsuiteApi } from '../../../../../app/api/esuite-api';
import {
  ApplicationCurrency,
  SelfCheckoutResponse,
  TransactionMethod,
  TransactionStatus,
  TransactionType,
} from 'esuite-client';
import { startCase } from 'lodash';
import { quickEnumToOptions } from '../../../../../app/shared/utils/quick-enum-to-options';

@CadenceView({
  viewType: 'dialog',
  title: 'Complete Order',
})
export class CheckoutToOrder extends CadenceFormViewModel<{
  checkoutToken: string;
  checkoutId: number;
}> {
  @CadenceFormControl(CadenceFormControlType.Select, {
    options: () => {
      return quickEnumToOptions(TransactionMethod);
    },
  })
  paymentMethod: string;

  @CadenceFormControl(CadenceFormControlType.Select, {
    options: async () => {
      return (await getEsuiteApi().selfCheckout.list()).map((t) => ({
        label: t.name,
        value: t.id,
      }));
    },
  })
  processThrough: string;

  @CadenceFormAction()
  async save() {
    const checkout = await getEsuiteApi().checkout.get(this._params.checkoutId);
    const resp = await getEsuiteApi().selfCheckoutOrder.create(
      this._controlData$.value.processThrough,
      {
        order: {
          checkoutToken: this._params.checkoutToken,
        },
        transaction: {
          method: this._controlData$.value.paymentMethod,
          amount: checkout.totalPrice,
          type: TransactionType.Purchase,
          currency: ApplicationCurrency.Cad,
          status: TransactionStatus.Success,
        },
      }
    );
    this._postEvent('dismissDialog');
    return resp;
  }
}
