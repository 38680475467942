import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from 'esuite-dashboard/app/shared/components/dashboard/dashboard.component';
import { DigitalSignageEditorComponent } from './editor/digital-signage-editor.component';
import { KioskLayoutEditorComponent } from './editor/kiosk-layout-editor/kiosk-layout-editor.component';
import { DigitalSignageListComponent } from 'esuite-dashboard/app/pages/digital-signage/list/digital-signage-list.component';
import { AuthGuard } from 'esuite-dashboard/app/shared/guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'digital-signage',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
      {
        path: '',
        component: DigitalSignageListComponent,
      },
      {
        path: ':layoutId',
        component: DigitalSignageEditorComponent,
        children: [
          {
            path: '',
            component: KioskLayoutEditorComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class DigitalSignagePageRoutingModule {}
