import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AbstractControl, FormControl } from '@angular/forms';
import { Ace } from '../../../../assets/ace';
(window as any).ace.config.set('basePath', 'assets/ace');

interface AceEditorOptions {
  el: HTMLDivElement;
  mode;
  control?: AbstractControl;
  initialValue?: any;
  height?: string;
  style?: { [key: string]: string };
  onChange?: (val: string) => void;
}
export function makeAceEditor({
  el,
  mode,
  control,
  initialValue,
  height,
  style,
  onChange,
}: AceEditorOptions): Ace.Editor {
   ;
  const editor = (window as any).ace.edit(el);
   ;
  if (height) {
    editor.container.style.height = height || '240px';
  }

  if (style) {
    Object.assign(editor.container.style, style);
  }

  // editor.setTheme('ace/theme/monokai');
  editor.session.setMode(mode);
   ;
  editor.setValue(initialValue || '');
  if (control) {
    editor.setValue(control.value || '');
  }
  const editorSub = fromEvent(editor, 'change')
    .pipe(debounceTime(500))
    .subscribe(() => {
      const value = editor.getValue();
      if (onChange) {
        onChange(value);
      }
      if (control) {
        control.setValue(value);
      }
    });

  const destroyElement = editor.destory;
  editor.destroy = () => {
    destroyElement?.();
    editorSub.unsubscribe();
  };
  return editor;
}
