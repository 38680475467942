import { Component, Input, OnInit } from '@angular/core';
import { KioskResponse } from 'esuite-client';
import { getEsuiteApi } from '../../../api/esuite-api';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-kiosk-picker',
  templateUrl: './kiosk-picker.component.html',
  styleUrls: ['./kiosk-picker.component.scss'],
})
export class KioskPickerComponent implements OnInit {
  constructor() {}
  @Input()
  multiple = false;

  @Input()
  instruction: string;

  loadingError;
  kiosks: KioskResponse[];

  async ngOnInit(): Promise<void> {
    this.kiosks = await getEsuiteApi().kiosk.list();

    this.instruction =
      this.instruction ??
      (this.multiple
        ? 'Select kiosks to proceed'
        : 'Select a kiosk to proceed.');
  }
}
