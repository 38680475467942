<h5 mat-dialog-title>Create Slide</h5>

<mat-dialog-content *ngIf="form; else spinner" class="p-0" style="min-width: 80vw">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <mat-tab-group>
          <mat-tab label="General">
            <div class="container-fluid py-3" [formGroup]="form">
              <mat-form-field class="w-100 mb-4" appearance="fill">
                <mat-label>Name</mat-label>
                <input formControlName="name" matInput>
              </mat-form-field>
              <mat-form-field class="w-100 mb-4" appearance="fill">
                <mat-label>Duration in Seconds</mat-label>
                <input formControlName="duration" matInput type="number">
              </mat-form-field>
              <label>Background Image</label>
              <app-image-upload
                [(image)]="bgImage"
                [imageFormControl]="form.controls.bgImageId"
              ></app-image-upload>
            </div>
          </mat-tab>
          <mat-tab label="Edit Layers">
            <app-kiosk-layer-editor
              [slideSize]="slideContainer"
              [layerFormControl]="form.get('layers')"
              [layers]="data?.slide?.layers"
            ></app-kiosk-layer-editor>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button color="warn" mat-stroked-button [disabled]="loading" (click)="close()"> Close</button>
  <button color="primary" mat-raised-button [disabled]="form.invalid || loading" (click)="done()">Save</button>
<!--  <button *ngIf="!editMode" color="primary" mat-raised-button [disabled]="form.invalid || loading" (click)="create()"> Add Slide</button>-->
<!--  <button *ngIf="editMode" color="primary" mat-raised-button [disabled]="form.invalid || loading" (click)="update()"> Update Slide</button>-->
  <mat-spinner *ngIf="loading" [diameter]="20" ></mat-spinner>
</mat-dialog-actions>
<ng-template #spinner>
  <mat-spinner [diameter]="50"></mat-spinner>
</ng-template>
