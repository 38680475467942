import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../../shared/components/alert/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { getEsuiteApi } from '../../../../api/esuite-api';
import { KioskResponse } from 'esuite-client';
import { map, pluck, switchMap, tap } from 'rxjs/operators';
import { pluckAsInt } from 'esuite-dashboard/app/shared/utils/pluck-as-int';

@Component({
  selector: 'app-kiosk-detail',
  templateUrl: './kiosk-detail.component.html',
  styles: [],
})
export class KioskDetailComponent implements OnInit {
  kioskId: number;
  kioskId$ = this.activatedRoute.params.pipe(
    pluckAsInt('kioskId'),
    tap((id) => (this.kioskId = id))
  );

  kiosk$ = this.kioskId$.pipe(
    switchMap((kioskId) => getEsuiteApi().kiosk.get(kioskId))
  );

  form$ = this.kiosk$.pipe(map((kiosk) => this.makeUpdateForm(kiosk)));

  activations$ = this.kioskId$.pipe(
    switchMap((kioskId) => getEsuiteApi().activation.list({ kioskId }))
  );

  paymentTerminals$ = this.kioskId$.pipe(
    switchMap(() => getEsuiteApi().paymentTerminal.list())
  );

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  private makeUpdateForm(kiosk: KioskResponse) {
    return this.fb.group({
      name: [kiosk.name, Validators.required],
      password: [null],
      activationId: [kiosk.activationId],
      paymentTerminalId: [kiosk.paymentTerminal?.id],
      printerName: [kiosk.printerName],
    });
  }

  handleUpdate(form: FormGroup) {
    form.disable();
    const update = { ...form.value };

    // In case no password is provided delete it from the update request
    if (!update.password) {
      delete update.password;
    }
    this.alertService
      .runWithLoader(
        () => getEsuiteApi().kiosk.update(this.kioskId, update),
        'Kiosk updated successfully',
        'Error updating kiosk'
      )
      // If an error is thrown we need to enable the form again
      .finally(() => {
        form.enable();
      });
  }
}
