/*
 * AdmissionOptionDeleteForm
 * Generated delete resource from cadence/scripts/generate
 */

import {
  CadenceFormViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  cadenceRouter,
  CadenceFormControl,
} from 'cadence';
import { AdmissionOptionListView } from './admission-option-list';
import { getEsuiteApi } from '../../../../app/api/esuite-api';

interface IAdmissionOptionDeleteFormParams {
  id: number;
  admissionId: number;
}

@CadenceView({
  title: 'Delete Admission Option',
  viewType: 'dialog',
})
export class AdmissionOptionDeleteForm extends CadenceFormViewModel<IAdmissionOptionDeleteFormParams> {
  /*
   * Form Controls
   */
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  /*
   * Form Actions
   */
  @CadenceFormAction({
    label: 'Delete',
  })
  async delete(): Promise<void> {
    return getEsuiteApi().admissionOption.remove(
      this._params.admissionId,
      this._params.id,
    );
  }

  /*
   * Form Events
   */
  @CadenceViewEvent('action.delete')
  afterDelete(): void {
    this._postEvent('dismissDialog');
  }
}
