<ng-container *ngIf="{ form: form$ | async, floorId: floorId$ | async } as view">
  <h5>Edit Floor</h5>

  <form  *ngIf="view.form" [formGroup]="view.form">
    <div class="d-flex align-items-stretch justify-content-center flex-column">
      <mat-form-field  appearance="fill" class="w-100">
        <mat-label>Name</mat-label>
        <input formControlName="name" matInput>
      </mat-form-field>
      <mat-form-field  appearance="fill" class="w-100">
        <mat-label>Number</mat-label>
        <input formControlName="number" matInput>
      </mat-form-field>

      <app-image-upload [imageFormControl]="view.form.get('mapImageId')"></app-image-upload>
    </div>
    <button color="primary" mat-raised-button [disabled]="view.form.disabled || view.form.invalid" (click)="handleUpdate(view.floorId, view.form)"> Save</button>

  </form>
</ng-container>
<ng-template #spinner>
  <mat-spinner [diameter]="50"></mat-spinner>
</ng-template>
