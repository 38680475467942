import 'reflect-metadata';
import { CadenceViewModel, ICadenceActionMetadata } from '../index';

/*
Transforms a link into the corresponding router parameters including _params
and events to forward to the new view.
 */
export const provideLinkParams = (
  link: ICadenceActionMetadata,
  from: CadenceViewModel,
  ...paramData: any[]
) => {
  const queryParams: any = {};
  // if (link.params && paramData) {
  //   Object.keys(link.params).forEach(
  //     (paramKey) =>
  //       (queryParams[paramKey] = link.params[paramKey](from, ...paramData)),
  //   );
  // }
  return queryParams;
};
