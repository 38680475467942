import { Pipe, PipeTransform } from '@angular/core';
import { ICadenceFormControlMetadata } from 'cadence';
/*
Transforms primitive static classes into input types
 */
@Pipe({
  name: 'cadFormControlInputType',
})
export class CadFormControlInputTypePipe implements PipeTransform {
  transform<T>(item: ICadenceFormControlMetadata): string {
    switch (item.type) {
      case String:
        return 'text';
      case Number:
        return 'number';
    }
    return 'text';
  }
}
