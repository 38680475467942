/*
 * FundOptionListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceActionColumn,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceListViewRow,
} from 'cadence';
import { getEsuiteApi } from '../../../../app/api/esuite-api';
import {
  FundOptionCreateForm,
  FundOptionUpdateForm,
} from '../../index';

@CadenceView({
  title: 'Fund Options',
})
export class FundOptionListView extends CadenceListViewModel<any> {
  @CadenceColumn()
  name: string;

  @CadenceColumn()
  price: number;

  @CadenceAction({ label: 'Create' })
  createForm(): void {
    cadenceRouter.navigate(FundOptionCreateForm, {
      from: this,
      events: {
        'action.create': 'created',
      },
      params: {
        fundId: this._params.id,
      },
    });
  }

  @CadenceActionColumn({ label: 'Delete' })
  async delete(
    row: CadenceListViewRow<FundOptionListView>,
  ): Promise<{ id: number }> {
    const confirmDelete = confirm(
      'Are you sure you want to delete this option: ' + row.name + ' ?',
    );
    if (confirmDelete) {
      await getEsuiteApi().fundOption.remove(this._params.id, row.id);
      return { id: row.id };
    }
  }

  @CadenceActionColumn({ label: 'Up' })
  async moveUp(row: CadenceListViewRow<FundOptionListView>): Promise<void> {
    const rowInd = this._rows$.value.indexOf(row);
    const newRowInd = rowInd - 1;
    if (newRowInd > -1) {
      this._rows$.swap(rowInd, newRowInd);
    }
    await getEsuiteApi().fund.update(row.fundId, {
      sortOptionsById: this._rows$.value.map((r) => r.id),
    });
  }

  @CadenceActionColumn({ label: 'Down' })
  async moveDown(row: CadenceListViewRow<FundOptionListView>): Promise<void> {
    const rowInd = this._rows$.value.indexOf(row);
    const newRowInd = rowInd + 1;
    if (newRowInd <= this._rows$.value.length - 1) {
      this._rows$.swap(rowInd, newRowInd);
    }
    await getEsuiteApi().fund.update(row.fundId, {
      sortOptionsById: this._rows$.value.map((r) => r.id),
    });
  }

  @CadenceActionColumn({ label: 'Edit' })
  updateForm(row: CadenceListViewRow<FundOptionListView>): void {
    cadenceRouter.navigate(FundOptionUpdateForm, {
      from: this,
      params: {
        id: row.id,
        fundId: this._params.id,
      },
      events: {
        'action.update': 'updated',
      },
    });
  }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  @CadenceViewEvent('updated')
  update(event: any): void {
    this._rows$.updateBy((c) => c.id === event.data.id, event.data);
  }

  @CadenceViewEvent('action.delete')
  remove(event: any): void {
    if (event.data) {
      this._rows$.deleteBy((c) => c.id === event.data.id);
    }
  }

  async _init(): Promise<void> {
    const optionList = await getEsuiteApi().fundOption.list(this._params.id);
    this._rows$.set(optionList.sort((a, b) => a.sort - b.sort));
  }
}
