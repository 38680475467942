import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { PhpDatePipe } from './pipes/php-date.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxFileDropModule } from 'ngx-file-drop';
import { Ng2SearchPipe, Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatRadioModule } from '@angular/material/radio';
import { CalendarModule } from 'angular-calendar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatMenuModule } from '@angular/material/menu';
import { SafePipe } from './pipes/safe.pipe';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';
import { MatStepperModule } from '@angular/material/stepper';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DynamicFormsMaterialUIModule } from '@ng-dynamic-forms/ui-material';
import { PickFormArrayPipe } from 'esuite-dashboard/app/shared/pipes/pick-form-array.pipe';
import { ConfirmDialogComponent } from 'esuite-dashboard/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DashboardComponent } from 'esuite-dashboard/app/shared/components/dashboard/dashboard.component';
import { ShortUrlDialogComponent } from 'esuite-dashboard/app/shared/components/short-url-dialog/short-url-dialog.component';
import { ImageUploadComponent } from 'esuite-dashboard/app/shared/components/image-upload/image-upload.component';
import { VideoUploadComponent } from 'esuite-dashboard/app/shared/components/video-upload/video-upload.component';
import { BoxPositionComponent } from 'esuite-dashboard/app/shared/components/box-position/box-position.component';
import { ResourceListComponent } from 'esuite-dashboard/app/shared/components/resource-list/resource-list.component';
import { AppLinkComponent } from 'esuite-dashboard/app/shared/components/app-link/app-link.component';
import { CreateUpdateButtonComponent } from 'esuite-dashboard/app/shared/components/create-update-button/create-update-button.component';
import { TemplateEditorComponent } from 'esuite-dashboard/app/shared/components/template-editor/template-editor.component';
import { ListSidebarDetailComponent } from 'esuite-dashboard/app/shared/components/list-sidebar-detail/list-sidebar-detail.component';
import { FormArrayChipsComponent } from 'esuite-dashboard/app/shared/components/form-array-chips/form-array-chips.component';
import { CustomFieldEditorComponent } from 'esuite-dashboard/app/shared/components/custom-field-editor/custom-field-editor.component';
import { AlertComponent } from 'esuite-dashboard/app/shared/components/alert/alert.component';
import { CodeScannerComponent } from 'esuite-dashboard/app/shared/components/code-scanner/code-scanner.component';
import { KioskPickerComponent } from 'esuite-dashboard/app/shared/components/kiosk-picker/kiosk-picker.component';
import { KioskPreviewLauncherComponent } from 'esuite-dashboard/app/shared/components/kiosk-preview-launcher/kiosk-preview-launcher.component';
import { AceEditorComponent } from 'esuite-dashboard/app/shared/components/ace-editor/ace-editor.component';
import { CommonModule } from '@angular/common';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { OrderByPipe } from 'esuite-dashboard/app/shared/pipes/order-by.pipe';

/*
External components shared in every component across the entire application.
Provides the most fundamental utilities we use for building components
like form controls, inputs, layout utilities, etc.
 */
const modules = [
  // Angular Common Modules
  FormsModule,
  ReactiveFormsModule,
  DynamicFormsMaterialUIModule,
  RouterModule,

  // Third-party
  NgxFileDropModule,
  ColorPickerModule,
  Ng2SearchPipeModule,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  CalendarModule,

  // Material
  DragDropModule,
  MatButtonToggleModule,
  MatTreeModule,
  MatProgressBarModule,
  MatDialogModule,
  MatTableModule,
  MatFormFieldModule,
  MatPaginatorModule,
  MatToolbarModule,
  MatButtonModule,
  MatIconModule,
  MatSidenavModule,
  MatListModule,
  MatCheckboxModule,
  MatChipsModule,
  MatTabsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSelectModule,
  MatInputModule,
  MatCardModule,
  MatSortModule,
  MatGridListModule,
  NgxChartsModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatRadioModule,
  MatMenuModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatStepperModule,
  ClipboardModule,

  CommonModule,
  ZXingScannerModule,
];

export const declarations = [
  // Pipes
  PhpDatePipe,
  SafePipe,
  PickFormArrayPipe,
  OrderByPipe,

  // Components
  ConfirmDialogComponent,
  DashboardComponent,
  ShortUrlDialogComponent,
  ImageUploadComponent,
  VideoUploadComponent,
  BoxPositionComponent,
  ResourceListComponent,
  AppLinkComponent,
  CreateUpdateButtonComponent,
  TemplateEditorComponent,
  ListSidebarDetailComponent,
  FormArrayChipsComponent,
  CustomFieldEditorComponent,
  AlertComponent,
  CodeScannerComponent,
  KioskPickerComponent,
  KioskPreviewLauncherComponent,
  AceEditorComponent,
];

@NgModule({
  declarations,
  imports: modules,
  exports: [...modules, ...declarations],
  providers: [declarations],
})
export class SharedModule {}
