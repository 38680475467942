<h4>Create a Kiosk</h4>

<form [formGroup]="form" >
  <div class="d-flex align-items-stretch justify-content-between flex-column">

    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Username</mat-label>
      <input matInput formControlName="username">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput formControlName="password" type="password">
    </mat-form-field>

  </div>
  <button mat-raised-button color="primary"
          (click)="handleCreate()"
          [disabled]="!form.enabled || form.invalid">
    Create
  </button>
</form>


