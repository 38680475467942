import { Component, OnInit } from '@angular/core';
import { RoleName } from 'esuite-client';
import { UserService } from 'esuite-dashboard/app/shared/services/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  RoleName = RoleName;
  latestPlayer: { url: string; name: string };

  constructor(public userService: UserService) {}

  async ngOnInit(): Promise<void> {
    const base =
      'https://nyc3.digitaloceanspaces.com/myeflyn/releases/platform';
    const ymlText = await fetch(`${base}/beta.yml`).then((r) => r.text());
    const end = `${ymlText.split('url: ')[1].split('\n')[0]}`;
    const url = `${base}/${end}`;
    this.latestPlayer = {
      url,
      name: end.split('.exe')[0],
    };
  }
}
