import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  cadenceRouter,
  getCadence,
  getCadenceViews,
  getCadenceViewsAsArray,
  ICadenceViewMetadata,
} from 'cadence';
import { RoleName } from 'esuite-client';
import { CadenceHookType } from '../../../cadence/decorators/cadence-hook.decorator';

export interface IMenuItem {
  view?: ICadenceViewMetadata;
  type: 'link' | 'dropDown' | 'icon' | 'separator' | 'extLink';
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown value
  badges?: IBadge[];
  roles?: RoleName[];
}
export interface IChildItem {
  view?: ICadenceViewMetadata;
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
  roles?: RoleName[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private afterMenuItems: IMenuItem[] = [
    {
      type: 'link',
      name: 'Settings',
      state: '/settings',
      sub: [],
      icon: 'settings',
    },
  ];
  private primaryMenu: IMenuItem[] = [
    {
      type: 'link',
      name: 'Self Checkout',
      state: '/self-checkouts',
      icon: 'storefront',
      sub: [
        {
          type: 'link',
          name: 'Categories',
          state: '/categories',
        },
      ],
    },
    {
      type: 'link',
      name: 'Digital Signage',
      state: '/digital-signage',
      icon: 'slideshow',
    },
    {
      type: 'link',
      name: 'Media Collections',
      state: '/media-collections',
      icon: 'collections',
      roles: [RoleName.MediaCollectionAdmin],
    },
    {
      type: 'link',
      name: 'Photobooths',
      state: '/photobooths',
      icon: 'photo_camera',
      roles: [RoleName.PhotoboothAdmin],
    },
    {
      type: 'link',
      name: 'Spin To Win',
      state: '/spin-to-win',
      icon: 'emoji_events',
      roles: [RoleName.SpinToWinAdmin],
    },
    {
      type: 'link',
      name: 'Shopify Checkouts',
      state: '/shopify_checkout',
      icon: 'shopping_cart',
      // roles: [RoleName.SpinToWinAdmin],
    },
    {
      type: 'link',
      name: 'Directories',
      state: '/directory',
      icon: 'business',
      // roles: [RoleName.SpinToWinAdmin],
    },
    // {
    //   type: 'link',
    //   name: 'Platforms',
    //   state: '/platforms',
    // },
    // {
    //   type: 'link',
    //   name: 'Apps',
    //   state: '/rest-apps',
    // },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets primaryMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.primaryMenu);

  constructor() {
    this.initMenus();
  }

  async initMenus() {
    const rootCadenceViews: IMenuItem[] = getCadenceViewsAsArray()
      .filter((view) => view.showInMenu === 'root')
      .map(this.cadViewToMenuItem) as IMenuItem[];

    const childViews = getCadenceViewsAsArray().filter(
      (view) => typeof view.showInMenu === 'function'
    );
    childViews.forEach((child) => {
      const foundParent = rootCadenceViews.find(
        (nav) =>
          (nav.view as ICadenceViewMetadata).view() === child.showInMenu()
      );
      if (foundParent) {
        foundParent.type = 'dropDown';
        const level2Item = this.cadViewToMenuItem(child) as IChildItem;
        foundParent.sub.push(level2Item);
      }
    });

    for (const pm of this.primaryMenu) {
      const subCadenceViews = getCadenceViewsAsArray()
        .filter((view) => view.showInMenu === pm.state)
        .map(this.cadViewToMenuItem) as IChildItem[];
      if (subCadenceViews) {
        pm.sub = [...(pm.sub || []), ...subCadenceViews];
      }
    }

    for (const pm of this.afterMenuItems) {
      const subCadenceViews = getCadenceViewsAsArray()
        .filter((view) => view.showInMenu === pm.state)
        .map(this.cadViewToMenuItem) as IChildItem[];
      if (subCadenceViews) {
        pm.sub = [...(pm.sub || []), ...subCadenceViews];
      }
    }

    this.menuItems.next([
      ...this.primaryMenu,
      ...rootCadenceViews,
      ...this.afterMenuItems,
    ]);
  }

  cadViewToMenuItem(view: ICadenceViewMetadata): IMenuItem | IChildItem {
    return {
      view,
      name: view.title as any,
      type: 'link',
      icon: view.icon,
      state: '/' + cadenceRouter.prefix + '/' + view.route,
      sub: [],
      roles: view.roles as RoleName[],
    };
  }

  // You can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    this.menuItems.next(this.primaryMenu);
  }
}
