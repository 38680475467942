import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AbstractFormComponent } from '../../classes/abstract-form.component';
import { FieldOption } from 'esuite-client';
import { Output } from 'liquidjs/dist/template/output';
import { CdkDragSortEvent, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-custom-field-editor',
  templateUrl: './custom-field-editor.component.html',
  styleUrls: ['./custom-field-editor.component.scss'],
})
export class CustomFieldEditorComponent implements OnInit {
  form: FormGroup;
  private localCustomControl: AbstractControl;
  @Input('customControl')
  get customControl(): AbstractControl {
    return this.localCustomControl;
  }
  set customControl(c: AbstractControl) {
    this.localCustomControl = c;
    this.form = this.makeUpdateForm(this.customControl.value);
  }
  edit = false;
  FieldOption = FieldOption;
  constructor(private fb: FormBuilder) {
    this.form = this.makeCreateForm();
  }

  private defaultField = {
    component: ['line'],

    label: [null, Validators.required],

    name: [null, Validators.required],

    option: [FieldOption.Show],

    width: [4],
  };

  async ngOnInit(): Promise<void> {}

  drop(event: CdkDragSortEvent) {
    moveItemInArray(
      (this.form.get('fields') as FormArray).controls,
      event.previousIndex,
      event.currentIndex
    );
    moveItemInArray(
      (this.form.get('fields') as FormArray).value,
      event.previousIndex,
      event.currentIndex
    );
    this.form.updateValueAndValidity();
  }

  handleCreate(dto: any): any {
    if (this.customControl) {
      this.customControl.setValue(dto);
    }
  }

  handleUpdate(dto: any): any {
    if (this.customControl) {
      this.customControl.setValue(dto);
    }
  }

  loadFormData(id: number): Promise<void> | void {
    return undefined;
  }

  makeCreateForm(): FormGroup {
    return this.fb.group({
      fields: this.fb.array([this.fb.group(this.defaultField)]),
    });
  }

  removeField(form: FormGroup, i: number) {
    (form.get('fields') as FormArray).removeAt(i);
  }

  addNewField(form: FormGroup) {
    (form.get('fields') as FormArray).push(this.fb.group(this.defaultField));
  }
  done(form: FormGroup) {
     ;
    if (this.customControl) {
      this.customControl.setValue(form.value.fields);
    }
    this.edit = false;
  }

  makeUpdateForm(customFields: any): FormGroup {
     ;
    return this.fb.group({
      fields: this.fb.array(
        customFields.map((customField) => {
          return this.fb.group({
            component: [customField.component],

            label: [customField.label, Validators.required],

            name: [customField.name, Validators.required],

            option: [customField.option],

            width: [customField.width],
          });
        })
      ),
    });
  }
}
