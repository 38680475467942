import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import {
  CadenceComponent,
  CadenceListViewModel,
  ICadenceActionMetadata,
  ICadenceListViewColumnMetadata,
} from 'cadence';

import {
  BaseCadViewComponent,
  IBaseCadViewComponent,
} from '../base-cad-view/base-cad-view.component';
import { orderBy } from 'lodash';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Parser } from 'json2csv';
import { emulateDataDownload } from './emulate-data.download';

/*
Renders a given cadence list view, remote and _params
Currently VIEW and PARAMS comes from router, API comes from esuiteService
 */
@Component({
  selector: 'app-cad-list-view',
  templateUrl: './cad-list-view.component.html',
  styleUrls: ['./cad-list-view.component.scss'],
})
@CadenceComponent(() => CadenceListViewModel)
export class CadListViewComponent
  extends BaseCadViewComponent<CadenceListViewModel>
  implements IBaseCadViewComponent {
  pageIndex = 0;
  /* Cadence List Params */

  /* Tracks latest listParams for paging */
  listParams$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  /* Represents the current list to show based on View, Params and Paging */
  // remoteList$: Observable<any[]>;
  list$: Observable<any[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  columns: ICadenceListViewColumnMetadata[] = [];
  // footerColumns: ICadenceListViewColumnMetadata[] = [];
  links: ICadenceActionMetadata[] = [];

  get displayedColumns(): string[] {
    return this.columns.map((c) => c.name);
  }

  dynamicValues = {};

  constructor(route: ActivatedRoute) {
    super(route);
  }

  async exportToCsv() {
    const { pager, ...opts } = this.cadView._csvExport;

    try {
      const parser = new Parser(opts);

      const value: any = await pager({});
       ;
      let items = [...value];

      if (value.pagination?.totalPages > 1) {
        let newItems = value;
        do {
          newItems = await pager(newItems.pagination.nextPageParameters);
           ;
          items = [...items, ...newItems];
        } while (newItems.pagination?.nextPageParameters);
      }
      const csv = parser.parse(items);
       ;
      emulateDataDownload(
        csv,
        'text/csv',
        'esuite-export-' +
          [
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            new Date().getDate(),
          ].join('-') +
          '.csv',
      );
    } catch (err) {
      console.error(err);
    }
  }
  protected async initializeView() {
    await super.initializeView();

    this.list$ = this.cadView._rows$.value$.pipe(
      debounceTime(150),
      // filter(([a, b]) => a || b),
      map((list) => {
        return orderBy(list, 'createdAt', 'desc');
      }),
      tap((list) => {
        setTimeout(() => {
          // this.dataSource.paginator = this.paginator;
          this.dataSource.data = list;
          // if (this.dataSource.paginator) {
          //   this.dataSource.paginator.firstPage();
          // }
        }, 100);
      }),
    );
    //
    // if (this.cadView._paginator) {
    //   this.subscriptions.push();
    // }

    // Any code that relies on viewData to be available runs here
    // Todo: create some kind of decorator or override for this
    this.columns = this.cadView._columns;
    this.subscriptions.push(
      this.list$.subscribe((rows) => {
        for (const col of this.columns) {
          this.dynamicValues[col.name] = rows.map((r) =>
            Promise.resolve(col.value(r, this.cadView)),
          );
        }
      }),
    );
    this.links = this.cadView._links;
  }
}

