import 'reflect-metadata';
import { getCadence } from '../../cadence';
import { startCase } from 'lodash';
import { CadenceViewLinkParamProvider } from '../../types';
import { CadenceUnarray } from '../../types';
import { CadenceObjectType } from '../../types';
import {
  CadenceListViewModel,
  ICadenceListViewColumnMetadata,
  CadenceViewModel,
} from 'cadence';

interface ICadenceColumnOptions<From extends CadenceListViewModel> {
  header?: string;
  footer?: (view?: From) => any;
  value?: (item?: CadenceUnarray<From['_rows$']['value']>, view?: From) => any;
}

export function CadenceColumn<T extends CadenceListViewModel>(
  view?: () => CadenceObjectType<T>,
  options?: ICadenceColumnOptions<T>,
): PropertyDecorator {
  return (target: T, propertyKey: string) => {
    const t = Reflect.getMetadata('design:type', target, propertyKey);
    const targetName = target.constructor.name;
    const column: ICadenceListViewColumnMetadata<T> = {
      name: propertyKey,
      header: startCase(propertyKey),
      value: (resp) => resp[propertyKey],
      type: t,
      ...options,
    };
    getCadence().column.set(targetName, propertyKey, column);
    return null;
  };
}

interface ICadenceLinkColumnOptions<
  From extends CadenceListViewModel,
  View extends CadenceViewModel
> extends Omit<ICadenceColumnOptions<From>, 'value'> {
  label: string;
  params?: CadenceViewLinkParamProvider<
    View['_params'],
    [From, CadenceUnarray<From['_rows$']['value']>]
  >;
  events?: { [key: string]: string };
}

export function CadenceActionColumn<
  From extends CadenceListViewModel,
  View extends CadenceViewModel
>(options: ICadenceLinkColumnOptions<From, View>): PropertyDecorator {
  return (target: From, propertyKey: string) => {
    const t = Reflect.getMetadata('design:type', target, propertyKey);
    const targetName = target.constructor.name;

    const column: ICadenceListViewColumnMetadata<From> = {
      name: propertyKey,
      header: startCase(propertyKey),
      value: () => options.label,
      type: t,
      ...options,
    };

    column.action = {
      name: propertyKey,
      label: options.label,
      action: target[propertyKey],
    };

    getCadence().column.set(targetName, propertyKey, column);
    return null;
  };
}
