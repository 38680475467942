import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../../../shared/components/alert/alert.service';
import { Directory } from 'esuite-client';
import { getEsuiteApi } from '../../../../api/esuite-api';
import { BehaviorSubject } from 'rxjs';
import { MultiBehaviorSubject } from 'esuite-dashboard/app/shared/utils/multi-behaviorsubject';

@Component({
  selector: 'app-directory-list',
  templateUrl: './directory-list.component.html',
  styles: [],
})
export class DirectoryListComponent {
  directories$: BehaviorSubject<Directory[]> = new MultiBehaviorSubject(
    // Use null to track that list has not loaded.
    null,
    // Load initial directories from the API while showing loader
    this.alertService.loader(
      () => getEsuiteApi().directory.list(),
      'Loading Directories...'
    )
  );
  displayedColumns = ['name', 'actions'];

  constructor(private alertService: AlertService) {}

  /*
  Deletes a Directory and removes it from the list
   */
  remove(directory: Directory): Promise<any> {
    return this.alertService.confirmLoaderAndResult(
      'Are you sure you want to remove this directory?',
      () =>
        getEsuiteApi()
          .directory.remove(directory.id)
          .then(() => {
            // Next value is list with the deleted directory removed
            this.directories$.next(
              this.directories$.value.filter((p) => p !== directory)
            );
          }),
      'Kiosk removed successfully',
      'Error removing directory'
    );
  }
}
