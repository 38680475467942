import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Ace } from '../../../../assets/ace';
import { makeAceEditor } from './make-ace-editor';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ace-editor',
  template: ` <div #htmlEditor style="width: 100%;"></div> `,
  styles: [],
})
export class AceEditorComponent implements AfterViewInit, OnDestroy {
  val = ''; // this is the updated value that the class accesses
  private localCustomControl: AbstractControl;

  @Input()
  get customControl(): AbstractControl {
    return this.localCustomControl;
  }
  set customControl(c: AbstractControl) {
    this.localCustomControl = c;
    if (this.htmlEditor) {
      this.ngAfterViewInit();
    }
  }

  constructor(private _sce: DomSanitizer) {}

  @Input()
  value: string;

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter();

  @Input() mode: string;
  @Input() height: string;
  @Input() style: any;

  @ViewChild('htmlEditor') htmlEditor: ElementRef<HTMLDivElement>;
  editor: Ace.Editor;

  ngAfterViewInit(): void {
    this.editor?.destroy();
    this.editor = makeAceEditor({
      el: this.htmlEditor.nativeElement,
      mode: `ace/mode/${this.mode}`,
      control: this.customControl,
      initialValue: this.value,
      height: this.height,
      style: this.style,
      onChange: (v) => this.valueChange.emit(v),
    });
  }
  ngOnDestroy(): void {
    this.editor?.destroy();
  }
}

