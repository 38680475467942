import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from 'esuite-dashboard/app/shared/components/dashboard/dashboard.component';
import { ProductCategoryListComponent } from 'esuite-dashboard/app/pages/product-category/list/product-category-list.component';
import { ProductCategoryEditorComponent } from 'esuite-dashboard/app/pages/product-category/editor/product-category-editor.component';
import { AuthGuard } from 'esuite-dashboard/app/shared/guards/auth-guard.service';


const routes: Routes = [
  {
    path: 'categories',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProductCategoryListComponent,
      },
      {
        path: ':productCategoryId',
        component: ProductCategoryEditorComponent,
      },
    ],
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class ProductCategoryPageRoutingModule { }
