import { Component, Input, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-form-array-chips',
  templateUrl: './form-array-chips.component.html',
  styleUrls: ['./form-array-chips.component.scss'],
})
export class FormArrayChipsComponent implements OnInit {
  @Input()
  control: AbstractControl;

  // tagEntries = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  constructor() {}

  ngOnInit(): void {}

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.control.setValue([...this.control.value, value]);
    }

    // Clear the input value
    event.input.value = '';
  }

  remove(v: string): void {
    const index = this.control.value.indexOf(v);

    if (index >= 0) {
      this.control.setValue([
        ...this.control.value.slice(0, index),
        ...this.control.value.slice(index + 1),
      ]);
    }
  }

  handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    event.clipboardData
      .getData('Text')
      .split(/;|,|\n/)
      .forEach((value) => {
        if (value.trim()) {
          this.control.setValue([...this.control.value, value.trim()]);
        }
      });
  }
}
