import { getCadence } from '../cadence';
import { CadenceViewModel } from '../models';

export interface ICadenceComponentMetadata {
  viewModel: () => typeof CadenceViewModel;
  component: () => any;
}

export function CadenceComponent<T>(viewModel: () => T) {
  return (target: any) => {
    getCadence().components.set(target.name, {
      component: () => target,
      viewModel,
    });
  };
}
