/*
 * OrderList
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceAction,
  CadenceActionColumn,
  CadenceColumn,
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceListViewModel,
  CadenceListViewPaginator,
  CadenceListViewRow,
  cadenceRouter,
  CadenceStackViewChild,
  CadenceStackViewModel,
  CadenceView,
  CadenceViewEvent,
  CadenceViewSubscription,
  ICadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import {
  CustomerResponse,
  CustomerUpdateRequest,
  EsuiteApp,
  KioskRemoteCommand,
  OrderResponse,
} from 'esuite-client';
import { OrderDetailView } from '../order/order-view';
import { toCurrency } from '../../util/toCurrency';
import { toDisplayDate } from '../../util/toDisplayDate';
import { startCase } from 'lodash';
import { toDateControl } from '../../util/to-date-control';
import { flatten } from 'lodash';
import { AdmissionDetail } from '../admission/admission-detail';

function makePaginator(list: CadenceListViewModel) {
  const _paginator = new CadenceListViewPaginator();
  _paginator.loadPage = async (page) => {
    const skip = page.pageIndex * page.pageSize;
    const limit = page.pageSize;
    list._rows$.set([]);
    list._rows$.set(
      await getEsuiteApi().customer.list({
        skip,
        limit,
      })
    );
  };
  return _paginator;
}

@CadenceView({
  showInMenu: '/self-checkouts',
  title: 'Customers',
})
export class CustomerList extends CadenceListViewModel<CustomerResponse> {
  @CadenceColumn()
  email: string;

  @CadenceColumn()
  firstName: string;

  @CadenceColumn()
  lastName: string;

  // tslint:disable-next-line:variable-name
  _paginator = makePaginator(this);

  async _init(): Promise<void> {
    this._rows$.set(await getEsuiteApi().customer.list());
  }

  @CadenceActionColumn({ label: 'Edit', header: '' })
  updateForm(row: CadenceListViewRow<CustomerList>): void {
    cadenceRouter.navigate(CustomerUpdateForm, {
      from: this,
      params: {
        id: row.id,
      },
      events: {
        'action.update': 'updated',
      },
    });
  }
}

@CadenceView({
  title: 'Update Customer',
})
export class CustomerUpdateForm extends CadenceFormViewModel<
  { id: number },
  CustomerResponse
> {
  @CadenceFormControl()
  email: string;

  @CadenceFormControl()
  firstName: string;

  @CadenceFormControl()
  lastName: string;

  @CadenceFormControl()


  @CadenceFormAction({ label: 'Save' })
  async save() {
    await getEsuiteApi().customer.update(
      this._params.id,
      this._controlData$.value
    );
    alert('Customer saved successfully');
  }

  async _init(): Promise<void> {
    this._controlData$.next(await getEsuiteApi().customer.get(this._params.id));
  }
}
