import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertComponent } from './alert.component';

type ImplementMessage<T> = string | ((data: T) => string);

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private dialog: MatDialog) {}

  createAlert(message: string, title?: string): MatDialogRef<AlertComponent> {
    const modalRef = this.dialog.open(AlertComponent, {});
    const ref: AlertComponent = modalRef.componentInstance;
    ref.message = message;
    ref.title = title;
    return modalRef;
  }

  confirm(message: string, title?: string): Promise<any> {
    const alert = this.createAlert(message, title);
    alert.componentInstance.confirm = true;
    alert.disableClose = true;
    return alert.afterClosed().toPromise();
  }

  /*
  Make the app wait displaying a message and loader.
  Returns the result of the function passed
   */
  async loader<T>(
    fn: () => Promise<T>,
    message = '',
    title?: string
  ): Promise<T> {
    const alert = this.createAlert(message, title);
    alert.componentInstance.spinner = true;
    alert.disableClose = true;
    try {
      const res = await fn();
      alert.close();
      return res;
    } catch (e) {
      alert.close();
      throw e;
    }
  }

  async confirmLoaderAndResult<T>(
    confirm: string,
    fn: () => Promise<T>,
    success: ImplementMessage<T>,
    error: ImplementMessage<T>,
    loadMessage?: string
  ): Promise<T> {
    if (await this.confirm(confirm)) {
      return this.runWithLoader(fn, success, error, loadMessage);
    }
  }

  implementMessage<T>(message: ImplementMessage<T>, data: T) {
    return typeof message === 'string' ? message : message(data);
  }

  /* Use loader along with a success or fail message */
  async runWithLoader<T>(
    fn: () => Promise<T>,
    success: ImplementMessage<T>,
    error: ImplementMessage<T>,
    loadMessage?: string
  ): Promise<T> {
    try {
      const resp = await this.loader(fn, loadMessage);
      this.createAlert(this.implementMessage(success, resp));
      return resp;
    } catch (e) {
      this.createAlert(this.implementMessage(error, e));
      throw e;
    }
  }
}
