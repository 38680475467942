/*
 * CheckoutUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel, cadenceRouter,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { CadenceFormArray } from '../../models/form-view/cadence-form-array.decorator';
import { filter, pluck, switchMap } from 'rxjs/operators';
import { AddAdmissionLineItem } from './checkout-line-item/add-admission/add-admission-line-item';
import { CheckoutToOrder } from './checkout-line-item/add-admission/checkout-to-order';

interface ICheckoutUpdateFormParams {
  id: number;
}

@CadenceView()
export class CheckoutLineItemForm extends CadenceFormViewModel {
  @CadenceFormControl(CadenceFormControlType.Select, {
    options: () => [
      { label: 'Admission', value: 'admission' },
      { label: 'App Purchase', value: 'appPurchase' },
    ],
  })
  productType: 'admission' | 'appPurchase';

  @CadenceFormControl(CadenceFormControlType.Select, {
    options: (form: CheckoutLineItemForm) => {
      return form._controlData$.pipe(
        pluck('productType'),
        filter((v) => !!v),
        switchMap(async (productType) => {
          let products = [];
          if (productType === 'admission') {
            products = await getEsuiteApi().admission.list();
          } else if (productType == 'appPurchase') {
            products = await getEsuiteApi().appPurchase.list();
          }
          return products.map((p) => ({ label: p.title, value: p.id }));
        }),
      );
    },
  })
  productId: number;

  @CadenceFormControl()
  quantity: number;
}

@CadenceView({
  title: 'Update Checkout',
})
export class CheckoutUpdateForm extends CadenceFormViewModel<ICheckoutUpdateFormParams> {
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  @CadenceFormControl({
    hidden: true,
  })
  token: string;

  @CadenceFormControl({ required: false })
  phone?: string;

  @CadenceFormControl({ required: false })
  email?: string;

  @CadenceFormAction({
    label: 'Update',
  })
  async update(): Promise<any> {
    return getEsuiteApi().checkout.update(this._params.id, {
      phone: this.phone,
      email: this.email,
    });
  }

  @CadenceFormAction({
    label: 'Create Order',
  })
  async createOrder(): Promise<any> {
    cadenceRouter.navigate(CheckoutToOrder, {
      from: this,
      params: {
        checkoutToken: this._controlData$.value.token,
        checkoutId: this._controlData$.value.id,
      },
      events: {
        'action.save': 'created',
      },
    });
    // return getEsuiteApi().order.create({
    //   checkoutToken: this.token,
    // });
  }

  @CadenceViewEvent('action.update')
  afterUpdate(): void {}

  async _init(): Promise<void> {
    this._controlData$.next(await getEsuiteApi().checkout.get(this._params.id));
  }
}
