<button [matMenuTriggerFor]="settingsMenu" mat-icon-button (click)="loadKiosks()">
  <mat-icon>preview</mat-icon>
</button>

<mat-menu #settingsMenu>
  <ng-container *ngIf="loading">
    <menu mat-menu-item class="d-flex align-items-center justify-content-center">
      <mat-spinner [diameter]="30"></mat-spinner>
    </menu>
  </ng-container>
  <ng-container *ngIf="!loading">
    <menu mat-menu-item *ngFor="let kiosk of kiosks" (click)="launchPreview(kiosk)">{{ kiosk.name }}</menu>
  </ng-container>
</mat-menu>
