<ng-container *ngIf="
{
  directoryId: directoryId$ | async,
  form: form$ | async
} as view">

  <form *ngIf="view.form" [formGroup]="view.form">
    <div class="d-flex align-items-center justify-content-between">
      <h4 class="mb-0">Update Directory</h4>
      <button mat-raised-button color="primary"
              (click)="handleUpdate(view.directoryId, view.form)"
              [disabled]="!view.form.enabled || view.form.invalid">Update</button>
    </div>
    <div class="d-flex align-items-stretch justify-content-center py-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
    </div>


  </form>


  <mat-tab-group>
    <mat-tab label="Floors">
      <app-directory-floor-list></app-directory-floor-list>
    </mat-tab>
    <mat-tab label="Listings">
      <app-directory-listing-list></app-directory-listing-list>
    </mat-tab>
    <mat-tab label="Kiosks">
      <app-directory-activation-list></app-directory-activation-list>
    </mat-tab>
  </mat-tab-group>

</ng-container>
