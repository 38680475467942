<div *ngIf="form" [ngClass]="cadViewMetadata.classes" class="p-2">
    <mat-card class="mat-elevation-z">
      <div class="d-flex align-items-center justify-content-between">
        <mat-card-title *ngIf="cadViewMetadata.title">
          {{ cadViewMetadata.title }}
        </mat-card-title>
        <mat-card-actions *ngIf="cadView._actions.length" class="d-flex my-1 justify-content-between align-items-center flex-grow-1 pl-3">
          <div *ngIf="stillRequired" class="small text-danger mr-2">
            {{ stillRequired }}
          </div>
          <div class="ml-auto">
            <button
              (click)="handleAction(action)"
              mat-button
              color="primary"
              [disabled]="form.invalid || form.disabled"
              *ngFor="let action of cadView._actions">
              {{ action.label }}
            </button>
          </div>
        </mat-card-actions>
      </div>
      <mat-card-content class="mb-0">
        <form [formGroup]="form" *ngIf="form" #formDirective="ngForm">
          <div class="d-flex flex-wrap">
            <ng-container *ngFor="let ctrl of cadView._controls">
              <app-cad-form-control
                [ngClass]="ctrl.classes ? ctrl.classes : 'w-100'"
                [formView]="cadView"
                [cadFormControl]="ctrl"
                [control]="form.get(ctrl.name)">
              </app-cad-form-control>
            </ng-container>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  <mat-card *ngFor="let formArray of cadView._formArrays" class="mt-4">
    <mat-card-title *ngIf="formArray.title">
      {{ formArray.title }}
    </mat-card-title>
    <div class="d-flex flex-wrap">
      <div class="position-relative" *ngFor="let group of cadView[formArray.name]" [ngClass]="formArray.classes ? formArray.classes : 'w-100'">
        <button *ngIf="formArray.delete" style="top: 0px;
    right: -11px;
    z-index: 999;" class="position-absolute" mat-mini-fab color="warn" (click)="removeFromFormArray(group, formArray)">
          <mat-icon>delete</mat-icon>
        </button>
        <app-cad-form-view
          [cadView]="group"></app-cad-form-view>
      </div>
    </div>

    <button *ngIf="formArray.create" style="z-index: 10;" class="mr-3" mat-button color="primary" (click)="addToFormArray(formArray)">
      <mat-icon>add</mat-icon>
      {{ formArray.create === true ? 'Add' : formArray.create }}
    </button>
  </mat-card>
</div>
