<ng-container *ngIf="form$ | async as form">
  <div class="d-flex align-items-center justify-content-between">
    <span class="h5 m-0">{{ isNew ? 'Create' : 'Update' }} Self Checkout</span>
    <div>
      <app-kiosk-preview-launcher *ngIf="!isNew" [app]="EsuiteApp.SelfCheckout" [id]="id"></app-kiosk-preview-launcher>
      <app-create-update-button
        [disabled]="form.invalid || !form.enabled"
        [isNew]="isNew"
        (create)="handleCreate(form.value)"
        (update)="handleUpdate(form.value)" label="Self Checkout">
      </app-create-update-button>
    </div>
  </div>
  <hr class="mb-0">
  <mat-tab-group>
    <mat-tab label="General">
      <mat-card class="mt-2">
        <mat-card-content [formGroup]="form">
          <mat-form-field class="d-block" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>
          <mat-form-field class="d-block" appearance="outline">
            <mat-label>Revenue Center</mat-label>
            <mat-select formControlName="revenueCenterId">
              <mat-option [value]="revenueCenter.id" *ngFor="let revenueCenter of revenueCenters">{{ revenueCenter.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="d-block" appearance="outline">
            <mat-label>Categories</mat-label>
            <mat-select formControlName="categoryIds" [multiple]="true">
              <mat-option [value]="category.id" *ngFor="let category of categories">{{ category.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="d-block" appearance="outline">
            <mat-label>Integrations</mat-label>
            <mat-select formControlName="integrationIds" [multiple]="true">
              <mat-option [value]="integration.id" *ngFor="let integration of integrations">{{ integration.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="d-block" appearance="outline">
            <mat-label>Screensaver Sign</mat-label>
            <mat-select formControlName="screensaverId">
              <mat-option [value]="null">None</mat-option>
              <mat-option [value]="sign.id" *ngFor="let sign of signs">{{ sign.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="d-block" appearance="outline">
            <mat-label>Countries</mat-label>
            <mat-select [multiple]="true" formControlName="countryIds">
              <mat-option [value]="country.id" *ngFor="let country of countries">{{ country.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="d-block" appearance="outline">
            <mat-label>Banner Sign</mat-label>
            <mat-select formControlName="bannerId">
              <mat-option [value]="null">None</mat-option>
              <mat-option [value]="sign.id" *ngFor="let sign of signs">{{ sign.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="d-block" appearance="outline">
            <mat-label>Max Idle Time</mat-label>
            <input matInput min="1" step="1" type="number" formControlName="maxIdleTime">
          </mat-form-field>
          <mat-form-field class="d-block" appearance="outline">
            <mat-label>Screensaver Timeout</mat-label>
            <input matInput min="1" step="1" type="number" formControlName="screensaverTimeout">
          </mat-form-field>
          <ng-container formGroupName="preferences">

            <div formGroupName="ui">

              <mat-checkbox class="mr-4" formControlName="enableTags">Enable Tags</mat-checkbox>


              <mat-form-field class="d-block" appearance="outline">
                <mat-label>How to show Unavailable Products</mat-label>
                <mat-select formControlName="unavailableProductDisplay">
                  <mat-option [value]="upd.value" *ngFor="let upd of unavailableProductDisplay">{{ upd.label }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="d-block" appearance="outline">
                <mat-label>UI Mode</mat-label>
                <mat-select formControlName="mode">
                  <mat-option [value]="upd.value" *ngFor="let upd of selfCheckoutUIMode">{{ upd.label }}</mat-option>
                </mat-select>
              </mat-form-field>



              <mat-form-field class="d-block" appearance="outline">
                <mat-label>Start Menu at</mat-label>
                <input matInput type="number" formControlName="menuStartIndex">
              </mat-form-field>


            </div>
            <div formGroupName="order">
              <h5>Order Settings</h5>

  <!--            <mat-checkbox class="mr-4" formControlName="sendToShopify">Send orders to Shopify</mat-checkbox>-->
              <mat-checkbox class="mr-4" formControlName="enableNotification">Enable System Order Notifications</mat-checkbox>

              <mat-form-field class="d-block" appearance="outline">
                <mat-label>Inventory Behavior</mat-label>
                <mat-select formControlName="inventoryBehavior">
                  <mat-option [value]="invbev.value" *ngFor="let invbev of inventoryBehaviors">{{ invbev.label }}</mat-option>
                </mat-select>
              </mat-form-field>


              <mat-form-field class="d-block" appearance="outline">
                <mat-label>Fulfillment Behavior</mat-label>
                <mat-select formControlName="fulfillmentBehavior">
                  <mat-option [value]="invbev.value" *ngFor="let invbev of fulfillmentBehaviors">{{ invbev.label }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="d-block" appearance="outline">
                <mat-label>Marketing Behavior</mat-label>
                <mat-select formControlName="customerMarketingBehavior">
                  <mat-option [value]="markbev.value" *ngFor="let markbev of marketingBehaviors">{{ markbev.label }}</mat-option>
                </mat-select>
              </mat-form-field>


              <mat-form-field class="d-block" appearance="outline">
                <mat-label>Inventory Location</mat-label>
                <input matInput  formControlName="inventoryLocation">
              </mat-form-field>

              <div class="py-3">
                <h6>Include Tags</h6>
                <app-form-array-chips [control]="form.get('preferences.order.includeTags')"></app-form-array-chips>
              </div>

              <mat-form-field class="d-block" appearance="outline">
                <mat-label>Default Customer</mat-label>
                <input matInput type="email" formControlName="defaultCustomer">
                <mat-hint>
                  Add an email address to this field that will automatically be applied to customers who do not provide an email address.
                </mat-hint>
              </mat-form-field>

            </div>
            <div formGroupName="checkout">
  <!--            <mat-checkbox class="mr-4" formControlName="customerEmailRequired">Require Customer E-mail</mat-checkbox>-->
              <mat-checkbox class="mr-4" formControlName="forceAddSingleItem">Force add single item</mat-checkbox>


              <div class="py-3" formGroupName="fields">
                <h5>Customer Info</h5>
                <table class="table ">
                  <thead>
                  <tr>
                    <th>Field</th>
                    <th>Hide / Show / Required</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Full Name</td>
                    <td>
                      <mat-select formControlName="name">
                        <mat-option [value]="FieldOption.Hide">Hide</mat-option>
                        <mat-option [value]="FieldOption.Show">Show</mat-option>
                        <mat-option [value]="FieldOption.Required">Required</mat-option>
                      </mat-select>
                    </td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>
                      <mat-select formControlName="phone">
                        <mat-option [value]="FieldOption.Hide">Hide</mat-option>
                        <mat-option [value]="FieldOption.Show">Show</mat-option>
                        <mat-option [value]="FieldOption.Required">Required</mat-option>
                      </mat-select>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>
                      <mat-select formControlName="email">
                        <mat-option [value]="FieldOption.Hide">Hide</mat-option>
                        <mat-option [value]="FieldOption.Show">Show</mat-option>
                        <mat-option [value]="FieldOption.Required">Required</mat-option>
                      </mat-select>
                    </td>
                  </tr>

                  <tr>
                    <td>Shipping Address</td>
                    <td>
                      <mat-select formControlName="shippingAddress">
                        <mat-option [value]="FieldOption.Hide">Hide</mat-option>
                        <mat-option [value]="FieldOption.Show">Show</mat-option>
                        <mat-option [value]="FieldOption.Required">Required</mat-option>
                      </mat-select>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>


            </div>

            <!-- Shopify Preferences -->
            <div formGroupName="shopify">
              <h3>Shopify Preferences</h3>
              <mat-checkbox class="mr-4" formControlName="discountCodes">Enable Discount Codes</mat-checkbox>
            </div>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab *ngIf="!isNew" label="Kiosks">
      <div class="container-fluid mt-4">
        <mat-progress-bar style="top: 5px" class="position-absolute w-100" *ngIf="loadingActivation" mode="indeterminate"></mat-progress-bar>
        <div class="row">
          <div [ngClass]="activation ? 'col' : 'col-5'">
            <mat-selection-list [multiple]="false" (selectionChange)="editActivation($event.options[0].value)">
              <mat-list-option *ngFor="let kiosk of kiosks" [value]="kiosk">
                {{ kiosk.name }} ({{ kiosk.username }})
              </mat-list-option>
            </mat-selection-list>
          </div>
          <!-- Spin off and add all self order activation-specific fields with conditional switching here -->
          <div class="col-7">
            <mat-card *ngIf="activationForm" [formGroup]="activationForm">
              <div class="d-flex align-items-start justify-content-between">
                <mat-card-subtitle>{{ activation.kiosk.name }}</mat-card-subtitle>
                <app-create-update-button
                  [disabled]="activationForm.invalid || !activationForm.enabled"
                  [isNew]="false"
                  (update)="updateActivation()">
                </app-create-update-button>
              </div>
              <mat-card-content>
                <mat-form-field class="d-block" appearance="outline">
                  <mat-label>Type</mat-label>
                  <mat-select formControlName="type">
                    <mat-option value="default">Self Order Kiosk</mat-option>
                    <mat-option value="pos">Point of Sale</mat-option>
                    <mat-option value="omd">Order Management Display</mat-option>
                    <mat-option value="osd">Order Status Display</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="d-block" appearance="outline">
                  <mat-label>Payment Terminal</mat-label>
                  <mat-select formControlName="paymentTerminalId">
                    <mat-option>-- None --</mat-option>
                    <mat-optgroup *ngFor="let group of paymentTerminalGroups" [label]="group.name" >
                      <mat-option [value]="paymentTerminal.id" *ngFor="let paymentTerminal of group.terminals">{{ paymentTerminal.name }}</mat-option>

                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>


                <ng-container formGroupName="data">
                  <h6>Additional Printers</h6>
                  <mat-form-field class="d-block" appearance="outline">
                    <mat-label>Printer</mat-label>
                    <mat-select formControlName="printerName" placeholder="No Printer">
                      <mat-option [value]="null">No Printer</mat-option>
                      <mat-option [value]="printer.name" *ngFor="let printer of activation.kiosk.lastState?.printers">{{ printer.displayName }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="container-fluid p-0 small" formArrayName="printers">
                    <div class="row" *ngFor="let printerForm of getFormArray(activationForm, ['data', 'printers'])?.controls; let i = index" [formGroupName]="i">
                      <mat-form-field class="col-4 pr-0" appearance="outline" >
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name">
                      </mat-form-field>
                      <mat-form-field class="col-6 pr-0" appearance="outline">
                        <mat-label>Printers</mat-label>
                        <mat-select formControlName="printerName" placeholder="No Printer">
                          <mat-option [value]="null">No Printer</mat-option>
                          <mat-option [value]="printer.name" *ngFor="let printer of activation.kiosk.lastState?.printers">{{ printer.displayName }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <button class="col-2" style="
                        margin-top: 4px;
                        transform: scale(0.7);
                      " mat-icon-button color="warn" (click)="getFormArray(activationForm, ['data', 'printers']).removeAt(i)"><mat-icon>delete</mat-icon></button>
                    </div>
                    <a mat-stroked-button color="primary" (click)="getFormArray(activationForm, ['data', 'printers'])?.push(createPrinter({ name: null, printerName: null }))">Add Printer</a>
                  </div>
                </ng-container>

              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="!isNew" label="Extras">
      <mat-card class="mt-2">
        <mat-card-content [formGroup]="form.get('preferences.shopify')">
          <mat-form-field class="d-block" appearance="outline">
            <mat-label>Mulberry Token</mat-label>
            <input matInput formControlName="mulberryToken">
          </mat-form-field>
          <mat-form-field class="d-block" appearance="outline">
            <mat-label>Parcelport Token</mat-label>
            <input matInput formControlName="parcelportToken">
          </mat-form-field>
          <mat-form-field class="d-block" appearance="outline">
            <mat-label>Penguin Lockers Token</mat-label>
            <input matInput formControlName="penguinLockersToken">
          </mat-form-field>
          <mat-checkbox formControlName="hideCollectionImage">Hide Collection Images</mat-checkbox>
          <ng-container formGroupName="localDelivery">
            <hr>
            <mat-checkbox class="d-block" #shopifyLocalDelivery formControlName="enabled"></mat-checkbox> Shopify Local Delivery
            <ng-container *ngIf="shopifyLocalDelivery.checked">
              <mat-form-field appearance="outline">
                <mat-label>via Atom Yard Local Delivery</mat-label>
                <input matInput formControlName="atomYardConfigUrl">
              </mat-form-field>

<!--                <app-form-array-chips [control]="form.get('preferences.shopify.localDelivery.postalCodes')"></app-form-array-chips>-->

<!--              <mat-form-field>-->
<!--                <mat-label>Minimum Order Price</mat-label>-->
<!--                <input matInput formControlName="minOrderPrice" type="number">-->
<!--              </mat-form-field>-->
<!--              <mat-form-field>-->
<!--                <mat-label>Delivery Price</mat-label>-->
<!--                <input matInput formControlName="deliveryPrice" type="number">-->
<!--              </mat-form-field>-->
              <hr>
            </ng-container>
          </ng-container>

        </mat-card-content>
      </mat-card>
    </mat-tab>
  </mat-tab-group>
</ng-container>
