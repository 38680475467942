import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PhotoboothRoutingModule } from './photobooth-routing.module';
import { PhotoboothListComponent } from './photobooth-list/photobooth-list.component';
import { PhotoboothDetailComponent } from './photobooth-detail/photobooth-detail.component';

import { SharedModule } from 'esuite-dashboard/app/shared/shared.module';
import { PhotoboothFrameDetailComponent } from './photobooth-frame-detail/photobooth-frame-detail.component';

@NgModule({
  declarations: [
    PhotoboothListComponent,
    PhotoboothDetailComponent,
    PhotoboothFrameDetailComponent,
  ],
  imports: [
    CommonModule,
    PhotoboothRoutingModule,

    SharedModule,
  ],
})
export class PhotoboothModule {}
