<mat-card class="row" style="box-shadow: none !important; margin: 0 0; padding: 0 0;">
  <mat-card-content class="overflow-auto">

    <table *ngIf="(displayedCols$ | async) as displayedCols" mat-table class="w-100"
           [dataSource]="rows$">

      <ng-container matColumnDef="selector">

        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Dynamic Cols -->
      <ng-container
        [matColumnDef]="'col.' + i"
        *ngFor="let resourceCol of config.cols; let i = index;">

        <th mat-header-cell *matHeaderCellDef>{{ resourceCol.header | titlecase }}</th>

        <!-- Dynamic cols -->
        <td  mat-cell  *matCellDef="let element; let elementInd = index;">
          <span *ngIf="!resourceCol.link" (click)="handleCellClick(element, resourceCol, $event)" [innerHTML]="cellRenderer(element, resourceCol) | async"></span>
          <ng-container *ngIf="makeCellLink(element, resourceCol) as linkOptions">
            <a [queryParams]="linkOptions.queryParams" *ngIf="resourceCol.link" [routerLink]="linkOptions.commands ? linkOptions.commands : linkOptions" (click)="handleCellClick(element, resourceCol, $event)" [innerHTML]="cellRenderer(element, resourceCol) | async"></a>
          </ng-container>
        </td>
      </ng-container>

      <!-- Action Cols -->

      <ng-container matColumnDef="action.view">
        <th mat-header-cell *matHeaderCellDef>View</th>
        <td  mat-cell  *matCellDef="let element; let elementInd = index;">
          <button (click)="handleView(element, elementInd)" mat-icon-button color="primary">
            <mat-icon>visibility</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="action.edit">
        <th mat-header-cell *matHeaderCellDef>Edit</th>
        <td  mat-cell  *matCellDef="let element; let elementInd = index;">
          <button (click)="handleEdit(element, elementInd)" mat-icon-button color="primary">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="action.delete">
        <th mat-header-cell *matHeaderCellDef>Delete</th>
        <td  mat-cell  *matCellDef="let element; let elementInd = index;">
          <button (click)="handleDelete(element, elementInd)" mat-icon-button color="warn">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="links">
        <th mat-header-cell *matHeaderCellDef> - </th>
        <td  mat-cell  *matCellDef="let element; let elementInd = index;">
          <button *ngFor="let link of config.links" (click)="link.onClick(element, elementInd, rows)" mat-button color="primary">
            <span [innerHTML]="link.label"></span>
          </button>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedCols"></tr>
      <tbody>
      <tr mat-row *matRowDef="let element; columns: displayedCols;"></tr>
      </tbody>

    </table>
    <p class="text-center mt-3" *ngIf="!showLoader && rows.length === 0">{{ emptyMessage }}</p>
    <div class="p-3" *ngIf="showLoader" >
      <mat-spinner class="mx-auto" [diameter]="20"></mat-spinner>
    </div>
    <!--      <mat-paginator *ngIf="cadView._paginator?.enabled" [pageSizeOptions]="cadView._paginator.pageSizeOptions"></mat-paginator>-->
  </mat-card-content>
  <mat-card-footer>
    <mat-paginator *ngIf="rows.pagination && rows.pagination.count > rows.pagination.limit" [length]="rows.pagination.count"
                   [pageSize]="rows.pagination.limit"
                   (page)="handlePage($event)">
    </mat-paginator>
  </mat-card-footer>
</mat-card>
