/*
 * AdmissionTemplateListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceActionColumn,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceListViewRow,
} from 'cadence';
import { AdmissionTemplateCreateForm } from './admission-template-create';
import { AdmissionTemplateUpdateForm } from './admission-template-update';
import { AdmissionTemplateDeleteForm } from './admission-template-delete';
import { getEsuiteApi } from '../../../../app/api/esuite-api';

@CadenceView()
export class AdmissionTemplateListView extends CadenceListViewModel {
  @CadenceColumn()
  id: number;

  @CadenceColumn()
  name: string;

  @CadenceAction({ label: 'Create' })
  createForm(): void {
    cadenceRouter.navigate(AdmissionTemplateCreateForm, {
      from: this,
      events: {
        'action.create': 'created',
      },
    });
  }

  @CadenceActionColumn({ label: 'Delete' })
  deleteForm(row: CadenceListViewRow<AdmissionTemplateListView>): void {
    cadenceRouter.navigate(AdmissionTemplateDeleteForm, {
      from: this,
      events: {
        'action.delete': 'deleted',
      },
      params: {
        id: row.id,
      },
    });
  }

  @CadenceActionColumn({ label: 'Edit' })
  updateForm(row: CadenceListViewRow<AdmissionTemplateListView>): void {
    cadenceRouter.navigate(AdmissionTemplateUpdateForm, {
      from: this,
      params: {
        id: row.id,
        name: row.name,
      },
      events: {
        'action.update': 'updated',
      },
    });
  }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  @CadenceViewEvent('updated')
  update(event: any): void {
    this._rows$.updateBy((c) => c.id === event.data.id, event.data);
  }

  @CadenceViewEvent('deleted')
  remove(event: any): void {
    this._rows$.deleteBy((c) => c.id === event.from._params.id);
  }

  async _init(): Promise<void> {
    this._rows$.set(await getEsuiteApi().admissionTemplate.list());
  }
}
