import { pipe } from 'rxjs';
import { filter, map, pluck } from 'rxjs/operators';

/* Takes a parameter map and returns either 'new' or an integer or nothing if no proper number is available. */
export const intOrNewFromParams = (key: string) =>
  pipe(
    pluck(key),
    // Use the new keyword to indicate a -1 id, which should indicate to create a new resource
    map((p: string) => {
      const id = p === 'new' ? -1 : parseInt(p, 10);
      if (isNaN(id)) {
        throw new Error(
          'Non-number string passed to intOrNewFromParams: ' + id
        );
      }
      return id;
    })
  );
