/*
 * AdmissionOptionUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../../app/api/esuite-api';
import { CustomProductOptionType } from 'esuite-client';

interface IAdmissionOptionUpdateFormParams {
  id: number;
  admissionId: number;
}

@CadenceView()
export class AdmissionOptionValueForm extends CadenceFormViewModel {
  @CadenceFormControl({ classes: 'w-50' })
  name: string;

  @CadenceFormControl({ classes: 'w-50' })
  label: string;

  @CadenceFormControl({ required: false, classes: 'w-75' })
  answer: string;

  @CadenceFormControl(CadenceFormControlType.Number, {
    required: false,
    classes: 'w-25',
  })
  quantity: number;
}

@CadenceView({
  title: 'Update Admission Option',
})
export class AdmissionOptionUpdateForm extends CadenceFormViewModel<IAdmissionOptionUpdateFormParams> {
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  @CadenceFormControl()
  name: string;

  @CadenceFormControl(CadenceFormControlType.Select, {
    options: () => [
      { label: 'Question', value: 'question' },
      { label: 'Quantity', value: 'quantity' },
    ],
  })
  type: CustomProductOptionType;

  @CadenceFormControl({ required: false })
  question: string;

  @CadenceFormControl({ required: false })
  descriptionHtml: string;

  @CadenceFormControl(CadenceFormControlType.Number, {
    required: false,
    classes: 'w-50',
  })
  priceAdjustment: number;

  @CadenceFormControl(CadenceFormControlType.Number, {
    required: false,
    classes: 'w-50',
  })
  min: number;

  @CadenceFormControl(CadenceFormControlType.Number, {
    required: false,
    classes: 'w-50',
  })
  max: number;

  @CadenceFormControl(CadenceFormControlType.Checkbox, {
    required: false,
    classes: 'w-50',
  })
  required: boolean;

  @CadenceFormControl(CadenceFormControlType.Checkbox, {
    required: false,
    classes: 'w-50',
  })
  print: boolean;

  @CadenceFormControl(CadenceFormControlType.Select, {
    required: false,
    options: async () => {
      return [
        { label: 'None', value: null },
        ...(await getEsuiteApi().admissionTemplate.list()).map((tpl) => {
          return {
            label: tpl.name,
            value: tpl.id,
          };
        }),
      ];
    },
  })
  notificationId: number;

  @CadenceFormAction({
    label: 'Update',
  })
  async update(): Promise<any> {
    return getEsuiteApi().admissionOption.update(
      this._params.admissionId,
      this.id,
      {
        type: this.type,
        name: this.name,
        question: this.question,
        descriptionHtml: this.descriptionHtml,
        priceAdjustment: this.priceAdjustment,
        min: this.min,
        max: this.max,
        required: this.required,
        print: this.print,
        notificationId: this.notificationId,
      },
    );
  }

  @CadenceViewEvent('action.update')
  afterUpdate(): void {
    this._postEvent('dismissDialog');
  }

  async _init() {
    const resp = await getEsuiteApi().admissionOption.get(
      this._params.id,
      this._params.admissionId,
    );
    this._controlData$.next({
      ...resp,
    });
  }
}
