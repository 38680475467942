import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  DigitalSignageResponse,
  SelfCheckoutIntegrationResponse,
  ShopifyCheckoutCreateRequest,
  ShopifyCheckoutPOSType,
  ShopifyCheckoutResponse,
  ShopifyCheckoutUpdateRequest,
} from 'esuite-client';
import { AbstractFormComponent } from '../../shared/classes/abstract-form.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from '../../shared/components/alert/alert.service';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { quickEnumToOptions } from 'esuite-dashboard/app/shared/utils/quick-enum-to-options';
import { ShopifyCheckoutMode } from 'esuite-client';
import { ShopifyCheckoutMenuEditorComponent } from 'esuite-dashboard/app/pages/shopify-checkout/shopify-checkout-menu-editor/shopify-checkout-menu-editor.component';

@Component({
  selector: 'app-shopify-checkout-detail',
  template: `<mat-card *ngIf="form$ | async as form" [formGroup]="form">
    <mat-card-content>
      <div class="d-flex align-items-center justify-content-between mb-0">
        <span class="h5 m-0"
          >{{ id === -1 ? 'Create' : 'Update' }} Checkout</span
        >
        <div>
          <!--        <app-kiosk-preview-launcher *ngIf="!isNew" [app]="" [id]="id"></app-kiosk-preview-launcher>-->
          <app-create-update-button [formComponent]="[this, form]" label="App">
          </app-create-update-button>
        </div>
      </div>
      <hr />

      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>

      <mat-form-field class="d-block" appearance="outline">
        <mat-label>Mode</mat-label>
        <mat-select formControlName="mode">
          <mat-option [value]="mode.value" *ngFor="let mode of modes">{{
            mode.label
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="d-block" appearance="outline">
        <mat-label>Integration</mat-label>
        <mat-select formControlName="integrationId">
          <mat-option [value]="null">None</mat-option>
          <mat-option
            [value]="integration.id"
            *ngFor="let integration of integrations"
            >{{ integration.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <!-- Banner -->
      <mat-form-field class="d-block" appearance="outline">
        <mat-label>Banner</mat-label>
        <mat-select formControlName="bannerId">
          <mat-option [value]="null">None</mat-option>
          <mat-option [value]="sign.id" *ngFor="let sign of signs">{{
            sign.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Screensaver -->
      <mat-form-field class="d-block" appearance="outline">
        <mat-label>Screensaver</mat-label>
        <mat-select formControlName="screensaverId">
          <mat-option [value]="null">None</mat-option>
          <mat-option [value]="sign.id" *ngFor="let sign of signs">{{
            sign.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Web Options -->
      <ng-container *ngIf="form.get('mode').value === ShopifyCheckoutMode.Web">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Manual Payment Method</mat-label>
          <input matInput formControlName="manualPaymentName" />
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Start Page</mat-label>
          <input matInput formControlName="startPage" type="url" />
        </mat-form-field>
      </ng-container>

      <!-- Kiosk Options -->
      <ng-container
        *ngIf="
          formMode === 'update' &&
          form.get('mode').value === ShopifyCheckoutMode.Kiosk
        "
      >
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Inventory Location</mat-label>
          <input matInput formControlName="inventoryLocation" />
        </mat-form-field>

        <button
          *ngIf="!isNew"
          mat-raised-button
          color="primary"
          (click)="editMenu()"
        >
          Edit Menu
        </button>
        <hr />

        <div formGroupName="config">
          <mat-slide-toggle formControlName="catalogEnabled"
            >Show Catalog</mat-slide-toggle
          >
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card> `,
  styles: [],
})
export class ShopifyCheckoutDetailComponent
  extends AbstractFormComponent<
    ShopifyCheckoutResponse,
    ShopifyCheckoutCreateRequest,
    ShopifyCheckoutUpdateRequest
  >
  implements OnInit
{
  constructor(
    private fb: FormBuilder,
    activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private alertService: AlertService
  ) {
    super();
  }
  private shopifyCheckout: ShopifyCheckoutResponse;
  @Output()
  create: EventEmitter<ShopifyCheckoutResponse> = new EventEmitter<ShopifyCheckoutResponse>();

  @Output()
  update: EventEmitter<ShopifyCheckoutResponse> = new EventEmitter<ShopifyCheckoutResponse>();

  integrations: SelfCheckoutIntegrationResponse[] = [];
  signs: DigitalSignageResponse[] = [];
  modes = quickEnumToOptions(ShopifyCheckoutMode);
  ShopifyCheckoutMode = ShopifyCheckoutMode;
  ShopifyCheckoutPOSType = ShopifyCheckoutPOSType;
  JSON = JSON;
  integratedPos = false;
  posType = quickEnumToOptions(ShopifyCheckoutPOSType);
  syncingPOS = false;

  handleCreate(dto: ShopifyCheckoutCreateRequest): void {
    this.alertService
      .runWithLoader(
        () => getEsuiteApi().shopifyCheckout.create(dto),
        'ShopifyCheckout created successfully.',
        'Error creating app.'
      )
      .then((r) => this.create.emit(r));
  }

  handleUpdate(dto: ShopifyCheckoutUpdateRequest): void {
    this.alertService
      .runWithLoader(
        () => getEsuiteApi().shopifyCheckout.update(this.id, dto),
        'ShopifyCheckout updated successfully.',
        'Error creating app.'
      )
      .then((r) => this.update.emit(r));
  }

  async loadFormData(id: number): Promise<void> {}

  async ngOnInit(): Promise<void> {
    const [integrations, signs] = await Promise.all([
      getEsuiteApi().selfCheckoutIntegration.list(),
      getEsuiteApi().digitalSignage.list(),
    ]);
    this.integrations = integrations;
    this.signs = signs;
  }

  makeCreateForm(): Promise<FormGroup> | FormGroup {
    return this.fb.group({
      name: [null, Validators.required],
      mode: [ShopifyCheckoutMode.Kiosk, Validators.required],
      manualPaymentName: [null],
      startPage: [null],
      integrationId: [null],
      bannerId: [null],
      collectionTree: [[]],
      inventoryLocation: [null],
    });
  }

  async makeUpdateForm(id: number): Promise<FormGroup> {
    this.shopifyCheckout = await getEsuiteApi().shopifyCheckout.get(id);
     ;
    return this.fb.group({
      name: [this.shopifyCheckout.name, Validators.required],
      mode: [this.shopifyCheckout.mode, Validators.required],
      manualPaymentName: [this.shopifyCheckout.manualPaymentName],
      startPage: [this.shopifyCheckout.startPage],
      integrationId: [this.shopifyCheckout.integration?.id],
      bannerId: [this.shopifyCheckout.bannerId],
      screensaverId: [this.shopifyCheckout.screensaverId],
      collectionTree: [this.shopifyCheckout.collectionTree],
      inventoryLocation: [this.shopifyCheckout.inventoryLocation],
      config: this.fb.group({
        catalogEnabled: [this.shopifyCheckout.config?.catalogEnabled],
      }),
      pos: this.fb.group({
        type: [this.shopifyCheckout.pos?.type ?? ShopifyCheckoutPOSType.None],
        globalTill: this.fb.group({
          apiKey: [this.shopifyCheckout.pos?.globalTill?.apiKey],
          storeId: [this.shopifyCheckout.pos?.globalTill?.storeId],
          locationId: [this.shopifyCheckout.pos?.globalTill?.locationId],
        }),
      }),
    });
  }

  editMenu() {
    const modalRef = this.dialog.open(ShopifyCheckoutMenuEditorComponent, {
      width: '80vw',
      height: '80vh',
    });
    modalRef.componentInstance.collectionTree =
      this.form.get('collectionTree').value;
    modalRef.componentInstance.inventoryLocation =
      this.form.get('inventoryLocation').value;
    modalRef.componentInstance.integrationId =
      this.form.get('integrationId').value;
    modalRef
      .afterClosed()
      .toPromise()
      .then((c) => {
        if (c) {
          this.form.get('collectionTree').setValue(c);
        }
      });
  }
}
