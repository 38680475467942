/*
 * DigitalSignageFrameListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceActionColumn,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceListViewRow,
} from 'cadence';
import { DigitalSignageFrameCreateForm } from './digital-signage-frame-create';
import { DigitalSignageFrameUpdateForm } from './digital-signage-frame-update';
import { DigitalSignageFrameDetailView } from './digital-signage-frame-detail';
import { getEsuiteApi } from '../../../../app/api/esuite-api';
import { DigitalSignageFrameResponse } from 'esuite-client';

@CadenceView({
  title: 'Digital Signage Frames',
})
export class DigitalSignageFrameListView extends CadenceListViewModel<
  DigitalSignageFrameResponse,
  {
    signageId: number;
  }
> {
  @CadenceColumn()
  id: number;

  @CadenceColumn()
  name: string;

  @CadenceAction({ label: 'Create' })
  createForm(): void {
    cadenceRouter.navigate(DigitalSignageFrameCreateForm, {
      from: this,
      events: {
        'action.create': 'created',
      },
      params: {
        signageId: this._params.signageId,
      },
    });
  }

  @CadenceActionColumn({ label: 'Delete' })
  async deleteForm(
    row: CadenceListViewRow<DigitalSignageFrameListView>,
  ): Promise<void> {
    const confirmDelete = confirm(
      'Are you sure you want to delete this frame?',
    );
    if (confirmDelete) {
      await getEsuiteApi().digitalSignageFrame.remove(
        this._params.signageId,
        row.id,
      );
      this._rows$.deleteBy((c) => c.id === row.id);
    }
  }

  @CadenceActionColumn({ label: 'Edit' })
  updateForm(row: CadenceListViewRow<DigitalSignageFrameListView>): void {
    cadenceRouter.navigate(DigitalSignageFrameDetailView, {
      from: this,
      params: {
        signageId: this._params.signageId,
        frame: row,
      },
      events: {
        'action.update': 'updated',
      },
    });
  }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  @CadenceViewEvent('updated')
  update(event: any): void {
    this._rows$.updateBy((row) => row.id === event.data.id, event.data);
  }

  async _init(): Promise<void> {
    this._rows$.set(
      await getEsuiteApi().digitalSignageFrame.list(this._params.signageId),
    );
  }
}
