<ng-container *ngIf="form$ | async as form" >

  <mat-card-title>{{ isNew ? 'Create' : 'Update' }} Media Collection</mat-card-title>

  <app-create-update-button
    class="form-submit-btn"
    [disabled]="form.invalid || !form.enabled"
    [isNew]="isNew"
    (create)="handleCreate(form.value)"
    (update)="handleUpdate(form.value)" label="Media Collections">
  </app-create-update-button>

  <mat-tab-group [formGroup]="form">

    <mat-tab label="Setup">
      <mat-card>
        <mat-card-actions>
          <button mat-stroked-button color="primary"  (click)="sync()" *ngIf="!isNew">Sync Photos</button>
        </mat-card-actions>
        <mat-card-content>
          <mat-form-field class="d-block" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </mat-tab>

    <mat-tab label="S3 Settings">
      <mat-card>
        <mat-card-content>
          <ng-container formGroupName="syncRecord">
            <mat-form-field class="d-block" appearance="outline">
              <mat-label>accessKeyId</mat-label>
              <input matInput formControlName="accessKeyId">
            </mat-form-field>
            <mat-form-field class="d-block" appearance="outline">
              <mat-label>secretAccessKey</mat-label>
              <input matInput formControlName="secretAccessKey">
            </mat-form-field>
            <mat-form-field class="d-block" appearance="outline">
              <mat-label>endpoint</mat-label>
              <input matInput formControlName="endpoint">
            </mat-form-field>
            <mat-form-field class="d-block" appearance="outline">
              <mat-label>bucket</mat-label>
              <input matInput formControlName="bucket">
            </mat-form-field>
            <mat-form-field class="d-block" appearance="outline">
              <mat-label>path</mat-label>
              <input matInput formControlName="path">
            </mat-form-field>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </mat-tab>

    <mat-tab label="Access Codes">
      <div class="mt-4">
        <button color="primary" mat-raised-button (click)="createAccessCode()">Create Access Code</button>
        <app-resource-list #accessCodeList [config]="accessCodeListConfig"></app-resource-list>
      </div>
    </mat-tab>

  </mat-tab-group>
</ng-container>
