import { Injectable } from '@angular/core';
import {
  EsuiteApp,
  KioskResponse,
} from 'esuite-client';
import { getEsuitePlatformApi } from '../../api/esuite-platform-api';
import { environment } from 'esuite-dashboard/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KioskPreviewService {
  async previewKiosk(
    kiosk: KioskResponse,
    activation?: { app: EsuiteApp; id: number, width?: number, height?: number }
  ): Promise<void> {
    const platformClient = getEsuitePlatformApi();

    let url = environment.adminUrl + '/player';
    if (activation) {
      url = url + `/${activation.app}/${activation.id}?preview=1`;
    }
    await platformClient.preview.configure({
      width: activation?.width ?? 1080,
      height: activation?.height ?? 1920,
      url,
      username: kiosk.username,
      password: kiosk.secret,
    });
  }
}
