import { getCadence } from '../../cadence';
import { startCase } from 'lodash';
import { CadenceObjectType, ICadenceFormActionMetadata } from '../../types';
import { CadenceFormViewModel } from 'cadence';
import { Observable } from 'rxjs';

// Form Actions
export interface ICadenceFormActionOptions<From> {
  label?: string;
  eventName?: string;
  hidden?:
    | boolean
    | ((form: From) => Observable<boolean> | Promise<boolean> | boolean);
}

export function CadenceFormAction<From extends CadenceFormViewModel>(
  formActionOptions: ICadenceFormActionOptions<From> = {},
): PropertyDecorator {
  return (target: From, method: string) => {
    const targetName = target.constructor.name;
    const cadenceFormAction: ICadenceFormActionMetadata<From> = {
      name: method,
      label: startCase(method),
      eventName: `action.${method}`,
      ...formActionOptions,
    };
    getCadence().formAction.set(targetName, method, cadenceFormAction);
  };
}
