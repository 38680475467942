/*
 * AgreementUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';

interface IAgreementUpdateFormParams {
  id: number;
}

@CadenceView({
  title: 'Update Agreement',
})
export class AgreementUpdateForm extends CadenceFormViewModel<IAgreementUpdateFormParams> {
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  @CadenceFormControl()
  name: string;

  @CadenceFormControl(CadenceFormControlType.ContentEditor)
  html: string;

  @CadenceFormAction({
    label: 'Update',
  })
  async update(): Promise<any> {
    return getEsuiteApi().agreement.update(this.id, {
      name: this.name,
      html: this.html,
    });
  }

  @CadenceViewEvent('action.update')
  afterUpdate(): void {
    this._postEvent('dismissDialog');
  }

  async _init(): Promise<void> {
    this._controlData$.next(
      await getEsuiteApi().agreement.get(this._params.id),
    );
  }
}
