import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivationResponse } from 'esuite-client/dist/esuite-api/models/ActivationResponse';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DirectoryActivationData } from 'esuite-dashboard/app/pages/v2/directory/activations/directory-activation-list/directory-activation-list.component';
import { Activation, DirectoryFloor } from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-directory-activation-detail',
  templateUrl: './directory-activation-detail.component.html',
  styles: [],
})
export class DirectoryActivationDetailComponent implements OnInit {
  activation$ = of(this.data.activationId).pipe(
    switchMap((activationId) => getEsuiteApi().activation.get(activationId))
  );
  form$: Observable<FormGroup> = this.activation$.pipe(
    map((activation) => this.makeActivationForm(activation))
  );
  floors: DirectoryFloor[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { activationId: number; directoryId: number },
    private fb: FormBuilder,
    private alertService: AlertService,
    private activeDialog: MatDialogRef<DirectoryActivationDetailComponent>
  ) {}

  ngOnInit(): void {
    getEsuiteApi()
      .directoryFloor.list(this.data.directoryId)
      .then((floors) => (this.floors = floors));
  }

  private makeActivationForm(activation: Activation) {
    const data: DirectoryActivationData = activation.data as any;
    return this.fb.group({
      floorX: [data?.floorX],
      floorY: [data?.floorY],
      primaryLayout: [data?.primaryLayout || 'alpha'],
      floorIds: [data?.floorIds || []],
    });
  }

  async handleUpdate(form: FormGroup) {
    form.disable();
    const update = {
      data: form.value,
    };
    const updated = await this.alertService
      .runWithLoader(
        () => getEsuiteApi().activation.update(this.data.activationId, update),
        'Kiosk updated successfully.',
        'Error updating kiosk activation.'
      )
      .finally(() => form.enable());
    this.activeDialog.close(updated);
  }
}
