<mat-card *ngIf="(form$ | async) as form" [formGroup]="form">
  <mat-card-subtitle>
    <div class="d-flex align-items-center justify-content-between">
      <span>{{ isNew ? 'Create Template' : 'Update ' + form.get('name').value }} </span>
      <app-create-update-button [formComponent]="[this, form]" label="Template">
      </app-create-update-button>
    </div>
  </mat-card-subtitle>
  <mat-card-content>

    <mat-form-field appearance="outline" class="d-block mt-3">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="outline" class="d-block mt-3">
      <mat-label>Target</mat-label>
      <mat-select formControlName="target">
        <mat-option [value]="t.value" *ngFor="let t of templateTargets">{{ t.label }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="d-block mt-3">
      <mat-label>Self Checkouts</mat-label>
      <mat-select formControlName="selfCheckoutIds" [multiple]="true">
        <mat-option [value]="sc.id" *ngFor="let sc of selfCheckouts">{{ sc.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <app-template-editor
      [value]="form.get('template').value"
      (valueChange)="form.get('template').setValue($event)">

    </app-template-editor>

  </mat-card-content>
</mat-card>
