<mat-card *ngIf="(form$ | async) as form">
  <mat-card-title>
    Create Device
  </mat-card-title>

  <mat-card-content [formGroup]="form">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>IP Address</mat-label>
      <input matInput type="text" formControlName="ipAddress">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Device</mat-label>
      <mat-select formControlName="device">
        <mat-option [value]="device.value" *ngFor="let device of deviceTypes">{{ device.label }}</mat-option>
      </mat-select>
    </mat-form-field>


    <ng-container [ngSwitch]="gateway.type">
      <ng-container *ngSwitchCase="PaymentGatewayType.Moneris" formGroupName="moneris">
        <h3>Moneris Device</h3>
        <mat-form-field appearance="outline">
          <mat-label>ECR ID</mat-label>
          <input matInput type="text" formControlName="ecrId">
        </mat-form-field>
      </ng-container>
      <ng-container *ngSwitchCase="PaymentGatewayType.Openedge" formGroupName="openedge">
        <h3>OpenEdge Device</h3>
        <mat-form-field appearance="outline">
          <mat-label>Web Terminal ID</mat-label>
          <input matInput type="text" formControlName="webTerminalId">
        </mat-form-field>
      </ng-container>
    </ng-container>

  </mat-card-content>

  <mat-card-actions>
    <app-create-update-button
      [disabled]="form.invalid || !form.enabled"
      [isNew]="isNew"
      (create)="handleCreate(form.value)"
      (update)="handleUpdate(form.value)" label="Device">
    </app-create-update-button>
  </mat-card-actions>

</mat-card>
