import { BehaviorSubject, Observable } from 'rxjs';

export class ImmutableCollection<T = any> {
  private subject: BehaviorSubject<T[]>;

  get value$(): Observable<T[]> {
    return this.subject.asObservable();
  }

  get value(): T[] {
    return this.subject.value;
  }

  constructor() {
    this.subject = new BehaviorSubject([]);
  }

  add(item: T): void {
    if (item) {
      this.subject.next([...this.value, item]);
    }
  }

  update(itemInd: number, item: T): void {
    this.subject.next([
      ...this.subject.value.slice(0, itemInd),
      item,
      ...this.subject.value.slice(itemInd + 1),
    ]);
  }

  delete(item: T | number): void {
     ;
    const itemInd =
      typeof item === 'number' ? item : this.subject.value.indexOf(item);
    if (this.subject.value.length === 1 && itemInd === 0) {
       ;
      this.subject.next([]);
       ;
    } else if (itemInd !== -1) {
      this.subject.next([
        ...this.subject.value.slice(0, itemInd),
        ...this.subject.value.slice(itemInd + 1),
      ]);
    }
  }

  deleteBy(predicate: (item: T) => unknown): void {
    const itemInd = this.subject.value.findIndex(predicate);
    return this.delete(itemInd);
  }

  updateBy(predicate: (item: T) => unknown, update: T): void {
    const itemInd = this.subject.value.findIndex(predicate);
    return this.update(itemInd, update);
  }

  set(items: T[]): void {
    this.subject.next(items);
  }

  swap(firstIndex, secondIndex): void {
    this.set(
      this.value.map((element, index) => {
        if (index === firstIndex) return this.value[secondIndex];
        else if (index === secondIndex) return this.value[firstIndex];
        else return element;
      }),
    );
  }
}
