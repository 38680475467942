<mat-card class="mb-4">
  <img
    style="width: 150px;"
    mat-card-image
    *ngIf="image && !uploadingSrc"
    [src]="image.url">
  <img
    style="width: 150px;"
    mat-card-image
    *ngIf="uploadingSrc"
    [class.uploading]="loading"
    [src]="(uploadingSrc | safe:'url')">
  <div *ngIf="loading" class="loading-overlay">
    <mat-spinner [diameter]="20" ></mat-spinner>
  </div>
  <button
    [disabled]="loading"
    style="position: absolute; top: 10px; left: 10px; z-index: 10"
    mat-mini-fab color="warn" (click)="remove()" *ngIf="!removed && image">
    <mat-icon>
      delete
    </mat-icon>
  </button>
  <button
    [disabled]="loading"
    style="position: absolute; top: 10px; left: 10px; z-index: 10"
    mat-mini-fab color="primary" (click)="undoRemove()" *ngIf="removed">
    <mat-icon>
      undo
    </mat-icon>
  </button>
  <mat-card-content>
    <ngx-file-drop
      class="col"
      (onFileDrop)="startUpload($event)"
      dropZoneLabel="Drop files here"
      accept=".png,.jpg,.jpeg,.gif"
      [multiple]="false">
      <ng-template
        ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector">
        <h6>{{ label }}</h6>
        <div>
          <button [disabled]="loading" mat-stroked-button class="m-1" (click)="openFileSelector()">
            Browse Files
          </button>
<!--          <button [disabled]="loading" mat-stroked-button color="primary" class="m-1" (click)="openMediaLibrary()">-->
<!--            Media Library-->
<!--          </button>-->
        </div>
      </ng-template>
    </ngx-file-drop>
  </mat-card-content>
</mat-card>
