/*
 * PaymentGatewayCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  cadenceRouter,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { PaymentGatewayListView } from './payment-gateway-list';
import { PaymentGatewayType } from 'esuite-client';
import { getEsuiteApi } from '../../../../app/api/esuite-api';
import { quickEnumToOptions } from '../../../../app/shared/utils/quick-enum-to-options';

interface IPaymentGatewayCreateFormParams {}

@CadenceView({
  title: 'Create Payment Gateway',
  viewType: 'dialog',
})
export class PaymentGatewayCreateForm extends CadenceFormViewModel<IPaymentGatewayCreateFormParams> {
  @CadenceFormControl()
  name: string;

  @CadenceFormControl(CadenceFormControlType.Select, {
    options: () => {
      return quickEnumToOptions(PaymentGatewayType);
    },
  })
  type: PaymentGatewayType;

  // @CadenceFormControl()
  // merchantName: string;
  //
  // @CadenceFormControl()
  // merchantSiteId: string;
  //
  // @CadenceFormControl()
  // merchantKey: string;

  @CadenceFormAction({
    label: 'Create',
  })
  async create(): Promise<any> {
    /* Create logic goes here */
    return getEsuiteApi().paymentGateway.create({
      name: this.name,
      type: this.type,
      // cayan: {
      //   merchantName: this.merchantName,
      //   merchantSiteId: this.merchantSiteId,
      //   merchantKey: this.merchantKey,
      // },
    });
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    cadenceRouter.navigate(PaymentGatewayListView, {
      from: this,
    });
  }
}
