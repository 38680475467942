import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from 'esuite-dashboard/app/shared/components/dashboard/dashboard.component';
import { SpinToWinListComponent } from './spin-to-win-list/spin-to-win-list.component';
import { SpinToWinDetailComponent } from './spin-to-win-detail/spin-to-win-detail.component';
import { AuthGuard } from 'esuite-dashboard/app/shared/guards/auth-guard.service';
const routes: Routes = [
  {
    path: 'spin-to-win',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: SpinToWinListComponent,
      },
      {
        path: ':spinToWinId',
        component: SpinToWinDetailComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SpinToWinRoutingModule {}
