import { Component, OnInit } from '@angular/core';
import { ElsService } from '../../services/els.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-code-scanner',
  templateUrl: './code-scanner.component.html',
  styleUrls: ['./code-scanner.component.scss'],
})
export class CodeScannerComponent implements OnInit {
  constructor(
    private elsService: ElsService,
    private activeDialog: MatDialogRef<CodeScannerComponent>
  ) {}

  color = 'white';
  message = 'Scan Code';
  handling = false;

  ngOnInit(): void {}

  async handleScanSuccess($event: string): Promise<void> {
    if (this.handling) {
      return;
    }
    this.handling = true;
    try {
      await this.elsService.handleLink($event);
      this.activeDialog.close($event);
    } catch {
      this.message = 'Code invalid.<br>Please try again.';
      this.color = 'indianred';
      setTimeout(() => {
        this.message = 'Scan Code';
        this.color = 'white';
      }, 4000);
    }
    this.handling = false;
  }
}
