import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PluginRoutingModule } from './plugin-routing.module';

import { SharedModule } from 'esuite-dashboard/app/shared/shared.module';
import { PluginListComponent } from './plugin-list/plugin-list.component';
import { PluginDetailComponent } from './plugin-detail/plugin-detail.component';
import { PluginCreateComponent } from './plugin-create/plugin-create.component';

@NgModule({
  declarations: [
    PluginListComponent,
    PluginDetailComponent,
    PluginCreateComponent,
  ],
  imports: [
    CommonModule,

    SharedModule,
    PluginRoutingModule,
  ],
})
export class PluginModule {}
