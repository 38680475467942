/*
 * FundCreateForm
 * Generated create resource from cadence/scripts/generate
 */

import {
  CadenceFormViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  CadenceFormControl,
  CadenceFormControlType,
} from 'cadence';
import { ProductStatus } from 'esuite-client';
import { startCase } from 'lodash';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { quickEnumToOptions } from '../../../app/shared/utils/quick-enum-to-options';

interface IFundCreateFormParams {}

@CadenceView({
  title: 'Create Fund',
  viewType: 'dialog',
})
export class FundCreateForm extends CadenceFormViewModel<IFundCreateFormParams> {
  @CadenceFormControl()
  title: string;

  @CadenceFormControl(CadenceFormControlType.Select, {
    options: () => {
      return quickEnumToOptions(ProductStatus);
    },
  })
  status: ProductStatus;

  @CadenceFormControl(CadenceFormControlType.Checkbox, {
    default: () => false,
  })
  taxable: boolean;

  @CadenceFormAction({
    label: 'Create',
  })
  create(): Promise<any> {
    /* Create logic goes here */
    return getEsuiteApi().fund.create({
      title: this.title,
      status: this.status,
      taxable: this.taxable,
    });
  }

  @CadenceViewEvent('action.create')
  afterCreate(): void {
    this._postEvent('dismissDialog');
  }
}
