import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../../shared/components/alert/alert.service';
import { getEsuiteApi } from '../../../../api/esuite-api';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-kiosk-create',
  templateUrl: './kiosk-create.component.html',
  styles: [],
})
export class KioskCreateComponent implements OnInit {
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.form = this.makeCreateForm();
  }

  private makeCreateForm() {
    return this.fb.group({
      name: [null, Validators.required],
      username: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  async handleCreate() {
    this.form.disable();
    const createdKiosk = await this.alertService
      .runWithLoader(
        () => getEsuiteApi().kiosk.create(this.form.value),
        'Kiosk created successfully',
        'Error creating kiosk'
      )
      .finally(() => {
        this.form.enable();
      });
    this.router.navigate(['../', createdKiosk.id], {
      relativeTo: this.activatedRoute,
      replaceUrl: true, // important so we don't go back to create page
    });
  }
}
