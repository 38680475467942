import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ShopifyCheckoutCollectionTree } from 'esuite-client';
import { ShopifyCollection, ShopifyProduct } from 'esuite-util';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { switchMap } from 'rxjs/operators';
import { memoize, orderBy } from 'lodash';
import { FormBuilder, FormGroup } from '@angular/forms';
import { nanoid } from 'nanoid';

interface LinkedMenu extends ShopifyCheckoutCollectionTree {
  parent?: LinkedMenu;
  children: LinkedMenu[];
  shopifyCollection?: ShopifyCollection;
  shopifyProducts?: Observable<ShopifyProduct[]>;
  index: number;
}

@Component({
  selector: 'app-menu-card',
  template: `
    <div class="card w-100 shadow" [ngClass]="class">
      <div class="card-body p-3">
        <img [src]="image" class="card-img-top" *ngIf="image" />
        <p
          class="font-weight-bold"
          [ngClass]="text ? '' : 'mb-0 pb-0'"
          *ngIf="title"
        >
          {{ title }}
        </p>
        <p *ngIf="text" class="card-text">{{ text }}</p>
      </div>
    </div>
  `,
})
export class ShopifyCheckoutMenuCardComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  image: string;

  @Input()
  text: string;

  @Input()
  class: string;

  constructor() {}

  ngOnInit(): void {}
}

@Component({
  selector: 'app-shopify-checkout-menu-editor',
  template: `
    <div class="container-fluid p-0 h-100 position-relative">
      <div class="row h-100" style="overflow: auto;">
        <div class="col-3 h-100" style="overflow: scroll">
          <button
            [mat-dialog-close]="collectionTree"
            mat-stroked-button
            color="primary"
            class="w-100 mb-4"
          >
            Save Menu
          </button>
          <button
            *ngIf="!newMenu"
            (click)="newMenu = createMenu()"
            mat-raised-button
            color="primary"
            class="w-100 mb-4"
          >
            Add Menu
          </button>
          <ng-container *ngFor="let menu of menus; let menuIndex = index">
            <app-menu-card
              (click)="setMenu(menu)"
              [title]="menu.title"
            ></app-menu-card>
            <div class="mx-auto text-center">
              <button
                *ngIf="menuIndex > 0"
                (click)="moveUp(menu)"
                mat-icon-button
                color="primary"
              >
                <mat-icon>arrow_upward</mat-icon>
              </button>
              <button
                *ngIf="menuIndex < menus.length - 1"
                (click)="moveDown(menu)"
                mat-icon-button
                color="primary"
              >
                <mat-icon>arrow_downward</mat-icon>
              </button>
              <button (click)="deleteMenu(menu)" mat-icon-button color="warn">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </ng-container>
        </div>

        <!-- Info from active menus -->
        <div class="col-9 h-100 d-flex flex-column p-0" *ngIf="active">
          <!-- Menu Header -->
          <div
            class="d-flex align-items-center justify-content-between mb-2 px-3"
          >
            <h3>{{ active.title }}</h3>
            <button
              mat-button
              color="primary"
              (click)="setMenu(active.parent)"
              *ngIf="active.parent"
            >
              Back to {{ active.parent.title }}
            </button>
          </div>

          <div
            class="overflow-auto p-3"
            #menuItems
            (scroll)="handleScroll(menuItems)"
          >
            <!-- Child Menus -->
            <div class="row  pb-4" *ngIf="active.children.length">
              <div
                class="col-4 mt-4 h-100"
                *ngFor="
                  let childMenu of active.children;
                  let childMenuIndex = index
                "
              >
                <app-menu-card
                  (click)="setMenu(childMenu)"
                  [title]="childMenu.title"
                ></app-menu-card>
                <div class="mx-auto text-center">
                  <button
                    *ngIf="childMenuIndex > 0"
                    (click)="moveUp(childMenu)"
                    mat-icon-button
                    color="primary"
                  >
                    <mat-icon>arrow_upward</mat-icon>
                  </button>
                  <button
                    *ngIf="childMenuIndex < active.children.length - 1"
                    (click)="moveDown(childMenu)"
                    mat-icon-button
                    color="primary"
                  >
                    <mat-icon>arrow_downward</mat-icon>
                  </button>
                  <button
                    (click)="deleteMenu(childMenu)"
                    mat-icon-button
                    color="warn"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <!-- Products -->
            <div class="row" *ngIf="active.type === 'collection'">
              <div
                class="col-4 mt-4"
                *ngFor="let product of active.shopifyProducts | async"
              >
                <app-menu-card
                  class="h-100"
                  [image]="product.images[0]?.transformedSrc"
                  [text]="product.title"
                ></app-menu-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        [formGroup]="newMenu"
        *ngIf="newMenu"
        class="container-fluid position-absolute w-100 bg-white p-0"
        style="bottom: 0; left: 0;"
      >
        <div class="d-flex align-items-center justify-content-between mb-4">
          <h5 class="">Add a Menu</h5>
          <mat-radio-group aria-label="Select menu type" formControlName="type">
            <mat-radio-button class="mr-3" value="collection"
              >Collection</mat-radio-button
            >
            <mat-radio-button class="mr-3" value="menu">Menu</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="row">
          <div class="col-12" *ngIf="newMenu.get('type').value === 'menu'">
            <mat-form-field>
              <mat-label>Menu Title</mat-label>
              <input matInput formControlName="title" />
            </mat-form-field>
          </div>
          <div
            class="col-12"
            *ngIf="newMenu.get('type').value === 'collection'"
          >
            <mat-form-field appearance="outline">
              <mat-label>Shopify Collection</mat-label>
              <mat-select formControlName="collectionId">
                <input
                  class="p-3"
                  style="background: #f9f9f9"
                  matInput
                  (keyup)="onKey($event.target.value)"
                />
                <mat-option
                  [value]="collection.id"
                  *ngFor="let collection of selectedShopifyCollections"
                  >{{ collection.title }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 mt-4">
            <button mat-button color="warn" (click)="newMenu = null">
              Cancel
            </button>
            <button
              mat-stroked-button
              color="primary"
              (click)="addNewMenu(true)"
            >
              Add To Sidebar
            </button>
            <button
              class="ml-3"
              *ngIf="active"
              mat-raised-button
              color="primary"
              (click)="addNewMenu()"
            >
              Add To Menu
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
})
export class ShopifyCheckoutMenuEditorComponent implements OnInit {
  constructor(private fb: FormBuilder) {}
  @ViewChild('menuItems') menuItems: ElementRef<HTMLDivElement>;

  integrationId: number;
  collectionTree: ShopifyCheckoutCollectionTree[];

  productCache: { [key: string]: ShopifyProduct[] } = {};
  menus: LinkedMenu[] = [];
  active: LinkedMenu;
  limit$ = new BehaviorSubject(18);
  skip$ = new BehaviorSubject(0);
  inventoryLocation = '';

  newMenu: FormGroup;

  fetchProducts = memoize(
    (ids: string[], limit: number, skip: number) => {
      const query: any = {
        id: {
          $in: ids,
        },
      };
      if (this.inventoryLocation) {
        query[
          'variants.inventoryItem.location' +
            this.inventoryLocation +
            '.available'
        ] = { $gt: 0 };
      } else {
        query.totalInventory = { $gt: 0 };
      }

      return getEsuiteApi().selfCheckoutIntegration.find(
        this.integrationId,
        'products',
        {
          limit,
          skip,
          query,
        }
      ) as any;
    },
    (ids, limit, skip) => JSON.stringify([ids, limit, skip])
  );
  shopifyCollections: ShopifyCollection[] = [];
  selectedShopifyCollections = this.shopifyCollections;
  // Receive user input and send to search method
  onKey(value) {
    this.selectedShopifyCollections = this.search(value);
  }

  // Filter the states list and send back to populate the selectedShopifyCollections
  search(value: string) {
    const filter = value.toLowerCase();
    return this.shopifyCollections.filter((option) =>
      option.title.toLowerCase().startsWith(filter)
    );
  }

  createMenu(): FormGroup {
     ;
    return this.fb.group({
      collectionId: [],
      title: [],
      type: ['collection'],
    });
  }

  setMenu(menu: LinkedMenu): void {
    this.active = menu;
    this.skip$.next(0);
    setTimeout(() => {
      this.menuItems.nativeElement.scrollTo(0, 0);
    }, 10);
  }

  async ngOnInit(): Promise<void> {
    // Load collection query
    this.shopifyCollections = orderBy(
      await getEsuiteApi().selfCheckoutIntegration.find(
        this.integrationId,
        'collections',
        { query: {}, limit: 10000 }
      ),
      'title'
    ) as ShopifyCollection[];
    this.selectedShopifyCollections = [...this.shopifyCollections];
    this.linkCollectionTree();
    this.active = this.menus[0];
  }

  handleScroll(el: HTMLDivElement): void {
    if (
      el.scrollHeight !== el.offsetHeight &&
      el.offsetHeight + el.scrollTop >= el.scrollHeight
    ) {
      this.skip$.next(this.skip$.value + this.limit$.value);
    }
  }

  private linkCollectionTree(): void {
    const linkedList: LinkedMenu[] = [
      ...this.collectionTree.map((ct, ind) => {
        const linkedMenu = {
          ...ct,
          parent: null,
          children: [],
          shopifyCollection: null,
          shopifyProducts: null,
          index: ind,
        };
        if (linkedMenu.type === 'collection') {
          linkedMenu.shopifyProducts = combineLatest([this.limit$, this.skip$])
            // ever-increasing list of loaded items
            .pipe(
              switchMap(async ([limit, skip]) => {
                 ;
                this.productCache[ct.id] = this.productCache[ct.id] || [];
                this.productCache[ct.id] = [
                  ...this.productCache[ct.id].slice(0, skip),
                  // Are the number of products that should be loaded already loaded?
                  ...(await this.fetchProducts(
                    linkedMenu.shopifyCollection.productIds,
                    limit,
                    skip
                  )),
                ];
                return this.productCache[ct.id];
              })
            );
        }
        return linkedMenu;
      }),
    ];

    // Link parents
    for (const coll of linkedList) {
      if (coll.parentId) {
        coll.parent = linkedList.find((l) => l.id === coll.parentId);
      }
    }
    // Link children
    for (const coll of linkedList) {
      if (coll.parent) {
        coll.parent.children.push(coll);
      }
    }
     ;
    // Link collection data with query
    for (const coll of linkedList) {
      if (coll.type === 'collection') {
         ;
        coll.shopifyCollection = this.shopifyCollections.find(
          (c) => c.id === coll.collectionId
        );
        // Fill in title in case not defined
        coll.title = coll.title ?? coll.shopifyCollection?.title ?? 'Unavailable';
      }
    }

    this.menus = linkedList.filter((i) => !i.parent);
     ;
     ;
  }

  moveItem(from, to): void {
    // remove `from` item and store it
    const f = this.collectionTree.splice(from, 1)[0];
    // insert stored item into position `to`
    this.collectionTree.splice(to, 0, f);
  }

  addNewMenu(topMenu = false): void {
     ;
    const parentId = topMenu ? null : this.active?.id;
    this.collectionTree.push({
      ...this.newMenu.value,
      id: `${nanoid()}`,
      parentId,
    });
    this.linkCollectionTree();
    // Select parent
    if (parentId) {
      this.active = this.menus.find((tm) => tm.id === parentId);
    }
    this.newMenu = null;
    this.selectedShopifyCollections = [...this.shopifyCollections];
  }

  deleteMenu(m: LinkedMenu): void {
    this.collectionTree.splice(m.index, 1);

    this.linkCollectionTree();

    if (m.parent) {
      this.active = this.menus.find((tm) => tm.id === m.parentId);
    } else {
      this.active = this.menus[0];
    }
  }

  moveUp(m: LinkedMenu): void {
    this.moveItem(m.index, m.index - 1);
    this.linkCollectionTree();
    if (m.parent) {
      this.active = this.menus.find((tm) => tm.id === m.parentId);
    }
  }

  moveDown(m: LinkedMenu): void {
    this.moveItem(m.index, m.index + 1);
    this.linkCollectionTree();
    if (m.parent) {
      this.active = this.menus.find((tm) => tm.id === m.parentId);
    }
  }
}
