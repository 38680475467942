import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseCadViewComponent } from '../components/base-cad-view/base-cad-view.component';
import {
  cadenceRouter,
  CadenceViewModel,
  CVM_REGISTRY,
  getCadenceView,
  ICadenceViewMetadata,
} from 'cadence';
import { getByViewModel } from 'cadence';

const openDialogs: Map<
  ICadenceViewMetadata,
  MatDialogRef<BaseCadViewComponent>
> = new Map();

@Injectable({
  providedIn: 'any',
})
export class CadenceViewGuard implements CanActivate {
  constructor(private dialog: MatDialog) {
    cadenceRouter.events$.subscribe((event) => {
      const fromViewData = getCadenceView(event.context.from.constructor);
      if (fromViewData.viewType === 'dialog' && openDialogs.has(fromViewData)) {
        openDialogs.get(fromViewData).close();
        openDialogs.delete(fromViewData);
      }
    });
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    if (route.data.view.viewType === 'dialog') {
      const modalRef = this.dialog.open<BaseCadViewComponent>(
        getByViewModel(route.data.view.view()).component(),
        {
          panelClass: 'cadence-view-dialog',
        },
      );

      const toViewData = getCadenceView(route.data.view.name);

      if (toViewData.viewType === 'dialog') {
        openDialogs.set(toViewData, modalRef);
      }
       ;
      modalRef.componentInstance.cadView = new (getCadenceView(
        route.data.view.name,
      ).view())();
      modalRef.componentInstance.subscriptions.push(
        modalRef.componentInstance.cadView._events$.subscribe((event) => {
           ;
          if (event.name === 'dismissDialog') {
            modalRef.close();
          }
        }),
      );
      if (route.queryParams.p) {
        modalRef.componentInstance.cadView._params = JSON.parse(
          atob(route.queryParams.p),
        );
      }
      if (route.queryParams.e) {
        modalRef.componentInstance.cadViewEvents = JSON.parse(
          atob(route.queryParams.e),
        );
      }
       ;
      await modalRef.afterClosed().toPromise();
      openDialogs.delete(toViewData);
      return false;
    }
    return true;
  }
}
