import { Component, OnInit } from '@angular/core';
import { AbstractFormComponent } from 'esuite-dashboard/app/shared/classes/abstract-form.component';
import {
  SelfCheckoutIntegrationCreateRequest,
  SelfCheckoutIntegrationResponse,
  SelfCheckoutIntegrationUpdateRequest,
  SelfCheckoutIntegrationType,
} from 'esuite-client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { startCase } from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { quickEnumToOptions } from 'esuite-dashboard/app/shared/utils/quick-enum-to-options';

export interface IdCursor {
  next: (id: number) => number;
  prev: (id: number) => number;
}

@Component({
  selector: 'app-self-checkout-integration-detail',
  templateUrl: './self-checkout-integration-detail.component.html',
  styleUrls: ['./self-checkout-integration-detail.component.scss'],
})
export class SelfCheckoutIntegrationDetailComponent
  extends AbstractFormComponent<
    SelfCheckoutIntegrationResponse,
    SelfCheckoutIntegrationCreateRequest,
    SelfCheckoutIntegrationUpdateRequest
  >
  implements IdCursor
{
  SelfCheckoutIntegrationType = SelfCheckoutIntegrationType;
  types = quickEnumToOptions(SelfCheckoutIntegrationType);
  private integration: SelfCheckoutIntegrationResponse;
  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private alertService: AlertService
  ) {
    super();
  }

  async handleCreate(dto: SelfCheckoutIntegrationCreateRequest): Promise<any> {
    const integration = await this.alertService.runWithLoader(
      () => getEsuiteApi().selfCheckoutIntegration.create(dto),
      'Integration created successfully',
      'Error cerating integration'
    );
    this.activeModal.close(integration);
  }

  async handleUpdate(dto: SelfCheckoutIntegrationUpdateRequest): Promise<any> {
    const updated = await this.alertService.runWithLoader(
      () => getEsuiteApi().selfCheckoutIntegration.update(this.id, dto),
      'Integration updated successfully',
      'Error updating integration'
    );
    this.activeModal.close(updated);
  }

  async loadFormData(id: number): Promise<void> {
    return undefined;
  }

  makeCreateForm(): FormGroup {
    return this.fb.group({
      name: [null, Validators.required],
      type: [null, Validators.required],
      shopify: this.fb.group({
        shopName: [null],
        apiKey: [null],
        password: [null],
        domain: [null],
        storefrontAccessToken: [null],
        submitOrders: [true],
        requireShippingMethod: [false],
      }),
    });
  }

  async makeUpdateForm(id: number): Promise<FormGroup> {
     ;

    this.integration = await this.alertService.loader(() =>
      getEsuiteApi().selfCheckoutIntegration.get(id)
    );
    return this.fb.group({
      name: [this.integration.name, Validators.required],
      type: [this.integration.type, Validators.required],
      shopify: this.fb.group({
        shopName: [this.integration.shopify?.shopName],
        apiKey: [this.integration.shopify?.apiKey],
        password: [this.integration.shopify?.password],
        domain: [this.integration.shopify?.domain],
        storefrontAccessToken: [
          this.integration.shopify?.storefrontAccessToken,
        ],
        submitOrders: [this.integration.shopify?.submitOrders],
        requireShippingMethod: [
          this.integration.shopify?.requireShippingMethod,
        ],
      }),
    });
  }

  next(id: number): number {
    return null;
  }

  prev(id: number): number {
    return null;
  }
}
