import { Component, OnInit, ViewChild } from '@angular/core';
import { DigitalSignageResponse } from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import {
  ResourceListComponent,
  ResourceListConfig,
} from 'esuite-dashboard/app/shared/components/resource-list/resource-list.component';
import { MatDialog } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { KioskLayoutDetailComponent } from 'esuite-dashboard/app/pages/digital-signage/editor/kiosk-layout-detail/kiosk-layout-detail.component';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';

@Component({
  selector: 'app-digital-signage-list',
  template: `
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="mb-0">Digital Signage</h5>
      <button mat-raised-button color="primary" (click)="handleCreate()">
        Create
      </button>
    </div>
    <app-resource-list #dsList [config]="config"> </app-resource-list>
  `,
  styles: [],
})
export class DigitalSignageListComponent {
  @ViewChild('dsList') dsList: ResourceListComponent<DigitalSignageResponse>;
  constructor(
    private matDialog: MatDialog,
    private alertService: AlertService,
    private clipboard: Clipboard
  ) {}

  config: ResourceListConfig<DigitalSignageResponse> = {
    cols: [
      { key: 'id', header: 'ID' },
      { key: 'name', header: 'Name', link: (r) => [r.id] },
    ],
    links: [
      {
        label:
          '<span class="material-icons md-dark align-middle" style="vertical-align: middle">link</span>',
        onClick: (ds) => {
          this.clipboard.copy(`player/digital_signage/${ds.id}`);
        },
      },
      { label: 'Duplicate', onClick: (ds) => this.duplicate(ds) },
    ],
    delete: (sign) =>
      this.alertService.confirmLoaderAndResult(
        `Are you sure you want to delete <strong>${sign.name}</strong>?`,
        () =>
          getEsuiteApi()
            .digitalSignage.remove(sign.id)
            .then(() => true),
        'Sign deleted successfully.',
        'Error deleting kiosk'
      ),
    rowLoader(pageParameters?: {
      skip: number;
      limit: number;
    }): Promise<DigitalSignageResponse[]> | DigitalSignageResponse[] {
      return getEsuiteApi()
        .digitalSignage.list({ ...pageParameters })
        .then((rs) => {
           ;
          return rs;
        });
    },
  };

  async duplicate(sign: DigitalSignageResponse): Promise<void> {
    this.alertService.confirmLoaderAndResult(
      `Are you sure you want to duplicate ${sign.name}?`,
      async () => {
        const duplicated = await getEsuiteApi().digitalSignage.create({
          duplicateId: sign.id,
          name: `Copy of ${sign.name}`,
          width: sign.width,
          height: sign.height,
        });
        this.dsList.appendRow(duplicated);
      },
      'Sign duplicated successfully.',
      'Error duplicating sign'
    );
  }

  // Todo: clean this up
  async handleCreate(): Promise<void> {
    this.matDialog.open(KioskLayoutDetailComponent);
  }
}
