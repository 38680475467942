import 'reflect-metadata';
import { CadenceObjectType } from '../types';
import { getCadence } from '../cadence';
import { filter, pluck } from 'rxjs/operators';
import { CadenceViewModel } from '../models';

/*
Syntactic sugar over CadenceViewSubscription
which provides CadenceView.events$ as the source observable (with option to filter by event name)
and handles the function by passing it the event.
 */
export function CadenceViewEvent<T extends CadenceObjectType<CadenceViewModel>>(
  eventName?: string,
  eventSource?: (view: CadenceViewModel) => CadenceViewModel,
): PropertyDecorator {
  return (target: T, propertyKey: string) => {
    getCadence().subscriptions.set(target.constructor.name, propertyKey, {
      subject: (top: CadenceViewModel) => {
        let vm = top;
        if (eventSource) {
          vm = eventSource(top);
        }
        return eventName
          ? vm._events$.pipe(
              filter((e) => {
                return e.name === eventName;
              }),
            )
          : vm._events$;
      },
      handler: target[propertyKey],
    });
  };
}
