import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CadenceComponent,
  CadenceFormControlType,
  CadenceFormViewModel,
  ICadenceFormActionMetadata,
  ICadenceFormArrayMetadata,
} from 'cadence';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import {
  BaseCadViewComponent,
  IBaseCadViewComponent,
} from '../base-cad-view/base-cad-view.component';
import { CadenceNavigationService } from '../../services/cadence-navigation.service';

@Component({
  selector: 'app-cad-form-view',
  templateUrl: './cad-form-view.component.html',
  styleUrls: ['./cad-form-view.component.scss'],
})
@CadenceComponent(() => CadenceFormViewModel)
export class CadFormViewComponent
  extends BaseCadViewComponent<CadenceFormViewModel>
  implements IBaseCadViewComponent
{
  @ViewChild('formDirective') formDirective: FormGroupDirective;

  /* Represents the current list to show based on View, Params and Paging */
  form: FormGroup;

  requirements: { [key: string]: string } = {};

  get stillRequired() {
    for (const ctrlName of Object.keys(this.requirements)) {
      if (!this.form.get(ctrlName).valid) {
        return this.requirements[ctrlName];
      }
    }
  }

  @Output()
  controlData: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private navService: CadenceNavigationService,
    private router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute
  ) {
    super(route);
  }

  buildItemForm(): FormGroup {
    const controls: { [key: string]: any } = {};
    for (const ctrl of this.cadView._controls) {
      const validators = [];

      if (ctrl.required !== false) {
        validators.push(Validators.required);
      }
      if (ctrl.controlType === CadenceFormControlType.Email) {
        validators.push(Validators.email);
      }
      controls[ctrl.name] = [
        ctrl.default ? ctrl.default(this.cadView) : null,
        Validators.compose(validators),
      ];
    }

    const group = this.fb.group(controls);

    let saving = false;

    // Bi-directional between controlData$ and FormControls
    this.subscriptions.push(
      this.cadView._controlData$.subscribe((c) => {
        if (!saving) {
          group.patchValue(c, { emitEvent: false });
        }
      })
    );

    this.subscriptions.push(
      group.valueChanges.subscribe((c) => {
        if (!saving) {
          saving = true;
          this.cadView._controlData$.next(c);
          this.controlData.next(c);
          setTimeout(() => {
            saving = false;
          }, 100);
        }
      })
    );

    return group;
  }

  addToFormArray(fa: ICadenceFormArrayMetadata) {
    const v = new (fa.view())();
    this.cadView[fa.name].push(v);
  }

  removeFromFormArray(group: FormGroup, fa: ICadenceFormArrayMetadata) {
    const removeInd = this.cadView[fa.name].indexOf(group);
    this.cadView[fa.name] = [
      ...this.cadView[fa.name].slice(0, removeInd),
      ...this.cadView[fa.name].slice(removeInd + 1),
    ];
  }

  async handleAction(action: ICadenceFormActionMetadata): Promise<void> {
    this.form.disable();
    try {
      // Strip untouched controls
      const formValue = this.form.value;
      for (const ctrlKey of Object.keys(this.form.controls)) {
        if (this.form.get(ctrlKey).untouched && !this.form.get(ctrlKey).value) {
          delete formValue[ctrlKey];
        }
      }
      this.cadView._controlData$.next(formValue);
      const resp = await this.cadView[action.name](this.cadView);
      this.cadView._postEvent({
        name: action.eventName,
        data: resp,
      });
    } catch (e) {
       ;
      alert('Error creating');
    }
    this.form.enable();
  }

  buildFormRequirements() {
    const reqs: { [key: string]: string } = {};
    for (const ctrl of this.cadView._controls) {
      if (typeof ctrl.required === 'string') {
        reqs[ctrl.name] = ctrl.required;
      }
    }
    return reqs;
  }

  protected async initializeView() {
    await super.initializeView();
     ;
    this.form = this.buildItemForm();
    this.requirements = this.buildFormRequirements();
     ;
    // Additional subscriptions
    this.subscriptions.push(
      this.cadView._events$.subscribe((event) => {
        // ENUM THIS
        if (event.name === 'resetForm') {
          setTimeout(() => {
            this.form.reset();
            this.formDirective.resetForm();
          });
        }
      })
    );
  }
}
