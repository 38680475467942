import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ResourceListComponent,
  ResourceListConfig,
} from 'esuite-dashboard/app/shared/components/resource-list/resource-list.component';
import { SelfCheckoutResponse } from 'esuite-client';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';

@Component({
  selector: 'app-self-checkout-list',
  template: `
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="mb-0">Self Checkouts</h5>
      <app-create-update-button
        [isNew]="true"
        [routerLink]="['new']"
        label="Self Checkout"
      ></app-create-update-button>
    </div>
    <app-resource-list #selfCheckoutList [config]="config"></app-resource-list>
  `,
})
export class SelfCheckoutListComponent {
  @ViewChild('selfCheckoutList')
  selfCheckoutList: ResourceListComponent<SelfCheckoutResponse>;
  config: ResourceListConfig<SelfCheckoutResponse> = {
    cols: [{ key: 'name', header: 'Name', link: (r) => [r.id] }],
    links: [
      {
        label: 'Duplicate',
        onClick: (item) => {
          this.alertService.confirmLoaderAndResult(
            'Are you sure you want to duplicate this self checkout app?',
            () =>
              getEsuiteApi()
                .selfCheckout.create({
                  name: 'Copy of ' + item.name,
                  duplicateId: item.id,
                })
                .then((newApp) => this.selfCheckoutList.appendRow(newApp)),
            'Self checkout duplicated successfully.',
            'Error duplicating self checkout.'
          );
        },
      },
    ],
    delete: (sign) =>
      this.alertService.confirmLoaderAndResult(
        'Are you sure you want to delete this self checkout app?',
        () =>
          getEsuiteApi()
            .selfCheckout.remove(sign.id)
            .then(() => true),
        'Self checkout deleted successfully.',
        'Error deleting self checkout.'
      ),
    rowLoader(pageParameters?: {
      skip: number;
      limit: number;
    }): Promise<SelfCheckoutResponse[]> | SelfCheckoutResponse[] {
      return getEsuiteApi()
        .selfCheckout.list({ ...pageParameters })
        .then((rs) => {
           ;
          return rs;
        });
    },
  };

  constructor(private alertService: AlertService) {}
}
