import { Component, OnInit } from '@angular/core';
import { ResourceListConfig } from 'esuite-dashboard/app/shared/components/resource-list/resource-list.component';
import { PaymentGatewayResponse } from 'esuite-client';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';

@Component({
  selector: 'app-payment-gateway-list',
  template: `
    <div class="d-flex align-items-center justify-content-between">
      <h5>Gateways</h5>
      <a mat-raised-button color="primary" [routerLink]="['new']"> Create </a>
    </div>
    <app-resource-list [config]="config"> </app-resource-list>
  `,
})
export class PaymentGatewayListComponent implements OnInit {
  config: ResourceListConfig<PaymentGatewayResponse> = {
    afterRowLoader(rows: PaymentGatewayResponse[]): Promise<void> | void {
      return undefined;
    },
    cols: [{ key: 'name', header: 'Name', link: (r) => [r.id] }],
    delete: (
      item: PaymentGatewayResponse,
      ind: number,
      items: PaymentGatewayResponse[]
    ) =>
      this.alertService.confirmLoaderAndResult(
        'Are you sure you want to delete this gateway?',
        () =>
          getEsuiteApi()
            .paymentGateway.remove(item.id)
            .then(() => true),
        'Gateway deleted successfully',
        'Error deleting gateway'
      ),
    rowLoader(
      pageParameters: { skip: number; limit: number } | undefined
    ): Promise<PaymentGatewayResponse[]> | PaymentGatewayResponse[] {
      return getEsuiteApi().paymentGateway.list(pageParameters);
    },
  };
  constructor(private alertService: AlertService) {}

  ngOnInit(): void {}
}
