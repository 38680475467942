import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'esuite-dashboard/app/shared/shared.module';
import { KioskLayoutDetailComponent } from './editor/kiosk-layout-detail/kiosk-layout-detail.component';
import { KioskLayoutEditorComponent } from './editor/kiosk-layout-editor/kiosk-layout-editor.component';
import { KioskSlideEditorComponent } from './editor/kiosk-slide-editor/kiosk-slide-editor.component';
import { KioskLayerEditorComponent } from './editor/kiosk-layer-editor/kiosk-layer-editor.component';
import { KioskLayerComponent } from './editor/kiosk-layer/kiosk-layer.component';
import { KioskSlideComponent } from './editor/kiosk-slide/kiosk-slide.component';
import { KioskFrameComponent } from './editor/kiosk-frame/kiosk-frame.component';
import { DigitalSignageEditorComponent } from './editor/digital-signage-editor.component';
import { DigitalSignageListComponent } from 'esuite-dashboard/app/pages/digital-signage/list/digital-signage-list.component';
import { DigitalSignagePageRoutingModule } from 'esuite-dashboard/app/pages/digital-signage/digital-signage-page-routing.module';

@NgModule({
  declarations: [
    DigitalSignageListComponent,
    KioskLayoutDetailComponent,
    KioskLayoutEditorComponent,
    KioskSlideEditorComponent,
    KioskLayerEditorComponent,
    KioskLayerComponent,
    KioskSlideComponent,
    KioskFrameComponent,
    DigitalSignageEditorComponent,
  ],
  imports: [CommonModule, DigitalSignagePageRoutingModule, SharedModule],
})
export class DigitalSignageDashboardModule {}
