/*
 * AdmissionListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceActionColumn,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceListViewRow,
  CadenceStackViewModel,
  CadenceStackViewChild,
} from 'cadence';
import { AdmissionCreateForm } from './admission-create';
import { AdmissionDeleteForm } from './admission-delete';
import { AdmissionTemplateListView } from '../index';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { AdmissionDetail } from './admission-detail';
import { AdmissionResponse } from 'esuite-client';
import { CadenceStackViewTab } from '../../models/stack-view/cadence-stack-view-tab.decorator';

@CadenceView({
  title: 'Admissions',
  showInMenu: '/self-checkouts',
})
export class AdmissionResource extends CadenceStackViewModel {
  @CadenceStackViewTab(() => AdmissionResource, ['admissionList'])
  items: string;

  @CadenceStackViewTab(() => AdmissionResource, ['templateList'])
  templates: string;

  @CadenceStackViewChild(() => AdmissionListView)
  admissionList() {
    const list = new AdmissionListView();
    return list;
  }

  @CadenceStackViewChild(() => AdmissionTemplateListView)
  templateList() {
    const list = new AdmissionTemplateListView();
    return list;
  }
}

@CadenceView()
export class AdmissionListView extends CadenceListViewModel<AdmissionResponse> {
  @CadenceColumn(() => AdmissionListView, {
    value: (row) =>
      row.image?.url
        ? `<div class="p-2"><img class="cad-thumbnail" src="${row.image.url}"></div>`
        : '',
  })
  image: number;

  @CadenceColumn()
  title: string;

  @CadenceAction({ label: 'Create' })
  createForm(): void {
    cadenceRouter.navigate(AdmissionCreateForm, {
      from: this,
      events: {
        'action.create': 'created',
      },
    });
  }

  @CadenceActionColumn({ label: 'Duplicate', header: '' })
  async duplicateForm(
    row: CadenceListViewRow<AdmissionListView>
  ): Promise<void> {
    const dupRow = await getEsuiteApi().admission.duplicate(row.id);
    this._rows$.add(dupRow);
  }

  @CadenceActionColumn({ label: 'Edit', header: '' })
  updateForm(row: CadenceListViewRow<AdmissionListView>): void {
    cadenceRouter.navigate(AdmissionDetail, {
      from: this,
      params: {
        id: row.id,
      },
      events: {
        'action.update': 'updated',
      },
    });
  }

  @CadenceActionColumn({ label: 'Delete', header: '' })
  deleteForm(row: CadenceListViewRow<AdmissionListView>): void {
    cadenceRouter.navigate(AdmissionDeleteForm, {
      from: this,
      events: {
        'action.delete': 'deleted',
      },
      params: {
        id: row.id,
      },
    });
  }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  @CadenceViewEvent('updated')
  update(event: any): void {
    this._rows$.updateBy((c) => c.id === event.data.id, event.data);
  }

  @CadenceViewEvent('deleted')
  remove(event: any): void {
    this._rows$.deleteBy((c) => c.id === event.from._params.id);
  }

  async _init(): Promise<void> {
    this._rows$.set(await getEsuiteApi().admission.list());
  }
}
