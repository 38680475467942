import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cadParamsFromHash',
})
export class CadParamsFromHashPipe implements PipeTransform {
  transform(value: string): any {
    return JSON.parse(atob(value));
  }
}
