export const collectionsByIdsPath = (tags: string[]) => {
  const arr = JSON.stringify(tags.map((t) => parseInt(t, 10)));
  return `$.collections[?(${arr}.indexOf(@.id) > -1)]`;
};

export const collectionsByHandlesPath = (tags: string[]) => {
  const arr = JSON.stringify(tags);
  return `$.collections[?(${arr}.indexOf(@.handle) > -1)]`;
};

export const collectionsByTitlesPath = (tags: string[]) => {
  const arr = JSON.stringify(tags);
  return `$.collections[?(${arr}.indexOf(@.title) > -1)]`;
};

export const productsByIdsPath = (tags: string[]) => {
  const arr = JSON.stringify(tags.map((t) => parseInt(t, 10)));
  return `$.products[?(${arr}.indexOf(@.id) > -1)]`;
};

export const productsByHandlesPath = (tags: string[]) => {
  const arr = JSON.stringify(tags);
  return `$.products[?(${arr}.indexOf(@.handle) > -1)]`;
};

export const productsByTitlesPath = (tags: string[]) => {
  const arr = JSON.stringify(tags);
  return `$.products[?(${arr}.indexOf(@.title) > -1)]`;
};

export const productsByTagsPath = (tags: string[]) => {
  const arr = JSON.stringify(tags);
  return `$.products[?(@.tags.split(', ').filter(t => ${arr}.includes(t)).length)]`;
};
