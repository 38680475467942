import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractFormComponent } from 'esuite-dashboard/app/shared/classes/abstract-form.component';
import {
  DigitalSignageResponse,
  EsuiteApp,
  PhotoboothCreateRequest,
  PhotoboothFrameResponse,
  PhotoboothResponse,
  PhotoboothUpdateRequest,
} from 'esuite-client';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import { MatDialog } from '@angular/material/dialog';
import { PhotoboothFrameDetailComponent } from 'esuite-dashboard/app/pages/photobooth/photobooth-frame-detail/photobooth-frame-detail.component';
import { AlertService } from 'esuite-dashboard/app/shared/components/alert/alert.service';

@Component({
  selector: 'app-photobooth-detail',
  templateUrl: './photobooth-detail.component.html',
  styleUrls: ['./photobooth-detail.component.scss'],
})
export class PhotoboothDetailComponent
  extends AbstractFormComponent<
    PhotoboothResponse,
    PhotoboothCreateRequest,
    PhotoboothUpdateRequest
  >
  implements OnInit
{
  signs: DigitalSignageResponse[] = [];
  EsuiteApp = EsuiteApp;
  frames: PhotoboothFrameResponse[] = [];
  @Output()
  create: EventEmitter<PhotoboothResponse> = new EventEmitter<PhotoboothResponse>();
  @Output()
  update: EventEmitter<PhotoboothResponse> = new EventEmitter<PhotoboothResponse>();
  constructor(
    private fb: FormBuilder,
    activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private alertService: AlertService
  ) {
    super();
    // this.connectRouteToIdentity('photoboothId', activatedRoute);
  }

  handleCreate(dto: PhotoboothCreateRequest): void {
    this.alertService.runWithLoader(
      async () => {
        const created = await getEsuiteApi().photobooth.create(dto);
        this.id = created.id;
        this.makeUpdateForm(this.id);
        this.create.emit(created);
      },
      'Photobooth created successfully.',
      'Error creating photobooth.'
    );
  }

  handleUpdate(dto: PhotoboothUpdateRequest): void {
    this.alertService.runWithLoader(
      async () => {
        const updated = await getEsuiteApi().photobooth.update(this.id, dto);
        this.update.emit(updated);
      },
      'Photobooth updated successfully.',
      'Error creating photobooth.'
    );
  }

  async loadFormData(id: number): Promise<void> {}

  async ngOnInit(): Promise<void> {
    const [digitalSignage] = await Promise.all([
      getEsuiteApi().digitalSignage.list(),
    ]);
    this.signs = digitalSignage;
  }

  makeCreateForm(): Promise<FormGroup> | FormGroup {
    return this.fb.group({
      name: [null, Validators.required],
      fields: [[]],
      backgroundId: [],
      screensaverId: [],
      emailShareNotification: this.fb.group({
        html: [''],
        subject: [''],
      }),
    });
  }

  async makeUpdateForm(id: number): Promise<FormGroup> {
    const photobooth = await getEsuiteApi().photobooth.get(id);

    this.frames = photobooth.frames;
    return this.fb.group({
      name: [photobooth.name, Validators.required],
      fields: [photobooth.fields],
      backgroundId: [photobooth.backgroundId],
      screensaverId: [photobooth.screensaverId],
      emailShareNotification: this.fb.group({
        html: [photobooth.emailShareNotification.html],
        subject: [photobooth.emailShareNotification.subject],
      }),
    });
  }
  async openFrameEditor(frameIndex: number) {
    const frame = this.frames[frameIndex];
    const dialogRef = this.dialog.open(PhotoboothFrameDetailComponent);
    dialogRef.componentInstance.frame = frame;
    dialogRef.componentInstance.photoboothId = this.id;
    const result: -1 | PhotoboothFrameResponse = await dialogRef
      .afterClosed()
      .toPromise();

    // Update or Remove
    this.frames = [
      ...this.frames.slice(0, frameIndex),
      ...(result === -1 ? [] : result ? [result] : [frame]),
      ...this.frames.slice(frameIndex + 1),
    ];
  }
  async addFrameFromImage(image) {
    const frame = await getEsuiteApi().photoboothFrame.create(this.id, {
      overlayImageId: image.id,
    });
    this.frames = [...this.frames, frame];
  }
}
