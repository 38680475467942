/*
 * DigitalSignageDetailView
 * Generated delete resource from cadence/scripts/generate
 */

import {
  CadenceStackViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  cadenceRouter,
  CadenceFormControl,
  CadenceStackViewTab,
  CadenceStackViewChild,
} from 'cadence';

import { DigitalSignageUpdateForm } from './digital-signage-update';
import { DigitalSignageFrameListView } from './digital-signage-frame';

interface IDigitalSignageDetailViewParams {
  id: number;
}

@CadenceView({
  title: 'Update Sign',
})
export class DigitalSignageDetailView extends CadenceStackViewModel<IDigitalSignageDetailViewParams> {
  @CadenceStackViewTab(() => DigitalSignageDetailView, ['updateForm'], {
    label: 'General',
  })
  generalTab: any;

  @CadenceStackViewTab(() => DigitalSignageDetailView, ['frameList'], {
    label: 'Frames',
  })
  framesTab: any;

  @CadenceStackViewChild(() => DigitalSignageUpdateForm)
  updateForm() {
    const form = new DigitalSignageUpdateForm();
    form._params = { id: this._params.id };
    return form;
  }

  @CadenceStackViewChild(() => DigitalSignageFrameListView)
  frameList() {
    const form = new DigitalSignageFrameListView();
    form._params = { signageId: this._params.id };
    return form;
  }
}
