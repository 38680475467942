<div class="d-flex align-items-center justify-content-between mb-4">
  <h3 class="m-0">Kiosks</h3>
  <button mat-button color="primary" [routerLink]="['new']">
    Create Kiosk
  </button>
</div>
<table
  *ngIf="kiosks$.value.length; else noKiosks"
  mat-table
  [dataSource]="kiosks$"
  class="w-100"
>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">
      <a [routerLink]="[element.id]">{{ element.name }}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="remove(element)">
          <mat-icon>delete</mat-icon>
          <span>Remove</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>
</table>
<ng-template #noKiosks>
  <div class="mx-auto text-center">
    <p class="text-center">No Kiosks to Show</p>
    <a [routerLink]="['new']">Create one now.</a>
  </div>
</ng-template>
