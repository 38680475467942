/*
 * TransactionListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceViewSubscription,
} from 'cadence';
import { TransactionCreateForm } from './transaction-create';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { TransactionResponse, TransactionStatus } from 'esuite-client';
import { toCurrency } from '../../util/toCurrency';
import { toDisplayDate } from '../../util/toDisplayDate';

interface TransactionListParams {
  orderId: number;
}

@CadenceView({
  title: 'Transaction List',
})
export class TransactionListView extends CadenceListViewModel<
  TransactionResponse,
  TransactionListParams
> {
  @CadenceColumn(() => TransactionListView, {
    value: (row) => toDisplayDate(row.createdAt),
  })
  createdAt: Date;

  @CadenceColumn(() => TransactionListView, {
    value: (row) => toCurrency(row.amount),
  })
  amount: number;

  @CadenceColumn()
  type: number;

  @CadenceColumn()
  status: TransactionStatus;

  @CadenceViewSubscription(() => TransactionListView, (view) => view._params$)
  async setTransactionsFromOrder(params) {
    if (params?.order) {
      this._rows$.set(
        await getEsuiteApi().transaction.list({
          orderId: params.order.id,
        }),
      );
    }
  }

  @CadenceAction({ label: 'Create' })
  createForm(): void {
    cadenceRouter.navigate(TransactionCreateForm, {
      from: this,
      params: {
        orderId: this._params.orderId,
      },
      events: {
        'action.create': 'created',
      },
    });
  }

  // @CadenceActionColumn({ label: 'Delete' })
  // async delete(row: CadenceListViewRow<TransactionListView>) {
  //   const confirmDelete = confirm(
  //     `Are you sure you want to delete this transaction?`,
  //   );
  //
  //   if (confirmDelete) {
  //     await getEsuiteApi().transaction.remove(row.id);
  //   }
  //   return row;
  // }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  @CadenceViewEvent('updated')
  update(event: any): void {
    this._rows$.updateBy((c) => c.id === event.data.id, event.data);
  }

  @CadenceViewEvent('action.delete')
  remove(event: any): void {
    this._rows$.deleteBy((c) => c.id === event.data.id);
  }

  async _init(): Promise<void> {
    this._rows$.set(
      await getEsuiteApi().transaction.list({ orderId: this._params.orderId }),
    );
  }
}
