/*
 * KioskUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewEvent,
} from 'cadence';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { ImageUploadComponent } from '../../../app/shared/components/image-upload/image-upload.component';
import { KioskRemoteCommand } from 'esuite-client';
import { startCase } from 'lodash';

interface IKioskUpdateFormParams {
  id: number;
  name: string;
}

@CadenceView({
  title: 'Update Kiosk',
})
export class KioskUpdateForm extends CadenceFormViewModel<IKioskUpdateFormParams> {
  kioskData: any;
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  @CadenceFormControl({ required: false })
  name: string;

  @CadenceFormControl({ required: false })
  username: string;

  @CadenceFormControl({ required: false })
  password: string;

  @CadenceFormControl(CadenceFormControlType.Textarea, {
    label: 'Send offline alerts to',
    required: false,
  })
  notificationsNetwork: string;

  @CadenceFormControl(CadenceFormControlType.Custom, {
    component: () => ImageUploadComponent,
    required: false,
  })
  bgImageId: number;

  @CadenceFormControl(CadenceFormControlType.Select, {
    label: 'Activation',
    options: async (form: KioskUpdateForm) => {
      const activations = await getEsuiteApi().activation.list({
        kioskId: form._params.id,
      });
      return activations.map((ac) => {
        return {
          label: `${
            ac.app.name
          }&nbsp; <small class="font-weight-bold text-muted">— ${startCase(
            ac.type
          )}</small>`,
          value: ac.id,
        };
      });
    },
    required: false,
  })
  activationId: number;

  @CadenceFormControl(CadenceFormControlType.Select, {
    label: 'Printer',
    options: async (form: KioskUpdateForm) => {
      const printers = form.kioskData?.lastState?.printers ?? [];
      return printers.map((ac) => ({
        label: ac.displayName,
        value: ac.name,
      }));
    },
    required: false,
  })
  printerName: string;

  @CadenceFormControl(CadenceFormControlType.Select, {
    label: 'Payment Terminal',
    options: async (form: KioskUpdateForm) => {
      const terminals = await getEsuiteApi().paymentTerminal.list();
      return [
        { label: 'None', value: null },
        ...terminals.map((ac) => {
          let ipState = ac.ipAddress ? ' (offline)' : '';
          if (ac.ipAddress && form.kioskData?.lastState?.devices?.length) {
            const foundDevice = form.kioskData.lastState.devices.find((d) =>
              [d.ip, d.mac].includes(ac.ipAddress.toLowerCase())
            );
            if (foundDevice?.online) {
              ipState = ' (online)';
            }
          }
          return {
            label: ac.name + ipState,
            value: ac.id,
          };
        }),
      ];
    },
    required: false,
  })
  paymentTerminalId: number;

  @CadenceFormAction({
    label: 'Save',
  })
  update(): Promise<any> {
    const updateRequest = {
      name: this.name,
      username: this.username,
      password: this.password,
      activationId: this.activationId,
      paymentTerminalId: this.paymentTerminalId,
      printerName: this.printerName,
      bgImageId: this.bgImageId || null,
      notifications: {
        network: this.notificationsNetwork
          ? this.notificationsNetwork.split('\n').map((t) => t.trim())
          : [],
      },
    };
    return getEsuiteApi().kiosk.update(this.id, updateRequest);
  }

  @CadenceFormAction({
    label: 'Relogin',
  })
  relogin(): Promise<any> {
    return getEsuiteApi().kiosk.sendMessage(this.id, {
      target: 'platform',
      type: KioskRemoteCommand.Reload,
      // data: {},
    });
  }

  @CadenceFormAction({
    label: 'Reset',
  })
  async reset(): Promise<any> {
    try {
      await getEsuiteApi().activation.resetForKiosk(this.kioskData.id);
      alert('Kiosk activations reset successfully.');
    } catch {
      alert('Error resetting kiosk activations.');
    }
  }

  @CadenceFormAction({
    label: 'Upgrade Player',
  })
  updatePlayer(): Promise<any> {
    return getEsuiteApi().kiosk.sendMessage(this.id, {
      target: 'platform',
      type: 'update_player',
      // data: {},
    });
  }

  @CadenceViewEvent('action.update')
  afterUpdate(): void {}

  async _init(): Promise<void> {
    this.kioskData = await getEsuiteApi().kiosk.get(this._params.id);
    this._controlData$.next({
      ...this.kioskData,
      notificationsNetwork: this.kioskData.notifications.network?.join('\n'),
      paymentTerminalId: this.kioskData.paymentTerminal?.id,
    });
  }
}
