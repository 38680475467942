import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from '../../app/shared/shared.module';
import { NgxTablePopupComponent } from './ngx-table-popup/ngx-table-popup.component';
import { CadListViewCellPipe } from './pipes/cadence-list-view-cell.pipe';
import { CadenceRoutingModule } from './cadence-routing.module';
import { CadViewPipe } from './pipes/cadence-view-link.pipe';
import { CadPathPipe } from './pipes/cadence-path.pipe';
import { CadViewLinkParamsPipe } from './pipes/cadence-value.pipe';
import { CadFormControlInputTypePipe } from './pipes/cadence-form-control-input-type.pipe';
import { CadParamsFromHashPipe } from './pipes/cadence-params-from-hash.pipe';
import { CadParamsToHashPipe } from './pipes/cadence-params-to-json.pipe';
import { CadLinkComponent } from './components/cad-link/cad-link.component';
import { CadFormViewComponent } from './components/cad-form-view/cad-form-view.component';
import { CadListViewComponent } from './components/cad-list-view/cad-list-view.component';
import { CadStackViewComponent } from './components/cad-stack-view/cad-stack-view.component';
import { CadFormControlComponent } from './components/cad-form-view/cad-form-control/cad-form-control.component';
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';
import { CadenceNavigationService } from './services/cadence-navigation.service';
import { QuillModule } from 'ngx-quill';

class CustomRouteReuseStrategy implements RouteReuseStrategy {
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ): boolean {
    return false;
  }
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null) {}
}

/*
Provides Angular-based view engine for Cadence Views
 */
@NgModule({
  declarations: [
    CadListViewCellPipe,
    CadFormControlInputTypePipe,
    NgxTablePopupComponent,
    CadViewPipe,
    CadPathPipe,
    CadViewLinkParamsPipe,
    CadParamsFromHashPipe,
    CadParamsToHashPipe,

    // Components
    CadListViewComponent,
    CadLinkComponent,
    CadFormViewComponent,
    CadStackViewComponent,
    CadFormControlComponent,
  ],
  imports: [
    CommonModule,
    CadenceRoutingModule,
    // App Inbox
    FormsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatExpansionModule,
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatButtonModule,
    MatChipsModule,
    MatListModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    SharedModule,
    QuillModule.forRoot(),
  ],
  providers: [
    CadenceNavigationService,
    CadParamsFromHashPipe,
    CadParamsToHashPipe,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ],
  exports: [CadStackViewComponent, CadListViewComponent],
})
export class CadenceModule {
  constructor(nav: CadenceNavigationService) {}
}
