import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'lodash';
import { CadenceListViewModel, ICadenceListViewColumnMetadata } from 'cadence';

@Pipe({
  name: 'cadListViewCell',
})
export class CadListViewCellPipe implements PipeTransform {
  private static transformString<T>(value: (item: T) => any, item: T) {
    //  ;
    // return json[path] ? json[path] : JSONPath({
    //   path,
    //   json,
    // });

    return value(item);
  }

  transform<T extends CadenceListViewModel>(
    item: T,
    col: ICadenceListViewColumnMetadata<T>,
  ): unknown {
    if (isArray(col.value)) {
      return col.value
        .map((c) => {
          if (c.length > 1) {
            return c;
          }
          return CadListViewCellPipe.transformString(c, col);
        })
        .join('');
    } else {
      return null;
      // return CadListViewCellPipe.transformString<T>(col.value, item);
    }
  }
}
