import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaCollectionComponent } from './media-collection.component';
import { MediaCollectionListComponent } from './media-collection-list/media-collection-list.component';
import { MediaCollectionDetailComponent } from './media-collection-detail/media-collection-detail.component';
import { MediaCollectionRoutingModule } from 'esuite-dashboard/app/pages/media-collection/media-collection-routing.module';

import { SharedModule } from 'esuite-dashboard/app/shared/shared.module';


@NgModule({
  declarations: [
    MediaCollectionComponent,
    MediaCollectionListComponent,
    MediaCollectionDetailComponent,
  ],
  imports: [
    CommonModule,
    MediaCollectionRoutingModule,

    SharedModule,
  ],
})
export class MediaCollectionModule {}
