import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  DigitalSignageSlideResponse,
  ImageUploadResponse,
} from 'esuite-client';

@Component({
  selector: 'app-kiosk-slide-editor',
  templateUrl: './kiosk-slide-editor.component.html',
  styleUrls: ['./kiosk-slide-editor.component.scss'],
})
export class KioskSlideEditorComponent implements OnInit {
  editMode = false;
  bgImage: ImageUploadResponse;
  loading = false;
  form: FormGroup;

  get slideContainer(): number[] {
    return [this.data.width, this.data.height];
  }

  constructor(
    private fb: FormBuilder,
    public activeModal: MatDialogRef<KioskSlideEditorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      edit: boolean;
      slide: DigitalSignageSlideResponse;
      width: number;
      height: number;
    },
  ) {}

  get slideResult(): DigitalSignageSlideResponse {
    return {
      ...(this.data ? this.data.slide : {}),
      ...this.form.value,
      bgImage: this.bgImage,
    };
  }

  async create(): Promise<void> {
    this.loading = true;
    // this.resetErrors();
    try {
      // const slideCreate = await this.slideService.create(this.form.value);
      this.activeModal.close(this.slideResult);
    } catch (e) {
      if (e.status === 422 && e.error) {
        // this.displayErrors(e.error);
      }
      //  ;
    }
    this.loading = false;
  }

  done(): void {
    this.activeModal.close(this.slideResult);
  }

  async update(): Promise<void> {
    this.loading = true;
    // this.resetErrors();
    // const slideUpdate = await this.slideService.update(this.data.slide.id, this.form.value);
    this.loading = false;
    this.activeModal.close(this.slideResult);
  }

  close(): void {
    this.activeModal.close();
  }

  async ngOnInit(): Promise<void> {
    if (this.data?.edit && this.data?.slide) {
      this.bgImage = this.data.slide.bgImage;
      this.editMode = true;
      this.form = this.fb.group({
        name: [this.data.slide.name, Validators.required],
        // Config Items
        // sort: [this.data.slide.sort || 0, Validators.required],
        duration: [this.data.slide.duration, Validators.required],
        enabled: [this.data.slide.enabled],
        bgImageId: [this.data.slide.bgImageId],
        layers: [this.data.slide.layers || []],
      });
    } else {
      this.form = this.fb.group({
        name: ['', Validators.required],
        // Config Items
        // sort: [-1, Validators.required],
        duration: [8, Validators.required],
        enabled: [true],
        bgImageId: [null],
        layers: [[]],
      });
    }
  }
}
