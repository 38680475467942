import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cadParamsToHash',
})
export class CadParamsToHashPipe implements PipeTransform {
  transform(value: any): string {
    return btoa(JSON.stringify(value));
  }
}
