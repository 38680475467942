import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractFormComponent } from 'esuite-dashboard/app/shared/classes/abstract-form.component';
import {
  RestAppCreateRequest,
  RestAppUpdateRequest,
  RestAppResponse,
} from 'esuite-client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getEsuiteApi } from 'esuite-dashboard/app/api/esuite-api';
import {
  ComponentIOStrategy,
  createComponentIO,
} from 'esuite-util/components';

@Component({
  selector: 'app-rest-app-detail',
  templateUrl: './rest-app-detail.component.html',
  styleUrls: ['./rest-app-detail.component.scss'],
})
export class RestAppDetailComponent extends AbstractFormComponent<
  RestAppResponse,
  RestAppCreateRequest,
  RestAppUpdateRequest
> {
  private restAppIO: ComponentIOStrategy<RestAppResponse> = createComponentIO();
  get restApp(): RestAppResponse {
    return this.restAppIO.get();
  }
  set restApp(ra: RestAppResponse) {
    this.restAppIO.set(ra);
  }

  @Output()
  create: EventEmitter<RestAppCreateRequest> = new EventEmitter();

  @Output()
  update: EventEmitter<RestAppUpdateRequest> = new EventEmitter();

  constructor(private fb: FormBuilder) {
    super();
  }
  handleCreate(dto: RestAppCreateRequest): any {
    this.create.emit(dto);
  }

  handleUpdate(dto: RestAppUpdateRequest): any {
    this.update.emit(dto);
  }

  async loadFormData(id: number): Promise<void> {
    if (id || id === 0) {
      this.restApp = await getEsuiteApi().restApp.get(id);
    }
  }

  getTestUrl(restApp: RestAppResponse): string {
    return `https://${restApp.username}:${restApp.password}@${
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '')
    }/api/orders`;
  }

  makeCreateForm(): FormGroup {
    return this.fb.group({
      name: [null, Validators.required],
    });
  }

  makeUpdateForm(id: number): FormGroup {
    return this.fb.group({
      name: [this.restApp.name, Validators.required],
    });
  }
}
