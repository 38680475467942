import { startCase } from 'lodash';
import { CadenceObjectType, CadenceSubscriptionFactory } from '../../types';
import { CadenceViewModel } from 'cadence/models';
import { CadenceStackViewModel } from 'cadence/models';
import { getCadence } from '../../cadence';
import 'reflect-metadata';
import { filter, take } from 'rxjs/operators';

interface ICadenceStackViewTabOptions<
  StackView extends CadenceViewModel,
  Tab extends CadenceViewModel
> {
  label?: string;
  title?: string;
  classes?: string;
}

export interface ICadenceStackViewTabMetadata {
  name: string;
  label: string;
  title?: string;
  children: string[];
}

/*
Allows a function to be called once to render the view
 */
export function CadenceStackViewTab<
  StackView extends CadenceStackViewModel,
  Tab extends CadenceViewModel
>(
  parent: () => CadenceObjectType<StackView>,
  children: (keyof StackView)[],
  options: ICadenceStackViewTabOptions<StackView, Tab> = {},
): PropertyDecorator {
  return (target: StackView, propertyKey: string) => {
    const childOps: ICadenceStackViewTabMetadata = {
      name: propertyKey,
      label: startCase(propertyKey),
      children: children as string[],
      ...options,
    };
    getCadence().stackViewTabs.set(
      target.constructor.name,
      propertyKey,
      childOps,
    );
  };
}
