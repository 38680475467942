import {
  CadenceFormAction,
  CadenceFormControl,
  CadenceFormControlType,
  CadenceFormViewModel,
  CadenceView,
  CadenceViewModel,
} from 'cadence';
import { ImageUploadCreateRequest, ImageUploadResponse } from 'esuite-client';
import { getEsuiteApi } from '../../../../app/api/esuite-api';

@CadenceView()
export class ImageUpload extends CadenceFormViewModel<
  ImageUploadCreateRequest,
  ImageUploadResponse
> {
  /*
  @CadenceColumn()
  thing: SpecificColumnValueIsRetained
   */

  @CadenceFormControl({
    label: 'Upload File',
    controlType: CadenceFormControlType.File,
  })
  file: File;

  @CadenceFormAction({
    label: 'Upload Image',
  })
  async uploadImage() {
    try {
      const upload = await getEsuiteApi().imageUpload.uploadFile(this.file);
      alert('Image uploaded successfully.');
      return upload;
    } catch (e) {
      alert('Error uploading image.');
       ;
    }
  }
}
