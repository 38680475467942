<ng-container *ngIf="filter">
  <mat-form-field appearance="outline">
    <mat-select [value]="filter.target" (valueChange)="updateFilter({ target: $event})">
      <mat-option value="shopifyProduct">Products</mat-option>
      <mat-option value="shopifyCollection">Collections</mat-option>
      <mat-option value="shopifyMenu">Menu</mat-option>
    </mat-select>
  </mat-form-field>


  <!-- Shopify Product Filters -->
  <mat-form-field appearance="outline" *ngIf="filter.target === 'shopifyProduct'">
    <mat-select [value]="filter.filter" (valueChange)="updateFilter({ filter: $event })">
      <mat-option value="none">All</mat-option>
      <mat-option value="handles">By Handles</mat-option>
      <mat-option value="titles">By Titles</mat-option>
      <mat-option value="ids">By IDs</mat-option>
      <mat-option value="tags">By Tags</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Shopify Collection Filters -->
  <mat-form-field appearance="outline" *ngIf="filter.target === 'shopifyCollection'">
    <mat-select [value]="filter.filter" (valueChange)="updateFilter({ filter: $event })">
      <mat-option value="none">All</mat-option>
      <mat-option value="titles">By Titles</mat-option>
      <mat-option value="handles">By Handles</mat-option>
      <mat-option value="ids">By IDs</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Shopify Menu -->
  <mat-form-field appearance="outline" *ngIf="filter.target === 'shopifyMenu'">
    <mat-label>Menu URL</mat-label>
    <input matInput [value]="filter.filter" (change)="updateFilter({ filter: $event.target.value })">
  </mat-form-field>

  <mat-form-field *ngIf="filter.filter && filter.filter !== 'none'" class="d-block w-100">
    <mat-chip-list #chipList>
      <mat-chip [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(entry)"
                *ngFor="let entry of tagEntries">
        {{ entry }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <input [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="addOnBlur"
           (matChipInputTokenEnd)="add($event)">
  </mat-form-field>

</ng-container>
