<ng-container *ngIf="
  {
    form: form$ | async,
    kiosk: kiosk$ | async,
    activations: activations$ | async,
    paymentTerminals: paymentTerminals$ | async
  } as view"
>
  <h4>Update Kiosk</h4>

  <form *ngIf="view.form" [formGroup]="view.form" >

    <div class="d-flex align-items-stretch justify-content-between flex-column">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput formControlName="password">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Activation ID</mat-label>
        <mat-select [multiple]="false" formControlName="activationId">
          <mat-option [value]="activation.id" *ngFor="let activation of view.activations">
            {{ activation.app.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Payment Terminal</mat-label>
        <mat-select [multiple]="false" formControlName="paymentTerminalId">
          <mat-option [value]="paymentTerminal.id" *ngFor="let paymentTerminal of view.paymentTerminals">
            {{ paymentTerminal.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Printer Name</mat-label>
        <input matInput formControlName="printerName">
      </mat-form-field>
    </div>

    <button mat-raised-button color="primary" (click)="handleUpdate(view.form)" [disabled]="!view.form.enabled || view.form.invalid">Update</button>

  </form>

</ng-container>

