import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  CadenceFormControlType,
  CadenceFormViewModel,
  ICadenceFormControlMetadata,
} from 'cadence';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, fromEvent, Observable, of, Subscription } from 'rxjs';
import { Ace } from '../../../../../assets/ace';
import { debounceTime } from 'rxjs/operators';
import { CadCustomControl } from '../../../../cad-custom-control';
import { isPlainObject } from 'lodash';

@Component({
  selector: 'app-cad-form-control',
  templateUrl: './cad-form-control.component.html',
  styleUrls: ['./cad-form-control.component.scss'],
})
export class CadFormControlComponent implements AfterViewInit, OnDestroy {
  ControlType = CadenceFormControlType;

  @ViewChild('htmlEditor') htmlEditor: ElementRef<HTMLDivElement>;
  editor: Ace.Editor;

  editorSub: Subscription;

  active$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  disabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input()
  cadFormControl: ICadenceFormControlMetadata;

  @Input()
  formView: CadenceFormViewModel;

  @Input()
  control: AbstractControl;

  options$: Observable<{ label: string; value: any }[]>;

  @ViewChild('componentTarget', { read: ViewContainerRef })
  target: ViewContainerRef;

  customComponent: ComponentRef<any>;

  customComponentSubs: Subscription[] = [];

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  async ngAfterViewInit(): Promise<void> {
    if (this.cadFormControl.controlType === CadenceFormControlType.Select) {
      const control = this
        .cadFormControl as ICadenceFormControlMetadata<CadenceFormControlType.Select>;
      const options = control.options(this.formView);
      if (options instanceof Array) {
        this.options$ = of(options);
      } else if (options instanceof Observable) {
        this.options$ = options;
      } else {
        this.options$ = of(await options);
      }
    } else if (
      this.cadFormControl.controlType === CadenceFormControlType.Custom
    ) {
      this.customComponent = await this.renderCustomComponent(
        this.cadFormControl.component(),
      );
    } else if (
      this.cadFormControl.controlType === CadenceFormControlType.Html
    ) {
    }

    if (this.cadFormControl.hidden === true) {
      this.active$.next(false);
    } else if (typeof this.cadFormControl.hidden === 'function') {
      const hiddenVal = this.cadFormControl.hidden(this.formView);
      if (hiddenVal instanceof Observable) {
        this.customComponentSubs.push(
          hiddenVal.subscribe((hidden) => this.active$.next(!hidden)),
        );
      } else if (hiddenVal instanceof Promise) {
        this.active$.next(await hiddenVal);
      } else {
        this.active$.next(hiddenVal);
      }
    }

    if (this.cadFormControl.disabled === true) {
      this.disabled$.next(true);
    } else if (typeof this.cadFormControl.disabled === 'function') {
      const disabledVal = this.cadFormControl.disabled(this.formView);
      if (disabledVal instanceof Observable) {
        this.customComponentSubs.push(
          disabledVal.subscribe((disabled) => this.disabled$.next(disabled)),
        );
      } else if (disabledVal instanceof Promise) {
        this.disabled$.next(await disabledVal);
      } else {
        this.disabled$.next(disabledVal);
      }
    }

    (window as any).ace.config.set('basePath', 'assets/ace');

    if (this.cadFormControl.controlType === CadenceFormControlType.Html) {
      this.editor = (window as any).ace.edit(this.htmlEditor.nativeElement);

      // this.editor.setTheme('ace/theme/monokai');
      this.editor.session.setMode('ace/mode/liquid');
      this.editor.setValue(this.control.value);
      this.editorSub = fromEvent(this.editor, 'change')
        .pipe(debounceTime(500))
        .subscribe((c) => {
          this.control.setValue(this.editor.getValue());
        });
    }
  }

  renderCustomComponent = async (comp: any): Promise<ComponentRef<any>> => {
     ;
    const factory =
      this.componentFactoryResolver.resolveComponentFactory<{
        control: any;
        controlChange: EventEmitter<any>;
      }>(comp);

    const componentRef =
      this.target.createComponent<CadCustomControl<any>>(factory);
     ;
    if (this.control.value) {
      componentRef.instance.control = this.control.value;
    }
    // if (this.cadFormControl.props) {
    //   componentRef.instance.props = await this.cadFormControl.props(
    //     this.formView,
    //   );
    // }
    if (isPlainObject(this.cadFormControl.props)) {
       ;
      componentRef.instance.props = this.cadFormControl.props;
    } else if (typeof this.cadFormControl.props === 'function') {
      const propsVal = this.cadFormControl.props(this.formView);
      if (propsVal instanceof Observable) {
         ;
        this.customComponentSubs.push(
          propsVal.subscribe((pv) => {
             ;
            (componentRef.instance.props = pv);
          }),
        );
      } else if (propsVal instanceof Promise) {
         ;
        componentRef.instance.props = await propsVal;
      } else {
         ;
        componentRef.instance.props = propsVal;
      }
    }
    this.customComponentSubs.push(
      componentRef.instance.controlChange.subscribe((c) =>
        this.control.setValue(c),
      ),
    );
    return componentRef;
  };

  ngOnDestroy() {
    this.customComponentSubs.forEach((s) => s.unsubscribe());
    this.editorSub?.unsubscribe();
    this.editor?.destroy();
  }

  renderHtml(html: string) {
    return html.replace(/(?:\r\n|\r|\n)/g, '\n<br>\n');
  }
}
