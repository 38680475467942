import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl,
} from '@angular/platform-browser';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

@Pipe({
  name: 'pickFormArray',
})
export class PickFormArrayPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: AbstractControl, key: string): FormArray {
    if (!(value.get(key) instanceof FormArray)) {
      throw new Error('Unable to pick form array with key ' + key);
    }
    return value.get(key) as FormArray;
  }
}
