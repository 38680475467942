<mat-card *ngIf="form$ | async as form" [formGroup]="form">
  <mat-card-content>
    <div class="d-flex align-items-center justify-content-between mb-0">
      <span class="h5 m-0">{{ id === -1 ? 'Create' : 'Update' }} App</span>
      <app-create-update-button
        [formComponent]="[this, form]"
        label="App">
      </app-create-update-button>
    </div>
    <hr>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
    <ng-container *ngIf="restApp">
      <mat-form-field class="w-100" appearance="outline" *ngIf="id > -1">
        <mat-label>Username</mat-label>
        <input matInput [value]="restApp.username" readonly disabled>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline" *ngIf="id > -1">
        <mat-label>Password</mat-label>
        <input matInput [value]="restApp.password" readonly disabled>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline" *ngIf="id > -1">
        <mat-label>Test URL</mat-label>
        <input matInput [value]="getTestUrl(restApp)" readonly disabled>
      </mat-form-field>
    </ng-container>
  </mat-card-content>
</mat-card>
