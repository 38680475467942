import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../../../shared/components/dashboard/dashboard.component';
import { DirectoryListComponent } from './directory-list/directory-list.component';
import { DirectoryDetailComponent } from './directory-detail/directory-detail.component';
import { AuthGuard } from '../../../shared/guards/auth-guard.service';
import { DirectoryCreateComponent } from 'esuite-dashboard/app/pages/v2/directory/directory-create/directory-create.component';
import { EsuiteApp } from 'esuite-client';
import { DirectoryFloorCreateComponent } from 'esuite-dashboard/app/pages/v2/directory/floors/directory-floor-create/directory-floor-create.component';
import { DirectoryFloorDetailComponent } from 'esuite-dashboard/app/pages/v2/directory/floors/directory-floor-detail/directory-floor-detail.component';
import { DirectoryListingCreateComponent } from 'esuite-dashboard/app/pages/v2/directory/listings/directory-listing-create/directory-listing-create.component';
import { DirectoryListingDetailComponent } from 'esuite-dashboard/app/pages/v2/directory/listings/directory-listing-detail/directory-listing-detail.component';

const routes: Routes = [
  {
    path: EsuiteApp.Directory,
    canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
      {
        path: '',
        component: DirectoryListComponent,
      },
      {
        path: 'new',
        component: DirectoryCreateComponent,
      },
      {
        path: ':directoryId',
        component: DirectoryDetailComponent,
      },
      {
        path: ':directoryId/floors',
        redirectTo: ':directoryId',
      },
      {
        path: ':directoryId/listings',
        redirectTo: ':directoryId',
      },
      {
        path: ':directoryId/floors/new',
        component: DirectoryFloorCreateComponent,
      },
      {
        path: ':directoryId/floors/:directoryFloorId',
        component: DirectoryFloorDetailComponent,
      },
      {
        path: ':directoryId/listings/new',
        component: DirectoryListingCreateComponent,
      },
      {
        path: ':directoryId/listings/:directoryListingId',
        component: DirectoryListingDetailComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DirectoryRoutingModule {}
