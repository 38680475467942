<app-kiosk-layout-editor [id]="id"></app-kiosk-layout-editor>
<!--<div class="p-3" *ngIf="(layout$ | async) as layout">-->
<!--  <h3>{{ layout.name }}</h3>-->
<!--</div>-->
<!--<nav mat-tab-nav-bar class="w-100" *ngIf="activeLink">-->
<!--  <a mat-tab-link-->
<!--     *ngFor="let link of navLinks"-->
<!--     (click)="activeLink = link"-->
<!--     [active]="activeLink == link"-->
<!--    >{{ link.label }}-->
<!--  </a>-->
<!--</nav>-->
<!--<router-outlet></router-outlet>-->

