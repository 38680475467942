/*
 * RevenueCenterListView
 * Generated list resource from cadence/scripts/generate
 */

import {
  CadenceColumn,
  CadenceAction,
  CadenceActionColumn,
  CadenceListViewModel,
  CadenceView,
  CadenceViewEvent,
  cadenceRouter,
  CadenceListViewRow,
} from 'cadence';
import { RevenueCenterCreateForm } from './revenue-center-create';
import { RevenueCenterUpdateForm } from './revenue-center-update';
import { RevenueCenterDeleteForm } from './revenue-center-delete';
import { getEsuiteApi } from '../../../app/api/esuite-api';
import { RevenueCenterResponse } from 'esuite-client';

@CadenceView({
  title: 'Revenue Centers',
  showInMenu: '/settings',
})
export class RevenueCenterListView extends CadenceListViewModel<RevenueCenterResponse> {
  @CadenceColumn()
  id: number;

  @CadenceColumn()
  name: string;

  @CadenceAction({ label: 'Create' })
  createForm(): void {
    cadenceRouter.navigate(RevenueCenterCreateForm, {
      from: this,
      events: {
        'action.create': 'created',
      },
    });
  }

  @CadenceActionColumn({ label: 'Delete' })
  deleteForm(row: CadenceListViewRow<RevenueCenterListView>): void {
    cadenceRouter.navigate(RevenueCenterDeleteForm, {
      from: this,
      events: {
        'action.delete': 'deleted',
      },
      params: {
        id: row.id,
      },
    });
  }

  @CadenceActionColumn({ label: 'Edit' })
  updateForm(row: CadenceListViewRow<RevenueCenterListView>): void {
    cadenceRouter.navigate(RevenueCenterUpdateForm, {
      from: this,
      params: {
        id: row.id,
      },
      events: {
        'action.update': 'updated',
      },
    });
  }

  @CadenceViewEvent('created')
  create(event: any): void {
    this._rows$.add(event.data);
  }

  @CadenceViewEvent('updated')
  update(event: any): void {
    this._rows$.updateBy((c) => c.id === event.data.id, event.data);
  }

  @CadenceViewEvent('deleted')
  remove(event: any): void {
    this._rows$.deleteBy((c) => c.id === event.from._params.id);
  }

  async _init(): Promise<void> {
    this._rows$.set(await getEsuiteApi().revenueCenter.list());
  }
}
