import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ImageUploadResponse } from 'esuite-client';
import { CadCustomControl } from '../../../../cadence/cad-custom-control';
import { getEsuiteApi } from '../../../api/esuite-api';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent
  implements OnInit, OnDestroy, CadCustomControl<number>
{
  formSub: Subscription;
  loading = false;

  @Input()
  get label(): string {
    return this.localLabel;
  }
  set label(iu: string) {
    this.localLabel = iu;
  }

  @Input()
  get image(): ImageUploadResponse {
    return this.localImage;
  }
  set image(iu: ImageUploadResponse) {
    this.localImage = iu;
    this.imageChange.emit(iu);
    this.controlChange.emit(iu?.id);
  }

  @Input()
  get imageFormControl(): AbstractControl {
    return this.localFormControl;
  }
  set imageFormControl(fc: AbstractControl) {
    this.localFormControl = fc;
    this.detectForm();
  }

  @Output()
  imageChange: EventEmitter<ImageUploadResponse> = new EventEmitter<ImageUploadResponse>();

  @Input()
  control: number;

  @Output()
  controlChange: EventEmitter<number> = new EventEmitter<number>();

  constructor(private dialog: MatDialog) {}

  // Holds the image ID of the upload
  private localFormControl: AbstractControl;

  private localImage: ImageUploadResponse;
  uploadingSrc;

  removed: any = false;

  private localLabel = 'Upload Image';

  remove(): void {
    this.removed = { value: this.image.id, image: this.image };
    if (this.imageFormControl) {
      this.imageFormControl.setValue(null);
    }
    this.image = null;
  }
  undoRemove(): void {
    if (this.imageFormControl) {
      this.imageFormControl.setValue(this.removed.value);
    }
    this.image = this.removed.image;
    this.removed = false;
  }

  async ngOnInit(): Promise<void> {
    if (this.control) {
      this.image = await getEsuiteApi().imageUpload.get(this.control);
    }
  }

  private async reloadFormImage(): Promise<ImageUploadResponse> {
    if (this.imageFormControl?.value) {
      this.image = await getEsuiteApi().imageUpload.get(
        this.imageFormControl.value
      );
    }
    return this.image;
  }

  private detectForm(): void {
    if (this.imageFormControl) {
      this.formSub = this.imageFormControl.valueChanges
        .pipe(startWith([this.imageFormControl.value]))
        .subscribe(async (imageId) => {
          if (imageId && !this.image) {
            this.image = await this.reloadFormImage();
          }
        });
    }
  }

  async startUpload(event): Promise<void> {
    this.loading = true;

    event[0].fileEntry.file((file) => {
      setTimeout(async () => {
        this.uploadingSrc = URL.createObjectURL(file);

        try {
          await this.uploadImage(file);
          this.uploadingSrc = null;
          this.removed = false;
        } catch (e) {}
        this.loading = false;
      });
    });
  }

  private setImage(image: ImageUploadResponse): void {
    if (this.imageFormControl) {
      this.imageFormControl.setValue(image.id);
    }
    this.image = image;
  }

  private async uploadImage(file: File): Promise<ImageUploadResponse> {
    // Here you can access the real file
    const image = await getEsuiteApi().imageUpload.uploadFile(file);
    this.setImage(image);
    return image;
  }

  // async openMediaLibrary(): Promise<void> {
  //   const dialogRef = this.dialog.open(MediaLibraryComponent, {
  //     width: '90vw',
  //     maxWidth: '90vw',
  //     maxHeight: '90vh',
  //   });
  //   const images: ImageUploadResponse[] = await dialogRef
  //     .afterClosed()
  //     .toPromise();
  //   if (images?.length) {
  //     this.setImage(images[0]);
  //   }
  // }

  ngOnDestroy(): void {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }
}
