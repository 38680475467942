import 'reflect-metadata';
import { CadenceObjectType } from '../types';
import { Observable } from 'rxjs';
import { getCadence } from '../cadence';

export interface CadenceViewSubscriptionMetadata {
  subject: (view: any) => Observable<any>;
  handler: (data: any) => any;
}

export function CadenceViewSubscription<T>(
  type: () => CadenceObjectType<T>,
  subject: (view: T) => Observable<any>,
): PropertyDecorator {
  return (target: any, propertyKey: string) => {
    getCadence().subscriptions.set(target.constructor.name, propertyKey, {
      subject,
      handler: target[propertyKey],
    });
  };
}
