/*
 * CheckoutLineItemUpdateForm
 * Generated update resource from cadence/scripts/generate
 */

import {
  CadenceFormViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  cadenceRouter,
  CadenceFormControl,
} from 'cadence';
import { CheckoutLineItemListView } from './checkout-line-item-list';

interface ICheckoutLineItemUpdateFormParams {
  id: number;
  name: string;
}

@CadenceView({
  title: 'Update Checkout Line Item',
})
export class CheckoutLineItemUpdateForm extends CadenceFormViewModel<ICheckoutLineItemUpdateFormParams> {
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  @CadenceFormControl({
    default: (view) => view._params.name,
  })
  name: string;

  @CadenceFormAction({
    label: 'Update',
  })
  async update(): Promise<any> {
    return { id: this.id, name: this.name };
  }

  @CadenceViewEvent('action.update')
  afterUpdate(): void {
    cadenceRouter.navigate(CheckoutLineItemListView, {
      from: this,
    });
  }

  _init(): void {
    this._controlData$.next({ id: Date.now() + 1, name: 'Row 1' });
  }
}
