import { CadenceUnarray } from '../../types';
import { ICadenceActionMetadata } from '../../types';
import { CadenceListViewModel } from './cadence-list-view.model';

export interface ICadenceListViewColumnMetadata<
  T extends CadenceListViewModel = CadenceListViewModel
> {
  name: string;
  header: string;
  value: (item?: CadenceUnarray<T['_rows$']['value']>, view?: T) => any;
  type: any;
  format?: string;
  action?: ICadenceActionMetadata;
  footer?: (view?: T) => any | any[];
  paginate?: boolean;
  // Useful features
  // includeInExport: boolean;
}
