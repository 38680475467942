<ng-container *ngIf="{ form: form$ | async } as view">
  <h5>Edit Activation</h5>

  <form *ngIf="view.form" [formGroup]="view.form">

    <mat-form-field class="w-100" appearance="fill">
      <mat-label>Floors to Display</mat-label>
      <mat-select formControlName="floorIds" [multiple]="true">
        <mat-option [value]="floor.id" *ngFor="let floor of floors">{{ floor.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-100" appearance="fill">
      <mat-label>Primary Display</mat-label>
      <mat-select formControlName="primaryLayout">
        <mat-option value="alpha">Alphabetical</mat-option>
        <mat-option value="list">Listings</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="d-flex align-items-stretch justify-content-center">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>X</mat-label>
        <input matInput formControlName="floorX">
      </mat-form-field>
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Y</mat-label>
        <input matInput formControlName="floorY">
      </mat-form-field>
    </div>

    <button mat-raised-button color="primary"
            (click)="handleUpdate(view.form)"
            [disabled]="!view.form.enabled || view.form.invalid">Update</button>

  </form>

</ng-container>
