import { NgModule } from '@angular/core';
import { ProductCategoryModule } from 'esuite-dashboard/app/pages/product-category/product-category.module';
import { StartModule } from 'esuite-dashboard/app/pages/start/start.module';
import { OrganizationModule } from 'esuite-dashboard/app/pages/organization/organization.module';
import { DigitalSignageDashboardModule } from 'esuite-dashboard/app/pages/digital-signage/digital-signage-dashboard.module';
import { SelfCheckoutModule } from 'esuite-dashboard/app/pages/self-checkout/self-checkout-page.module';
import { PaymentGatewayModule } from 'esuite-dashboard/app/pages/payment-gateway/payment-gateway.module';

import { SelfCheckoutIntegrationModule } from 'esuite-dashboard/app/pages/self-checkout-integration/self-checkout-integration.module';
import { RestAppModule } from 'esuite-dashboard/app/pages/rest-app/rest-app.module';
import { MediaCollectionModule } from 'esuite-dashboard/app/pages/media-collection/media-collection.module';
import { PhotoboothModule } from 'esuite-dashboard/app/pages/photobooth/photobooth.module';
import { SettingsModule } from 'esuite-dashboard/app/pages/settings/settings.module';
import { SpinToWinModule } from 'esuite-dashboard/app/pages/spin-to-win/spin-to-win.module';
import { ShopifyCheckoutModule } from 'esuite-dashboard/app/pages/shopify-checkout/shopify-checkout.module';
import { PluginModule } from 'esuite-dashboard/app/pages/plugin/plugin.module';
import { DirectoryModule } from 'esuite-dashboard/app/pages/v2/directory/directory.module';
import { LoginModule } from 'esuite-dashboard/app/pages/login/login.module';
import { KiosksModule } from 'esuite-dashboard/app/pages/v2/kiosks/kiosks.module';

@NgModule({
  declarations: [],
  imports: [
    StartModule,
    RestAppModule,
    OrganizationModule,
    SelfCheckoutModule,
    SelfCheckoutIntegrationModule,
    ShopifyCheckoutModule,
    ProductCategoryModule,
    DigitalSignageDashboardModule,
    PaymentGatewayModule,
    MediaCollectionModule,
    PhotoboothModule,
    SpinToWinModule,
    SettingsModule,
    PluginModule,
    DirectoryModule,
    LoginModule,

    // v2 Modules
    KiosksModule,
  ],
})
export class PagesModule {}
