import { Component, Input, OnInit } from '@angular/core';
import { EsuiteApp, KioskResponse } from 'esuite-client';
import { KioskPreviewService } from '../../services/kiosk-preview.service';
import { MatDialog } from '@angular/material/dialog';
import { isNumber } from 'lodash';
import { getEsuiteApi } from '../../../api/esuite-api';

@Component({
  selector: 'app-kiosk-preview-launcher',
  templateUrl: './kiosk-preview-launcher.component.html',
  styleUrls: ['./kiosk-preview-launcher.component.scss'],
})
export class KioskPreviewLauncherComponent implements OnInit {
  private localApp: EsuiteApp;
  private localId: number;
  loading = false;
  kiosks: KioskResponse[] = [];
  @Input()
  get app(): EsuiteApp {
    return this.localApp;
  }
  set app(a: EsuiteApp) {
    this.localApp = a;
  }

  @Input()
  get id(): number {
    return this.localId;
  }
  set id(d: number) {
    this.localId = d;
  }

  constructor(
    private matDialog: MatDialog,
    private previewService: KioskPreviewService
  ) {}

  async ngOnInit(): Promise<void> {

  }

  async launchPreview(kiosk: KioskResponse): Promise<void> {
    if (kiosk && this.app && isNumber(this.id)) {
      this.previewService.previewKiosk(kiosk, {
        app: this.app,
        id: this.id,
      });
    }
  }

  async loadKiosks() {
    if (!this.kiosks.length) {
      this.loading = true;
      this.kiosks = await getEsuiteApi().kiosk.list();
      this.loading = false;
    }
  }
}
