/*
 * CheckoutDeleteForm
 * Generated delete resource from cadence/scripts/generate
 */

import {
  CadenceFormViewModel,
  CadenceView,
  CadenceFormAction,
  CadenceViewEvent,
  cadenceRouter,
  CadenceFormControl,
} from 'cadence';
import { CheckoutListView } from './checkout-list';
import { getEsuiteApi } from '../../../app/api/esuite-api';

interface ICheckoutDeleteFormParams {
  id: number;
}

@CadenceView({
  title: 'Delete Checkout',
  viewType: 'dialog',
})
export class CheckoutDeleteForm extends CadenceFormViewModel<ICheckoutDeleteFormParams> {
  /*
   * Form Controls
   */
  @CadenceFormControl({
    default: (view) => view._params.id,
    hidden: true,
  })
  id: number;

  /*
   * Form Actions
   */
  @CadenceFormAction({
    label: 'Delete',
  })
  async delete(): Promise<void> {
    return getEsuiteApi().checkout.remove(this._params.id);
  }

  /*
   * Form Events
   */
  @CadenceViewEvent('action.delete')
  afterDelete(): void {
    this._postEvent('dismissDialog');
  }
}
